import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDatabase,
  useStorage,
} from 'reactfire';
import {
  onValue,
  ref as databaseRef,
  get,
} from 'firebase/database';
import {
  ref as storageRef,
  getDownloadURL,
  listAll,
} from 'firebase/storage';

import {
  getCanvas,
  getEnvironments,
  getMaps,
  getMapsLoaded,
  getScene,
  loadObject,
  setMaps,
  setEnvironments,
  updateBackground,
  updateLights,
  updateMaterials,
  updateMaterialProperties,
  updateNotes,
} from 'modules/redux/threejs';
import { getVersion } from 'modules/redux/storage';

export default function useThreejs({ loadObjectManually = false } = {}) {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const navigate = useNavigate();

  const canvas = useSelector(getCanvas);
  const environments = useSelector(getEnvironments);
  const maps = useSelector(getMaps);
  const mapsLoaded = useSelector(getMapsLoaded);
  const scene = useSelector(getScene);

  const version = useSelector(getVersion);

  const loaderRef = React.useRef();

  const handleLoadObject = async () => {
    const objectRef = storageRef(storage, `objects/${version}`);

    const { url, type, originalType } = await new Promise(async (resolve, reject) => {
      try {
        let ref = await listAll(objectRef);

        // if (ref.prefixes[0]) ref = await listAll(ref.prefixes[0]);

        let _url, _type, name;
        if (ref.items[0]) {
          _url = await getDownloadURL(ref.items[0]);
          _type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 1].toLowerCase();
          name = await get(databaseRef(database, `objects/${version}/name`))
            .then((snapshot) => snapshot.val() || null)
            .catch(() => null);
        }

        resolve({
          url: _url,
          type: _type,
          originalType: name ? name.split('.')[name.split('.').length - 1].toLowerCase() : null,
        });
      } catch (e) { resolve({}) };
    });

    const environmentsDatabaseRef = databaseRef(database, `environments/${version}`);
    onValue(environmentsDatabaseRef, async () => {
      const environmentsRef = storageRef(storage, `environments/${version}`);

      const environments = await new Promise(async (resolve, reject) => {
        try {
          const ref = await listAll(environmentsRef);

          const _environments = await Promise.all(ref.prefixes.map((uuid) => listAll(uuid)
            .then((file) => getDownloadURL(file.items[0])
              .then((url) => ({ url, name: file.items[0].name, uuid: uuid.name, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() }))
              .catch(() => null))
          ))
            .then((__environments) => __environments.filter((environment) => environment));

          // const _environments = await Promise.all(ref.items.map((ref) => getDownloadURL(ref)
          //   .then((url) => ({ url, name: ref.name }))
          //   .catch(() => null)
          // ))
          //   .then((__environments) => __environments.filter((environment) => environment));

          resolve(_environments);
        } catch (e) { resolve([]) };
      });

      dispatch(setEnvironments({ environments, canvasId: canvas?.id }));
    });

    const mapsDatabaseRef = databaseRef(database, `maps/${version}`);
    onValue(mapsDatabaseRef, async (snapshot) => {
      const mapsRef = storageRef(storage, `maps/${version}`);

      const storageMaps = await new Promise(async (resolve, reject) => {
        try {
          const ref = await listAll(mapsRef);

          const _storageMaps = await Promise.all(ref.prefixes.map((map) => listAll(map)
            .then((file) => getDownloadURL(file.items[0])
              .then((url) => [map.name, ({ url, ref: file.items[0], uuid: map.name, name: file.items[0].name, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() })])
            )
          ))
            .then((maps) => Object.fromEntries(maps));

          // const _storageMaps = await Promise.all(ref.prefixes.map((key) => listAll(key)
          //   .then((maps) => Promise.all(maps.prefixes.map((map) => listAll(map)
          //     .then((file) => getDownloadURL(file.items[0])
          //       .then((url) => ({ url, uuid: map.name, name: file.items[0].name, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() }))
          //     )
          //   )))
          //   .then((maps) => [key.name, maps.filter((map) => map)])
          //   // .then((maps) => [key.name, Object.fromEntries(maps.filter((map) => map).map((map) => [map.uuid, map]))])
          // ))
          //   .then((maps) => Object.fromEntries(maps))
          
          resolve(_storageMaps);
        } catch (e) { resolve([]) };
      });

      const databaseMaps = { ...snapshot.val() || {} };

      const _maps = Object.fromEntries(Object.entries(databaseMaps).map(([id, _maps]) =>
        [id, Object.fromEntries(Object.entries(_maps).map(([key, __maps]) =>
          [key, Object.keys(__maps).map((uuid) => storageMaps[uuid])]
        ))]
      ));

      dispatch(setMaps(_maps));
    });

    if (url && type) {
      if (!loadObjectManually) {
        dispatch(loadObject({
          url,
          type,
          originalType,
          onProgress: ({ loaded, total }) => loaderRef?.current?.setValue(50 * (loaded / total)),
        }));
      }
    } else {
      navigate('/');
    }
  };

  const [loadedObject, setLoadedObject] = React.useState(null);

  React.useEffect(() => {
    if (!version) setLoadedObject(null);

    if (canvas && version && version != loadedObject) {
      handleLoadObject();
      setLoadedObject(version);
    }
  }, [canvas, version, loadedObject]);

  const [notesUnsubscribe, setNotesUnsubscribe] = React.useState(null);

  React.useEffect(() => {
    if (!scene || !version) return;

    if (notesUnsubscribe) notesUnsubscribe.unsubscribe();

    const notesRef = databaseRef(database, `objects/${version}/reviews`);
    const unsubscribe = onValue(notesRef, async (snapshot) => {
      const notes = snapshot.val() || {};

      dispatch(updateNotes(notes));
    });

    setNotesUnsubscribe({ unsubscribe });

    return unsubscribe;
  }, [scene, version]);

  const [mapData, setMapData] = React.useState({ indices: null, list: null });
  const [mapsUnsubscribe, setMapsUnsubscribe] = React.useState(null);

  React.useEffect(() => {
    if (!scene || !maps || !version) return;
    
    if (mapsUnsubscribe) mapsUnsubscribe.unsubscribe();

    const mapsRef = databaseRef(database, `objects/${version}/maps`);

    const unsubscribe = onValue(mapsRef, async (snapshot) => {
      const selectedMaps = { ...snapshot.val() || {} };

      let mapList;
      if (loaderRef?.current) {
        mapList = Object.values(selectedMaps).reduce((idArr, idMaps) =>
          [...idArr, ...Object.values(idMaps).reduce((keyArr, uuid) =>
            [...keyArr, uuid]
            , [])]
          , []);
      }

      const mapIndices = Object.fromEntries(Object.entries(selectedMaps).map(([id, _maps]) =>
        [id, Object.fromEntries(Object.entries(_maps).map(([key, uuid]) =>
          [key, (maps?.[id]?.[key] || []).indexOf((maps?.[id]?.[key] || []).find((map) => map?.uuid == uuid))]
        )
          .filter(([_, index]) => index !== null && index !== undefined))]
      ));

      // if (!_.isEqual(mapData.indices, mapIndices) || (mapData.indices && !mapsLoaded)) {
      setMapData({ indices: mapIndices, list: mapList });
      // }
    });

    setMapsUnsubscribe({ unsubscribe });

    return unsubscribe;
  }, [scene, maps, mapsLoaded, version]);

  React.useEffect(() => {
    if (mapsLoaded && loadObjectManually) return;

    if (mapData.indices) dispatch(updateMaterials({ selectedMaps: mapData.indices, mapList: mapData.mapList, loader: loaderRef?.current }));
  }, [mapData]);

  const [otherUnsubscribe, setOtherUnsubscribe] = React.useState(null);

  React.useEffect(() => {
    if (!scene || !environments || !version) return;

    if (otherUnsubscribe) otherUnsubscribe.unsubscribe();

    const propertiesRef = databaseRef(database, `objects/${version}/properties`);
    const propertiesUnsubscribe = onValue(propertiesRef, async (snapshot) => {

      const properties = { ...snapshot.val() || {} };

      dispatch(updateMaterialProperties(properties));
    });

    const lightsRef = databaseRef(database, `objects/${version}/lights`);
    const lightsUnsubscribe = onValue(lightsRef, async (snapshot) => {
      const lights = { ...snapshot.val() || {} };

      dispatch(updateLights(lights));
    });

    const backgroundRef = databaseRef(database, `objects/${version}/background`);
    const backgroundUnsubscribe = onValue(backgroundRef, async (snapshot) => {
      const background = snapshot.val() || '#e5e5e5';

      dispatch(updateBackground(background));
    });

    const unsubscribe = () => {
      propertiesUnsubscribe();
      lightsUnsubscribe();
      backgroundUnsubscribe();
    };

    setOtherUnsubscribe({ unsubscribe });

    return unsubscribe;
  }, [scene, environments, version]);

  return { loaderRef };
}