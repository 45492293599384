import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDatabase, useUser } from "reactfire";
import { ref as databaseRef, get } from "firebase/database";
import { useChat } from "@chatscope/use-chat";
import {
  CreditCardIcon,
} from "lucide-react"
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';


import { Message, UserData } from "@/app/chatdata";
import { cn } from "@/lib/utils";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button"
import { AnimatePresence, motion } from "framer-motion";

import { set } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import { getImages, getUsers } from '@/modules/redux/storage';
import { clientId as payPalClientId } from '@/config/paypal';

import { uuid as generateUuid } from '@/modules/utils';

export function ChatList({
  srcs = {},
  updateChat = () => { },
}) {

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const database = useDatabase();

  const { data: user } = useUser();

  const images = useSelector(getImages);
  const users = useSelector(getUsers);

  const {
    currentMessages, conversations, activeConversation, setActiveConversation, sendMessage, getUser, currentMessage, setCurrentMessage,
    sendTyping, setCurrentUser, getConversation, addMessage, messages,
  } = useChat();

  const messagesContainerRef = React.useRef(null);

  const cMessages = messages[activeConversation?.id]?.reduce((arr, g) => arr.concat(g.messages), []) || [];

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [cMessages]);

  const handleImageClick = async (m) => {
    const src = srcs[`comments/${m.content.object}/${m.content.uuid}/${m.content.image}`];

    const image = await fetch(src.url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = src.name;
    link.click();

    URL.revokeObjectURL(imageURL);
    link.remove();
  };

  const handleLinkClick = (m) => {
    navigate(
      m.content.link.split(window.location.origin)[1],
      { state: { back: pathname.split('/')[1] != 'messages' ? `/${pathname.split('/')[1]}/messsages` : '/messages' } },
    );
  };

  const handlePaymentClick = (m) => {
    if (m.content.checkoutSession.receiptUrl) {
      window.open(m.content.checkoutSession.receiptUrl);
    }
    else if (m.content.checkoutSession.url) {
      window.open(m.content.checkoutSession.url);
    }
  };

  const handleCreateOrder = async (m) => m.content.checkoutSession.orderId;

  const handleOnApprove = async (m, { orderID, paymentID }, actions) => {
    const { data } = await httpsCallable('createCheckoutSession')({
      orderId: orderID,
    });

    if (data.error === 'INSTRUMENT_DECLINED') return actions.restart();

    if (data.details?.[0]) return alert(data.details[0].description);

    if (data.status == 'COMPLETED') {
      const { object, uuid, tags } = activeConversation.data;
      const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

      const ref = `messages/${uuid}/${tag}s/${object.uuid}`;

      const messages = await get(databaseRef(database, ref)).then((snapshot) => snapshot.val() || []);

      await set(databaseRef(database, `${ref}/${messages.length}`), {
        checkoutSession: {
          paymentId: paymentID,
          amount: m.content.checkoutSession.amount,
          provider: 'paypal',
        },
        note: m.content.note,
        timestamp: Date.now(),
        user: uuid,
        unread: true,
      });

      updateChat();

      const from = users[uuid];
      const to = users[m.content.user];

      const sendEmailNotification = await get(databaseRef(database, `users/${to.uid}/notifications/payment`)).then((snapshot) => !!snapshot.val());

      if (sendEmailNotification) {
        const { data: { token: emailToken } } = await httpsCallable('encrypt')({
          public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
          payload: { [tag]: { object: object.uuid, user: from.uid }, timestamp: Date.now() },
        }).catch((e) => ({ data: {} }));

        await httpsCallable('sendEmail')({
          from: { name: 'Modelsend', email: 'noreply@news.modelsend.com' },
          to: {
            name: to.displayName,
            email: to.email,
          },
          subject: `Your payment request was fulfilled`,
          html: `
          <div>
            <p>Hello,</p>
            <p>Your payment request was fulfilled by ${from.displayName || from.email}. <a href="${window.location.origin}/messages?token=${emailToken}">View in Modelsend.</a></p>
            <p>Thanks,</p>
            <p>Your Modelsend team</p>
          </div>`,
        }).catch(() => null);
      }
    }
  };

  return (
    <div className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col">
      <div
        ref={messagesContainerRef}
        className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col"
      >
        <AnimatePresence>
          {currentMessages.map((g) => g.messages
            .reduce((arr, m) => arr.concat(m), [])
            .filter((m) => !Object.keys(m.content.archived || {}).includes(user?.uid))
            .map((m, i) => <motion.div
              key={m.id}
              layout
              initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
              animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
              exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
              transition={{
                opacity: { duration: 0.1 },
                layout: {
                  type: "spring",
                  bounce: 0.3,
                  duration: i * 0.05 + 0.2,
                },
              }}
              style={{
                originX: 0.5,
                originY: 0.5,
              }}
              className={cn(
                "flex flex-col gap-2 p-4 whitespace-pre-wrap",
                m.direction == 'outgoing' ? "items-end" : "items-start"
              )}
            >
              <div className="flex gap-3 items-center">
                {m.direction == 'incoming' && (
                  <Avatar className="flex justify-center items-center">
                    <AvatarImage
                      src={images[m.senderId]?.url}
                      alt={users[m.senderId]?.displayName}
                      width={6}
                      height={6}
                      className="object-cover"
                    />
                  </Avatar>
                )}
                {m.content.image ?
                  <Button
                    variant="link"
                    onClick={() => handleImageClick(m)}
                    className="bg-accent p-3 rounded-md max-w-xs">
                    <div className="truncate">{srcs[`comments/${m.content.object}/${m.content.uuid}/${m.content.image}`]?.name}</div>
                  </Button> :
                  m.content.link ?
                    <Button
                      variant="link"
                      onClick={() => handleLinkClick(m)}
                      className="bg-accent p-3 rounded-md max-w-xs">
                      <span className="truncate">{m.content.link}</span>
                    </Button> :
                    m.content.checkoutSession ?
                      <div className="bg-accent p-3 rounded-md max-w-xs truncate">
                        <div>{(m.content.checkoutSession.provider == 'stripe' && m.content.checkoutSession.receiptUrl && !m.content.checkoutSession.url) ||
                          (m.content.checkoutSession.provider == 'paypal' && m.content.checkoutSession.paymentId) ? 'Payment successful' : 'Payment Request'}</div>
                        <div>{`$${parseFloat(m.content.checkoutSession.amount).toFixed(2)}`}</div>
                        {m.content.note && <div>{m.content.note}</div>}
                        <div className="mt-2"></div>
                        {(m.content.checkoutSession.url || m.content.checkoutSession.receiptUrl || m.content.checkoutSession.orderId || m.content.checkoutSession.paymentId || m.content.user == user?.uid) &&
                          (m.content.checkoutSession.provider != 'paypal' ||
                            !!m.content.checkoutSession.paymentId ||
                            (!!m.content.checkoutSession.orderId && cMessages.find((_m) => _m.content.checkoutSession?.paymentId && _m.content.checkoutSession?.paymentId == m.content.checkoutSession?.orderId)) ||
                            m.content.user == user?.uid) ?
                          <Button
                            disabled={m.content.checkoutSession.url ? cMessages.find((_m) => _m.content.checkoutSession?.id == m.content.checkoutSession?.id && _m.content.checkoutSession?.receiptUrl) || m.content.user == user?.uid : m.content.checkoutSession.provider == 'paypal'}
                            onClick={() => handlePaymentClick(m)}>
                            <CreditCardIcon className="mr-2 w-[18px] h-[18px]" />
                            {m.content.checkoutSession.receiptUrl ?
                              'View payment' :
                              (m.content.checkoutSession.provider == 'stripe' && m.content.checkoutSession.url &&
                                !cMessages.find((_m) => _m.content.checkoutSession?.id == m.content.checkoutSession?.id && _m.content.checkoutSession?.receiptUrl)) ||
                                (m.content.checkoutSession.provider == 'paypal' && m.content.checkoutSession.orderId) ? 'View payment link' :
                                'Payment successful'}
                          </Button> :
                          <PayPalScriptProvider options={{ clientId: payPalClientId, intent: 'capture' }}>
                            <PayPalButtons
                              style={{ layout: 'vertical' }}
                              forceReRender={[]}
                              onError={(e) => alert(e)}
                              createOrder={() => handleCreateOrder(m)}
                              onApprove={(o, a) => handleOnApprove(m, o, a)}
                            />
                          </PayPalScriptProvider>}
                      </div> :
                      <div className="bg-accent p-3 rounded-md max-w-xs break-words">{m.content.text}</div>
                }
                {m.direction == 'outgoing' && (
                  <Avatar className="flex justify-center items-center">
                    <AvatarImage
                      src={images[m.senderId]?.url}
                      alt={users[m.senderId]?.displayName}
                      width={6}
                      height={6}
                      className="object-cover"
                    />
                  </Avatar>
                )}
              </div>
            </motion.div>))}
        </AnimatePresence>
      </div>
    </div>
  );
}
