import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth, useSigninCheck, useUser } from 'reactfire';
import {
    signInWithEmailLink,
    isSignInWithEmailLink,
} from 'firebase/auth';
import {
    AutoDraft,
    ChatProvider,
} from "@chatscope/use-chat";
import {
    LoaderCircle,
} from "lucide-react"

import { tabConfig } from "@/config/tab-config"

import RootLayout from '@/app/layout/buyer';
import Cart from '@/app/cart/cart';
import Forms from '@/app/forms/forms';
import FormsLayout from '@/app/forms/layout';
import FormsAccount from '@/app/forms/account/account';
import Mail from '@/app/mail/mail';
import { ProductTabs } from "@/components/products/products-tab";
import Products from '@/app/products/products';
import Tasks from '@/app/tasks/tasks';
import { ResizablePanelGroup } from "@/components/ui/resizable"

import { generateUsernameForNewUser } from '@/components/signin-dlg';
import useChat from '@/modules/useChat';
import useDownloadRecentPurchases from '@/modules/useDownloadRecentPurchases';
import useMarketplace from '@/modules/useMarketplace';
import useUploadHandler from '@/modules/useUploadHandler';
import useProjects from '@/modules/useProjects';
import useQuery from '@/modules/useQuery';
import useRedirect from '@/modules/useRedirect';
import useThreejs from '@/modules/useThreejs';
import useWebsite from '@/modules/useWebsite';

import { setVersion } from '@/modules/redux/storage';
import { fetchUser, getUser } from '@/modules/redux/user';
import { getPayloadFromToken } from '@/modules/utils';

function BuyerPage() {
    const dispatch = useDispatch();

    const auth = useAuth();

    const { data: user } = useUser();

    const { data: signInCheckResult } = useSigninCheck();

    const { search } = useLocation();

    const navigate = useNavigate();

    const { tab, username } = useParams();

    const { seller: { initiated } = {} } = useSelector(getUser);
    
    const onEmailLinkSignIn = async () => {
        try {
            const credential = await signInWithEmailLink(auth, getPayloadFromToken().email, window.location.href);

            await generateUsernameForNewUser(credential.user);
        } catch (e) { console.log(e) }
    };

    React.useEffect(() => {
        if (!signInCheckResult.signedIn &&
            isSignInWithEmailLink(auth, window.location.href) &&
            getPayloadFromToken().email)
            onEmailLinkSignIn();
    }, []);

    React.useEffect(() => { dispatch(fetchUser(true)) }, [user]);

    useDownloadRecentPurchases();

    const queryProps = useQuery();

    const productTab = React.useState(tabConfig.productTab[0]);

    const marketplaceProps = useMarketplace({ ...queryProps, id: [productTab[0].value] });

    const { scrollProps: { loading, hasNextPage, loadingRef } } = marketplaceProps;

    const threejsProps = useThreejs({ loadObjectManually: true });

    const uploadHandlerProps = useUploadHandler();

    const websiteProps = useWebsite();

    const { initialized: websiteInitialized, objects: websiteObjects, website: [, fetchWebsite] } = websiteProps;

    const projectsProps = useProjects();

    const { objects: projectObjects, projects: [, fetchProjects] } = projectsProps;

    const { initialized: redirectInitialized, shouldRedirect } = useRedirect();

    const [isSellerRedirectCheckDone, setSellerRedirectCheckDone] = React.useState(false);

    React.useEffect(() => {
        if (!signInCheckResult.signedIn && !username && shouldRedirect) navigate('/');
        if (!signInCheckResult.signedIn && ['account', 'messages', 'projects', 'purchases', 'following', 'cart'].includes(tab))
            navigate('/');
        
        if (initiated && ['account', 'messages', 'projects', 'purchases', 'following'].includes(tab)) {
            navigate(`/seller/${tab}${search}`);
        }
        else if ([undefined, 'cart'].includes(tab) || (!initiated && initiated !== undefined)) {
            setSellerRedirectCheckDone(true);
        }
    
        dispatch(setVersion(null))
    }, [tab, shouldRedirect, initiated]);

    const chatProps = useChat({
        projectObjects,
        websiteObjects,
        fetchProjects,
        fetchWebsite,
    });

    const { serviceFactory, storage } = chatProps;

    return (
        [undefined, 'account', 'messages', 'projects', 'purchases', 'following', 'cart'].includes(tab) ?
            <ChatProvider serviceFactory={serviceFactory} storage={storage} config={{
                typingThrottleTime: 250,
                typingDebounceTime: 900,
                debounceTyping: true,
                autoDraft: AutoDraft.Save | AutoDraft.Restore
            }}>
                <RootLayout
                    loading={!isSellerRedirectCheckDone}
                    {...marketplaceProps}
                    {...queryProps}>
                    {username ?
                        websiteInitialized && <Products
                            isPublic
                            {...chatProps}
                            {...threejsProps}
                            {...queryProps}
                            {...websiteProps} /> :
                        tab == 'account' ?
                            <FormsLayout {...websiteProps} /> :
                            tab == 'messages' ?
                                <div className="md:container px-4 h-full">
                                    <ResizablePanelGroup
                                        direction="horizontal"
                                        className="flex items-stretch border">
                                        <Mail
                                            {...chatProps}
                                            {...projectsProps}
                                            {...uploadHandlerProps} />
                                    </ResizablePanelGroup>
                                </div> :
                                ['projects', 'purchases', 'following'].includes(tab) ?
                                    <Tasks
                                        {...projectsProps}
                                        {...uploadHandlerProps} /> :
                                    tab == 'cart' ?
                                        <Cart /> :
                                        <div className="md:container px-4 relative">
                                            <ProductTabs
                                                isPublic
                                                tab={productTab}
                                                tabs={tabConfig.productTab}
                                                {...chatProps}
                                                {...marketplaceProps}
                                                {...queryProps} />
                                            {(loading || hasNextPage) && (
                                                <div ref={loadingRef} className="w-full flex flex-row justify-center items-center py-4">
                                                    <LoaderCircle className="animate-spin w-6 h-6" />
                                                </div>
                                            )}
                                        </div>
                    }
                </RootLayout>
            </ChatProvider> :
            redirectInitialized && websiteInitialized &&
            <Products
                isPublic
                {...chatProps}
                {...threejsProps}
                {...queryProps}
                {...websiteProps} />
    );
}

export default BuyerPage;