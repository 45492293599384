import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import {
  ref as databaseRef,
} from 'firebase/database';
import {
  ref as storageRef,
  uploadBytes,
  deleteObject,
  getMetadata,
} from 'firebase/storage';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  IconButton,
  Input,
  Menu,
  Paper,
  Slider,
  Typography,
} from '@mui/material';
import {
  Add,
  Close,
  KeyboardArrowDown,
  Public,
  MoreVert,
  VisibilityOutlined,
  LightModeOutlined,
  PanToolOutlined,
} from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import {
  Color,
  Vector3,
  MathUtils,
} from 'three';
import { isMobile } from 'react-device-detect';
import { useDebouncedCallback } from 'use-debounce';

import { set, increment, update } from 'modules/firebase/database';
import { getVersion } from 'modules/redux/storage';
import {
  getLights,
  updateLight,
  addLight,
  removeLight,
  getEnvironments,
  getIncludeDefaultEnvMap,
  getShowBackground,
  toggleBackground,
  loadEnvironment,
  setLight,
  getType,
} from 'modules/redux/threejs';
import { getPayloadFromToken, uuid } from 'modules/utils';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
});

const lightProps = {
  'AmbientLight': { key: 'ambient', label: 'Ambient' },
  'DirectionalLight': { key: 'directional', label: 'Directional' },
  'Environment': { key: 'environment', label: 'Environment' },
  'SpotLight': { key: 'spot', label: 'Spot' },
  'PointLight': { key: 'point', label: 'Point' },
  'HemisphereLight': { key: 'hemisphere', label: 'Hemisphere' },
};

const Light = ({ light, editable, sx, menuSx }) => {
  const dispatch = useDispatch();

  const ref = React.useRef();

  const [open, setOpen] = React.useState(null);

  const classes = useStyles();

  const id = lightProps[light.type].key;
  const label = lightProps[light.type].label;

  if (light.type == 'Environment') return null;

  return (
    <Box
      ref={ref}
      sx={{
        width: isMobile ? 'calc(100vw - 20px)' : 250,
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // paddingLeft: '30px',
        padding: '0px 10px',
      }}>
      <Box
        sx={{

        }}>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '3px 0px',
          }}>
          <Box sx={{ width: isMobile ? '100vw' : 150, color: 'primary.dark' }}>
            <Typography variant='body2'>{label}</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Button
              onClick={() => setOpen(open != 'color' ? 'color' : null)}
              sx={{
                width: 40,
                height: 30,
                minWidth: 0,
                backgroundColor: `#${light.color.getHexString()}`,
                '&:hover': {
                  backgroundColor: `#${light.color.getHexString()}`,
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '0px',
                borderRadius: '10px',
              }}></Button>
            {/* <Paper
              elevation={1}
              sx={{
                width: 40,
                height: 30,
                backgroundColor: 'secondary.main',
                borderRadius: '10px',
                // margin: '0px 10px',
                padding: '0px',
              }}>
              <Input
                disableUnderline
                value={light.intensity}
                size='small'
                className={classes.input}
                sx={{
                  // width: 15,
                  color: 'primary.dark',
                  // backgroundColor: 'secondary.main',
                  // borderRadius: '4px',
                  // margin: '0px 10px',
                  padding: '4px',
                  '&:focused': {
                    borderWidth: 0,
                  },
                  fontSize: '.875rem',
                }}
                inputProps={{
                  step: .1,
                  type: 'number',
                  style: {
                    padding: '0px',
                    textAlign: 'center',
                  },
                }}
                onChange={(e) => dispatch(updateLight({ light, updates: { intensity: e.target.value } }))}
              />
            </Paper> */}
            <IconButton
              onClick={() => { setOpen(open != 'intensity' ? 'intensity' : null) }}
              sx={{
                width: 40,
                height: 30,
                backgroundColor: 'secondary.main',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '10px',
              }}>
              <LightModeOutlined fontSize='small' />
            </IconButton>
            <IconButton
              onClick={() => { if (id != 'ambient') setOpen(open != 'position' ? 'position' : null) }}
              sx={{
                width: 40,
                height: 30,
                backgroundColor: 'secondary.main',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '10px',
                visibility: id == 'ambient' ? 'hidden' : '',
              }}>
              <Public fontSize='small' />
            </IconButton>
          </Box>
          <IconButton
            onClick={() => setOpen(open != 'additional' ? 'additional' : null)}
            sx={{
              backgroundColor: 'secondary.dark',
              color: 'primary.dark',
              '&:hover': {
                backgroundColor: 'secondary.main',
                opacity: .6,
                boxShadow: 'none',
              },
              padding: '4px 0px',
              borderRadius: '2px',
              visibility: !['spot', 'point', 'hemisphere'].includes(id) ? 'hidden' : '',
            }}>
            <MoreVert fontSize='small' />
          </IconButton>
          {editable &&
            <IconButton
              onClick={() => dispatch(removeLight(light))}
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '2px',
                // visibility: (!material?.material?.userData?.textures[id].index && material?.material?.userData?.textures[id].index != 0) ? 'hidden' : '',
              }}>
              <Close sx={{ fontSize: 10 }} />
            </IconButton>
          }
        </Box>
        <Menu
          anchorEl={ref.current}
          open={open == 'color'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setOpen(null)}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.background',
              ...sx, ...menuSx,
            },
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 0px',
            }}>
            <SketchPicker
              color={`#${light.color.getHexString()}`}
              disableAlpha
              presetColors={[]}
              onChange={(e) => {
                dispatch(updateLight({ light, updates: { color: new Color(e.hex) } }));
              }}
            // onChangeComplete={(e) => {
            //   dispatch(updateLight({ light, updates: { color: new Color(e.hex) } }));
            // }}
            />
          </Box>
        </Menu>
        <Menu
          anchorEl={ref.current}
          open={open == 'position'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setOpen(null)}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.background',
              ...sx, ...menuSx,
            },
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: '5px 0px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Box sx={{ color: 'primary.dark', margin: '0px 8px' }}>
                <Typography variant='body2'>x</Typography>
              </Box>
              <Paper
                elevation={1}
                sx={{
                  width: 40,
                  height: 30,
                  backgroundColor: 'secondary.main',
                  borderRadius: '10px',
                  padding: '0px',
                }}>
                <Input
                  disableUnderline
                  value={light.position.x}
                  size='small'
                  className={classes.input}
                  sx={{
                    color: 'primary.dark',
                    padding: '4px',
                    '&:focused': {
                      borderWidth: 0,
                    },
                    fontSize: '.875rem',
                  }}
                  inputProps={{
                    step: .1,
                    type: 'number',
                    sx: {
                      padding: '0px',
                    },
                  }}
                  onChange={(e) => dispatch(updateLight({ light, updates: { position: new Vector3(e.target.value, light.position.y, light.position.z) } }))}
                />
              </Paper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Box sx={{ color: 'primary.dark', margin: '0px 8px' }}>
                <Typography variant='body2'>y</Typography>
              </Box>
              <Paper
                elevation={1}
                sx={{
                  width: 40,
                  height: 30,
                  backgroundColor: 'secondary.main',
                  borderRadius: '10px',
                  padding: '0px',
                }}>
                <Input
                  disableUnderline
                  value={light.position.y}
                  size='small'
                  className={classes.input}
                  sx={{
                    color: 'primary.dark',
                    padding: '4px',
                    '&:focused': {
                      borderWidth: 0,
                    },
                    fontSize: '.875rem',
                  }}
                  inputProps={{
                    step: .1,
                    type: 'number',
                    sx: {
                      padding: '0px',
                    },
                  }}
                  onChange={(e) => dispatch(updateLight({ light, updates: { position: new Vector3(light.position.x, e.target.value, light.position.z) } }))}
                />
              </Paper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Box sx={{ color: 'primary.dark', margin: '0px 8px' }}>
                <Typography variant='body2'>z</Typography>
              </Box>
              <Paper
                elevation={1}
                sx={{
                  width: 40,
                  height: 30,
                  backgroundColor: 'secondary.main',
                  borderRadius: '10px',
                  padding: '0px',
                }}>
                <Input
                  disableUnderline
                  value={light.position.z}
                  size='small'
                  className={classes.input}
                  sx={{
                    color: 'primary.dark',
                    padding: '4px',
                    '&:focused': {
                      borderWidth: 0,
                    },
                    fontSize: '.875rem',
                  }}
                  inputProps={{
                    step: .1,
                    type: 'number',
                    sx: {
                      padding: '0px',
                    },
                  }}
                  onChange={(e) => dispatch(updateLight({ light, updates: { position: new Vector3(light.position.x, light.position.y, e.target.value) } }))}
                />
              </Paper>
            </Box>
            <IconButton
              onClick={() => {
                dispatch(setLight(light));
                setOpen(null);
              }}
              sx={{
                width: 40,
                height: 30,
                backgroundColor: 'secondary.main',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '10px',
              }}>
              <PanToolOutlined fontSize='small' />
            </IconButton>
          </Box>
        </Menu>
        <Menu
          anchorEl={ref.current}
          open={open == 'intensity'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setOpen(null)}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.background',
              ...sx, ...menuSx,
            },
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: '5px 0px',
            }}>
            <Slider
              color='secondary'
              value={light.intensity * 2}
              onChange={(e, value) => dispatch(updateLight({ light, updates: { intensity: value / 2 } }))}
              sx={{
                color: 'secondary.light',
                width: 130,
                marginLeft: '10px',
              }}
            />
          </Box>
        </Menu>
        <Menu
          anchorEl={ref.current}
          open={open == 'additional'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setOpen(null)}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.background',
              ...sx, ...menuSx,
            },
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '3px 0px',
            }}>
            {['spot', 'point'].includes(id) &&
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0px',
                }}>
                <Box sx={{ width: 77, color: 'primary.dark' }}>
                  <Typography variant='body2'>Distance</Typography>
                </Box>
                <Paper
                  elevation={1}
                  sx={{
                    width: 107,
                    height: 30,
                    backgroundColor: 'secondary.main',
                    borderRadius: '10px',
                    padding: '0px 10px',
                  }}>
                  <Input
                    disableUnderline
                    value={light.distance}
                    size='small'
                    className={classes.input}
                    sx={{
                      color: 'primary.dark',
                      padding: '4px',
                      '&:focused': {
                        borderWidth: 0,
                      },
                      fontSize: '.875rem',
                    }}
                    inputProps={{
                      step: .1,
                      type: 'number',
                      sx: {
                        padding: '0px',
                        textAlign: 'center',
                      },
                    }}
                    onChange={(e) => dispatch(updateLight({ light, updates: { distance: e.target.value } }))}
                  />
                </Paper>
              </Box>
            }
            {id == 'spot' &&
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0px',
                }}>
                <Box sx={{ width: 77, color: 'primary.dark' }}>
                  <Typography variant='body2'>Angle</Typography>
                </Box>
                <Paper
                  elevation={1}
                  sx={{
                    width: 107,
                    height: 30,
                    backgroundColor: 'secondary.main',
                    borderRadius: '10px',
                    padding: '0px 10px',
                  }}>
                  <Input
                    disableUnderline
                    value={light.userData.angle}
                    size='small'
                    className={classes.input}
                    sx={{
                      color: 'primary.dark',
                      padding: '4px',
                      '&:focused': {
                        borderWidth: 0,
                      },
                      fontSize: '.875rem',
                    }}
                    inputProps={{
                      step: .1,
                      type: 'number',
                      sx: {
                        padding: '0px',
                        textAlign: 'center',
                      },
                    }}
                    onChange={(e) => dispatch(updateLight({ light, updates: { angle: e.target.value } }))}
                  />
                </Paper>
              </Box>
            }
            {['spot', 'point'].includes(id) &&
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0px',
                }}>
                <Box sx={{ width: 77, color: 'primary.dark' }}>
                  <Typography variant='body2'>Decay</Typography>
                </Box>
                <Paper
                  elevation={1}
                  sx={{
                    width: 107,
                    height: 30,
                    backgroundColor: 'secondary.main',
                    borderRadius: '10px',
                    padding: '0px 10px',
                  }}>
                  <Input
                    disableUnderline
                    value={light.decay}
                    size='small'
                    className={classes.input}
                    sx={{
                      color: 'primary.dark',
                      padding: '4px',
                      '&:focused': {
                        borderWidth: 0,
                      },
                      fontSize: '.875rem',
                    }}
                    inputProps={{
                      step: .1,
                      type: 'number',
                      sx: {
                        padding: '0px',
                        textAlign: 'center',
                      },
                    }}
                    onChange={(e) => dispatch(updateLight({ light, updates: { decay: e.target.value } }))}
                  />
                </Paper>
              </Box>
            }
            {id == 'hemisphere' &&
              <Box
                sx={{
                  display: 'flex',
                  margin: '5px 0px',
                }}>
                <Box sx={{ width: 77, color: 'primary.dark' }}>
                  <Typography variant='body2'>Ground</Typography>
                </Box>
                <SketchPicker
                  color={`#${light.groundColor.getHexString()}`}
                  disableAlpha
                  presetColors={[]}
                  onChange={(e) => {
                    dispatch(updateLight({ light, updates: { groundColor: new Color(e.hex) } }));
                  }}
                />
              </Box>
            }
          </Box>
        </Menu>
      </Box>
    </Box>
  );
};

export default function Lighting({
  editable = false,
  environmentsEditable = false,
  sx = {},
  menuSx = {},
}) {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const lights = useSelector(getLights);
  const environments = useSelector(getEnvironments);
  const includeDefaultEnvMap = useSelector(getIncludeDefaultEnvMap);
  const showBackground = useSelector(getShowBackground);
  const type = useSelector(getType);

  const version = useSelector(getVersion);

  const [open, setOpen] = React.useState(false);
  const ref = React.useRef();

  const environmentsRef = React.useRef();
  const environmentInputRef = React.useRef();
  const [environmentsOpen, setEnvironmentsOpen] = React.useState(false);

  const environment = environments?.find((_environment) => _environment.uuid == Object.entries(lights || {}).find(([_, light]) => light.type == 'Environment')?.[0]);

  const onLightUpdate = useDebouncedCallback((newLights) => {
    const _lights = Object.fromEntries(Object.entries(newLights).map(([uuid, light]) => {
      switch (light.type) {
        case 'AmbientLight':
          return [uuid, { type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity }];
        case 'DirectionalLight':
          return [uuid, {
            type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
            position: { x: light.position.x, y: light.position.y, z: light.position.z },
          }];
        case 'Environment':
          return [uuid, { type: light.type, uuid: light.uuid, }];
        case 'SpotLight':
          return [uuid, {
            type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
            position: { x: light.position.x, y: light.position.y, z: light.position.z },
            distance: light.distance, angle: light.userData.angle, decay: light.decay,
          }];
        case 'PointLight':
          return [uuid, {
            type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
            position: { x: light.position.x, y: light.position.y, z: light.position.z },
            distance: light.distance, decay: light.decay,
          }];
        case 'HemisphereLight':
          return [uuid, {
            type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
            position: { x: light.position.x, y: light.position.y, z: light.position.z },
            groundColor: `#${light.groundColor.getHexString()}`,
          }];
        default:
          return [uuid, {}];
      }
    }));

    set(databaseRef(database, `objects/${version}/lights`), _lights);
    set(databaseRef(database, `objects/${version}/needsUpdate`), true);
  }, 250);

  React.useEffect(() => {
    onLightUpdate.cancel();

    if (lights) onLightUpdate(lights);
  }, [lights]);

  return (
    <>
      <Box
        ref={ref}
        sx={sx}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // padding: '3px 0px',
            padding: '0px 10px',
          }}>
          <Typography variant='subtitle2'>Lighting</Typography>
          {(editable || environmentsEditable) &&
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '2px',
              }}>
              <Add fontSize='small' />
            </IconButton>
          }
        </Box>
        <Menu
          anchorEl={ref.current}
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              backgroundColor: 'secondary.main',
              ...sx, ...menuSx,
            },
          }}
          MenuListProps={{
            sx: {
              padding: '0px',
            }
          }}>
          <Box
            sx={{
              height: '100%',
              color: 'primary.dark',
            }}>
            <input
              ref={environmentInputRef}
              accept='.hdr, .exr'
              type='file'
              multiple
              hidden
              onChange={async () => {
                if (!environmentInputRef.current.files || environmentInputRef.current.files.length < 1) return;

                const files = environmentInputRef.current.files;

                await Promise.all([...files].map((file, i) => new Promise(async (resolve, reject) => {
                  try {
                    const _uuid = uuid();
                    const _file = await uploadBytes(storageRef(storage, `environments/${version}/${_uuid}/${file.name == 'Default' ? 'Default (1)' : file.name}`), file);
                    if (user?.uid) {
                      const metadata = await getMetadata(_file.ref);
                      await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(metadata.size));
                    }
                    await set(databaseRef(database, `environments/${version}/${_uuid}`), file.name == 'Default' ? 'Default (1)' : file.name);
                    if (i == 0) {
                      const updates = { [_uuid]: { uuid: _uuid, type: 'Environment' } };
                      const environment = Object.entries(lights).find(([_, light]) => light.type == 'Environment');
                      if (environment) updates[environment[0]] = null;
                      await Promise.all([
                        update(databaseRef(database, `objects/${version}/lights`), updates),
                        set(databaseRef(database, `objects/${version}/needsUpdate`), true),
                      ]);
                    }
                    resolve();
                  } catch (e) { resolve() }
                })));

                environmentInputRef.current.value = null;

                setEnvironmentsOpen(false);
                setOpen(false);
              }}
            />
            {Object.entries(lightProps)
              .filter(([type]) => editable || (environmentsEditable && type == 'Environment'))
              .map(([type, light]) => (
                <Button
                  key={light.key}
                  variant='outlined'
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    if (type == 'Environment') {
                      environmentInputRef.current.click();
                    } else {
                      dispatch(addLight(type));
                      setOpen(false);
                    }
                  }}
                  sx={{
                    justifyContent: 'flex-start',
                    color: 'primary.main',
                    '&:hover': {
                      borderWidth: '0px',
                    },
                    minWidth: 0,
                    padding: '6px 6px 6px 10px',
                    borderWidth: '0px',
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: 'none',
                  }}>
                  <Typography
                    variant='body2'
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}>
                    {light.label}
                  </Typography>
                </Button>
              ))}
          </Box>
        </Menu>
      </Box>
      {Object.values(lights || {}).map((light) => (
        <Light key={light.uuid} light={light} editable={editable} sx={sx} menuSx={menuSx} />
      ))}
      {environments?.length > 0 &&
        <Box
          sx={{
            width: isMobile ? 'calc(100vw - 20px)' : 250,
            // display: 'flex',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // paddingLeft: '30px',
            padding: '0px 10px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '3px 0px',
            }}>
            <Box sx={{ width: isMobile ? '100vw' : 72, color: 'primary.dark' }}>
              <Typography variant='body2'>Environment</Typography>
            </Box>
            <Box
              sx={{
                // width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Button
                id='environments'
                ref={environmentsRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setEnvironmentsOpen(!environmentsOpen);
                }}
                sx={{
                  backgroundColor: 'secondary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    opacity: .6,
                    boxShadow: 'none',
                  },
                  borderRadius: '10px',
                  margin: '3px',
                  padding: '4px',
                  color: 'primary.dark',
                  width: isMobile ? 'calc(100vw - 170px)' : 100,
                  textTransform: 'none',
                  boxShadow: 'none',
                }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    wordBreak: 'break-all',
                    fontWeight: 400,
                  }}>
                  {environment?.name ? <span title={environment.name}>{environment.name}</span> : 'No Environment'}
                </Typography>
                <KeyboardArrowDown fontSize='small' />
              </Button>
              <IconButton
                onClick={() => dispatch(toggleBackground())}
                sx={{
                  width: 40,
                  height: 30,
                  backgroundColor: showBackground ? '#0b8ce9' : 'secondary.main',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: showBackground ? '#0b8ce9' : 'secondary.main',
                    opacity: .6,
                    boxShadow: 'none',
                  },
                  margin: '3px',
                  padding: '4px',
                  borderRadius: '10px',
                }}>
                <VisibilityOutlined fontSize='small' />
              </IconButton>
            </Box>
            <IconButton
              onClick={async () => {
                if (!environment || environment?.uuid == 'Default' || !(editable || environmentsEditable)) return;
                dispatch(loadEnvironment());
                if (user?.uid) {
                  const metadata = await getMetadata(storageRef(storage, `environments/${version}/${environment.uuid}/${environment.name}`));
                  await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-metadata.size));
                }
                await deleteObject(storageRef(storage, `environments/${version}/${environment.uuid}/${environment.name}`));
                await Promise.all([
                  set(databaseRef(database, `environments/${version}/${environment.uuid}`), null),
                  set(databaseRef(database, `objects/${version}/lights/${environment.uuid}`), null),
                  set(databaseRef(database, `objects/${version}/needsUpdate`), true),
                ]);
              }}
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '2px',
                visibility: !environment || environment?.uuid == 'Default' || !(editable || environmentsEditable) ? 'hidden' : '',
              }}>
              <Close sx={{ fontSize: 10 }} />
            </IconButton>
          </Box>
          <Menu
            anchorEl={environmentsRef.current}
            open={environmentsOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={() => setEnvironmentsOpen(false)}
            PaperProps={{
              sx: {
                backgroundColor: 'secondary.main',
                borderRadius: '10px',
              },
            }}
            MenuListProps={{
              sx: {
                padding: '0px',
              }
            }}>
            <ButtonGroup
              id='textures'
              orientation='vertical'
              sx={{
                width: 145,
                // position: 'absolute',
                // transform: `translate(${(environmentsRef.current?.offsetLeft || 0)}px, ${-scrollTop - 5}px)`,
                // margin: '5px',\
                left: 0,
                borderRadius: '10px',
                zIndex: 1,
                fontWeight: 600,
                boxShadow: 'none',
              }}>
              <Button
                variant='outlined'
                disabled={!environment?.url}
                onClick={() => {
                  dispatch(loadEnvironment());
                  setEnvironmentsOpen(false);
                }}
                sx={{
                  justifyContent: 'flex-start',
                  backgroundColor: 'secondary.main',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    opacity: .6,
                    boxShadow: 'none',
                    borderWidth: '0px',
                  },
                  '&:disabled': {
                    color: 'primary.dark',
                    backgroundColor: '#0b8ce9',
                    borderWidth: '0px',
                  },
                  padding: '6px 15px',
                  textTransform: 'none',
                  borderRadius: '10px',
                  borderWidth: '0px',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    fontWeight: 400,
                  }}>
                  No Environment
                </Typography>
              </Button>
              {environments
                .filter((_environment) => includeDefaultEnvMap || _environment.uuid != 'Default')
                .map((_environment) => (
                  <Button
                    key={_environment.uuid}
                    variant='outlined'
                    disabled={_environment.uuid == environment?.uuid}
                    onClick={() => {
                      dispatch(loadEnvironment(_environment));
                      setEnvironmentsOpen(false);
                    }}
                    sx={{
                      backgroundColor: 'secondary.main',
                      color: 'primary.dark',
                      '&:hover': {
                        backgroundColor: 'secondary.main',
                        opacity: .6,
                        boxShadow: 'none',
                        borderWidth: '0px',
                      },
                      '&:disabled': {
                        color: 'primary.dark',
                        backgroundColor: '#0b8ce9',
                        borderWidth: '0px',
                      },
                      padding: '6px 15px',
                      textTransform: 'none',
                      borderRadius: '10px',
                      borderWidth: '0px',
                      fontWeight: 600,
                      boxShadow: 'none',
                    }}>
                    <Typography
                      variant='subtitle2'
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        fontWeight: 400,
                      }}>
                      {_environment.name}
                    </Typography>
                  </Button>
                ))}
            </ButtonGroup>
          </Menu>
        </Box>
      }
    </>
  );
}