import React from 'react';
import { useSelector } from 'react-redux';
import { useDatabase } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import {
    CopyIcon
} from "@radix-ui/react-icons"
import { useDebouncedCallback } from 'use-debounce';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Switch } from "@/components/ui/switch"

import { set } from '@/modules/firebase/database';
import { getVersion } from '@/modules/redux/storage';
import { getUser } from '@/modules/redux/user';
import { getPayloadFromToken } from '@/modules/utils';

export default function ProjectDetailShare({
}) {

    const database = useDatabase();

    const { isUpgraded } = useSelector(getUser);

    const version = useSelector(getVersion);

    const [autoplay, setAutoplay] = React.useState(false);
    const [redirect, setRedirect] = React.useState();
    const embedHtml = `<div class="modelsend-embed" data-token="${redirect}" style="width:600px;height:400px"></div>`;
    const scriptHtml = `<script defer src="${window.location.origin}/modelsend-embed.js"></script>`;

    React.useEffect(() => {
        get(databaseRef(database, `objects/${getPayloadFromToken().object}/redirect`))
            .then((snapshot) => setRedirect(snapshot.val()))
    });

    const handleCopyLinkClick = () => navigator.clipboard.writeText(`${window.location.host}/${redirect}`);
    const handleCopyHeaderClick = () => navigator.clipboard.writeText(scriptHtml);
    const handleCopyBodyClick = () =>  navigator.clipboard.writeText(embedHtml);

    const handleAutoplayChangeDebounce = useDebouncedCallback((bool) => {
        set(databaseRef(database, `objects/${getPayloadFromToken().object}/autoplay`), bool);
    }, 500);

    React.useEffect(() => {
        if (!version) return;

        get(databaseRef(database, `objects/${version}/autoplay`)).then((snapshot) => setAutoplay(!!snapshot.val()));
    }, [version]);

    const handleAutoplayChange = () => {
        handleAutoplayChangeDebounce.cancel();
        setAutoplay(!autoplay);
        handleAutoplayChangeDebounce(!autoplay);
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button>Share</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Share preset</DialogTitle>
                    <DialogDescription>
                        Anyone who has this link will be able to view this.
                    </DialogDescription>
                    <div className="flex items-center space-x-2">
                        <Input
                            readOnly
                            defaultValue={`${window.location.host}/${redirect}`}
                            className="h-9"
                        />
                        <Button size="sm" className="px-3" onClick={handleCopyLinkClick}>
                            <CopyIcon className="h-4 w-4" />
                        </Button>
                    </div>
                </DialogHeader>
                <div className="space-y-6 py-6">
                    <h1 className="text-md font-medium">Embed</h1>
                    <div className="space-y-4">
                        <p className="text-sm leading-none">
                            1. Paste into header
                        </p>
                        <div className="flex items-center space-x-2">
                            <Textarea
                                defaultValue={scriptHtml}
                                className="min-w-80 resize-none"
                            />
                            <Button size="sm" className="px-3" onClick={handleCopyHeaderClick}>
                                <CopyIcon className="" />
                            </Button>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <p className="text-sm leading-none">
                            2. Paste into body
                        </p>
                        <div className="flex items-center space-x-2">
                            <Textarea
                                defaultValue={embedHtml}
                                className="min-w-80 resize-none"
                            />
                            <Button size="sm" className="px-3" onClick={handleCopyBodyClick}>
                                <CopyIcon className="" />
                            </Button>
                        </div>
                    </div>
                    <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none">
                            Auto play
                        </p>
                        <Switch checked={autoplay} onCheckedChange={handleAutoplayChange} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}