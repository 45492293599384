import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@mui/material';

import Loader from 'components/Loader';

import { uuid } from 'modules/utils';

import {
  changeCanvas,
  getCanvas,
  getEnvironments,
  getEnvironmentLoaded,
  getLoadingRoom,
  getMaps,
  getMapsLoaded,
  getScene,
  getSettingLight,
  initThreejs,
  loadObject,
  resetThreejs,
  setMaps,
  setEnvironments,
  updateBackground,
  updateLights,
  updateMaterials,
  updateMaterialProperties,
  updateNotes,
} from 'modules/redux/threejs';
import { getVersion, setVersion } from 'modules/redux/storage';

export default React.forwardRef(({
  children,
  width = '100%',
  height = '100%',
  loaderRef,
  renderCss2d = false,
  autoInit = true,
  resetOnClickAway = false,
  canvasStyle = {},
}, ref) => {
  const dispatch = useDispatch();

  const canvas = useSelector(getCanvas);
  const settingLight = useSelector(getSettingLight);

  const domElementRef = React.useRef();
  const webGLCanvasRef = React.useRef();

  const init = () => {
    dispatch(initThreejs({
      domElement: domElementRef.current,
      webGLCanvas: webGLCanvasRef.current,
      renderCss2d,
    }));
  };

  React.useEffect(() => {
    if (autoInit) init();
  }, []);

  const [initializing, setInitializing] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    initThreejs: (reset = true) => {
      if (reset) {
        if (canvas) dispatch(resetThreejs());
        setInitializing(true);
      }
      else {
        init();
      }
    },
  }));

  React.useEffect(() => {
    if (!canvas && initializing) {
      init();
      setInitializing(false);
    }
  }, [canvas, initializing]);

  const [canvasId, setCanvasId] = React.useState();

  React.useEffect(() => { setCanvasId(uuid()) }, []);

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        display: 'grid',
        gridTemplateColumns: '1fr',
        ...canvasStyle,
      }}>
      {children}
      <ClickAwayListener
        onClickAway={() => {
          if (resetOnClickAway) {
            dispatch(resetThreejs());
            dispatch(setVersion(null));
          }
        }}
      >
        <div
          ref={domElementRef}
          style={{
            width,
            height,
            gridRowStart: 1,
            gridColumnStart: 1,
            zIndex: settingLight ? 4 : 2,
          }}></div>
      </ClickAwayListener>
      <canvas
        ref={webGLCanvasRef}
        id={canvasId}
        style={{
          width,
          height,
          gridRowStart: 1,
          gridColumnStart: 1,
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          ...canvasStyle,
        }} />
    </div>
  );
});
