import React from 'react';
import { useDatabase } from 'reactfire';
import { ref, get } from 'firebase/database';

import Products from '@/app/products/products';

import useWebsite from '@/modules/useWebsite';

export default function RedirectPage() {
  const database = useDatabase();

  const [initialized, setInitialized] = React.useState(null);

  const handleRedirect = async () => {
    const websiteRef = ref(database, `websites${window.location.pathname.toLowerCase()}`);
    const websiteExists = await get(websiteRef).then((snapshot) => !!snapshot.val());
    if (websiteExists) return setInitialized(true);

    const redirectRef = ref(database, `redirects${window.location.pathname}`);
    const redirect = await get(redirectRef).then((snapshot) => snapshot.val() || '/')
      .catch(() => '/');

    window.location = redirect;
  };

  React.useEffect(() => { handleRedirect() }, []);

  const websiteProps = useWebsite({ isPublic: initialized !== false });

  const queryProps = {
    category: {},
    fileType: {},
    free: {},
    search: {},
  };

  return (initialized && websiteProps.initialized) ?
    <Products
      isPublic
      {...queryProps}
      {...websiteProps} /> :  null;
}