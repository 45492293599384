
import { configureStore } from '@reduxjs/toolkit';

import storageReducer from '../storage';
import threejsReducer from '../threejs';
import userReducer from '../user';

export default configureStore({
  reducer: {
    storage: storageReducer,
    threejs: threejsReducer,
    user: userReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
});