import { loadStripe } from "@stripe/stripe-js";

export const stripePromise = () => {
  try {
    // loadStripe.setLoadParameters({ advancedFraudSignals: false });
    const stripePromise = loadStripe(
      ['modelsend.com', 'dcomments-prod.web.app', 'dcomments-prod.firebaseapp.com'].includes(window.location.host) ?
        process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY :
        process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY
    );

    return stripePromise;
  } catch (e) { return null }
};