
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, onValue, ref as databaseRef, get } from 'firebase/database';
import { getDownloadURL, getStorage, ref as storageRef, listAll } from 'firebase/storage';

import store from 'modules/redux/store';
import { getPayloadFromToken } from 'modules/utils';

import { httpsCallable } from 'modules/firebase/functions';

const initialState = {
  images: {},
  users: {},
  version: null,
  versions: [],
  websites: {},
};

export const fetchImages = createAsyncThunk(
  'storage/fetchImages',
  async () => {
    try {
      const storage = getStorage();
      const newUsers = store.getState().threejs.comments
        .map((comment) => comment.user)
        .filter((user) => user && !Object.keys(store.getState().storage.images).includes(user));

      const newImages = store.getState().threejs.comments
        .map((comment) => ({ uuid: comment.uuid, image: comment.image }))
        .filter(({ image }) => image && !Object.keys(store.getState().storage.images).includes(image));

      const userProfiles = await Promise.all(newUsers.map((user) => new Promise(async (resolve) => {
        try {
          const profileRef = await listAll(storageRef(storage, `websites/${user}/profile`));
          const profile = profileRef.items[0] ? await getDownloadURL(profileRef.items[0]).catch(() => null) : null;
          resolve([user, { url: profile }]);
        } catch (e) { resolve([user, { url: null }]) };
      })))
        .then((userProfiles) => userProfiles.filter(([_, { url }]) => url));

      const userPhotoURLs = await httpsCallable('fetchUsers')({
        users: newUsers.filter((user) => !userProfiles.find(([_user]) => user == _user)).map((uid) => ({ uid }))
      })
        .then(({ data: { users } }) => users.map((user) => [user.uid, { url: user.photoURL }]));

      const users = userProfiles.concat(userPhotoURLs);

      const images = await Promise.all(newImages.map(({ image, uuid }) => listAll(storageRef(storage, `comments/${getPayloadFromToken().object}/${uuid}/${image}`))
        .then((ref) => getDownloadURL(ref.items[0])
          .then((url) => [image, { url, name: ref.items[0].name }])
        )
      ));

      return Object.fromEntries(users.concat(images));
    } catch (e) { console.log(e) }
  }
);

export const fetchUsers =  createAsyncThunk(
  'storage/fetchUsers',
  async (users = []) => {
    try {
      users = users.filter((uid) => !store.getState().storage.users[uid]);

      if (!users.length) return { images: {}, users: {}, websites: {} };

      const userProfiles = await Promise.all(users.map((user) => new Promise(async (resolve) => {
        try {
          const profileRef = await listAll(storageRef(getStorage(), `websites/${user}/profile`));
          const profile = profileRef.items[0] ? await getDownloadURL(profileRef.items[0]).catch(() => null) : null;
          resolve([user, { url: profile }]);
        } catch (e) { resolve([user, { url: null }]) }
      })))
        .then((userProfiles) => userProfiles.filter(([_, { url }]) => url));

      const usersFetched = await httpsCallable('fetchUsers')({ users: users.map((uid) => ({ uid })) })
        .then(({ data: { users = [] } }) => users.map((user) => [user.uid, user]));

      const images = userProfiles.concat(usersFetched
        .filter(([uid]) => !userProfiles.find(([_user]) => uid == _user))
        .map(([uid, user]) =>  [uid, { url: user.photoURL }])
      );

      const websites = await Promise.all(users.map((user) => new Promise(async (resolve) => {
        try {
          const website = await get(databaseRef(getDatabase(), `users/${user}/website`)).then((snapshot) => snapshot.val());

          resolve([user, website]);
        } catch (e) { resolve([user, null]) }
      })))
        .then((userWebsites) => userWebsites.filter(([, website]) => website));

      return {
        images: Object.fromEntries(images),
        users: Object.fromEntries(usersFetched),
        websites: Object.fromEntries(websites),
      };
    } catch (e) { console.log(e) }
  }
);

export const fetchVersions = createAsyncThunk(
  'storage/fetchVersions',
  async () => {
    try {
      const versions = await get(databaseRef(getDatabase(), `objects/${getPayloadFromToken().object}/versions`))
        .then((snapshot) => snapshot.val() || []);

      return {
        version: versions[versions.length - 1] || getPayloadFromToken().object,
        versions: [getPayloadFromToken().object].concat(versions),
      };
    } catch (e) { console.log(e) }
  },
);

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setVersion: (state, action) => { state.version = action.payload },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImages.fulfilled, (state, action) => {
        state.images = { ...state.images, ...action.payload };
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.images = { ...state.images, ...action.payload.images };
        state.users = { ...state.users, ...action.payload.users };
        state.websites = { ...state.websites, ...action.payload.websites };
      })
      .addCase(fetchVersions.fulfilled, (state, action) => {
        state.version = action.payload.version;
        state.versions = action.payload.versions;
      });
  },
});

export const {
  setVersion,
} = storageSlice.actions;

export const getImages = (state) => state.storage.images;
export const getUsers = (state) => state.storage.users;
export const getVersion = (state) => state.storage.version;
export const getVersions = (state) => state.storage.versions;
export const getWebsites = (state) => state.storage.websites;

export default storageSlice.reducer;