const routes = [
    'seller',
    'pricing',
    'blog',
    'artists',
    // 'website',
    'messages',
    'projects',
    'purchases',
    'following',
    'account',
    'cart',
    'project',
    'viewer',
];

export default routes;