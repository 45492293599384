import React from 'react';
import { useSelector } from 'react-redux';
import { useFunctions, useStorage } from 'reactfire';
import {
  ref as storageRef,
  listAll,
  getDownloadURL,
} from 'firebase/storage';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import {
  Close,
  Check,
} from '@mui/icons-material';

import { getPayloadFromToken } from 'modules/utils';

export default function Comment({
  comment,
  uuid,
  showLabels = false,
  onClick,
  onDelete,
  onImageClick = () => { },
}) {

  if (!comment?.text) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '6px 0px',
        padding: '12px',
        color: 'primary.main',
        backgroundColor: 'secondary.main',
        borderRadius: '2px',
      }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          color: 'primary.main',
        }}>
        <Typography
          variant='subtitle1'
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 99999,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '.8rem',
            wordBreak: 'keep-all',
          }}>
          {comment.text}
        </Typography>
      </Box>
    </Box>
  )
};