import { VertexLayout, WebIO } from '@gltf-transform/core';
import { ALL_EXTENSIONS } from '@gltf-transform/extensions';
import {
  listTextureInfoByMaterial,
  draco,
  dedup,
  instance,
  palette,
  flatten,
  join,
  weld,
  simplify,
  resample,
  prune,
  sparse,
  meshopt,
  reorder,
  quantize,
  WELD_DEFAULTS,
  SIMPLIFY_DEFAULTS,
} from '@gltf-transform/functions';
import { ready as resampleReady, resample as resampleWASM } from 'keyframe-resample';
import { MeshoptEncoder, MeshoptDecoder, MeshoptSimplifier } from 'meshoptimizer';
import {
  ArrowHelper,
  Box3,
  Box3Helper,
  BufferAttribute,
  Euler,
  ExtrudeGeometry,
  ImageBitmapLoader,
  MathUtils,
  Mesh,
  MeshPhongMaterial,
  Raycaster,
  REVISION,
  Scene,
  Skeleton,
  SkeletonHelper,
  Source,
  Vector3,
  Vector4,
} from 'three';

import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { createMeshesFromMultiMaterialMesh } from 'three/examples/jsm/utils/SceneUtils.js';
import { clone as cloneSkinnedMesh } from 'three/examples/jsm/utils/SkeletonUtils.js';

import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';

import { GLTFLoader } from './loaders/GLTFLoader.js';
import { DRACOExporter } from './exporters/DRACOExporter.js';
import { GLTFExporter } from './exporters/GLTFExporter.js';
import { ADDITION, Brush, Evaluator, SUBTRACTION } from './three-bvh-csg/src';

import placeholder from './assets/textures/placeholder.png';
import watermarkSvg from './assets/svg/watermark.svg';
import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial.js';
import { Quaternion } from 'three/src/math/Quaternion.js';
// import { ADDITION, Brush, Evaluator } from 'three-bvh-csg';

await MeshoptEncoder.ready;
await MeshoptDecoder.ready;

const ALL_DEPENDENCIES = {
  'meshopt.decoder': MeshoptDecoder,
  'meshopt.encoder': MeshoptEncoder,
};

// Used for environment loading
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;

let image;
new ImageBitmapLoader().loadAsync(placeholder).then((i) => image = i);

const keys = ['map', 'matcap', 'specularMap', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'roughnessMap', 'metalnessMap', 'envMap', 'lightMap', 'aoMap', 'gradientMap'];

const gltfMaps = {
  'metalnessMap': 'metallicRoughnessTextureInfo',
  'roughnessMap': 'metallicRoughnessTextureInfo',
  'map': 'baseColorTextureInfo',
  'emissiveMap': 'emissiveTextureInfo',
  'normalMap': 'normalTextureInfo',
  'aoMap': 'occlusionTextureInfo',
};

function removeTextureSources() {
  return (document) => {
    for (const texture of document.getRoot().listTextures()) {
      texture.setImage(null);
    }
  };
}

export const compressModel = async ({ content, type, name }) => {
  let io, document, mesh, encoder, data, ext, hasAnimations, placeholder, finalMaterial;

  switch (type) {
    case 'fbx':
      mesh = content.object;
      hasAnimations = (content.animations?.length || 0) > 0;

      const source = new Source(image);

      mesh.traverse((_object) => {
        if (_object.material) {
          if (Array.isArray(_object.material)) createMeshesFromMultiMaterialMesh(_object);
        }
      });

      const flipY = {};
      placeholder = {};

      mesh.traverse((_object) => {
        if (_object.material) {
          (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material) => {
            keys.forEach((key) => {
              if (material[key]?.source && !material[key].source.data) {
                material[key].source = source;
                if (material.name in placeholder === false) placeholder[material.name] = {};
                placeholder[material.name][key] = true;
              }
            });
            Object.entries(gltfMaps).forEach(([key, value]) => {
              if (material[key]) {
                if (material.name in flipY === false) flipY[material.name] = {};
                flipY[material.name][value] = material[key].flipY;
              }
            });
          });
        }
      });

      content = await new Promise((resolve, reject) => {
        new GLTFExporter()
          .parse(mesh, resolve, reject, {
            trs: true,
            onlyVisible: false,
            binary: true,
            animations: content.animations || [],
          });
      });

      source.data = null;

      io = new WebIO()
        .setVertexLayout(VertexLayout.SEPARATE)
        .registerExtensions(ALL_EXTENSIONS)
        .registerDependencies(ALL_DEPENDENCIES);

      document = await io.readBinary(new Uint8Array(content));

      function setFlipY() {
        return (document) => {
          for (const material of document.getRoot().listMaterials()) {
            for (const textureInfo of listTextureInfoByMaterial(material)) {
              textureInfo.setExtras({ ...textureInfo.getExtras(), flipY: flipY[material.getName()][textureInfo.getName()] });
            }
          }
        };
      }

      await document.transform(setFlipY());

      ext = '.glb';
      finalMaterial = MeshPhongMaterial;
      break;
    case 'glb':
      io = new WebIO()
        .setVertexLayout(VertexLayout.SEPARATE)
        .registerExtensions(ALL_EXTENSIONS)
        .registerDependencies(ALL_DEPENDENCIES);

      document = await io.readBinary(new Uint8Array(content));

      // await document.transform(draco());

      data = await io.writeBinary(document);

      content = await new Promise((resolve, reject) => {
        new GLTFLoader()
          .setDRACOLoader(new DRACOLoader().setDecoderPath(`${THREE_PATH}/examples/jsm/libs/draco/gltf/`))
          .setMeshoptDecoder(MeshoptDecoder)
          .parse(data.buffer, undefined, resolve, reject);
      });

      mesh = content.scene || content.scenes?.[0];
      hasAnimations = (content.animations?.length || 0) > 0;

      mesh.updateMatrixWorld(true);

      ext = '.glb';
      break;
    case 'ifc':
      mesh = content.object;
      hasAnimations = (content.animations?.length || 0) > 0;

      content = await new Promise((resolve, reject) => {
        new GLTFExporter()
          .parse(mesh, resolve, reject, {
            trs: true,
            onlyVisible: false,
            binary: true,
            animations: content.animations || [],
          });
      });

      io = new WebIO()
        .setVertexLayout(VertexLayout.SEPARATE)
        .registerExtensions(ALL_EXTENSIONS)
        .registerDependencies(ALL_DEPENDENCIES);

      document = await io.readBinary(new Uint8Array(content));

      ext = '.glb';
      break;
    case 'obj':
    case 'stl':
      mesh = content.object;
      hasAnimations = (content.animations?.length || 0) > 0;

      content = await new Promise((resolve, reject) => {
        new GLTFExporter()
          .parse(mesh, resolve, reject, {
            // trs: true,
            onlyVisible: false,
            binary: true,
            animations: content.animations || [],
          });
      });

      io = new WebIO()
        .setVertexLayout(VertexLayout.SEPARATE)
        .registerExtensions(ALL_EXTENSIONS)
        .registerDependencies(ALL_DEPENDENCIES);

      document = await io.readBinary(new Uint8Array(content));

      data = await io.writeBinary(document);
      
      finalMaterial = MeshPhongMaterial;

      content = await new Promise((resolve, reject) => {
        new GLTFLoader()
          .setDRACOLoader(new DRACOLoader().setDecoderPath(`${THREE_PATH}/examples/jsm/libs/draco/gltf/`))
          .setMeshoptDecoder(MeshoptDecoder)
          .parse(data.buffer, undefined, resolve, reject);
      });

      mesh = content.scene || content.scenes?.[0];

      mesh.updateMatrixWorld(true);

      ext = '.glb';
    default:
  }

  let vertices = 0;
  let polycount = 0;

  mesh.traverse((object) => {

    if (object.isMesh) {

      const geometry = object.geometry;

      vertices += geometry.attributes.position.count;

      if (geometry.index !== null) {

        polycount += geometry.index.count / 3;

      } else {

        polycount += geometry.attributes.position.count / 3;

      }

    }

  });

  await document.transform(
    ...optimize(),
    reorder({ encoder: MeshoptEncoder }),
    // draco(),
    // removeTextureSources(),
  );

  data = await io.writeBinary(document);

  content = await new Promise((resolve, reject) => {
    new GLTFLoader()
      .setDRACOLoader(new DRACOLoader().setDecoderPath(`${THREE_PATH}/examples/jsm/libs/draco/gltf/`))
      .setMeshoptDecoder(MeshoptDecoder)
      .parse(data.buffer, undefined, resolve, reject);
  });
  
  mesh = content.scene || content.scenes?.[0];

  mesh.traverse((object) => {
    if (object.material) {
      (Array.isArray(object.material) ? object.material : [object.material]).forEach((material) => {
        keys.forEach((key) => {
          if (placeholder?.[material.name]?.[key]) material[key] = null;
        });
      });
    }
  });

  mesh.updateMatrixWorld(true);

  mesh.userData.vertices = vertices;
  mesh.userData.polycount = polycount;
  mesh.userData.hasAnimations = hasAnimations;
  mesh.userData.originalType = type;

  await addWatermark(mesh);

  content = await new Promise((resolve, reject) => {
    new GLTFExporter()
      .parse(mesh, resolve, reject, {
        trs: true,
        onlyVisible: false,
        binary: true,
        animations: content.animations || [],
      });
  });

  document = await io.readBinary(new Uint8Array(content));

  await document.transform(
    removeTextureSources(),
  );

  data = await io.writeBinary(document);

  return { object: mesh, data, name: `${name}${ext}` };
};

export const addWatermark = async (object, scene) => {
  if (!scene) {
    object.userData.position = object.position.clone();

    const center = new Box3().setFromObject(object).getCenter(new Vector3());
    object.position.copy(center.negate());

    object.updateMatrixWorld(true);
  }

  const size = new Box3().setFromObject(object).getSize(new Vector3());

  const data = await new SVGLoader().loadAsync(watermarkSvg);

  const paths = data.paths;

  let brush;

  for (let i = 0; i < paths.length; i++) {

    const path = paths[i];

    const shapes = SVGLoader.createShapes(path);

    for (let j = 0; j < shapes.length; j++) {

      const shape = shapes[j];
      const geometry = new ExtrudeGeometry(shape, { depth: 396 / 2 });

      const _brush = new Brush(geometry);

      const evaluator = new Evaluator();
      brush = brush ? evaluator.evaluate(brush, _brush, ADDITION) : _brush;

    }

  }

  let watermark, watermarkSize, watermarkCenter, intersects, _brush;

  watermarkSize = new Box3().setFromObject(brush).getSize(new Vector3());
  // scene.add(new Box3Helper(new Box3().setFromObject(object)))
  // scene.add(new Box3Helper(new Box3().setFromObject(brush), 'white'))

  let scale = .1 * size.length() / watermarkSize.length();

  watermark = brush.clone();

  // watermark.scale.set(scale, scale, scale);

  // watermark.rotation.x = -Math.PI / 2;

  intersects = [];

  const getIntersects = () => {
    watermarkSize = new Box3().setFromObject(watermark).getSize(new Vector3());
    watermarkCenter = new Box3().setFromObject(watermark).getCenter(new Vector3());

    watermark.position.sub(watermarkCenter);
    watermark.position.sub(new Vector3(.5 * size.x, 0, .5 * size.z));

    watermarkSize = new Box3().setFromObject(watermark).getSize(new Vector3());
    watermarkCenter = new Box3().setFromObject(watermark).getCenter(new Vector3());

    for (let x = 0; x <= size.x; x = x + .05 * size.x) {
      for (let z = 0; z <= size.z; z = z + .05 * size.z) {

        intersects.push(
          new Raycaster(watermarkCenter.clone().add(new Vector3(x, 0, z)).add(new Vector3(0, -.5 * size.length(), 0)), new Vector3(0, 1, 0), 0, size.length())
            .intersectObject(object)
            .map((i) => ({ ...(i || {}), deltaX: x, deltaZ: z, distance: i?.distance || size.length() }))[0]
        );

      }
    }

  }

  getIntersects();

  // watermark.rotation.z = Math.PI / 2;
  // getIntersects();

  // watermark.rotation.z = Math.PI / 4;
  // getIntersects();

  intersects.sort((a, b) => a.distance - b.distance);

  let intersect = intersects[0];

  const reset = () => {
    object.traverse((_object) => {
      // if (_object.isBone) {
      //   const bone = bones[_object.uuid]
      //   _object.matrix.copy(bone.matrix);
      //   _object.matrixWorld.copy(bone.matrixWorld);
      //   _object.position.copy(bone.position);
      //   _object.quaternion.copy(bone.quaternion);
      //   _object.scale.copy(bone.scale);
      // }
    });

    if (!scene) {
      object.position.copy(object.userData.position);

      object.updateMatrixWorld(true);
    }
  };

  if (!intersect) return reset();

  const vertex1 = new Vector3().fromBufferAttribute(intersect.object.geometry.getAttribute('position'), intersect.face.a);
  const vertex2 = new Vector3().fromBufferAttribute(intersect.object.geometry.getAttribute('position'), intersect.face.b);
  const vertex3 = new Vector3().fromBufferAttribute(intersect.object.geometry.getAttribute('position'), intersect.face.c);

  let _vertex1, _vertex2, _vertex3;
  if (intersect.object.isSkinnedMesh) {
    _vertex1 = intersect.object.applyBoneTransform(intersect.face.a, vertex1.clone()).applyMatrix4(intersect.object.matrixWorld);
    _vertex2 = intersect.object.applyBoneTransform(intersect.face.b, vertex2.clone()).applyMatrix4(intersect.object.matrixWorld);
    _vertex3 = intersect.object.applyBoneTransform(intersect.face.c, vertex3.clone()).applyMatrix4(intersect.object.matrixWorld);
  }
  else {
    _vertex1 = vertex1.clone().applyMatrix4(intersect.object.matrixWorld);
    _vertex2 = vertex2.clone().applyMatrix4(intersect.object.matrixWorld);
    _vertex3 = vertex3.clone().applyMatrix4(intersect.object.matrixWorld);
  }

  // const direction1 = vertex1.clone().sub(vertex2).normalize();
  // const direction2 = vertex2.clone().sub(vertex3).normalize();
  // const direction3 = vertex3.clone().sub(vertex1).normalize();

  // const _direction1 = _vertex1.clone().sub(_vertex2).normalize();
  // const _direction2 = _vertex2.clone().sub(_vertex3).normalize();
  // const _direction3 = _vertex3.clone().sub(_vertex1).normalize();

  // const quaternion1 = new Quaternion().setFromUnitVectors( direction1, _direction1 );
  // const quaternion2 = new Quaternion().setFromUnitVectors( direction2, _direction2 );
  // const quaternion3 = new Quaternion().setFromUnitVectors( direction3, _direction3 );

  // console.log(
  //   direction1,
  //   direction2,
  //   direction3,
  //   _direction1,
  //   _direction2,
  //   _direction3,
  //   quaternion1,
  //   quaternion2,
  //   quaternion3,
  //   new Euler().setFromQuaternion( quaternion1.clone().multiply(quaternion2).multiply(quaternion3) ),
  // )

  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), intersect.point, size.length(), 'purple'));

  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), vertex1, size.length(), 'red'));
  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), vertex2, size.length(), 'yellow'));
  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), vertex3, size.length(), 'blue'));
  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), _vertex1, size.length(), 'pink'));
  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), _vertex2, size.length(), 'yellow'));
  // scene.add(new ArrowHelper(new Vector3(1, -1, 1).normalize(), _vertex3, size.length(), 'cyan'));

  const heronsFormula = (v1, v2, v3) => {
    const a = v1.distanceTo(v2);
    const b = v2.distanceTo(v3);
    const c = v3.distanceTo(v1);

    const s = (a + b + c) / 2;

    return Math.sqrt(s * (s - a) * (s - b) * (s - c));
  };

  const intersectSize = new Box3().setFromObject(intersect.object).getSize(new Vector3());

  scale = .05 *
    (Math.sqrt(heronsFormula(vertex1, vertex2, vertex3)) / Math.sqrt(heronsFormula(_vertex1, _vertex2, _vertex3))) *
    // (intersectSize.length() / size.length()) *
    (size.length() / watermarkSize.length());

  watermark.scale.set(scale, scale, scale);

  const rotation = intersect.object.getWorldDirection(new Vector3());

  if (Math.asin(Math.round(rotation.y)) >= Math.PI / 2) watermark.rotation.x = -Math.PI / 2;
  if (Math.asin(Math.round(rotation.x)) >= Math.PI / 2) watermark.rotation.y = Math.PI / 2;

  watermark.position.copy(vertex1);

  watermarkCenter = new Box3().setFromObject(watermark).getCenter(new Vector3());

  watermark.position.sub(watermarkCenter.clone().sub(watermark.position));

  watermark.updateMatrixWorld();
  intersect.object.updateMatrixWorld();

  _brush = new Brush(intersect.object.geometry);
  // scene.add(new Box3Helper(new Box3().setFromObject(intersect.object), 'orange'))
  // scene.add(new Box3Helper(new Box3().setFromObject(_brush), 'red'))
  // scene.add(_brush)
  // scene.add(brush)
  // scene.add(watermark)
  
  // object.visible = false;
  brush.geometry.applyMatrix4(watermark.matrixWorld);
  // brush.geometry.applyMatrix4(intersect.object.matrixWorld.clone().invert());
  Object.keys(intersect.object.geometry.attributes).forEach((key) => {
    if (!brush.geometry.hasAttribute(key)) brush.geometry.setAttribute(key,
      new BufferAttribute(new (intersect.object.geometry.getAttribute(key).array.constructor)(
        brush.geometry.getAttribute('position').count * intersect.object.geometry.getAttribute(key).itemSize),
        intersect.object.geometry.getAttribute(key).itemSize
      )
    );
  });
  Object.keys(brush.geometry.attributes).forEach((key) => {
    if (!intersect.object.geometry.hasAttribute(key)) brush.geometry.deleteAttribute(key);
  });
  ['skinIndex', 'skinWeight'].forEach((key) => {
    if (intersect.object.geometry.hasAttribute(key)) {
      const vertex = new Vector4().fromBufferAttribute(intersect.object.geometry.getAttribute(key), intersect.face.a);

      brush.geometry.setAttribute(key,
        new BufferAttribute(new (intersect.object.geometry.getAttribute(key).array.constructor)(
          Array(brush.geometry.getAttribute('position').count/* * intersect.object.geometry.getAttribute(key).itemSize*/).fill([...vertex]).flat()),
          intersect.object.geometry.getAttribute(key).itemSize
        )
      );
    }
  });

  if (intersect.object.geometry.index) {
    let index = [];
    for (let i = 0; i < brush.geometry.attributes.position.count; i++)
      index.push(i);
    brush.geometry.setIndex(index);
  }


  // const evaluator = new Evaluator();
  // evaluator.attributes = Object.keys(brush.geometry.attributes);

  const merged = mergeGeometries([intersect.object.geometry, brush.geometry]);

  // brush = evaluator.evaluate(brush, _brush, SUBTRACTION);


  // if (merged && merged.index !== null &&
  //   merged.attributes.position !== undefined &&
  //   merged.attributes.normal !== undefined &&
  //   merged.attributes.uv !== undefined) merged.computeTangents();

  intersect.object.geometry = merged;//brush.geometry;

  reset();
}

function optimize() {
  const transforms = [];
  // transforms.push(dedup());
  // transforms.push(instance({ min: 5 }));
  // if (typeof document != 'undefined') transforms.push(palette({ min: 5 }));
  // transforms.push(flatten());
  // transforms.push(join());
  transforms.push(
    weld({
      tolerance: WELD_DEFAULTS.tolerance,
      toleranceNormal: 0.5,//WELD_DEFAULTS.toleranceNormal,
    }),
  );
  // transforms.push(simplify({ simplifier: MeshoptSimplifier, error: SIMPLIFY_DEFAULTS.error }));
  transforms.push(
    // resample({ ready: resampleReady, resample: resampleWASM }),
    prune({
      keepAttributes: true,//false,
      keepIndices: false,
      keepLeaves: false,
      keepSolidTextures: false,
    }),
    sparse(),
  );
  // if (opts.textureCompress === 'ktx2') {
  //   const slotsUASTC = micromatch.makeRe(
  //     '{normalTexture,occlusionTexture,metallicRoughnessTexture}',
  //     MICROMATCH_OPTIONS,
  //   );
  //   transforms.push(
  //     toktx({ mode: Mode.UASTC, slots: slotsUASTC, level: 4, rdo: 4, zstd: 18 }),
  //     toktx({ mode: Mode.ETC1S, quality: 255 }),
  //   );
  // } else if (opts.textureCompress !== false) {
  //   const { default: encoder } = await import('sharp');
  //   transforms.push(
  //     textureCompress({
  //       encoder,
  //       targetFormat: opts.textureCompress === 'auto' ? undefined : opts.textureCompress,
  //       resize: [opts.textureSize, opts.textureSize],
  //     }),
  //   );
  // }
  // if (opts.compress === 'draco') {
  //   transforms.push(draco());
  // } else if (opts.compress === 'meshopt') {
  //   transforms.push(meshopt({ encoder: MeshoptEncoder }));
  // } else if (opts.compress === 'quantize') {
  //   transforms.push(quantize());
  // }

  return transforms;
}