import React from 'react';
import { useSigninCheck } from 'reactfire';

import { SigninDlg } from '@/components/signin-dlg';

export default function Home({
    onLoginSuccess = () => { },
    onSellerInitiated = () => { },
}) {
    const { data: signInCheckResult } = useSigninCheck();

    return (
        <div className="relative">
            <section className="space-y-6 pb-24 pt-24">
                <div className="container flex max-w-[64rem] flex-col items-center gap-4 text-center">
                    <h1 className="font-heading font-bold text-5xl sm:text-5xl md:text-6xl lg:text-7xl">Sell more 3D models.</h1>
                    <p className="max-w-[42rem] leading-normal text-muted-foreground sm:text-xl sm:leading-8">Modelsend is a 3D commissions marketplace that helps 3D artists earn more. $0 monthly fees and $0 revenue share fees.</p>
                    <div className="space-x-4">
                        {signInCheckResult.signedIn ?
                            <button className="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:bg-primary/90 h-11 px-8 rounded-md"
                                onClick={onSellerInitiated}>
                                Start Selling
                            </button> :
                            <SigninDlg onLoginSuccess={onLoginSuccess}>
                                <button className="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:bg-primary/90 h-11 px-8 rounded-md"
                                    href="/login">
                                    Start Selling
                                </button>
                            </SigninDlg>}
                    </div>
                    <div className="flex items-center justify-start mt-6">
                        <img className="relative z-30 inline-block h-6 w-6 my-auto object-cover rounded-full ring-2 ring-white" src="/images/customers/rita.jpg" alt="" />
                        <img className="relative z-20 inline-block h-6 w-6 my-auto object-cover rounded-full ring-2 ring-white" src="/images/customers/christina.jpg" alt="" />
                        <img className="relative z-10 inline-block h-6 w-6 my-auto object-cover rounded-full ring-2 ring-white" src="/images/customers/courtney.jpg" alt="" />
                        <img className="relative z-0 inline-block h-6 w-6 my-auto object-cover rounded-full ring-2 ring-white" src="/images/customers/irene.jpg" alt="" />
                        <img className="relative z-0 inline-block h-6 w-6 my-auto object-cover rounded-full ring-2 ring-white" src="/images/customers/etty.jpg" alt="" />
                        <div className="flex items-center gap-1">
                            <svg className="h-[14px] ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 14" id="svg3434201155"><path d="M 6.757 11.841 L 6.754 11.838 L 6.74 11.831 C 6.464 11.681 6.193 11.522 5.927 11.354 C 5.293 10.955 4.689 10.511 4.12 10.024 C 2.832 8.913 1.4 7.246 1.4 5.25 C 1.4 3.915 2.242 2.725 3.5 2.28 C 4.759 1.836 6.161 2.232 7 3.27 C 7.839 2.232 9.241 1.836 10.5 2.28 C 11.758 2.725 12.6 3.915 12.6 5.25 C 12.6 7.246 11.169 8.913 9.881 10.024 C 9.072 10.715 8.193 11.321 7.26 11.831 L 7.246 11.838 L 7.243 11.841 L 7.242 11.841 C 7.09 11.92 6.91 11.921 6.759 11.841 Z" fill="var(--token-95e1bfcc-02e8-4fdb-8080-e342e04d4d5d, rgb(252, 82, 0)) /* {&quot;name&quot;:&quot;Loops Orange&quot;} */"></path></svg>
                            <p className="my-auto text-gray-600" style={{ fontSize: "16px" }}>by 1,000+ happy 3D artists!</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="w-full">
                <div className="mx-auto max-w-7xl px-6">
                    <div className="relative pt-[65.534%]">
                        <video autoPlay muted controls controlsList="nodownload noplaybackrate"
                        className="rounded-[7px] absolute top-0 left-0 w-full">
                            <source src="images/modelsend.mp4" type="video/mp4" />
                        </video>

                    </div>
                </div>
            </div>
            <div className="pt-[8rem] pb-[4rem]">
                <div className="max-w-[1920px] mx-auto px-[3rem] sm:px-[4rem] md:px-[10rem] lg:px-[17.0625rem]">
                    <p className="-tracking-[0.01rem] text-[1.9rem] md:text-[2.4375rem] leading-[1.4] font-semibold">
                        Modelsend makes it easy to get commissioned for 3D models and deliver models that will make your clients happy, <span className="text-[#999]">thanks to built-in 3D viewers and feedback tools. Built by and for 3D artists.</span>
                    </p>
                </div>
            </div>
            <div className="flex flex-col items-center py-20 px-5">
                <h2 className="max-w-2xl font-semibold leading-[3rem] text-5xl sm:text-5xl md:text-5xl lg:text-6xl text-center mb-24">Why Modelsend</h2>
                <div className="flex flex-col gap-16 md:gap-20 lg:gap-40">
                    <div className="flex flex-col md:flex-row items-center gap-6 md:gap-8 lg:gap-16">
                        <div className="flex flex-col text-center items-center md:text-left md:items-start max-w-[480px] md:mb-6">
                            <div className="mb-2 text-sm 2xs:text-base sm:text-lg md:text-base lg:text-lg font-medium text-teal-600">High margin commissions</div>
                            <h3 className="font-display text-[1.5rem] text-zinc-950 font-bold tracking-[-0.02em] !leading-[1.1] mb-3">Earn more with 3D commissions</h3>
                            <p className="text-base sm:text-lg md:text-base lg:text-xl font-normal text-zinc-500 leading-normal">Your all-in-one platform to privately share 3D models, get feedback, and request payments.</p>
                        </div>
                        <div className="max-w-[38.25rem] overflow-hidden border border-gray-900/10 rounded-xl animate-scrollBackground">
                            <img src="/images/desc-1.png" alt="Buy 3D models" loading="lazy" width="1176" height="936" decoding="async" data-nimg="1" className="rounded-tl-lg" />
                        </div>
                    </div>
                    <div className="flex flex-col-reverse md:flex-row items-center gap-6 md:gap-8 lg:gap-16">
                        <div className="max-w-[38.25rem] overflow-hidden border border-gray-900/10 rounded-xl animate-scrollBackground">
                            <img src="/images/desc-2.png" alt="Custom 3D model commissions" loading="lazy" width="1176" height="936" decoding="async" data-nimg="1" className="rounded-tr-lg" />
                        </div>
                        <div className="flex flex-col text-center items-center md:text-left md:items-start max-w-[480px] md:mb-6">
                            <div className="mb-2 text-sm 2xs:text-base sm:text-lg md:text-base lg:text-lg font-medium text-teal-600">AI-assisted discoverability (coming soon)</div>
                            <h3 className="font-display text-[1.5rem] text-zinc-950 font-bold tracking-[-0.02em] !leading-[1.1] mb-3">Say goodbye to getting lost in the crowd</h3>
                            <p className="text-base sm:text-lg md:text-base lg:text-xl font-normal text-zinc-500 leading-normal">Modelsend uses natural language processing to match buyer's intent to your 3D models.</p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6 md:gap-8 lg:gap-16">
                        <div className="flex flex-col text-center items-center md:text-left md:items-start max-w-[480px] md:mb-6">
                            <div className="mb-2 text-sm 2xs:text-base sm:text-lg md:text-base lg:text-lg font-medium text-teal-600">Asset security</div>
                            <h3 className="font-display text-[1.5rem] text-zinc-950 font-bold tracking-[-0.02em] !leading-[1.1] mb-3">Have peace of mind</h3>
                            <p className="text-base sm:text-lg md:text-base lg:text-xl font-normal text-zinc-500 leading-normal">Modelsend protects your assets from unauthorized AI scrappers using encryption and geometry watermarking.</p>
                        </div>
                        <div className="max-w-[38.25rem] overflow-hidden border border-gray-900/10 rounded-xl animate-scrollBackground">
                            <img src="/images/desc-3.png" alt="Comprehensive 3D model security" loading="lazy" width="1176" height="936" decoding="async" data-nimg="1" className="rounded-tl-lg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-feature flex flex-col items-center pt-24 pb-40">
                <h2 className="max-w-2xl font-semibold leading-[3rem] text-5xl sm:text-5xl md:text-5xl lg:text-6xl text-center mb-24">All-in-one platform</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 sm:gap-y-10 lg:gap-y-16 max-w-7xl">
                    <div className="flex flex-col items-center md:px-5 text-center max-w-sm mx-auto">
                        <div className="group h-16 w-16 border-2 flex items-center justify-center rounded-2xl  border border-zinc-950/[7%] shadow-[0_1px_4px_2px_rgba(9,9,11,0.02),0_0px_1px_2px_rgba(255,255,255,0.8),0_-3px_6px_rgba(9,9,11,0.02)_inset] cursor-default mb-3">
                            <img src="/images/feature-1.svg" className="h-6 w-6 fill-zinc-950/30 group-hover:fill-teal-500 group-hover:[filter:drop-shadow(0_0_2px_rgba(45,212,191,0.3))]" />
                        </div>
                        <h4 className="font-display text-[1.25rem] font-semibold text-zinc-950 mb-1 sm:mb-2">Global marketplace</h4>
                        <p className="text-base font-normal text-zinc-500">Get commissioned for 3D models by people around the world</p>
                    </div>
                    <div className="flex flex-col items-center md:px-5 text-center max-w-sm mx-auto">
                        <div className="group h-16 w-16 border-2 flex items-center justify-center rounded-2xl  border border-zinc-950/[7%] shadow-[0_1px_4px_2px_rgba(9,9,11,0.02),0_0px_1px_2px_rgba(255,255,255,0.8),0_-3px_6px_rgba(9,9,11,0.02)_inset] cursor-default mb-3">
                            <img src="/images/feature-2.svg" className="h-6 w-6 fill-zinc-950/30 group-hover:fill-teal-500 group-hover:[filter:drop-shadow(0_0_2px_rgba(45,212,191,0.3))] " />
                        </div>
                        <h4 className="font-display text-[1.25rem] font-semibold text-zinc-950 mb-1 sm:mb-2">Custom website</h4>
                        <p className="text-base font-normal text-zinc-500">Show off your 3D models and get commissioned directly from your 3D website</p>
                    </div>
                    <div className="flex flex-col items-center md:px-5 text-center max-w-sm mx-auto">
                        <div className="group h-16 w-16 border-2 flex items-center justify-center rounded-2xl  border border-zinc-950/[7%] shadow-[0_1px_4px_2px_rgba(9,9,11,0.02),0_0px_1px_2px_rgba(255,255,255,0.8),0_-3px_6px_rgba(9,9,11,0.02)_inset] cursor-default mb-3">
                            <img src="/images/feature-3.svg" className="h-6 w-6 fill-zinc-950/30 group-hover:fill-teal-500 group-hover:[filter:drop-shadow(0_0_2px_rgba(45,212,191,0.3))]" />
                        </div>
                        <h4 className="font-display text-[1.25rem] font-semibold text-zinc-950 mb-1 sm:mb-2">Messaging</h4>
                        <p className="text-base font-normal text-zinc-500">Boost conversions by communicating with customers in a personal way</p>
                    </div>
                    <div className="flex flex-col items-center md:px-5 text-center max-w-sm mx-auto">
                        <div className="group h-16 w-16 border-2 flex items-center justify-center rounded-2xl  border border-zinc-950/[7%] shadow-[0_1px_4px_2px_rgba(9,9,11,0.02),0_0px_1px_2px_rgba(255,255,255,0.8),0_-3px_6px_rgba(9,9,11,0.02)_inset] cursor-default mb-3">
                            <img src="/images/feature-4.svg" className="h-6 w-6 fill-zinc-950/30 group-hover:fill-teal-500 group-hover:[filter:drop-shadow(0_0_2px_rgba(45,212,191,0.3))] " />
                        </div>
                        <h4 className="font-display text-[1.25rem] font-semibold text-zinc-950 mb-1 sm:mb-2">3D feedback</h4>
                        <p className="text-base font-normal text-zinc-500">Collect in-depth feedback directly on your 3D models from your customers</p>
                    </div>
                    <div className="flex flex-col items-center md:px-5 text-center max-w-sm mx-auto">
                        <div className="group h-16 w-16 border-2 flex items-center justify-center rounded-2xl  border border-zinc-950/[7%] shadow-[0_1px_4px_2px_rgba(9,9,11,0.02),0_0px_1px_2px_rgba(255,255,255,0.8),0_-3px_6px_rgba(9,9,11,0.02)_inset] cursor-default mb-3">
                            <img src="/images/feature-5.svg" className="h-6 w-6 fill-zinc-950/30 group-hover:fill-teal-500 group-hover:[filter:drop-shadow(0_0_2px_rgba(45,212,191,0.3))] " viewBox="0 0 40 40" fill="none" />
                        </div>
                        <h4 className="font-display text-[1.25rem] font-semibold text-zinc-950 mb-1 sm:mb-2">Project management</h4>
                        <p className="text-base font-normal text-zinc-500">Keep organized with dashboards, version control, and file storage</p>
                    </div>
                    <div className="flex flex-col items-center md:px-5 text-center max-w-sm mx-auto">
                        <div className="group h-16 w-16 border-2 flex items-center justify-center rounded-2xl  border border-zinc-950/[7%] shadow-[0_1px_4px_2px_rgba(9,9,11,0.02),0_0px_1px_2px_rgba(255,255,255,0.8),0_-3px_6px_rgba(9,9,11,0.02)_inset] cursor-default mb-3">
                            <img src="/images/feature-6.svg" className="h-6 w-6 fill-zinc-950/30 group-hover:fill-teal-500 group-hover:[filter:drop-shadow(0_0_2px_rgba(45,212,191,0.3))]" />
                        </div>
                        <h4 className="font-display text-[1.25rem] font-semibold text-zinc-950 mb-1 sm:mb-2">3D viewers</h4>
                        <p className="text-base font-normal text-zinc-500">Securely show off your 3D models with our public and private 3D viewers</p>
                    </div>
                </div>
            </div>
        </div>

    );
}