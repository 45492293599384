import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import { useChat } from '@chatscope/use-chat';
import _ from 'lodash';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuPortal,
} from "@/components/ui/dropdown-menu"

import {
    BellIcon,
    MessagesSquareIcon
} from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"

import { set } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import {
    fetchUsers,
    getImages,
    getUsers,
} from '@/modules/redux/storage';

export function NoticePopup({
    className,
}) {
    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const database = useDatabase();

    const { data: user } = useUser();

    const images = useSelector(getImages);
    const users = useSelector(getUsers);

    const { messages } = useChat();

    const handleClickLink = async (m) => {
        const { object, tags, user, uuid } = m.content;
        const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

        const { data: { token } } = await httpsCallable('encrypt')({
            public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
            payload: { [tag]: { object, user }, timestamp: Date.now() },
        }).catch((e) => ({ data: {} }));

        const messages = await get(databaseRef(database, `messages/${uuid}/${tag}s/${object}`)).then((snapshot) => snapshot.val() || []);

        await Promise.all(messages.map((message, i) =>
            message.unread ? set(databaseRef(database, `messages/${uuid}/${tag}s/${object}/${i}/unread`), false) : Promise.resolve(),
        ));

        navigate(`/messages?token=${token}`);
    };

    const newMessages = Object.values(messages).map((l) =>
        l.reduce((arr, g) => arr.concat(g.messages), [])
            .filter((m) => users[m.content.user] && m.content.user != user.uid))
        .flat()
        .flat()
        .toSorted((m1, m2) => m2.content.timestamp - m1.content.timestamp);

    const newMessagesCount = Object.values(messages).map((l) =>
        l.reduce((arr, g) => arr.concat(g.messages), [])
            .filter((m) => users[m.content.user] && m.content.user != user.uid)
            .filter((m) => m.content.unread))
        .flat()
        .flat()
        .length;

    return (
        <DropdownMenu open={!newMessages.length ? false : undefined}>
            <DropdownMenuTrigger asChild>
                <div className={cn("relative w-9 h-9", className)}>
                    <Button variant="outline" className="w-9 h-9 p-2">
                        <BellIcon />
                        <p className="absolute right-1 top-1 flex text-xs shrink-0">
                            {newMessagesCount || null}
                        </p>
                    </Button>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-80 py-2 max-h-64 overflow-y-auto">
                <DropdownMenuGroup>
                    {newMessages.map((m, i) => <DropdownMenuItem className={m.content.unread ? "bg-accent" : ""}
                        key={i}
                        onSelect={() => handleClickLink(m)}>
                        <button className="flex items-center space-x-2">
                            <MessagesSquareIcon className='w-[18px] h-[18px]' />
                            <Avatar className="h-9 w-9">
                                <AvatarImage src={images[m.content.user]?.url} alt={m.content.user} />
                                <AvatarFallback></AvatarFallback>
                            </Avatar>
                            <p className="flex-1 text-xs font-medium truncate">
                                {(m.content.checkoutSession?.provider == 'stripe' && m.content.checkoutSession?.url) ||
                                 (m.content.checkoutSession?.provider == 'paypal') ?
                                    `${users[m.content.user]?.displayName} sent you a payment request` :
                                    m.content.checkoutSession?.provider == 'stripe' && m.content.checkoutSession?.receiptUrl ?
                                        `${users[m.content.user]?.displayName} fulfilled your payment request` :
                                        m.content.image ?
                                            `${users[m.content.user]?.displayName} sent you an attachment` :
                                            `${users[m.content.user]?.displayName} sent you a message`}
                            </p>
                        </button>
                    </DropdownMenuItem>)}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}