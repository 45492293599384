import * as THREE from 'three';
import React, { useEffect, useRef } from "react";

var rendered = false;

export default function MyThree() {
  const refContainer = useRef(null);

  useEffect(() => {
 
    if (rendered) return;

    rendered = true;

    function createAirplaneMesh() {
        const mesh = new THREE.Object3D()
    
        // Cabin
        var matCabin = new THREE.MeshPhongMaterial({color: Colors.red, flatShading: true, side: THREE.DoubleSide})
    
        const frontUR = [ 40,  25, -25]
        const frontUL = [ 40,  25,  25]
        const frontLR = [ 40, -25, -25]
        const frontLL = [ 40, -25,  25]
        const backUR  = [-40,  15,  -5]
        const backUL  = [-40,  15,   5]
        const backLR  = [-40,   5,  -5]
        const backLL  = [-40,   5,   5]
    
        const vertices = new Float32Array(
            utils.makeTetrahedron(frontUL, frontUR, frontLL, frontLR).concat(   // front
            utils.makeTetrahedron(backUL, backUR, backLL, backLR)).concat(      // back
            utils.makeTetrahedron(backUR, backLR, frontUR, frontLR)).concat(    // side
            utils.makeTetrahedron(backUL, backLL, frontUL, frontLL)).concat(    // side
            utils.makeTetrahedron(frontUL, backUL, frontUR, backUR)).concat(    // top
            utils.makeTetrahedron(frontLL, backLL, frontLR, backLR))            // bottom
        )
        const geomCabin = new THREE.BufferGeometry()
        geomCabin.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
    
        var cabin = new THREE.Mesh(geomCabin, matCabin)
        cabin.castShadow = true
        cabin.receiveShadow = true
        mesh.add(cabin)
    
        // Engine
    
        var geomEngine = new THREE.BoxGeometry(20,50,50,1,1,1);
        var matEngine = new THREE.MeshPhongMaterial({color:Colors.white, flatShading:true,});
        var engine = new THREE.Mesh(geomEngine, matEngine);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        engine.position.x = 50;
        engine.castShadow = true;
        engine.receiveShadow = true;
        mesh.add(engine);
    
        // Tail Plane
        var geomTailPlane = new THREE.BoxGeometry(15,20,5,1,1,1);
        var matTailPlane = new THREE.MeshPhongMaterial({color:Colors.red, flatShading:true,});
        var tailPlane = new THREE.Mesh(geomTailPlane, matTailPlane);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        tailPlane.position.set(-40,20,0);
        tailPlane.castShadow = true;
        tailPlane.receiveShadow = true;
        mesh.add(tailPlane);
    
        // Wings
    
        var geomSideWing = new THREE.BoxGeometry(30,5,120,1,1,1);
        var matSideWing = new THREE.MeshPhongMaterial({color:Colors.red, flatShading:true,});
        var sideWing = new THREE.Mesh(geomSideWing, matSideWing);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        sideWing.position.set(0,15,0);
        sideWing.castShadow = true;
        sideWing.receiveShadow = true;
        mesh.add(sideWing);
    
        var geomWindshield = new THREE.BoxGeometry(3,15,20,1,1,1);
        var matWindshield = new THREE.MeshPhongMaterial({color:Colors.white,transparent:true, opacity:.3, flatShading:true,});;
        var windshield = new THREE.Mesh(geomWindshield, matWindshield);
        windshield.position.set(20,27,0);
    
        windshield.castShadow = true;
        windshield.receiveShadow = true;
    
        mesh.add(windshield);
    
        var geomPropeller = new THREE.BoxGeometry(20, 10, 10, 1, 1, 1);
        geomPropeller.attributes.position.array[4*3+1] -= 5
        geomPropeller.attributes.position.array[4*3+2] += 5
        geomPropeller.attributes.position.array[5*3+1] -= 5
        geomPropeller.attributes.position.array[5*3+2] -= 5
        geomPropeller.attributes.position.array[6*3+1] += 5
        geomPropeller.attributes.position.array[6*3+2] += 5
        geomPropeller.attributes.position.array[7*3+1] += 5
        geomPropeller.attributes.position.array[7*3+2] -= 5
        var matPropeller = new THREE.MeshPhongMaterial({color:Colors.brown, flatShading:true,});
        const propeller = new THREE.Mesh(geomPropeller, matPropeller);
    
        propeller.castShadow = true;
        propeller.receiveShadow = true;
    
        var geomBlade = new THREE.BoxGeometry(1,80,10,1,1,1);
        var matBlade = new THREE.MeshPhongMaterial({color:Colors.brownDark, flatShading:true,});
        var blade1 = new THREE.Mesh(geomBlade, matBlade);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        blade1.position.set(8,0,0);
    
        blade1.castShadow = true;
        blade1.receiveShadow = true;
    
        var blade2 = blade1.clone();
        blade2.rotation.x = Math.PI/2;
    
        blade2.castShadow = true;
        blade2.receiveShadow = true;
    
        propeller.add(blade1);
        propeller.add(blade2);
        propeller.position.set(60,0,0);
        mesh.add(propeller);
    
        var wheelProtecGeom = new THREE.BoxGeometry(30,15,10,1,1,1);
        var wheelProtecMat = new THREE.MeshPhongMaterial({color:Colors.red, flatShading:true,});
        var wheelProtecR = new THREE.Mesh(wheelProtecGeom,wheelProtecMat);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        wheelProtecR.position.set(25,-20,25);
        mesh.add(wheelProtecR);
    
        var wheelTireGeom = new THREE.BoxGeometry(24,24,4);
        var wheelTireMat = new THREE.MeshPhongMaterial({color:Colors.brownDark, flatShading:true,});
        var wheelTireR = new THREE.Mesh(wheelTireGeom,wheelTireMat);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        wheelTireR.position.set(25,-28,25);
    
        var wheelAxisGeom = new THREE.BoxGeometry(10,10,6);
        var wheelAxisMat = new THREE.MeshPhongMaterial({color:Colors.brown, flatShading:true,});
        var wheelAxis = new THREE.Mesh(wheelAxisGeom,wheelAxisMat);
        wheelTireR.add(wheelAxis);
    
        mesh.add(wheelTireR);
    
        var wheelProtecL = wheelProtecR.clone();
        wheelProtecL.position.z = -wheelProtecR.position.z ;
        mesh.add(wheelProtecL);
    
        var wheelTireL = wheelTireR.clone();
        wheelTireL.position.z = -wheelTireR.position.z;
        mesh.add(wheelTireL);
    
        var wheelTireB = wheelTireR.clone();
        wheelTireB.scale.set(.5,.5,.5);
        //Ð¿Ð¾Ð·Ð¸Ñ†Ð¸Ñ
        wheelTireB.position.set(-35,-5,0);
        mesh.add(wheelTireB);
    
        var suspensionGeom = new THREE.BoxGeometry(4,20,4);
        suspensionGeom.applyMatrix4(new THREE.Matrix4().makeTranslation(0,10,0))
        var suspensionMat = new THREE.MeshPhongMaterial({color:Colors.red, flatShading:true,});
        var suspension = new THREE.Mesh(suspensionGeom,suspensionMat);
        suspension.position.set(-35,-5,0);
        suspension.rotation.z = -.3;
        mesh.add(suspension)
    
        const pilot = new Pilot()
        pilot.mesh.position.set(5,27,0)
        mesh.add(pilot.mesh)
    
        mesh.castShadow = true
        mesh.receiveShadow = true
    
        return [mesh, propeller, pilot]
    }
    
    
    
    
    
    const utils = {
        normalize: function (v, vmin, vmax, tmin, tmax) {
            var nv = Math.max(Math.min(v,vmax), vmin)
            var dv = vmax-vmin
            var pc = (nv-vmin)/dv
            var dt = tmax-tmin
            var tv = tmin + (pc*dt)
            return tv
        },
    
        findWhere: function (list, properties) {
            for (const elem of list) {
                let all = true
                for (const key in properties) {
                    if (elem[key] !== properties[key]) {
                        all = false
                        break
                    }
                }
                if (all) {
                    return elem
                }
            }
            return null
        },
    
        randomOneOf: function (choices) {
            return choices[Math.floor(Math.random() * choices.length)]
        },
    
        randomFromRange: function (min, max) {
            return min + Math.random() * (max - min)
        },
    
        collide: function (mesh1, mesh2, tolerance) {
            const diffPos = mesh1.position.clone().sub(mesh2.position.clone())
            const d = diffPos.length()
            return d < tolerance
        },
    
        makeTetrahedron: function (a, b, c, d) {
            return [
                a[0], a[1], a[2],
                b[0], b[1], b[2],
                c[0], c[1], c[2],
                b[0], b[1], b[2],
                c[0], c[1], c[2],
                d[0], d[1], d[2],
            ]
        }
    }
    
    
    
    
    
    
    
    
    
    
    
    class SceneManager {
        constructor() {
            this.list = new Set()
        }
    
        add(obj) {
            scene.add(obj.mesh)
            this.list.add(obj)
        }
    
        remove(obj) {
            scene.remove(obj.mesh)
            this.list.delete(obj)
        }
    
        clear() {
            for (const entry of this.list) {
                this.remove(entry)
            }
        }
    
        tick(deltaTime) {
            for (const entry of this.list) {
                if (entry.tick) {
                    entry.tick(deltaTime)
                }
            }
        }
    }
    
    const sceneManager = new SceneManager()
    
    
    
    
    class LoadingProgressManager {
        constructor() {
            this.promises = []
        }
    
        add(promise) {
            this.promises.push(promise)
        }
    
        then(callback) {
            return Promise.all(this.promises).then(callback)
        }
    
        catch(callback) {
            return Promise.all(this.promises).catch(callback)
        }
    }
    
    const loadingProgressManager = new LoadingProgressManager()
    
    var Colors = {
        red: 0xf25346,
        orange: 0xffa500,
        white: 0xd8d0d1,
        brown: 0x59332e,
        brownDark: 0x23190f,
        pink: 0xF5986E,
        yellow: 0xf4ce93,
        blue: 0x68c3c0,
    }
    
    const COLOR_COINS = 0xFFD700 // 0x009999
    const COLOR_COLLECTIBLE_BUBBLE = COLOR_COINS
    
    
    
    ///////////////
    // GAME VARIABLES
    var canDie = true
    var world, game
    var newTime = new Date().getTime()
    var oldTime = new Date().getTime()
    
    
    
    
    let scene, camera, renderer
    
    
    //SCREEN & MOUSE VARIABLES
    var MAX_WORLD_X=1000
    
    
    
    
    //INIT THREE JS, SCREEN AND MOUSE EVENTS
    function createScene() {
        scene = new THREE.Scene()
        camera = new THREE.PerspectiveCamera(50, ui.width/ui.height, 0.1, 10000)
        scene.fog = new THREE.Fog(0xf7d9aa, 100, 950)
        //scene.background =new THREE.Color(0xff0000)
    
        renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });

        renderer.setSize(ui.width, ui.height)
        renderer.setPixelRatio(window.devicePixelRatio? window.devicePixelRatio : 1)
        refContainer.current && refContainer.current.appendChild(renderer.domElement); 
    
        renderer.shadowMap.enabled = true
    
    
        function setupCamera() {
            renderer.setSize(ui.width, ui.height)
            camera.aspect = ui.width / ui.height
            camera.updateProjectionMatrix()
    
            // setTimeout(() => {
            // 	const rayCaster = new THREE.Raycaster()
            // 	rayCaster.setFromCamera(new THREE.Vector2(1, 1), camera)
            // 	const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0)
            // 	const intersectPoint = new THREE.Vector3()
            // 	rayCaster.ray.intersectPlane(plane, intersectPoint)
            // 	console.log('max world x:', intersectPoint.x)
            // 	// MAX_WORLD_X = intersectPoint.x  doesn't work with first person view
            // }, 500)
        }
    
        setupCamera()
        ui.onResize(setupCamera)
    
        // const controls = new THREE.OrbitControls(camera, renderer.domElement)
        // controls.minPolarAngle = -Math.PI / 2
        // controls.maxPolarAngle = Math.PI
        // controls.addEventListener('change', () => {
        // 	console.log('camera changed', 'camera=', camera.position, ', airplane=', airplane.position, 'camera.rotation=', camera.rotation)
        // })
        // setTimeout(() => {
        // 	camera.lookAt(airplane.mesh.position)
        // 	controls.target.copy(airplane.mesh.position)
        // }, 100)
    
        // controls.noZoom = true
        //controls.noPan = true
    
        // handleWindowResize()
    }
    
    
    
    
    // LIGHTS
    var ambientLight
    
    function createLights() {
        const hemisphereLight = new THREE.HemisphereLight(0xaaaaaa,0x000000, .9)
        ambientLight = new THREE.AmbientLight(0xdc8874, .5)
        ambientLight.intensity = 2
        const shadowLight = new THREE.DirectionalLight(0xffffff, .9)
        shadowLight.position.set(150, 350, 350)
        shadowLight.castShadow = true
        shadowLight.shadow.camera.left = -400
        shadowLight.shadow.camera.right = 400
        shadowLight.shadow.camera.top = 400
        shadowLight.shadow.camera.bottom = -400
        shadowLight.shadow.camera.near = 1
        shadowLight.shadow.camera.far = 1000
        shadowLight.shadow.mapSize.width = 4096
        shadowLight.shadow.mapSize.height = 4096
    
        scene.add(hemisphereLight)
        scene.add(shadowLight)
        scene.add(ambientLight)
    }
    
    
    
    
    class Pilot {
        constructor() {
            this.mesh = new THREE.Object3D()
            this.angleHairs = 0
    
            var bodyGeom = new THREE.BoxGeometry(15,15,15)
            var bodyMat = new THREE.MeshPhongMaterial({
                color: Colors.brown,
                flatShading: true,
            })
            var body = new THREE.Mesh(bodyGeom, bodyMat)
            body.position.set(2, -12, 0)
            this.mesh.add(body)
    
            var faceGeom = new THREE.BoxGeometry(10,10,10)
            var faceMat = new THREE.MeshLambertMaterial({color: Colors.pink})
            var face = new THREE.Mesh(faceGeom, faceMat)
            this.mesh.add(face)
    
            var hairGeom = new THREE.BoxGeometry(4,4,4)
            var hairMat = new THREE.MeshLambertMaterial({color:Colors.brown})
            var hair = new THREE.Mesh(hairGeom, hairMat)
            hair.geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0,2,0))
            var hairs = new THREE.Object3D()
    
            this.hairsTop = new THREE.Object3D()
    
            for (var i=0; i<12; i++) {
                var h = hair.clone();
                var col = i%3;
                var row = Math.floor(i/3);
                var startPosZ = -4;
                var startPosX = -4;
                h.position.set(startPosX + row*4, 0, startPosZ + col*4);
                h.geometry.applyMatrix4(new THREE.Matrix4().makeScale(1,1,1));
                this.hairsTop.add(h);
            }
            hairs.add(this.hairsTop);
    
            var hairSideGeom = new THREE.BoxGeometry(12,4,2);
            hairSideGeom.applyMatrix4(new THREE.Matrix4().makeTranslation(-6,0,0));
            var hairSideR = new THREE.Mesh(hairSideGeom, hairMat);
            var hairSideL = hairSideR.clone();
            hairSideR.position.set(8,-2,6);
            hairSideL.position.set(8,-2,-6);
            hairs.add(hairSideR);
            hairs.add(hairSideL);
    
            var hairBackGeom = new THREE.BoxGeometry(2,8,10);
            var hairBack = new THREE.Mesh(hairBackGeom, hairMat);
            hairBack.position.set(-1,-4,0)
            hairs.add(hairBack);
            hairs.position.set(-5,5,0);
    
            this.mesh.add(hairs);
    
            var glassGeom = new THREE.BoxGeometry(5,5,5);
            var glassMat = new THREE.MeshLambertMaterial({color:Colors.brown});
            var glassR = new THREE.Mesh(glassGeom,glassMat);
            glassR.position.set(6,0,3);
            var glassL = glassR.clone();
            glassL.position.z = -glassR.position.z
    
            var glassAGeom = new THREE.BoxGeometry(11,1,11);
            var glassA = new THREE.Mesh(glassAGeom, glassMat);
            this.mesh.add(glassR);
            this.mesh.add(glassL);
            this.mesh.add(glassA);
    
            var earGeom = new THREE.BoxGeometry(2,3,2);
            var earL = new THREE.Mesh(earGeom,faceMat);
            earL.position.set(0,0,-6);
            var earR = earL.clone();
            earR.position.set(0,0,6);
            this.mesh.add(earL);
            this.mesh.add(earR);
        }
    
    
        updateHairs(deltaTime) {
            var hairs = this.hairsTop.children
            var l = hairs.length
            for (var i=0; i<l; i++) {
                var h = hairs[i]
                h.scale.y = .75 + Math.cos(this.angleHairs+i/3)*.25
            }
            this.angleHairs += game.speed * deltaTime * 40
        }
    }
    
    
    class Airplane {
        constructor() {
            const [mesh, propeller, pilot] = createAirplaneMesh()
            this.mesh = mesh
            this.propeller = propeller
            this.pilot = pilot
            this.weapon = null
            this.lastShot = 0
        }
    
    
        equipWeapon(weapon) {
            if (this.weapon) {
                this.mesh.remove(this.weapon.mesh)
            }
            this.weapon = weapon
            if (this.weapon) {
                this.mesh.add(this.weapon.mesh)
            }
        }
    
    
        shoot() {
            if (!this.weapon) {
                return
            }
    
            // rate-limit the shooting
            const nowTime = new Date().getTime() / 1000
            const ready = nowTime-this.lastShot > this.weapon.downtime()
            if (!ready) {
                return
            }
            this.lastShot = nowTime
    
            // fire the shot
            let direction = new THREE.Vector3(10, 0, 0)
            direction.applyEuler(airplane.mesh.rotation)
            this.weapon.shoot(direction)
    
            // recoil airplane
            const recoilForce = this.weapon.damage()
        }
    
    
        tick(deltaTime) {
            this.propeller.rotation.x += 0.2 + game.planeSpeed * deltaTime*.005
    
            if (game.status === 'playing') {
                game.planeSpeed = utils.normalize(ui.mousePos.x, -0.5, 0.5, world.planeMinSpeed, world.planeMaxSpeed)
                /*
                let targetX = utils.normalize(ui.mousePos.x, -1, 1, -world.planeAmpWidth*0.7, -world.planeAmpWidth)
                let targetY = utils.normalize(ui.mousePos.y, -0.75, 0.75, world.planeDefaultHeight-world.planeAmpHeight, world.planeDefaultHeight+world.planeAmpHeight)
                */
                let targetX = ui.mousePos.x
                let targetY = ui.mousePos.y
    
                game.planeCollisionDisplacementX += game.planeCollisionSpeedX
                targetX += game.planeCollisionDisplacementX
    
                game.planeCollisionDisplacementY += game.planeCollisionSpeedY
                targetY += game.planeCollisionDisplacementY
                
                this.mesh.position.x += (targetX - this.mesh.position.x) * deltaTime * world.planeMoveSensivity
                this.mesh.position.y += (targetY - this.mesh.position.y) * deltaTime * world.planeMoveSensivity
    
                this.mesh.rotation.x = (this.mesh.position.y - targetY) * deltaTime * world.planeRotZSensivity
                this.mesh.rotation.z = (targetY - this.mesh.position.y) * deltaTime * world.planeRotXSensivity
                /*
                if (game.fpv) {
                    camera.position.y = this.mesh.position.y + 20
                    // camera.setRotationFromEuler(new THREE.Euler(-1.490248, -1.4124514, -1.48923231))
                    // camera.updateProjectionMatrix ()
                } else {
                    camera.fov = utils.normalize(ui.mousePos.x, -30, 1, 40, 80)
                    camera.updateProjectionMatrix()
                    camera.position.y += (this.mesh.position.y - camera.position.y)// * deltaTime * world.cameraSensivity
                }
                */
            }
    
            game.planeCollisionSpeedX += (0-game.planeCollisionSpeedX)*deltaTime * 0.03;
            game.planeCollisionDisplacementX += (0-game.planeCollisionDisplacementX)*deltaTime *0.01;
            game.planeCollisionSpeedY += (0-game.planeCollisionSpeedY)*deltaTime * 0.03;
            game.planeCollisionDisplacementY += (0-game.planeCollisionDisplacementY)*deltaTime *0.01;
    
            this.pilot.updateHairs(deltaTime)
        }
    
    
        gethit(position) {
            const diffPos = this.mesh.position.clone().sub(position)
            const d = diffPos.length()
            game.planeCollisionSpeedX = 100 * diffPos.x / d
            game.planeCollisionSpeedY = 100 * diffPos.y / d
            //ambientLight.intensity = 2
        }
    }
        
    const COLOR_SEA_LEVEL = [
        0x68c3c0,  // hsl(178deg 43% 59%)
        0x47b3af,  // hsl(178deg 43% 49%)
        0x398e8b,  // hsl(178deg 43% 39%)
        0x2a6a68,  // hsl(178deg 43% 29%)
        0x1c4544,  // hsl(178deg 43% 19%)
        0x0d2120,  // hsl(178deg 43% 09%)
    ]
    
    // 3D Models
    let airplane
    
    
    function createPlane() {
        airplane = new Airplane()
        airplane.mesh.scale.set(.25,.25,.25)
        airplane.mesh.position.y = world.planeDefaultHeight
        scene.add(airplane.mesh)
    }
    
    function loop() {
        newTime = new Date().getTime()
        const deltaTime = newTime - oldTime
        oldTime = newTime
    
        if (game.status == 'playing') {
            if (!game.paused) {
                if (ui.mouseButtons[0] || ui.keysDown['Space']) {
                    airplane.shoot()
                }
    
                airplane.tick(deltaTime)
                game.distance += game.speed * deltaTime * world.ratioSpeedDistance
                game.baseSpeed += (game.targetBaseSpeed - game.baseSpeed) * deltaTime * 0.02
                game.speed = game.baseSpeed * game.planeSpeed
    
                if (game.lifes<=0 && canDie) {
                    game.status = "gameover"
                }
            }
        }
    
        if (!game.paused) {
            airplane.tick(deltaTime)
    
            sceneManager.tick(deltaTime)
        }
    
        renderer.render(scene, camera)
        requestAnimationFrame(loop)
    }
        
    function setSideView() {
        game.fpv = false
        camera.position.set(0, world.planeDefaultHeight, 200)
        camera.setRotationFromEuler(new THREE.Euler(0, 0, 0))
    }
    
    
    function setFollowView() {
        game.fpv = true
        camera.position.set(-89, airplane.mesh.position.y+20, 0)
        camera.setRotationFromEuler(new THREE.Euler(-1.490248, -1.4124514, -1.48923231))
        camera.updateProjectionMatrix ()
    }
    
    
    
    
    
    
    class UI {
        constructor() {
    
            document.addEventListener('keydown', this.handleKeyDown.bind(this), false)
            document.addEventListener('keyup', this.handleKeyUp.bind(this), false)
            document.addEventListener('mousedown', this.handleMouseDown.bind(this), false)
            document.addEventListener('mouseup', this.handleMouseUp.bind(this), false)
            window.addEventListener('mousemove', this.handleMouseMove.bind(this), false)
            //document.addEventListener('blur', this.handleBlur.bind(this), false)
    
            //document.oncontextmenu = document.body.oncontextmenu = function() {return false;}
    
            window.addEventListener('resize', this.handleWindowResize.bind(this), false)
    
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.mousePos = {x: 0, y: 0}
            this.canvas = document.getElementById('threejs-canvas')
    
            this.mouseButtons = [false, false, false]
            this.keysDown = {}
    
            this._resizeListeners = []
        }
    
    
        onResize(callback) {
            this._resizeListeners.push(callback)
        }
    
    
        handleWindowResize(event) {
            this.width = window.innerWidth
            this.height = window.innerHeight
    
            for (const listener of this._resizeListeners) {
                listener()
            }
        }
    
    
        handleMouseMove(event) {
            var tx = -1 + (event.clientX / this.width) * 2
            var ty = 1 - ((event.clientY + 40) / this.height) * 2
            //this.mousePos = {x:tx, y:ty}

            var vector = new THREE.Vector3(tx, ty, 0.5);
            vector.unproject( camera );
            var dir = vector.sub( camera.position ).normalize();
            var distance = - camera.position.z / dir.z;
            var pos = camera.position.clone().add( dir.multiplyScalar( distance ) );

            //airplane.mesh.rotation.x = (airplane.mesh.position.y - targetY)// * deltaTime * world.planeRotZSensivity
            //airplane.mesh.rotation.z = (targetY - airplane.mesh.position.y)// * deltaTime * world.planeRotXSensivity

            camera.fov = utils.normalize(tx, -30, 1, 40, 80)
            camera.updateProjectionMatrix()

            this.mousePos = {x: pos.x - tx, y: pos.y - ty}
        }
    
        handleTouchMove(event) {
            event.preventDefault()
            var tx = -1 + (event.touches[0].pageX / this.width)*2
            var ty = 1 - (event.touches[0].pageY / this.height)*2
            this.mousePos = {x: tx, y: ty}
        }
    
         handleMouseDown(event) {
            this.mouseButtons[event.button] = true
    
            if (event.button===1 && game.status==='playing') {
                airplane.shoot()
            }
        }
    
        handleKeyDown(event) {
            this.keysDown[event.code] = true
            if (event.code === 'KeyP') {
                game.paused = !game.paused
            }
            if (event.code === 'Space') {
                airplane.shoot()
            }
            if (event.code === 'Enter') {
                if (game.fpv) {
                    setSideView()
                } else {
                    setFollowView()
                }
            }
        }
    
        handleKeyUp(event) {
            this.keysDown[event.code] = false
        }
    
        handleMouseUp(event) {
            this.mouseButtons[event.button] = false
            event.preventDefault()
    
            if (game && game.status == "waitingReplay") {
                resetMap()
                game.paused = false
            }
        }
    
        handleBlur(event) {
            this.mouseButtons = [false, false, false]
        }
    
    
        // function handleTouchEnd(event) {
        // 	if (game.status == "waitingReplay"){
        // 		resetGame()
        // 		ui.hideReplay()
        // 	}
        // }
    
   
        showError(message) {
            /*
            document.getElementById('error').style.visibility = 'visible'
            document.getElementById('error-message').innerText = message
            */
        }
    }
    let ui
    
    
    
    function createWorld() {
        world = {
            initSpeed: 0.00035,
            incrementSpeedByTime: 0.0000025,
            incrementSpeedByLevel: 0.000005,
            distanceForSpeedUpdate: 100,
            ratioSpeedDistance: 50,
    
            simpleGunLevelDrop: 1.1,
            doubleGunLevelDrop: 2.3,
            betterGunLevelDrop: 3.5,
    
            maxLifes: 3,
            pauseLifeSpawn: 400,
    
            levelCount: 6,
            distanceForLevelUpdate: 500,
    
            planeDefaultHeight: 100,
            planeAmpHeight: 80,
            planeAmpWidth: 75,
            planeMoveSensivity: 0.005,
            planeRotXSensivity: 0.0008,
            planeRotZSensivity: 0.0004,
            planeMinSpeed: 1.2,
            planeMaxSpeed: 1.6,
    
            seaRadius: 600,
            seaLength: 800,
            wavesMinAmp: 5,
            wavesMaxAmp: 20,
            wavesMinSpeed: 0.001,
            wavesMaxSpeed: 0.003,
    
            cameraSensivity: 0.002,
    
            coinDistanceTolerance: 15,
            coinsSpeed: 0.5,
            distanceForCoinsSpawn: 50,
    
            collectibleDistanceTolerance: 15,
            collectiblesSpeed: 0.6,
    
            enemyDistanceTolerance: 10,
            enemiesSpeed: 0.6,
            distanceForEnemiesSpawn: 50,
        }
    
        // create the world
        createScene()
        createLights()
        createPlane()
    
        resetMap()
    }
    
    
    
    function resetMap() {
        game = {
            status: 'playing',
    
            speed: 0,
            paused: false,
            baseSpeed: 0.00035,
            targetBaseSpeed: 0.00035,
            speedLastUpdate: 0,
    
            distance: 0,
    
            coins: 0,
            fpv: false,
    
            // gun spawning
            spawnedSimpleGun: false,
            spawnedDoubleGun: false,
            spawnedBetterGun: false,
    
            lastLifeSpawn: 0,
            lifes: world.maxLifes,
    
            level: 1,
            levelLastUpdate: 0,
    
            planeFallSpeed: 0.001,
            planeSpeed: 0,
            planeCollisionDisplacementX: 0,
            planeCollisionSpeedX: 0,
            planeCollisionDisplacementY: 0,
            planeCollisionSpeedY: 0,
    
            coinLastSpawn: 0,
            enemyLastSpawn: 0,
    
            statistics: {
                coinsCollected: 0,
                coinsSpawned: 0,
                enemiesKilled: 0,
                enemiesSpawned: 0,
                shotsFired: 0,
                lifesLost: 0,
            }
        }

        sceneManager.clear()
        
        setSideView()
    
        airplane.equipWeapon(null)
    }
    
    
    
    let soundPlaying = false
    
    function startMap() {
        if (!soundPlaying) {
            soundPlaying = true
        }
    
        createWorld()
        loop()
    
        game.paused = false
    }
    
    
    
    function onWebsiteLoaded(event) {
    
        ui = new UI()
        loadingProgressManager
            .catch((err) => {
                ui.showError(err.message)
            })

        startMap()
    }
    
    onWebsiteLoaded()
    //window.addEventListener('load', onWebsiteLoaded, false)
    
    
  }, []);
  return (
    <div ref={refContainer} className="absolute w-full h-full z-50" style={{ pointerEvents: 'none' }}></div>

  );
}
