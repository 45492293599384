import React from 'react';
import { useSelector } from 'react-redux';
import {
    ChevronRightIcon,
    ImageIcon,
    XIcon,
    UserCircle,
} from "lucide-react"
import moment from 'moment';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectGroup,
    SelectValue,
} from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { Toggle } from "@/components/ui/toggle"
import { Badge } from "@/components/ui/badge"

import { cn } from '@/lib/utils';
import { getImages } from '@/modules/redux/storage';
import { getPayloadFromToken } from '@/modules/utils';

export default function ProjectDetailComment({
    comment,
    uuid,
    showLabels = false,
    onClick,
    onDelete,
    onImageClick = () => { },
}) {

    const images = useSelector(getImages);

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        onDelete();
    };

    const handleImageClick = async (e) => {
        e.stopPropagation();

        const blob = await fetch(images[comment.image.url]).then((res) => res.blob());
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = images[comment.image].name;
        link.click();

        URL.revokeObjectURL(url);
        link.remove();
    };

    return (
        <div
            onClick={onClick}
            className={cn(
                "flex flex-col border rounded-sm p-4 space-y-3 max-h-96 overflow-y-auto relative",
                onClick ? "cursor-pointer" : "cursor-default",
            )}>
            {onDelete ? <Button variant="ghost" size="icon" className="absolute right-0 top-0" onClick={handleDeleteClick}>
                <XIcon className="w-4 h-4 text-muted-foreground" />
            </Button> : <div className="ml-auto">
                <p className="text-xs text-muted-foreground">
                    {moment(comment.timestamp).format('MM/DD/YYYY h:mm A')}
                </p>
            </div>}
            <div className="flex w-full items-center gap-4">
                <Avatar className="hidden h-9 w-9 sm:flex">
                    {comment?.user in images ? <AvatarImage src={images[comment.user].url} alt="Avatar" className="object-cover" /> :
                        !comment?.user ? <UserCircle /> : null}
                </Avatar>
            </div>
            <div className="flex flex-1 text-sm font-medium overflow-y-auto break-words"
                style={{ wordBreak: 'break-word' }}>
                {comment.text}
                {comment.image && images?.[comment.image] && <Button variant="link" onClick={handleImageClick}>
                    {images[comment.image].name}</Button>}
            </div>
            {onDelete ? <div className="flex justify-end">
                <p className="text-sm text-muted-foreground">
                    {moment(comment.timestamp).format('MM/DD/YYYY h:mm A')}
                </p>
            </div> : <div className="flex items-center gap-2">
                {comment.tags.map((tag) =>
                    <Badge key={tag.label}>
                        {tag.label}
                    </Badge>
                )}
            </div>}
        </div>
    );
}