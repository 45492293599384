"use client"

import * as React from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import { useDatabase, useStorage, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import { ref as storageRef, getMetadata } from 'firebase/storage';

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"

import { set, increment } from '@/modules/firebase/database';
import { getFolderSize, deleteFolder } from '@/modules/utils';

import { DataTablePagination } from "../data-table-pagination"
import { DataTableToolbar } from "../data-table-toolbar"
import { makeData } from "../../data/tasks";

function useSkipper() {
  const shouldSkipRef = React.useRef(true)
  const shouldSkip = shouldSkipRef.current

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  React.useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}

export function DataTable({
  projects: [projects, fetchProjects] = [, () => { }],
  columns,
  ...props
}) {

  const { pathname } = useLocation();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(
      projects?.map((project) => ({
        uuid: project.uuid,
        name: project.name || 'Project Name',
        comments: project.objects.reduce((sum, object) => sum +
          Object.values(object.reviews || {}).reduce((_sum, review) => _sum + (review.comments || []).length, 0)
          , 0).toString(),
        models: project.objects.length.toString(),
        date: new Date(project.createdAt).toDateString(),
        token: project.token,
        status: project.status || 'todo',
      })) || []);
  }, [projects]);

  let navigate = useNavigate();

  const handleClickTableRow = (rowIndex) => {
    navigate(`/project?token=${data[rowIndex].token}`);
  };

  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] =
    React.useState({})
  const [columnFilters, setColumnFilters] = React.useState(
    []
  )
  const [sorting, setSorting] = React.useState([])

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

  const updateData = async (rowIndex, columnId, value) => {
    // Skip page index reset until after next rerender
    skipAutoResetPageIndex();

    await set(databaseRef(database, `projects/${data[rowIndex].uuid}/${columnId}`), value);

    fetchProjects();
  };

  const deleteRow = async (rowIndex) => {
    const project = projects[rowIndex];

    if (project.createdBy == user.uid) {
      const size = await Promise.all(project.objects.map((object) => Promise.all([
        Promise.resolve(object.size) ||
        getMetadata(storageRef(storage, `objects/${object.uuid}/${object.name}`))
          .then((metadata) => metadata.size),
        getFolderSize(storageRef(storage, `maps/${object.uuid}`)),
        getFolderSize(storageRef(storage, `environments/${object.uuid}`)),
        getFolderSize(storageRef(storage, `comments/${object.uuid}`)),
      ])
        .then(([objectSize, mapsSize, environmentsSize, commentsSize]) => objectSize + mapsSize + environmentsSize + commentsSize)
      ))
        .then((objectSizes) => objectSizes.reduce((sum, size) => sum + size, 0));


      await Promise.all(project.objects.map((object) => Promise.all([
        // deleteObject(storageRef(storage, `objects/${object.uuid}/${object.name}`)),
        deleteFolder(storageRef(storage, `maps/${object.uuid}`)),
        deleteFolder(storageRef(storage, `environments/${object.uuid}`)),
        deleteFolder(storageRef(storage, `thumbnails/${object.uuid}`)),
        set(databaseRef(database, `objects/${object.uuid}`), null),
        set(databaseRef(database, `redirects/${object.redirect}`), null),
        set(databaseRef(database, `maps/${object.uuid}`), null),
        set(databaseRef(database, `environments/${object.uuid}`), null),
      ])).concat([
        set(databaseRef(database, `projects/${project.uuid}`), null),
        set(databaseRef(database, `redirects/${project.redirect}`), null),
      ]));

      await Promise.all([
        set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-size)),
        Promise.all(Object.keys(project.editors || {}).map((uid) =>
          set(databaseRef(database, `users/${uid}/editing/${project.uuid}`), null),
        )),
        set(databaseRef(database, `users/${user.uid}/projects/${project.uuid}`), null),
      ])

    }
    else {
      await Promise.all([
        set(databaseRef(database, `users/${user.uid}/editing/${project.uuid}`), null),
        set(databaseRef(database, `projects/${project.uuid}/editors/${project.uuid}`), null),
      ]);
    }
  };

  const navigateToViewer = (rowIndex) => {
    navigate(`/project?token=${data[rowIndex].token}`, { state: {
      back: pathname.split('/')[1] != 'projects' ?  `/${pathname.split('/')[1]}` : '',
    } });
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    updateData,
    deleteRow,
    navigateToViewer,
  })

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table} {...props} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                // onClick={() => handleClickTableRow(index)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
