import React from 'react';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

export default function Pricing({ setPricing = () => {} }) {
    return (
        <div className="py-24 px-4">
            <section className="text-center">
                <h2 className="text-3xl font-bold">Pricing Plans</h2>
                <p className="text-xl pt-2 pb-2 text-muted-foreground">Free to sell 3D models. Upgrade for extra features and collaboration tools.</p><br />
            </section>
            <Tabs defaultValue="monthly" className="w-full">
                <TabsList className="flex mx-auto w-40 h-10 p-1 py-6 px-2">
                    <TabsTrigger value="monthly" className="px-3 py-1.5 font-medium text-base">Monthly</TabsTrigger>
                    <TabsTrigger value="yearly" className="px-3 py-1.5 font-medium text-base">Yearly</TabsTrigger>
                </TabsList>
                <TabsContent value="monthly">
                    <section className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8 mt-8">
                        <div className="rounded-lg border bg-card text-card-foreground shadow-sm w-96 flex flex-col justify-between py-1 mx-auto sm:mx-0">
                            <div>
                                <div className="flex flex-col space-y-1.5 p-6 pb-8 pt-4">
                                    <h3 className="font-semibold tracking-tight text-zinc-700 dark:text-zinc-300 text-lg">Core</h3>
                                    <div className="flex gap-0.5">
                                        <h3 className="text-3xl font-bold">$0</h3>
                                        <span className="flex flex-col justify-end text-sm mb-1">/month</span>
                                    </div>
                                    <p className="text-sm text-muted-foreground pt-1.5 h-12">Essential features you need to sell 3D models</p>
                                </div>
                                <div className="p-6 pt-0 flex flex-col gap-2">
                                <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload up to 25 GB</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to marketplace</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to custom 3D website</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Public and private 3D viewers</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">3 projects</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">3D annotations</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">File sharing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center p-6 pt-0 mt-2">
                                <button className="text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 py-2 relative inline-flex w-full items-center justify-center rounded-md bg-black text-white dark:bg-white px-6 font-medium dark:text-black transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                                    <div className="absolute -inset-0.5 -z-10 rounded-lg bg-gradient-to-b from-[#c7d2fe] to-[#8678f9] opacity-75 blur"></div>
                                    Get Started
                                </button>
                            </div>
                        </div>
                        <div className="rounded-lg border bg-card text-card-foreground shadow-sm w-96 flex flex-col justify-between py-1 mx-auto sm:mx-0">
                            <div>
                                <div className="flex flex-col space-y-1.5 p-6 pb-8 pt-4">
                                    <h3 className="font-semibold tracking-tight text-zinc-700 dark:text-zinc-300 text-lg">Pro</h3>
                                    <div className="flex gap-0.5">
                                        <h3 className="text-3xl font-bold">$19</h3>
                                        <span className="flex flex-col justify-end text-sm mb-1">/month</span>
                                    </div>
                                    <p className="text-sm text-muted-foreground pt-1.5 h-12">Perfect for small &amp; medium 3D modelling businessess</p>
                                </div>
                                <div className="p-6 pt-0 flex flex-col gap-2">
                                <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Unlimited uploads</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to marketplace</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Appear at the top of searches (coming soon)</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to custom 3D website</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Public and private 3D viewers</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Unlimited projects</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">3D annotations</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">File sharing with password protection</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Embed 3D viewer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center p-6 pt-0 mt-2">
                                <button className="text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 py-2 relative inline-flex w-full items-center justify-center rounded-md bg-black text-white dark:bg-white px-6 font-medium dark:text-black transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                                    onClick={() => setPricing({ plan: 'premium', planType: 'monthly' })}>
                                    <div className="absolute -inset-0.5 -z-10 rounded-lg bg-gradient-to-b from-[#c7d2fe] to-[#8678f9] opacity-75 blur"></div>
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </section>
                </TabsContent>
                <TabsContent value="yearly">
                    <section class="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8 mt-8">
                        <div class="rounded-lg border bg-card text-card-foreground shadow-sm w-96 flex flex-col justify-between py-1 mx-auto sm:mx-0">
                            <div>
                                <div class="flex flex-col space-y-1.5 p-6 pb-8 pt-4">
                                    <div class="flex justify-between">
                                        <h3 class="font-semibold tracking-tight text-zinc-700 dark:text-zinc-300 text-lg">Core</h3>
                                    </div>
                                    <div class="flex gap-0.5">
                                        <h3 class="text-3xl font-bold">$0</h3>
                                        <span class="flex flex-col justify-end text-sm mb-1">/year</span>
                                    </div>
                                    <p class="text-sm text-muted-foreground pt-1.5 h-12">Essential features you need to sell 3D models</p>
                                </div>
                                <div class="p-6 pt-0 flex flex-col gap-2">
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload up to 25 GB</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to marketplace</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to custom 3D website</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Public and private 3D viewers</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">3 projects</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">3D annotations</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">File sharing</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center p-6 pt-0 mt-2">
                                <button class="text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 py-2 relative inline-flex w-full items-center justify-center rounded-md bg-black text-white dark:bg-white px-6 font-medium dark:text-black transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                                    <div class="absolute -inset-0.5 -z-10 rounded-lg bg-gradient-to-b from-[#c7d2fe] to-[#8678f9] opacity-75 blur"></div>
                                    Get Started
                                </button>
                            </div>
                        </div>
                        <div class="rounded-lg border bg-card text-card-foreground shadow-sm w-96 flex flex-col justify-between py-1 mx-auto sm:mx-0">
                            <div>
                                <div class="flex flex-col space-y-1.5 p-6 pb-8 pt-4">
                                    <div class="flex justify-between">
                                        <h3 class="font-semibold tracking-tight text-zinc-700 dark:text-zinc-300 text-lg">Pro</h3>
                                        <div class="px-2.5 rounded-xl h-fit text-sm py-1 bg-zinc-200 text-black dark:bg-zinc-800 bg-gradient-to-r from-orange-400 to-rose-400 dark:text-black">Save 20%</div>
                                    </div>
                                    <div class="flex gap-0.5">
                                        <h3 class="text-3xl font-bold">$180</h3>
                                        <span class="flex flex-col justify-end text-sm mb-1">/year</span>
                                    </div>
                                    <p class="text-sm text-muted-foreground pt-1.5 h-12">Perfect for small &amp; medium 3D modelling businessess</p>
                                </div>
                                <div class="p-6 pt-0 flex flex-col gap-2">
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Unlimited uploads</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to marketplace</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Appear at the top of searches (coming soon)</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Upload to custom 3D website</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Public and private 3D viewers</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Unlimited projects</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">3D annotations</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">File sharing with password protection</p>
                                    </div>
                                    <div class="flex gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="my-auto text-green-400">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="m9 12 2 2 4-4"></path>
                                        </svg>
                                        <p class="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">Embed 3D viewer</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center p-6 pt-0 mt-2">
                                <button class="text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 py-2 relative inline-flex w-full items-center justify-center rounded-md bg-black text-white dark:bg-white px-6 font-medium dark:text-black transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                                    onClick={() => setPricing({ plan: 'premium', planType: 'yearly' })}>
                                    <div class="absolute -inset-0.5 -z-10 rounded-lg bg-gradient-to-b from-[#c7d2fe] to-[#8678f9] opacity-75 blur"></div>
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </section>
                </TabsContent>
            </Tabs>
            <div className="w-96 md:w-[800px] mx-auto mt-24">
                <Accordion type="single" collapsible className="w-full border rounded-lg py-4">
                    <AccordionItem value="item-1">
                        <AccordionTrigger className="px-4">Is it free to sell 3D models on Modelsend?</AccordionTrigger>
                        <AccordionContent className="px-4">
                        Yes! The core plan lets you upload 25 GB of your 3D models to the marketplace and your custom website to sell. We never charge you a fee. The core plan also comes with commission tools that help manage your commissions like 3D annotations and a private online 3D viewers.
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-2">
                        <AccordionTrigger className="px-4">What's the benefit of upgrading to a paid plan?</AccordionTrigger>
                        <AccordionContent className="px-4">
                        With premium you get more robust commission tools and get to increase the amount of files you can share publicly and privately. You also appear at the top of marketplace searches.
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-3">
                        <AccordionTrigger className="px-4">How do commissions work?</AccordionTrigger>
                        <AccordionContent className="px-4">
                        When customers message you about commissioning a unique 3d model, it will appear in your messages tab. From there you can add a project to the message to show your work in progress 3D model in a private 3D viewer. You can also request a fee before starting or request a fee before sending the finished commissioned model.
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-4">
                        <AccordionTrigger className="px-4">How do I receive my funds?</AccordionTrigger>
                        <AccordionContent className="px-4">
                        Just link your stripe account and all payments will automatically be available there.
                        </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-5" className="border-b-0">
                        <AccordionTrigger className="px-4">How can I ask other questions?</AccordionTrigger>
                        <AccordionContent className="px-4">
                        You can contact us at support@modelsend.com
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}