import * as React from "react"
import { useDatabase, useStorage, useUser } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import { ref as storageRef, deleteObject } from 'firebase/storage';

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectSeparator,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

import { Toggle } from "@/components/ui/toggle"


import { ModelItem } from "@/components/products/model-item"

import categories from '@/config/categories';
import fileTypes from '@/config/file-types';

import { set, increment } from '@/modules/firebase/database';
import { getFolderSize, deleteFolder } from '@/modules/utils';

export default function Models({
    objects = [],
    objectThumbnails = {},
    category: [category, setCategory] = [false, () => { }],
    fileType: [fileType, setFileType] = [false, () => { }],
    free: [free, setFree] = [false, () => { }],
    ...props
}) {

    const { website: [, fetchWebsite] = [, () => { }] } = props;

    const database = useDatabase();

    const storage = useStorage();

    const { data: user } = useUser();

    const [open, setOpen] = React.useState();

    const handleDelete = async (object) => {
        await Promise.all([object.root].concat(object.versions).map((objectUuid) =>
            (objects?.find((_object) => _object.uuid == objectUuid) ?
                Promise.resolve(objects?.find((_object) => _object.uuid == objectUuid)) :
                get(databaseRef(database, `objects/${objectUuid}`))
                    .then((snapshot) => snapshot.val())
                    .then((_object) => ({ ..._object, uuid: objectUuid }))
            )
                .then((_object) => Promise.all([
                    deleteObject(storageRef(storage, `objects/${object.uuid}/${object.metadata.name}`)),
                    Promise.all([
                        getFolderSize(storageRef(storage, `maps/${_object.uuid}`)),
                        getFolderSize(storageRef(storage, `environments/${_object.uuid}`)),
                        getFolderSize(storageRef(storage, `comments/${_object.uuid}`)),
                    ])
                        .then(([mapsSize, environmentsSize, commentsSize]) => Promise.all([
                            deleteFolder(storageRef(storage, `maps/${_object.uuid}`)),
                            // deleteFolder(storageRef(storage, `thumbnails/${_object.uuid}`)),
                            deleteFolder(storageRef(storage, `comments/${_object.uuid}`)),
                            set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(- mapsSize - environmentsSize - commentsSize - _object.size)),
                        ])),
                    set(databaseRef(database, `users/${user.uid}/website/objects/${_object.uuid}`), null),
                    set(databaseRef(database, `objects/${_object.uuid}/deleted`), true),
                    // set(databaseRef(database, `objects/${_object.uuid}`), null),
                    set(databaseRef(database, `redirects/${_object.redirect}`), null),
                    set(databaseRef(database, `maps/${_object.uuid}`), null),
                    set(databaseRef(database, `environments/${_object.uuid}`), null),

                    // Promise.all(Object.keys(object.editRequests || {}).map((uid) => Promise.all([
                    //     set(databaseRef(database, `messages/${uid}/editRequests/${_object.uuid}`), null),
                    //     set(databaseRef(database, `users/${uid}/editRequests/${user.uid}/${_object.uuid}`), null),
                    // ]))),

                    // Promise.all(Object.keys(object.commissions || {}).map((uid) => Promise.all([
                    //     set(databaseRef(database, `messages/${uid}/commissions/${_object.uuid}`), null),
                    //     set(databaseRef(database, `users/${uid}/commissions/${user.uid}/${_object.uuid}`), null),
                    // ]))),
                ]))
        ));

        fetchWebsite();
    };

    return (
        <div className="flex flex-col gap-4 my-4">
            <div className="flex flex-1 items-center space-x-2">
                <Select required={false} value={category} onValueChange={setCategory}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="All categories" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value={false}>All categories</SelectItem>
                            <SelectSeparator />
                            {categories.map((category) =>
                                <SelectItem key={category} value={category}>{category}</SelectItem>
                            )}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <Select required={false} value={fileType} onValueChange={setFileType}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="All file types" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value={false}>All file types</SelectItem>
                            <SelectSeparator />
                            {fileTypes.map((fileType) =>
                                <SelectItem key={fileType} value={fileType}>{fileType}</SelectItem>
                            )}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <Toggle variant="outline" aria-label="Toggle italic" pressed={free} onPressedChange={setFree}>
                    Free
                </Toggle>
            </div>
            <div className="relative">
                <ScrollArea>
                    <div className="grid md:grid-cols-4 grid-cols-2 justify-items-stretch gap-6">
                        {objects.map((object) => (
                            <ModelItem
                                key={object.uuid}
                                open={[open == object.uuid, (open) => setOpen(open ? object.uuid : undefined)]}
                                model={{ ...object, thumbnail: objectThumbnails[object.uuid], object }}
                                onDelete={() => handleDelete(object)}
                                aspectRatio="square"
                                width={300}
                                height={300}
                                {...props} />
                        ))}
                    </div>
                    <ScrollBar orientation="vertical" />
                </ScrollArea>
            </div>
        </div>
    );
}