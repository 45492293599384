import React from 'react';
import { cn } from "@/lib/utils"

import {
    AlertCircle,
    Archive,
    ArchiveX,
    File,
    Inbox,
    MessagesSquare,
    Search,
    Send,
    ShoppingCart,
    Trash2,
    Users2,
  } from "lucide-react"
  
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
} from "@/components/ui/sheet"
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { Separator } from "@/components/ui/separator"

import {
    MenuIcon,
  } from "lucide-react"

import { Nav } from "@/components/nav"
import { NavButtons } from '@/components/nav-buttons';

export function MobileNav({
    className,
    ...props
}) {
    return (
        <Sheet key="left">
            <SheetTrigger asChild>
                <Button variant="outline" size="icon"
                    className={cn(className)} style={{ zIndex: 1 }} {...props}>
                    <MenuIcon className="w-[18px] h-[18px]" />
                </Button>
            </SheetTrigger>
            <SheetContent side="left" className="px-6 w-[70px] flex flex-col justify-between">
                <NavButtons />
            </SheetContent>
        </Sheet>
    );
}