import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useDatabase,
  useStorage,
  useUser,
} from 'reactfire';
import {
  ref as databaseRef,
  get,
  onValue,
} from 'firebase/database';
import {
  ref as storageRef,
  getDownloadURL,
  getMetadata,
  listAll,
} from 'firebase/storage';
import {
  AutoDraft,
  BasicStorage,
  ChatMessage,
  ChatProvider,
  Conversation,
  ConversationId,
  ConversationRole,
  IStorage,
  MessageContentType,
  MessageDirection,
  MessageStatus,
  Participant,
  Presence,
  TypingUsersList,
  UpdateState,
  User,
  UserStatus
} from "@chatscope/use-chat";
import { ExampleChatService } from "@chatscope/use-chat/dist/examples";
import { nanoid } from "nanoid";
import _ from 'lodash';

import { httpsCallable } from '@/modules/firebase/functions';
import { fetchUsers } from '@/modules/redux/storage';
import { getPayloadFromToken } from '@/modules/utils';

// sendMessage and addMessage methods can automagically generate id for messages and groups
// This allows you to omit doing this manually, but you need to provide a message generator
// The message id generator is a function that receives message and returns id for this message
// The group id generator is a function that returns string
const messageIdGenerator = (message) => nanoid();
const groupIdGenerator = () => nanoid();

const labels = { editRequest: 'Edit Request', commission: 'Commission', comment: 'Comment' };

export default function _useChat({ projectObjects, websiteObjects, fetchProjects, fetchWebsite } = {}) {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const { tab } = useParams();

  const serviceFactory = React.useMemo(() => {
    return (storage, updateState) => {
      return new ExampleChatService(storage, updateState);
    };
  }, []);

  const [chatStorage] = React.useState(new BasicStorage({ groupIdGenerator, messageIdGenerator }));
  const [srcs, setSrcs] = React.useState({});
  const [updating, update] = React.useState(Date.now());
  const [, _update] = React.useState(Date.now());
  const [unsubscribe, setUnsubscribe] = React.useState();
  const [isGenerating, setGenerating] = React.useState(true);

  const unmountChat = () => {
    if (unsubscribe) for (const _unsubscribe of unsubscribe) { _unsubscribe() }
    setUnsubscribe();

    for (const conversation of chatStorage.conversations) {
      if (chatStorage.conversationExists(conversation.id)) {
        chatStorage.removeMessagesFromConversation(conversation.id);
        chatStorage.removeConversation(conversation.id);
      }
    }
  };

  React.useEffect(() => { unmountChat() }, [tab]);

  const generateChat = async () => {
    setGenerating(true);

    const users = { [user.uid]: {} };

    if (unsubscribe) unmountChat();

    const _unsubscribe = [];
  
    for (const object of projectObjects.filter((object) => object.reviews)) {
      const participants = _.uniq(Object.values(object.reviews).reduce((arr, review) => arr.concat((review.comments || []).map((review) => review.user)), []))
        .filter((participant) => participant);

      participants.forEach((participant) => {
        users[participant] = {};
      });

      Object.entries(object.reviews).forEach(([uuid, review]) => {
        if (review.deleted) return;

        if (review.comments) {
          if (chatStorage.conversationExists(uuid)) {
            chatStorage.removeMessagesFromConversation(uuid);
            chatStorage.removeConversation(uuid);
          }

          chatStorage.addConversation(new Conversation({
            id: uuid,
            participants: participants.map((participant) => new Participant({
              id: participant,
              role: new ConversationRole([])
            })),
            unreadCounter: 0,
            typingUsers: new TypingUsersList({ items: [] }),
            draft: "",
            data: {
              tags: ['Comment'],
              object: { ...object, thumbnail: `thumbnails/${object.uuid}` },
              uuid,
              archived: !!review.archived,
            },
          }));

          review.comments.forEach((message) => {
            chatStorage.addMessage(new ChatMessage({
              id: "", // Id will be generated by storage generator, so here you can pass an empty string
              content: {
                ...message,
                object: object.uuid,
                uuid,
                tags: ['Comment'],
              },
              contentType: MessageContentType.TextHtml,
              senderId: message.user,
              direction: message.user == user.uid ? MessageDirection.Outgoing : MessageDirection.Incoming,
              status: MessageStatus.Sent,
              createdTime: new Date(message.timestamp),
              // updatedTime: review.timestamp,
            }),
              uuid,
              true,
            )
          });
        }
      });
    };

    projectObjects.forEach((object) => {
      const objectRef = databaseRef(database, `objects/${object.uuid}/reviews`);

      const objectUnsubscribe = onValue(objectRef, (snapshot) => {
        if (!_.isEqual(snapshot.val(), object.reviews)) {
          fetchProjects();
        }
      });
      _unsubscribe.push(objectUnsubscribe);
    });

    await Promise.all([
      new Promise((resolve) => {
        let objectsInitiated = 0;

        if (!websiteObjects.length) resolve();

        for (const object of websiteObjects) {
          const tag = object.id == 'shop' ? 'editRequest' : object.id == 'commission' ? 'commission' : null;

          if (!tag) continue;

          if (!Object.keys(object[`${tag}s`] || {}).length) {
            objectsInitiated += 1;
            if (websiteObjects.length == objectsInitiated) resolve();
          }

          for (const [uuid, { archived, deleted }] of Object.entries(object[`${tag}s`] || {})) {
            users[uuid] = {};

            const ref = databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`);

            let initialized = false;

            const id = `${tag}-${uuid}-${object.uuid}`;

            const messagesUnsubscribe = onValue(ref, async (snapshot) => {
              if (chatStorage.conversationExists(id)) {
                chatStorage.removeMessagesFromConversation(id);
                chatStorage.removeConversation(id);
              }

              if (deleted) {
                _update(Date.now());

                objectsInitiated += 1;
                if (websiteObjects.length == objectsInitiated) resolve();

                return;
              }

              const messages = (snapshot.val() || []).filter((message) => !Object.keys(message.deleted || {}).includes(user.uid));

              chatStorage.addConversation(new Conversation({
                id,
                participants: [new Participant({
                  id: uuid,
                  role: new ConversationRole([])
                })],
                unreadCounter: 0,
                typingUsers: new TypingUsersList({ items: [] }),
                draft: "",
                data: {
                  tags: [labels[tag]],
                  object: { ...object, thumbnail: `thumbnails/${object.uuid}` },
                  uuid,
                  createdBy: user.uid,
                  archived,
                },
              }));

              for (const message of messages) {
                chatStorage.addMessage(new ChatMessage({
                  id: "", // Id will be generated by storage generator, so here you can pass an empty string
                  content: {
                    ...message,
                    uuid,
                    object: object.uuid,
                    tags: ['Edit Request'],
                    archived: !!message.archived?.[user.uid],
                    unread: message.unread && message.user != user.uid,
                  },
                  contentType: MessageContentType.TextHtml,
                  senderId: message.user,
                  direction: message.user == user.uid ? MessageDirection.Outgoing : MessageDirection.Incoming,
                  status: MessageStatus.Sent,
                  createdTime: new Date(message.timestamp),
                }),
                  `${tag}-${uuid}-${object.uuid}`,
                  true,
                );
              }

              if (initialized) {
                fetchImages();
              }
              else {
                initialized = true;
              }

              _update(Date.now());

              objectsInitiated += 1;
              if (websiteObjects.length == objectsInitiated) resolve();
            });
            _unsubscribe.push(messagesUnsubscribe);

            const archivedUnsubscribe = onValue(databaseRef(database, `objects/${object.uuid}/${tag}s/${uuid}/archived`), (snapshot) => {
              if (archived != snapshot.val()) {
                // unsubscribe();
                fetchWebsite();
              }
            });
            _unsubscribe.push(archivedUnsubscribe);

            const deletedUnsubscribe = onValue(databaseRef(database, `objects/${object.uuid}/${tag}s/${uuid}/deleted`), (snapshot) => {
              if (deleted != snapshot.val()) {
                // unsubscribe();
                fetchWebsite();
              }
            });
            _unsubscribe.push(deletedUnsubscribe);
          };

          const objectsUnsubscribe = onValue(databaseRef(database, `objects/${object.uuid}/${tag}s`), (snapshot) => {
            if (!_.isEqual(Object.keys(object[`${tag}s`] || {}), Object.keys(snapshot.val() || {}))) {
              fetchWebsite();
            }
          });
          _unsubscribe.push(objectsUnsubscribe);
        };
      }),
      new Promise(async (resolve) => {
        let tagsInitiated = 0;

        for (const tag of ['editRequest', 'commission']) {
          const requests = await get(databaseRef(database, `users/${user.uid}/${tag}s`)).then((snapshot) => snapshot.val() || {});

          let requestsInitiated = 0;

          if (!Object.keys(requests).length) {
            tagsInitiated += 1;
            if (tagsInitiated == 2) resolve();
          };

          for (const [uuid, objects] of Object.entries(requests)) {
            let objectsInitiated = 0;

            if (!Object.keys(objects).length) {
              requestsInitiated += 1;
              if (Object.keys(requests).length == requestsInitiated) tagsInitiated += 1;
              if (tagsInitiated == 2) resolve();
            }

            for (const [objectUuid, { archived, deleted }] of Object.entries(objects)) {
              const object = await get(databaseRef(database, `objects/${objectUuid}`)).then((snapshot) => snapshot.val()) || {};

              users[uuid] = {};

              const ref = databaseRef(database, `messages/${user.uid}/${tag}s/${objectUuid}`);

              let initialized = false;

              const id = `${tag}-${uuid}-${objectUuid}`;

              const messagesUnsubscribe = onValue(ref, async (snapshot) => {
                if (chatStorage.conversationExists(id)) {
                  chatStorage.removeMessagesFromConversation(id);
                  chatStorage.removeConversation(id);
                }

                if (deleted) {
                  _update(Date.now());

                  objectsInitiated += 1;
                  if (Object.keys(objects).length == objectsInitiated) requestsInitiated += 1;
                  if (Object.keys(requests).length == requestsInitiated) tagsInitiated += 1;
                  if (tagsInitiated == 2) resolve();

                  return;
                }

                const messages = (snapshot.val() || []).filter((message) => !Object.keys(message.deleted || {}).includes(user.uid));

                chatStorage.addConversation(new Conversation({
                  id,
                  participants: [new Participant({
                    id: uuid,
                    role: new ConversationRole([])
                  })],
                  unreadCounter: 0,
                  typingUsers: new TypingUsersList({ items: [] }),
                  draft: "",
                  data: {
                    tags: [labels[tag]],
                    object: { ...object, uuid: objectUuid, thumbnail: `thumbnails/${objectUuid}` },
                    uuid: user.uid,
                    createdBy: uuid,
                    archived,
                  },
                }));

                for (const message of messages) {
                  chatStorage.addMessage(new ChatMessage({
                    id: "", // Id will be generated by storage generator, so here you can pass an empty string
                    content: {
                      ...message,
                      uuid: user.uid,
                      object: objectUuid,
                      tags: [labels[tag]],
                      archived: !!message.archived?.[user.uid],
                      unread: message.unread && message.user != user.uid,
                    },
                    contentType: MessageContentType.TextHtml,
                    senderId: message.user,
                    direction: message.user == user.uid ? MessageDirection.Outgoing : MessageDirection.Incoming,
                    status: MessageStatus.Sent,
                    createdTime: new Date(message.timestamp),
                  }),
                    id,
                    true,
                  );
                }

                if (initialized) {
                  fetchImages();
                }
                else {
                  initialized = true;
                }

                _update(Date.now());

                objectsInitiated += 1;
                if (Object.keys(objects).length == objectsInitiated) requestsInitiated += 1;
                if (Object.keys(requests).length == requestsInitiated) tagsInitiated += 1;
              if (tagsInitiated == 2) resolve();
              });
              _unsubscribe.push(messagesUnsubscribe);

              const archivedUnsubscribe = onValue(databaseRef(database, `users/${user.uid}/${tag}s/${uuid}/${objectUuid}/archived`), (snapshot) => {
                if (archived != snapshot.val()) {
                  // unsubscribe();
                  update(Date.now());
                }
              });
              _unsubscribe.push(archivedUnsubscribe);

              const deletedUnsubscribe = onValue(databaseRef(database, `users/${user.uid}/${tag}s/${uuid}/${objectUuid}/deleted`), (snapshot) => {
                if (deleted != snapshot.val()) {
                  // unsubscribe();
                  update(Date.now());
                }
              });
              _unsubscribe.push(deletedUnsubscribe);
            }
          }
        }
      }),
    ]);

    Object.keys(users).map((user) => {
      chatStorage.addUser(new User({
        id: user,
        presence: new Presence({ status: UserStatus.Available, description: "" }),
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        avatar: "",
        bio: ""
      }));
    });

    setUnsubscribe(_unsubscribe);

    if (tab == 'messages') {
      if (getPayloadFromToken().commission) {
        setConversation(`commission-${getPayloadFromToken().commission.user}-${getPayloadFromToken().commission.object}`);
      }
      if (getPayloadFromToken().editRequest) {
        setConversation(`editRequest-${getPayloadFromToken().editRequest.user}-${getPayloadFromToken().editRequest.object}`);
      }
    }

    dispatch(fetchUsers(Object.keys(users)));

    fetchImages();

    _update(Date.now());

    setGenerating(false);
  };

  const fetchImages = async () => {
    const newSrcs = {};

    for (const conversation of chatStorage.conversations) {
      const object = conversation.data.object;
      const ref = `thumbnails/${object.uuid}`;

      const url = await listAll(storageRef(storage, object.thumbnail))
        .then((ref) => ref.prefixes.find((p) => p.name == 'custom') ?
          listAll(ref.prefixes.find((p) => p.name == 'custom')).then((ref) => getDownloadURL(ref.items[0])) :
          getDownloadURL(ref.items[0])
        ).catch(() => null);

      newSrcs[ref] = { url, name: object.name };
    }

    const messages = Object.values(chatStorage.messages)
      .flat()
      .reduce((arr, g) => arr.concat(g.messages), [])
      .map((m) => m.content);

    for (const message of messages) {
      if (message.image && !srcs[message]) {
        const ref = `comments/${message.object}/${message.uuid}/${message.image}`;
        const [metadata, url] = await listAll(storageRef(storage, ref))
          .then((ref) => Promise.all([getMetadata(ref.items[0]), getDownloadURL(ref.items[0])]))
          .catch(() => [,]);

        newSrcs[ref] = { url, name: metadata?.name };
      }
    }

    const _srcs = { ...srcs, ...newSrcs };
    if (Object.keys(_srcs).length > Object.keys(srcs).length) setSrcs(_srcs);
  };

  React.useEffect(() => { if (user && projectObjects && websiteObjects) { generateChat() } }, [user, projectObjects, websiteObjects, updating]);

  const [conversation, setConversation] = React.useState();

  return {
    serviceFactory,
    storage: chatStorage,
    srcs,
    conversation: [conversation, setConversation],
    updateChat: () => update(Date.now()),
    unmountChat,
    isGenerating,
  };
}