"use client"

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from 'reactfire';
import { cn } from "@/lib/utils"

import { TooltipProvider } from "@/components/ui/tooltip"
import { Separator } from "@/components/ui/separator"

import {
  AlertCircle,
  File,
  LogOutIcon,
  MessagesSquare,
  RocketIcon,
  Users2,
  DownloadIcon,
} from "lucide-react"
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable"
import {
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
} from "@/components/ui/toast"
import { Nav } from "@/components/nav"
import { AccountSwitcher } from "@/components/account-switcher"

import { MobileNav } from "@/components/mobile-nav"
import { NavButtons } from '@/components/nav-buttons'

import { getPayloadFromToken } from '@/modules/utils';

import "@/styles/globals.css";

import logo from '@/assets/logo/M32.png';

const defaultLayout = [265, 440, 655];
const defaultCollapsed = true;
const navCollapsedSize = 1;
const supportLink = 'https://modelsend.canny.io';

export default function RootLayout({ children }) {

  const auth = useAuth();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { tab } = useParams();

  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)

  return (
    <div
      className={cn(
        "max-h-screen overflow-hidden bg-background font-sans --font-sans-heading antialiased"
      )}
    >
      <div className="flex max-h-screen flex-col">
        <main className="flex-1">
          <div className="relative">
            <section>
              <div className="overflow-hidden rounded-[0.5rem] border bg-background shadow-md md:shadow-xl">
                <div className="max-h-screen flex-col md:flex">
                  <ResizablePanelGroup
                    direction="horizontal"
                    className="h-full items-stretch"
                    style={{ overflowY: 'auto' }}>
                    <ResizablePanel
                      defaultSize={navCollapsedSize}
                      collapsedSize={navCollapsedSize}
                      collapsible={true}
                      minSize={navCollapsedSize}
                      maxSize={navCollapsedSize}
                      onCollapse={() => {
                        /*
                        const collapsed = true
                        setIsCollapsed(collapsed)
                        document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                          collapsed
                        )}`
                        */
                      }}
                      onExpand={() => {
                        /*
                        const collapsed = false
                        setIsCollapsed(collapsed)
                        document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                          collapsed
                        )}`
                        */
                      }}
                      className={cn(
                        "hidden md:flex",
                        isCollapsed &&
                        "min-w-[50px] transition-all duration-300 ease-in-out",
                        "flex-col justify-between min-h-100vh"
                      )}
                    >
                      <NavButtons />
                    </ResizablePanel>
                    <ResizableHandle disabled />
                    <div className="absolute flex md:hidden h-12 items-center justify-between ml-8 mt-1">
                      <div className="flex items-center space-x-2">
                        <MobileNav />
                      </div>
                    </div>
                    <div className="w-full md:flex min-h-screen bg-transparent mt-10 md:mt-0"
                      style={{ height: 0 }}>
                      {children}
                    </div>
                  </ResizablePanelGroup>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
}