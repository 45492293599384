import React from 'react';
import { 
    CopyIcon 
} from "@radix-ui/react-icons"

import {
    ShareIcon,
  } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

export function ProjectShare({ redirect = '' }) {

  const handleCopyClick = () => navigator.clipboard.writeText(`${window.location.origin}/${redirect}`);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
            <ShareIcon className="mr-2 w-[18px] h-[18px]" />Share
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[520px]">
        <div className="flex flex-col space-y-2 text-center sm:text-left">
          <h3 className="text-lg font-semibold">Share this document</h3>
          <p className="text-sm text-muted-foreground">
            Anyone with the link can view this document.
          </p>
        </div>
        <div className="flex items-center space-x-2 pt-4">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Link
            </Label>
            <Input
              id="link"
              defaultValue={`${window.location.origin}/${redirect}`}
              readOnly
              className="h-9"
            />
          </div>
          <Button type="submit" size="sm" className="px-3" onClick={handleCopyClick}>
            <span className="sr-only">Copy</span>
            <CopyIcon className="h-4 w-4" />
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
