import React from 'react';
import { useSigninCheck } from 'reactfire';

import { SigninDlg } from '@/components/signin-dlg';

import { cn } from "@/lib/utils"

import {
    MenuIcon
} from "lucide-react"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"

export default function MobileNav({
    className,
    onLoginSuccess = () => { },
    onSellerInitiated = () => { },
    ...props
}) {
    const { data: signInCheckResult } = useSigninCheck();
    
    return (
        <Popover modal="true">
            <PopoverTrigger asChild>
                <Button size="icon" variant="outline"
                    className={cn(className)} {...props}
                >
                    <MenuIcon />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-screen">
                <div className="flex flex-col space-y-8 pt-4">
                    <a href="/docs">
                        <p className="text-sm font-medium">Docs</p>
                    </a>
                    <a href="/pricing">
                        <p className="text-sm font-medium">Pricing</p>
                    </a>
                    <div className="flex flex-col w-full space-y-4">
                        {signInCheckResult.signedIn ?
                            <Button className="w-full" variant="outline" onClick={onSellerInitiated}>Start selling</Button> :
                            <SigninDlg onLoginSuccess={onLoginSuccess}>
                                <Button className="w-full" variant="outline">Log in</Button>
                            </SigninDlg>}
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
}