import {
  getDatabase,
  ref as refDatabase,
  increment as incrementDatabase,
  set as setDatabase,
  update as updateDatabase,
} from 'firebase/database';

import getFirebaseConfig, { databaseURLs } from 'config/firebase';

const databaseFn = (fn) => async (ref, changes, firebaseConfig) => {
  if (!firebaseConfig) firebaseConfig = getFirebaseConfig();

  const path = ref.toString().split(firebaseConfig.databaseURL)[1];

  for (const databaseURL of Object.values(databaseURLs(firebaseConfig.projectId))) {
    if (databaseURL == firebaseConfig.databaseURL) continue;

    fn(refDatabase(getDatabase(undefined, databaseURL), path), changes);
  }

  await fn(refDatabase(getDatabase(), path), changes);
};

export const increment = databaseFn(incrementDatabase);
export const set = databaseFn(setDatabase);
export const update = databaseFn(updateDatabase);