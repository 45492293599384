"use client"

import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth, useUser } from 'reactfire';
import { cn } from "@/lib/utils"

import { TooltipProvider } from "@/components/ui/tooltip"
import { Separator } from "@/components/ui/separator"

import {
    AlertCircle,
    File,
    LogOutIcon,
    MessagesSquare,
    RocketIcon,
    UserIcon,
    DownloadIcon,
    CompassIcon,
    FolderIcon,
    DollarSignIcon,
} from "lucide-react"
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/components/ui/resizable"
import {
    ToastProvider,
    ToastViewport,
    Toast,
    ToastTitle,
    ToastDescription,
    ToastClose,
    ToastAction,
} from "@/components/ui/toast"
import { Nav } from "@/components/nav"
import { AccountSwitcher } from "@/components/account-switcher"

import { MobileNav } from "@/components/mobile-nav"

import { httpsCallable } from '@/modules/firebase/functions';
import { getUser } from '@/modules/redux/user';
import { getPayloadFromToken } from '@/modules/utils';

import logo from '@/assets/logo/M32.png';

const defaultLayout = [265, 440, 655];
const defaultCollapsed = true;
const navCollapsedSize = 1;
const supportLink = 'https://modelsend.canny.io';

export function NavButtons() {

    const auth = useAuth();

    const { data: user } = useUser();

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const { tab } = useParams();

    const { seller: { paymentsEnabled = false, provider } = {} } = useSelector(getUser);

    const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)

    const handleStripeClick = async () => {
        const { data = {} } = await httpsCallable('createAccountLink')({
            uid: user.uid,
            returnUrl: `${window.location.origin}/seller/website`,
            refreshUrl: `${window.location.origin}/seller/website`,
            // provider: 'paypal',
        });

        if (data?.url) window.open(data.url);
    };

    const handleSignout = async () => {
        await auth.signOut();
        navigate(`/`);
    };

    return (<>
        <div>
            <div
                className={cn(
                    "flex h-[52px] items-center justify-center",
                    isCollapsed ? "h-[52px]" : "px-2"
                )}
            >
                <button onClick={() => navigate('/')}>
                <img
                    src={logo}
                    className="w-6"
                />
                </button>
            </div>
            <Separator />
            <Nav
                isCollapsed={isCollapsed}
                links={[
                    {
                        title: "Website",
                        icon: RocketIcon,
                        variant: "ghost",
                        onClick: () => navigate(`/${pathname.split('/')[1]}`),
                        isActive: tab == undefined,
                    },
                    {
                        title: "Messages",
                        icon: MessagesSquare,
                        variant: "default",
                        onClick: () => navigate(`/${pathname.split('/')[1]}/messages`),
                        isActive: tab == 'messages',
                    },
                    {
                        title: "Projects",
                        icon: FolderIcon,
                        variant: "ghost",
                        onClick: () => navigate(`/${pathname.split('/')[1]}/projects`),
                        isActive: tab == 'projects',
                    },
                    {
                        title: "Purchases",
                        icon: DownloadIcon,
                        variant: "ghost",
                        onClick: () => navigate(`/${pathname.split('/')[1]}/purchases`),
                        isActive: tab == 'purchases',
                    },
                    {
                        title: "Marketplace",
                        icon: CompassIcon,
                        variant: "ghost",
                        onClick: () => navigate(`/`),
                        isActive: false,
                    },
                ]}
            />
            <Separator />
            <Nav
                isCollapsed={isCollapsed}
                links={[
                    {
                        title: "Account",
                        icon: UserIcon,
                        variant: "ghost",
                        onClick: () => navigate(`/${pathname.split('/')[1]}/account`),
                        isActive: tab == 'account',
                    },
                    paymentsEnabled && provider == 'stripe' && {
                        title: "Stripe",
                        icon: DollarSignIcon,
                        variant: "ghost",
                        onClick: handleStripeClick,
                        isActive: false,
                    },
                    {
                        title: "Support",
                        icon: AlertCircle,
                        variant: "ghost",
                        onClick: () => window.open(supportLink),
                        isActive: false,
                    },
                ].filter((l) => l)}
            />
        </div>
        <div>
            <Nav
                isCollapsed={isCollapsed}
                links={[
                    {
                        title: "Sign Out",
                        icon: LogOutIcon,
                        variant: "ghost",
                        onClick: handleSignout,
                        isActive: false,
                    },
                ]}
            />
        </div>
    </>);
}
