"use client"

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import { Cross2Icon } from "@radix-ui/react-icons"
import { Table } from "@tanstack/react-table"
import {
  PlusCircle,
} from "lucide-react"
import short from 'short-uuid';

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { UploadModelsDlg } from "@/components/upload-models-dlg"
import { DataTableViewOptions } from "@/app/tasks/components/data-table-view-options"

import { priorities, statuses } from "../data/data"
import { DataTableFacetedFilter } from "./data-table-faceted-filter"

import { set, update, increment } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import { uuid } from '@/modules/utils';

export function DataTableToolbar({ table, upload, ...props }) {

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const database = useDatabase();

  const { data: user } = useUser();

  const isFiltered = table.getState().columnFilters.length > 0

  const open = React.useState(false);

  const handleFilesUpload = async (files) => {
    if (files.length < 1) return;

    upload([...files], async (_objects) => {
      try {
        const _uuid = uuid();
        const timestamp = Date.now();

        const { data: { token } } = await httpsCallable('encrypt')({
          public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
          payload: { project: _uuid, timestamp },
        }).catch((e) => ({ data: {} }));

        const redirect = short.generate();

        await Promise.all(
          [
            set(databaseRef(database, `projects/${_uuid}`), {
              createdAt: timestamp,
              createdBy: user.uid,
              objects: Object.fromEntries(_objects.map((object) => [object.uuid, object.metadata.name])),
              token,
              redirect,
              status: 'todo',
            }),
            set(databaseRef(database, `redirects/${redirect}`), `/project?token=${token}`),
          ]
            .concat(_objects.map((object) => httpsCallable('encrypt')({
              public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
              payload: {
                ...{ object: object.uuid, project: _uuid, timestamp },
              },
            }).then(({ data }) => {
              const _redirect = short.generate();

              const lights = {
                [uuid()]: { type: 'AmbientLight', color: '#ffffff', intensity: object.originalType == 'fbx' ? .6 : .3 },
                [uuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: object.dimensions / 2, z: 0 } },
                [uuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: -object.dimensions / 2, z: 0 } },
              };
              if (object.includeDefaultEnvMap) lights.Default = { type: 'Environment', uuid: 'Default' };

              return Promise.all([
                update(databaseRef(database, `objects/${object.uuid}`), {
                  token: data.token,
                  redirect: _redirect,
                  lights,
                  needsUpdate: false,
                  name: object.name,
                  size: object.size,
                  polycount: object.polycount || 0,
                  vertices: object.vertices || 0,
                  hasAnimations: !!object.hasAnimations,
                  autoplay: false,
                }),
                set(databaseRef(database, `redirects/${_redirect}`), `/viewer?token=${data.token}`),
              ]);
            })))
        );

        await Promise.all([
          set(databaseRef(database, `users/${user.uid}/projects/${_uuid}`), _uuid),
          set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(_objects.reduce((sum, object) => sum + object.size || 0, 0))),
        ]);

        navigate(`/project?token=${token}`, { state: { back: pathname.split('/')[1] != 'projects' ?  `/${pathname.split('/')[1]}` : '' } });
      }
      catch (e) { console.log(e) }
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter projects..."
          value={(table.getColumn("name")?.getFilterValue()) ?? ""}
          onChange={(event) =>
            console.log(event)
            // table.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <UploadModelsDlg
        multiple
        open={open}
        onFilesUpload={handleFilesUpload}
        {...props}>
        <Button size="sm" className="h-7 gap-1">
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Create Project
          </span>
        </Button>
      </UploadModelsDlg>
    </div>
  )
}
