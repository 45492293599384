import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import {
    ref as databaseRef,
} from 'firebase/database';
import {
    ref as storageRef,
    listAll,
    getDownloadURL,
    uploadBytes,
    deleteObject,
    getMetadata,
    uploadString,
} from 'firebase/storage';
import {
    ChevronRightIcon,
    EyeIcon,
    EyeOffIcon,
    PlusIcon,
    XIcon,
    SunIcon,
    EarthIcon,
    SettingsIcon,
} from "lucide-react"
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import { Rnd } from 'react-rnd';
import {
    Color,
    EquirectangularReflectionMapping,
    TextureLoader,
} from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { isMobile } from 'react-device-detect';
import { useDebouncedCallback } from 'use-debounce';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectGroup,
    SelectValue,
} from "@/components/ui/select"

import { Slider } from "@/components/ui/slider"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

import ProjectDetailSceneLighting from '@/components/projects/project-detail-scene-lighting';
import { ColorPickerPopover } from '@/components/color-picker-popover';
import { UploadTexturesPopover } from '@/components/upload-textures-popover';

import { set, update, increment } from '@/modules/firebase/database';
import { getVersion } from '@/modules/redux/storage';
import {
    getRGBELoader,
    getBackground,
    getLights,
    getMaps,
    getMaterial,
    getMaterials,
    getObjects,
    setShininess,
    getMaxAnisotropy,
    setLight,
    setMaterial,
    setMaps,
    updateBackground,
    updateMaterial,
    toggleMaterial,
} from '@/modules/redux/threejs';
import { getPayloadFromToken, uuid, getFolderSize, generateThumbnail, deleteFolder } from '@/modules/utils';


const materialTypes = {
    'MeshBasicMaterial': ['diffuse', 'specular', 'alpha', 'env', 'light', 'ao'],
    'MeshDepthMaterial': ['diffuse', 'alpha', 'displace'],
    'MeshNormalMaterial': ['bump', 'normal', 'displace'],
    'MeshLambertMaterial': ['diffuse', 'specular', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'env', 'light', 'ao'],
    'MeshMatcapMaterial': ['diffuse', 'matcap', 'alpha', 'bump', 'normal', 'displace'],
    'MeshPhongMaterial': ['diffuse', 'specular', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'env', 'light', 'ao'],
    'MeshToonMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'light', 'ao', 'gradient'],
    'MeshStandardMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'rough', 'metal', 'env', 'light', 'ao'],
    'MeshPhysicalMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'clearcoat-normal', 'displace', 'rough', 'metal', 'sheen-color', 'sheen-rough', 'env', 'light', 'ao', 'transmission', 'thickness'],
};

const materialLabels = {
    'MeshToonMaterial': 'Toon',
    'MeshStandardMaterial': 'Standard',
    'MeshPhysicalMaterial': 'Physical',
    'MeshPhongMaterial': 'Phong',
    'MeshMatcapMaterial': 'Matcap',
    'MeshLambertMaterial': 'Lambert',
    'MeshBasicMaterial': 'Basic',
}

const materialProperties = {
    'MeshToonMaterial': ['color'],
    'MeshStandardMaterial': ['color', 'emissive', 'roughness', 'metalness'],
    'MeshPhysicalMaterial': ['color', 'emissive', 'roughness', 'metalness', 'reflectivity'],
    'MeshPhongMaterial': ['color', 'emissive', 'specular', 'shininess'],
    'MeshMatcapMaterial': ['color'],
    'MeshLambertMaterial': ['color', 'emissive'],
    'MeshBasicMaterial': ['color'],
};

const mapItems = {
    diffuse: { id: 'map', label: 'Diffuse' },
    matcap: { id: 'matcap', label: 'Matcap' },
    specular: { id: 'specularMap', label: 'Specular' },
    emissive: { id: 'emissiveMap', label: 'Emissive' },
    alpha: { id: 'alphaMap', label: 'Alpha' },
    bump: { id: 'bumpMap', label: 'Bump', value: 'bumpScale' },
    normal: { id: 'normalMap', label: 'Normal', valueXY: 'normalScale' },
    displace: { id: 'displacementMap', label: 'Displace', value: 'displacementMap' },
    rough: { id: 'roughnessMap', label: 'Rough' },
    metal: { id: 'metalnessMap', label: 'Metal' },
    light: { id: 'lightMap', label: 'Light' },
    ao: { id: 'aoMap', label: 'AO', value: 'aoMapIntensity' },
    gradient: { id: 'gradientMap', label: 'Gradient' },
};

const MapInput = ({
    label,
    id: key,
    open,
    editable,
    value,
    onValueChange,
    valueXY,
    onOpen,
    onClose,
    sx,
    menuSx,
}) => {
    const database = useDatabase();

    const storage = useStorage();

    const { data: user } = useUser();

    const maps = useSelector(getMaps);
    const material = useSelector(getMaterial);
    const materials = useSelector(getMaterials);

    const version = useSelector(getVersion);

    const id = material.material.userData.id;
    const materialMaps = (maps?.[id] || {})[key] || [];

    const index = material.material.userData.textures[key].index;

    const handleSelect = async (i) => {
        const objects = materials.filter((_material) => _material.material.uuid == material.material.uuid);

        await Promise.all(objects.map((object) => set(databaseRef(database, `objects/${version}/maps/${object.material.userData.id}/${key}`), i !== false ? materialMaps[i].uuid : null)));

        onClose();
    };

    const valueX = valueXY ? material.material[valueXY].x : material.material[value];
    const valueY = material.material[valueXY]?.y;

    const handleValueXChange = (e) => onValueChange(valueXY || value, valueXY ? { x: e.target.value, y: valueY } : e.target.value);
    const handleValueYChange = (e) => onValueChange(valueXY, { x: material.material[valueXY].x, y: e.target.value });

    const handleDeleteClick = async () => {
        if (!material?.material?.userData?.textures[key].index && material?.material?.userData?.textures[key].index != 0) return;

        const id = material.material.userData.id;

        const texture = materialMaps[material?.material?.userData?.textures[key].index];

        const found = Object.entries(maps).reduce((bool1, [_id, idMaps]) =>
            bool1 || Object.entries(idMaps).reduce((bool2, [_key, keyMaps]) =>
                bool2 || (_id != id || _key != key) && !!keyMaps.find((map) => map.uuid == texture.uuid)
                , false)
            , false);

        if (!found) {
            if (user?.uid) {
                const size = await getFolderSize(storageRef(storage, `maps/${version}/${texture.uuid}`));
                await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-size));
            }
            await deleteFolder(storageRef(storage, `maps/${version}/${texture.uuid}`));
        }

        await Promise.all([
            set(databaseRef(database, `maps/${version}/${id}/${key}/${texture.uuid}`), null),
            set(databaseRef(database, `objects/${version}/maps/${id}/${key}`), null),
        ]);
    };

    if (materialMaps.length < 1) return null;

    return (
        <div className="flex items-center justify-between space-x-2">
            <p className="text-sm leading-none w-1/3">
                {label}
            </p>
            <Select value={!isNaN(index) ? index : false} onValueChange={handleSelect}>
                <SelectTrigger className="flex-1 h-7 w-2">
                    <SelectValue placeholder="All textures" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value={false}>
                            No texture
                        </SelectItem>
                        {materialMaps.map((texture, i) => <SelectItem key={i} value={i}>
                            {texture.name}
                        </SelectItem>)}
                    </SelectGroup>
                </SelectContent>
            </Select>
            {(value || valueXY) && <Popover>
                <PopoverTrigger asChild>
                    <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm">
                        <SettingsIcon className="w-4 h-4 text-muted-foreground" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent align="end" className="w-auto p-2">
                    <div className="flex items-center gap-4 w-auto">
                        <div className="flex items-center space-x-2">
                            <p className="text-base">x</p>
                            <Input
                                type="number"
                                value={valueX}
                                onChange={handleValueXChange}
                                className="h-9 w-12 text-center" />
                        </div>
                        {valueXY && <div className="flex items-center space-x-2">
                            <p className="text-base">y</p>
                            <Input
                                type="number"
                                value={valueY}
                                onChange={handleValueYChange}
                                className="h-9 w-12 text-center" />
                        </div>}
                    </div>
                </PopoverContent>
            </Popover>}
            {editable && <Button size="icon" variant="ghost" className="h-7 w-7 rounded-sm" onClick={handleDeleteClick}>
                <XIcon className="w-4 h-4 text-muted-foreground" />
            </Button>}
        </div>
    );
};

export default function ProjectDetailScene({ editable = false, environmentsEditable = false }) {

    const dispatch = useDispatch();

    const database = useDatabase();

    const storage = useStorage();

    const { data: user } = useUser();

    const objects = useSelector(getObjects);
    const maps = useSelector(getMaps);
    const lights = useSelector(getLights);
    const background = useSelector(getBackground);
    const materials = useSelector(getMaterials);
    const material = useSelector(getMaterial);

    const version = useSelector(getVersion);

    const [open, setOpen] = React.useState(null);


    const materialSelected = (m) => material?.object?.uuid == m?.object?.uuid && material?.material?.uuid == m?.material?.uuid;

    const handlePropertiesUpdate = useDebouncedCallback((_material) => {
        const objects = materials.filter((__material) => __material.material.uuid == _material.material.uuid);

        update(databaseRef(database, `objects/${version}/properties`), Object.fromEntries(objects.map((object) => [
            object.material.userData.id,
            Object.fromEntries(materialProperties[object.material.type].map((key) => [
                key,
                typeof object.material[key] == 'object' && object.material[key].isColor ? `#${object.material[key].getHexString()}` : object.material[key],
            ]))
        ])));
    }, 250);

    React.useEffect(() => {
        handlePropertiesUpdate.cancel();
        handlePropertiesUpdate(material);
    }, [material]);

    const [color, setColor] = React.useState();

    const handleObjectClick = (material) => dispatch(setMaterial({ material, highlight: true }));
    const handleObjectVisibleClick = (material) => dispatch(toggleMaterial({ material, updates: { visible: !material.object.visible } }));
    const handleLightClick = (light) => { if (!['AmbientLight', 'HDRI'].includes(light.type)) dispatch(setLight(light)) };

    const handleMaterialColorClick = (key, open = true) => setColor(open ? key : undefined);

    const handleMaterialValueChange = (updates) => color == 'background' ?
        dispatch(updateBackground(updates)) :
        dispatch(updateMaterial({ material, updates }));

    const mapInputProps = (key) => ({
        editable,
        onClose: () => setOpen(),
        onValueChange: handleMaterialValueChange,
        ...mapItems[key],
    });

    const handleTextureUpload = async ({ files, texture, material: toMaterial }) => {
        await Promise.all(files.map((file, i) => new Promise(async (resolve, reject) => {
            try {
                const id = material.material.userData.id;

                const key = mapItems[texture].id;

                const { image, original } = await new Promise(async (_resolve, _reject) => {
                    const ele = new Image();
                    ele.src = URL.createObjectURL(file);
                    ele.onload = async () => {
                        const original = generateThumbnail(ele);
                        const img = { key, image: original };
                        URL.revokeObjectURL(ele.src);
                        _resolve(img);
                    }
                    ele.onerror = () => {
                        URL.revokeObjectURL(ele.src);
                        _reject();
                    };
                });

                const _uuid = uuid();

                const [_file] = await Promise.all([
                    uploadString(storageRef(storage, `maps/${version}/${_uuid}/${file.name == 'Default' ? 'Default (1)' : file.name}`), image, 'data_url'),
                ]);
                if (user?.uid) {
                    const metadata = await getMetadata(_file.ref);
                    await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(metadata.size));
                }

                await set(databaseRef(database, `maps/${version}/${id}/${key}/${_uuid}`), file.name == 'Default' ? 'Default (1)' : file.name);

                if (i == 0) await set(databaseRef(database, `objects/${version}/maps/${id}/${key}`), _uuid);

                resolve();
            } catch (e) { console.log(e); resolve() }
        })));

        if (material.material.uuid != toMaterial.material.uuid) {
            dispatch(setMaterial({ material: toMaterial.material }));
        }
    };

    const handleBackgroundChange = useDebouncedCallback((background) => {
        if (background) set(databaseRef(database, `objects/${version}/background`), background);
    }, 250);

    React.useEffect(() => {
        handleBackgroundChange.cancel();
        handleBackgroundChange(background);
    }, [background]);

    if (!lights) return;

    return (
        <div className="flex flex-col gap-6 h-full">
            <div className="flex flex-col gap-5 border rounded-lg p-3">
                <div className="flex flex-col gap-2">
                    <Accordion type="multiple" className="w-full">
                        <AccordionItem value="item-1" className="border-b-0">
                            <AccordionTrigger>Objects</AccordionTrigger>
                            {materials?.map((m, i) => <AccordionContent key={i}>
                                <div className="flex w-full items-center gap-2 justify-between">
                                    <ChevronRightIcon className="ml-4 w-4 h-4 text-muted-foreground" />
                                    <Button variant="link"
                                        className="w-40 h-6 p-1 justify-start font-normal truncate"
                                        style={{ fontWeight: materialSelected(m) ? 600 : 400 }}
                                        onClick={() => handleObjectClick(m)}>
                                        <p title={`${m.material.userData.name} (${m.material.name})`}>
                                            {`${m.material.userData.name} (${m.material.name})`}
                                        </p>
                                    </Button>
                                    <Button variant="ghost" className="w-6 h-6 p-1" onClick={() => handleObjectVisibleClick(m)}>
                                        {m.object.visible ?
                                            <EyeIcon className="ml-auto w-4 h-4 text-muted-foreground" /> :
                                            <EyeOffIcon className="ml-auto w-4 h-4 text-muted-foreground" />
                                        }
                                    </Button>
                                </div>
                            </AccordionContent>)}
                        </AccordionItem>
                        <AccordionItem value="item-2" className="border-b-0">
                            <AccordionTrigger>Lights</AccordionTrigger>
                            {Object.values(lights).map((light) => <AccordionContent key={light.uuid}>
                                <div className="flex w-full items-center gap-2">
                                    <ChevronRightIcon className="ml-4 w-4 h-4 text-muted-foreground" />
                                    <Button variant="link" className="h-6 p-1 justify-start font-normal truncate" onClick={() => handleLightClick(light)}>
                                        <p>{light.type.split('Light')[0]}</p>
                                    </Button>
                                </div>
                            </AccordionContent>)}
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="space-y-4">
                <h2 className="text-sm font-medium">{materialLabels[material.material.type]}</h2>
                <div className="grid gap-3">
                    {materialProperties[material.material.type].includes('color') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Color
                        </p>
                        <ColorPickerPopover
                            align="end"
                            color={color == 'color' && material.material.color.getHexString()}
                            onOpenChange={(open) => handleMaterialColorClick('color', open)}
                            onChange={({ hex }) => handleMaterialValueChange({ color: new Color(hex) })}>
                            <Button
                                className="h-7 flex-1 rounded-sm"
                                style={{ backgroundColor: `#${material.material.color.getHexString()}` }}
                            />
                        </ColorPickerPopover>
                    </div>}
                    {materialProperties[material.material.type].includes('emissive') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Emissive
                        </p>
                        <ColorPickerPopover
                            align="end"
                            color={color == 'emissive' && material.material.emissive.getHexString()}
                            onOpenChange={(open) => handleMaterialColorClick('emissive', open)}
                            onChange={({ hex }) => handleMaterialValueChange({ emissive: new Color(hex) })}>
                            <Button
                                className="h-7 flex-1 rounded-sm"
                                style={{ backgroundColor: `#${material.material.emissive.getHexString()}` }}
                            />
                        </ColorPickerPopover>
                    </div>}
                    {materialProperties[material.material.type].includes('specular') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Specular
                        </p>
                        <ColorPickerPopover
                            align="end"
                            color={color == 'specular' && material.material.specular.getHexString()}
                            onOpenChange={(open) => handleMaterialColorClick('specular', open)}
                            onChange={({ hex }) => handleMaterialValueChange({ specular: new Color(hex) })}>
                            <Button
                                className="h-7 flex-1 rounded-sm"
                                style={{ backgroundColor: `#${material.material.specular.getHexString()}` }}
                            />
                        </ColorPickerPopover>
                    </div>}
                    {materialProperties[material.material.type].includes('roughness') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Roughness
                        </p>
                        <Slider
                            value={[material.material.roughness]}
                            onValueChange={(value) => handleMaterialValueChange({ roughness: value })}
                            max={1} step={0.01}
                            className="w-2/3 h-7"
                        />
                    </div>}
                    {materialProperties[material.material.type].includes('metalness') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Metalness
                        </p>
                        <Slider
                            value={[material.material.metalness]}
                            onValueChange={(value) => handleMaterialValueChange({ metalness: value })}
                            max={1} step={0.01}
                            className="w-2/3 h-7"
                        />
                    </div>}
                    {materialProperties[material.material.type].includes('reflectivity') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Reflectivity
                        </p>
                        <Slider
                            value={[material.material.reflectivity]}
                            onValueChange={(value) => handleMaterialValueChange({ reflectivity: value })}
                            max={1} step={0.01}
                            className="w-2/3 h-7"
                        />
                    </div>}
                    {materialProperties[material.material.type].includes('shininess') && <div className="flex items-center justify-between space-x-2">
                        <p className="text-sm leading-none w-1/3">
                            Shininess
                        </p>
                        <Slider
                            value={[material.material.shininess]}
                            onValueChange={(value) => handleMaterialValueChange({ shininess: value })}
                            max={100} step={1}
                            className="w-2/3 h-7"
                        />
                    </div>}
                </div>
            </div>
            <ProjectDetailSceneLighting editable={editable} environmentsEditable={environmentsEditable} />
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <h2 className="text-sm font-medium">Textures</h2>
                    <UploadTexturesPopover multiple onUpload={handleTextureUpload}>
                        <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm border-0 shadow-none">
                            <PlusIcon className="w-4 h-4 text-muted-foreground" />
                        </Button>
                    </UploadTexturesPopover>
                </div>
                {materialTypes[material.material.type].includes('diffuse') && <MapInput {...mapInputProps('diffuse')} />}
                {materialTypes[material.material.type].includes('matcap') && <MapInput {...mapInputProps('matcap')} />}
                {materialTypes[material.material.type].includes('specular') && <MapInput {...mapInputProps('specular')} />}
                {materialTypes[material.material.type].includes('emissive') && <MapInput {...mapInputProps('emissive')} />}
                {materialTypes[material.material.type].includes('alpha') && <MapInput {...mapInputProps('alpha')} />}
                {materialTypes[material.material.type].includes('bump') && <MapInput {...mapInputProps('bump')} />}
                {materialTypes[material.material.type].includes('normal') && <MapInput {...mapInputProps('normal')} />}
                {materialTypes[material.material.type].includes('displace') && <MapInput {...mapInputProps('displace')} />}
                {materialTypes[material.material.type].includes('rough') && <MapInput {...mapInputProps('rough')} />}
                {materialTypes[material.material.type].includes('metal') && <MapInput {...mapInputProps('metal')} />}
                {materialTypes[material.material.type].includes('light') && <MapInput {...mapInputProps('light')} />}
                {materialTypes[material.material.type].includes('ao') && <MapInput {...mapInputProps('ao')} />}
                {materialTypes[material.material.type].includes('gradient') && <MapInput {...mapInputProps('gradient')} />}
            </div>
            <div className="flex items-center justify-between space-x-2 mb-16">
                <h2 className="text-sm font-medium w-1/3">Background</h2>
                <ColorPickerPopover
                    align="end"
                    color={color == 'background' && (background || '#e5e5e5')}
                    onOpenChange={(open) => handleMaterialColorClick('background', open)}
                    onChange={({ hex }) => handleMaterialValueChange(hex)}>
                    <Button
                        className="h-7 flex-1 rounded-sm"
                        style={{ backgroundColor: background }}
                    />
                </ColorPickerPopover>
            </div>
        </div>
    );
}