import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useAuth,
  useDatabase,
  useSigninCheck,
  useStorage,
  useUser,
} from 'reactfire';
import {
  ref as databaseRef,
  get,
  onValue,
} from 'firebase/database';
import {
  ref as storageRef,
  deleteObject,
  getDownloadURL,
  getMetadata,
  getBlob,
  listAll,
  uploadBytes,
  uploadBytesResumable,
  uploadString,
} from 'firebase/storage';
import { FileUploader } from 'react-drag-drop-files';
import { useResizeDetector } from 'react-resize-detector';
import { useDebouncedCallback } from 'use-debounce';
import { isMobile, isSafari } from 'react-device-detect';
import JSZip from 'jszip';
import prettyBytes from 'pretty-bytes';
import short from 'short-uuid';
import validator from 'email-validator';
import _ from 'lodash';

import RootLayout from '@/app/layout/seller';
import Projects from '@/app/projects/projects';

import { set, update, increment } from 'modules/firebase/database';
import { httpsCallable } from 'modules/firebase/functions';
import useUploadHandler from '@/modules/useUploadHandler';
import { useThreejsWorker, getData, getThumbnail } from 'modules/redux/threejs';
import { getUser, fetchUser } from 'modules/redux/user';
import {
  deleteFolder,
  getFolderSize,
  getPayloadFromToken,
  uuid,
} from 'modules/utils';

const textureLabels = {
  'map': 'diffuse',
  'matcap': 'matcap',
  'specularMap': 'specular',
  'emissiveMap': 'emissive',
  'alphaMap': 'alpha',
  'bumpMap': 'bump',
  'normalMap': 'normal',
  'displacementMap': 'displace',
  'roughnessMap': 'rough',
  'metalnessMap': 'metal',
  'lightMap': 'light',
  'aoMap': 'ao',
  'gradientMap': 'gradient',
}

export default function ProjectPage() {
  const dispatch = useDispatch();

  const auth = useAuth();

  const database = useDatabase();

  const { data: signInCheckResult } = useSigninCheck();

  const storage = useStorage();

  const { data: user } = useUser();

  const navigate = useNavigate();

  const userData = useSelector(getUser);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const settingsRef = React.useRef();
  const collaboratorsRef = React.useRef();
  const passwordRef = React.useRef();
  const fileInputLoaderRef = React.useRef();
  const shareRef = React.useRef();
  const objectMenusRef = React.useRef({});
  const objectLoadersRef = React.useRef({});
  const addFileRef = React.useRef();
  const objectsRef = React.useRef();

  const uploadHandlerProps = useUploadHandler({ loaderRef: fileInputLoaderRef });

  const [initialized, setInitialized] = React.useState(false);
  const [authenticated, setAuthenticated] = React.useState(false);

  const [project, setProject] = React.useState({});
  const [objects, setObjects] = React.useState([]);
  
  const [objectThumbnails, setObjectThumbnails] = React.useState({});
  const [objectThumbnail, setObjectThumbnail] = React.useState(null);

  React.useEffect(() => {
    if (!objectThumbnail) return;

    setObjectThumbnails({ ...objectThumbnails, ...objectThumbnail });
  }, [objectThumbnail]);

  const fetchObjects = async () => {
    const _objects = await Promise.all(objects.map((object) => new Promise(async (resolve) => {
      let thumbnail = objectThumbnails[object.uuid];
      const needsUpdate = await get(databaseRef(database, `objects/${object.uuid}/needsUpdate`)).then((snapshot) => !!snapshot.val());
      if (isSafari || !needsUpdate) {
        const ref = await listAll(storageRef(storage, `thumbnails/${object.uuid}`));
        if (ref.items[0]) thumbnail = await getDownloadURL(ref.items[0]);
      }

      resolve(thumbnail);
    })))
      .then((thumbnails) => objects.map((object, i) => ({ ...object, thumbnail: thumbnails[i], progress: objectLoadersRef.current?.[object.uuid]?.getValue() })));

    setObjectThumbnail(Object.fromEntries(_objects.map(({ uuid, thumbnail }) => [uuid, thumbnail])));

    for (const object of _objects) {
      const i = _objects.indexOf(object);

      if (!object.thumbnail && (object.progress === undefined || object.progress === null)) {
        const url = await new Promise(async (resolve) => {
          const canvas = document.createElement('canvas');
          const offscreen = canvas.transferControlToOffscreen();

          const { ref, ..._object } = object;
          // const blob = await getBlob(ref);

          const _url = await getDownloadURL(ref);//URL.createObjectURL(blob);

          getThumbnail({
            ...object,
            url: _url,
            onProgress: (progress) => {
              const _progress =
                (progress.id == 'upload' ? 90 : 0) +
                (progress.id == 'upload' ? 10 : 90) *
                (progress.loaded / progress.total);

              objectLoadersRef.current[object.uuid]?.setValue(_progress);
            },
            onComplete: ({ url }) => set(databaseRef(database, `objects/${object.uuid}/needsUpdate`), false)
              .then(() => resolve(url)),
          });
        });

        setObjectThumbnail({ [object.uuid]: url });
      }
    }
  };

  React.useEffect(() => { fetchObjects() }, [objects]);

  const fetchProject = async () => {
    if (!getPayloadFromToken().project) return;

    const project = await get(databaseRef(database, `projects/${getPayloadFromToken().project}`)).then((snapshot) => snapshot.val());

    if (!project) return navigate('/');

    const [creator, ...editors] = await httpsCallable('fetchUsers')({
      users: [{ uid: project.createdBy }].concat(Object.keys(project.editors || {}).map((uid) => ({ uid }))),
    })
      .then(({ data: { users } = {} }) => users);

    setProject({ ...project, creator, editors, uuid: getPayloadFromToken().project });

    if (!project.objects) {
      if (!initialized) setInitialized(true);
      setObjects([]);
      return;
    }

    let _objects = await Promise.all(Object.keys(project.objects).map((uuid) => new Promise(async (resolve, reject) => {
      try {
        const root = uuid;

        const { redirect, token, versions = [], ...rest } = await get(databaseRef(database, `objects/${uuid}`)).then((snapshot) => snapshot.val());

        let name, size;
        if (versions[versions.length - 1]) {
          uuid = versions[versions.length - 1];
          const payload = await get(databaseRef(database, `objects/${uuid}`)).then((snapshot) => snapshot.val());
          name = payload.name;
          size = payload.size;
        }
        else {
          name = rest.name;
          size = rest.size;
        }

        let ref = await listAll(storageRef(storage, `objects/${uuid}`));
        let _ref = ref;

        const [metadata, download] = await Promise.all([getMetadata(ref.items[0]), getDownloadURL(ref.items[0])]);

        let type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 1].toLowerCase();
        let zipped = false;
        if (type == 'zip') {
          type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 2].toLowerCase();
          zipped = true;
        }

        type = name.split('.')[name.split('.').length - 1].toLowerCase();

        if (ref.prefixes[0]) ref = await listAll(ref.prefixes[0]);

        resolve({
          metadata,
          uuid,
          name: name || metadata.name,
          redirect,
          token,
          size: size || metadata.size,
          thumbnail: objects.find((object) => object.uuid == uuid)?.thumbnail,
          type: zipped ? 'zip' : type,
          originalType: name.split('.')[name.split('.').length - 1].toLowerCase(),
          ref: _ref.items[0],
          download,
          versions,
          root,
        });
      } catch (e) {
        deleteFolder(storageRef(storage, `maps/${uuid}`));
        deleteFolder(storageRef(storage, `thumbnails/${uuid}`));
        deleteFolder(storageRef(storage, `comments/${uuid}`));
        set(databaseRef(database, `objects/${uuid}`), null);
        set(databaseRef(database, `projects/${getPayloadFromToken().project}/objects/${uuid}`), null);

        resolve(null);
      };
    })))
      .then((__objects) => __objects.filter((object) => object));

    if (!initialized) setInitialized(true);
    setObjects(_objects);
  };

  React.useEffect(() => {
    if (!getPayloadFromToken().project) return navigate('/projects');
    if (!getPayloadFromToken().project) return setInitialized(true);

    const projectsRef = databaseRef(database, `projects/${getPayloadFromToken().project}`);
    return onValue(projectsRef, () => fetchProject());
  }, []);

  React.useEffect(() => { dispatch(fetchUser(true)) }, [user]);

  React.useEffect(() => {
    if (!authenticated && !getPayloadFromToken().project) setAuthenticated(true);
    if (!authenticated && project.token && !project.password) setAuthenticated(true);
  }, [authenticated, project]);

  React.useEffect(() => {
    if (!userData) return;

    if (Object.keys(userData.projects || {}).includes(getPayloadFromToken().project) ||
      Object.keys(userData.editing || {}).includes(getPayloadFromToken().project)) setAuthenticated(true);
  }, [userData]);

  const { creator = {} } = project;

  const RootLayoutNullable = creator.uid == user?.uid ? RootLayout : 'span';

  return (
    // <RootLayoutNullable tab={['projects', (value) => navigate(`/home?=${value}`)]}>
      <Projects
        project={[project, setProject, fetchProject]}
        models={objects.map((object) => ({ ...object, thumbnail: objectThumbnails[object.uuid] }))}
        {...uploadHandlerProps} />
    // </RootLayoutNullable>
  );
}