import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDatabase, useStorage, useUser } from 'reactfire';
import {
    ref as databaseRef,
    get,
} from 'firebase/database';
import {
    ref as storageRef,
    uploadBytes,
    getMetadata,
    deleteObject,
} from 'firebase/storage';
import { styled } from '@mui/material/styles';
import { Rnd } from 'react-rnd';
import useMouse from '@react-hook/mouse-position';
import { useDebouncedCallback } from 'use-debounce';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import {
    ArrowLeftIcon,
    SettingsIcon,
    CirclePlusIcon,
    ChevronRightIcon,
    ImageIcon,
    XIcon
} from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Toggle } from "@/components/ui/toggle"
import {
    HoverCard,
    HoverCardTrigger,
    HoverCardContent,
} from "@/components/ui/hover-card"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectGroup,
    SelectValue,
} from "@/components/ui/select"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ProjectDetailComments from '@/components/projects/project-detail-comments';
import ProjectDetailScene from '@/components/projects/project-detail-scene';
import ProjectDetailOptions from '@/components/projects/project-detail-options';
import ProjectDetailShare from '@/components/projects/project-detail-share';
import ProjectDetailCommentLeave from '@/components/projects/project-detail-comment-leave';
import ProjectDetailComment from '@/components/projects/project-detail-comment';

import ShareSpace from '@/components/ShareSpace';
import Comment from '@/routes/Viewer/components/Comment';
import { set, increment } from '@/modules/firebase/database';
import {
    getVersion,
    getVersions,
    setVersion,
} from '@/modules/redux/storage';
import {
    addNote,
    updateNote,
    deleteNote,
    getComments,
    getCurrObject,
    getAmbientLight,
    getAnimations,
    getDirectionalLight,
    getEdges,
    getEdgesEnabled,
    getEnvironments,
    getExposure,
    getFreeview,
    getGrid,
    getNormals,
    getNotes,
    getPolycount,
    getShowBackground,
    getTags,
    getTextures,
    getType,
    getUVMap,
    getVertices,
    getWireframe,
    setCurrObject,
    toggleEdges,
    toggleGrid,
    toggleNormals,
    toggleWireframe,
    toggleUVMap,
    lookAt,
    resetThreejs,
    setAnimation,
    toggleViewMode,
} from '@/modules/redux/threejs';
import { getUser } from '@/modules/redux/user';
import {
    getPayloadFromToken,
    deleteFolder,
    getFolderSize,
    uuid,
} from '@/modules/utils';

export default function ProjectDetailOverlay() {
    const dispatch = useDispatch();

    const database = useDatabase();

    const storage = useStorage();

    const { data: user } = useUser();

    const userData = useSelector(getUser);

    const comments = useSelector(getComments);
    const currObject = useSelector(getCurrObject);
    const ambientLight = useSelector(getAmbientLight);
    const animations = useSelector(getAnimations);
    const directionalLight = useSelector(getDirectionalLight);
    const edges = useSelector(getEdges);
    const environments = useSelector(getEnvironments);
    const exposure = useSelector(getExposure);
    const freeview = useSelector(getFreeview);
    const grid = useSelector(getGrid);
    const normals = useSelector(getNormals);
    const notes = useSelector(getNotes);
    const polycount = useSelector(getPolycount);
    const showBackground = useSelector(getShowBackground);
    const tags = useSelector(getTags);
    const textures = useSelector(getTextures);
    const type = useSelector(getType);
    const uvMap = useSelector(getUVMap);
    const vertices = useSelector(getVertices);
    const wireframe = useSelector(getWireframe);

    const version = useSelector(getVersion);
    const versions = useSelector(getVersions);

    let navigate = useNavigate();

    addEventListener("load", function () {
        var viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
    })

    const ref = React.useRef();

    const notesRef = React.useRef({});

    const [initialized, setInitialized] = React.useState(false);

    const { host, search, state } = useLocation();
    const { back = '' } = state || {};

    const urlParams = new URLSearchParams(search);

    React.useEffect(() => {
        Object.entries(notesRef.current).forEach(([uuid, element]) => {
            if (!element || !notes[uuid]) return;

            // element.style.display = notes[uuid]?.userData.visible ? '' : 'none';
            element.style.opacity = notes[uuid]?.userData.visible ? 1 : .3;
            element.style.display = notes[uuid]?.element?.style?.display;
            element.style.transform = notes[uuid]?.element?.style?.transform;
            element.style.zIndex = 4;

            if (!notes[uuid]?.userData.visible && currObject?.userData.uuid == uuid) dispatch(setCurrObject(null));
        });

        if (!initialized && urlParams.has('review') && notes[urlParams.get('review')]) setInitialized(true);
    }, [notes]);

    React.useEffect(() => { dispatch(lookAt(notes[urlParams.get('review')])) }, [initialized]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const commentRef = React.useRef();

    const _mouse = useMouse(ref);
    const [mouse, setMouse] = React.useState(_mouse);

    React.useEffect(() => {
        if (_mouse.x === null || _mouse.y === null) return;

        const x = Math.min(_mouse.x, _mouse.elementWidth);
        const y = Math.min(_mouse.y, _mouse.elementHeight);

        if (x != mouse.x || y != mouse.y) setMouse({ x, y });
    }, [_mouse]);

    const handleAddNote = () => {
        const note = { position: { x: mouse.x, y: mouse.y }, user: user?.id };

        dispatch(addNote(note));
    };

    const handleNoteOver = useDebouncedCallback(({ element, object }) => {
        dispatch(setCurrObject(object));
        setAnchorEl(element);
    }, 250);

    React.useEffect(() => {
        if (!anchorEl && currObject) {
            // if (commentRef.current) commentRef.current.value = '';
            setTimeout(() => dispatch(setCurrObject(null)), 250);
        } else {
            if (commentRef.current) commentRef.current.value = '';
        }
    }, [anchorEl]);

    React.useEffect(() => {
        if (!currObject && anchorEl) {
            setAnchorEl(null);
        } else if (currObject) {
            setAnchorEl(notesRef.current[currObject.userData.uuid]);
        }
    }, [currObject]);

    const animationRef = React.useRef();

    React.useEffect(() => {
        if (animationRef.current) {
            [...animationRef.current.children].forEach((child) => {
                child.style.transition = 'none';
                child.style.WebkitTransition = 'none';
            });
        }
    }, [animationRef.current]);

    const tagRef = React.useRef();
    const [tagsOpen, setTagsOpen] = React.useState(false);
    const noteTags = _.uniqWith(currObject?.userData.tags || [], _.isEqual);
    const handleAddTag = ({ label, color }) => {
        if (!currObject.userData.uuid || !label) return;

        const tag = tags.find((_tag) => _tag.label == label);
        const noteHasTag = !!noteTags.find((_tag) => _tag.label == label);

        const newTags = noteHasTag ? noteTags.filter((_tag) => _tag.label != label) : noteTags.concat([{ label, color: color || tag?.color || `#${Math.floor(Math.random() * 16777215).toString(16)}` }]);

        dispatch(updateNote({ uuid: currObject.userData.uuid, updates: { tags: newTags } }));
    };

    const [redirect, setRedirect] = React.useState(null);
    const [token, setToken] = React.useState(null);

    React.useEffect(() => {
        if (getPayloadFromToken().project)
            get(databaseRef(database, `projects/${getPayloadFromToken().project}/token`)).then((snapshot) => setToken(snapshot.val()));
    }, []);


    const handleBackClick = () => {
        dispatch(resetThreejs());
        dispatch(setVersion(null));
        navigate(token ? `/project?token=${token}` : user ? '/projects' : '/', { state: { back } });
    };

    const handleCommentDeleteAll = async () => {
        if (user?.uid) {
            const size = await getFolderSize(storageRef(storage, `comments/${version}/${currObject.userData.uuid}`))
            await set(databaseRef(database, `users/${user?.uid}/fileSpaceUsed`), increment(-size));
        }

        await deleteFolder(storageRef(storage, `comments/${version}/${currObject.userData.uuid}`));

        dispatch(deleteNote(currObject.userData.uuid));
    };


    const handleCompletedChange = (open) => {
        dispatch(updateNote({ uuid: currObject.userData.uuid, updates: { completed: open } }));
    };

    const handleAddTagOpenChange = (open) => setTagsOpen(open);

    const handleTagSubmit = (e) => {
        if (e.key && e.key != 'Enter') return;

        if (!document.getElementById('tag').value) return;
        if (tags.find((tag) => tag.label == document.getElementById('tag').value)) return document.getElementById('tag').value = '';

        handleAddTag({ label: document.getElementById('tag').value });
        document.getElementById('tag').value = '';
    };
    const TagToggle = ({ label }) => <DropdownMenuItem key={label} asChild onSelect={(e) => e.preventDefault()}>
        <Toggle
            variant="ghost"
            pressed={!!noteTags.find((t) => t.label == label)}
            className="w-full text-[0.8rem] my-1"
            onPressedChange={() => handleAddTag({ label })}>
            {label}
        </Toggle>
    </DropdownMenuItem>;

    const handleCommentDelete = async (comment) => {
        if (comment.image) {
            if (user?.uid) {
                const size = await getFolderSize(storageRef(storage, `comments/${version}/${currObject.userData.uuid}/${comment.image}`));
                await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-size));
            }

            await deleteFolder(storageRef(storage, `comments/${version}/${currObject.userData.uuid}/${comment.image}`));
        }

        const i = currObject.userData.comments.indexOf(comment);

        const updates = {
            comments: [...currObject.userData.comments.slice(0, i), ...currObject.userData.comments.slice(i + 1)],
        };

        dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));
    };

    const handleUploadImageClick = () => document.getElementById('image').click();

    const handleImageUpload = async () => {
        const image = document.getElementById('image');

        if (!image.files || !image.files.length) return;

        const file = image.files[0];

        const _uuid = uuid();

        const fileRef = storageRef(storage, `comments/${version}/${currObject.userData.uuid}/${_uuid}/${file.name == 'Default' ? 'Default (1)' : file.name}`);

        const _file = await uploadBytes(fileRef, file);

        if (user?.uid) {
            const metadata = await getMetadata(_file.ref);
            await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(metadata.size));
        }

        const note = { image: _uuid, timestamp: Date.now(), user: user?.uid || null };

        const updates = {
            comments: [...currObject.userData.comments, note],
        };

        dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));

        if (document.getElementById('comment')) document.getElementById('comment').value = '';
    }

    const handleCommentSubmit = (e) => {
        if (e.key && (e.key != 'Enter' || e.shiftKey)) return;

        const comment = document.getElementById('comment');

        if (!comment.value) return;

        const updates = {
            comments: [
                ...currObject.userData.comments,
                {
                    text: comment.value,
                    timestamp: Date.now(),
                    user: user?.uid || null,
                },
            ],
        };

        dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));

        if (comment) comment.value = '';
    };

    return (
        <div className="absolute w-full h-full flex flex-col justify-between">
            <div className="flex flex-row">
                <div className="w-12 h-12 absolute" style={{ zIndex: 5 }}>
                    <Button variant="outline" size="icon" className="ml-4 mt-4" onClick={handleBackClick}>
                        <ArrowLeftIcon className="w-[18px] h-[18px]" />
                    </Button>
                </div>
                <div className="relative flex justify-center items-center gap-4 mx-auto border rounded-lg size-fit p-2 bg-background mt-4"
                    style={{ zIndex: 3 }}>
                    {/* <Select value="0.0.1">
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="All categories" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem key="0.0.1" value="0.0.1">
                                    v0.0.1
                                </SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select> */}
                    <ProjectDetailOptions />
                    {!getPayloadFromToken().website && <Button onClick={handleAddNote}>Comment</Button>}
                    <ProjectDetailShare
                        link={`${host}/${redirect}`}
                        embed={['pro', 'premium', 'enterprise'].includes(userData?.plan) ? redirect : undefined} />
                </div>
            </div>
            {Object.entries(notes).map(([uuid, note], i) => <HoverCard
                key={uuid}
                open={!!anchorEl && !!currObject && notesRef.current[uuid] == anchorEl}
                className="relative">
                <Rnd
                    style={{ zIndex: 4, cursor: 'default' }}
                    enableResizing={false}
                    disableDragging={!note.userData.visible}
                    position={{ x: 0, y: 0 }}
                    onDragStop={(e, d) => {
                        const x = Number(notesRef.current[uuid].style.transform.split('translate(')[2].split('px,')[0]);
                        const y = Number(notesRef.current[uuid].style.transform.split('translate(')[2].split('px')[1].split(',')[1]);

                        const updates = { position: { x: x + d.x, y: y + d.y } };

                        dispatch(updateNote({ uuid, updates }));
                    }}>
                    <HoverCardTrigger asChild>
                        <Button
                            ref={(el) => (notesRef.current[uuid] = el)}
                            {...(isMobile ? {
                                onTouchStart: (e) => handleNoteOver({ object: note, element: e.currentTarget }),
                                onTouchEnd: handleNoteOver.cancel,
                            } : {
                                onMouseEnter: (e) => handleNoteOver({ object: note, element: e.currentTarget }),
                                onMouseLeave: handleNoteOver.cancel,
                            })}
                            className="rounded-full w-9 h-9"
                            style={{ cursor: 'all-scroll' }}>
                            {i + 1}
                        </Button>
                    </HoverCardTrigger>
                </Rnd>
                <HoverCardContent className="grid border bg-background shadow-lg sm:rounded-lg p-0 z-10 w-[340px]">
                    <ScrollArea className="max-w-sm max-h-[300px]">
                        {currObject && <div className="grid gap-4 p-4 mt-5">
                            <Button variant="ghost" size="icon" className="absolute right-0 top-0" onClick={handleCommentDeleteAll}>
                                <XIcon className="w-4 h-4 text-muted-foreground" />
                            </Button>
                            <div className="flex flex-col space-y-1.5 text-center sm:text-left">
                                <div className="flex space-x-2 h-7">
                                    <Toggle
                                        variant="outline"
                                        pressed={currObject.userData.completed}
                                        onPressedChange={handleCompletedChange}
                                        className="basis-1/2 text-[0.8rem]">
                                        Completed
                                    </Toggle>
                                    <DropdownMenu align="start" open={tagsOpen} onOpenChange={handleAddTagOpenChange}>
                                        <DropdownMenuTrigger asChild>
                                            <Button variant="outline" className="basis-1/2 h-9 text-[0.8rem]">Tags</Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="w-[150px]" onInteractOutside={() => handleAddTagOpenChange(false)}>
                                            {/* <DropdownMenuLabel className="flex items-center justify-between px-0">
                                                <Input id="tag" className="h-7 text-[0.8rem] font-medium border" onKeyDown={handleTagSubmit}
                                                    style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} />
                                                <Button size="icon" className="w-7 h-7" onClick={handleTagSubmit}
                                                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}>
                                                    <ChevronRightIcon className="w-4 h-4" />
                                                </Button>
                                            </DropdownMenuLabel> */}
                                            <DropdownMenuGroup>
                                                <TagToggle label="Model" />
                                                <TagToggle label="Texture" />
                                                <TagToggle label="Animation" />
                                                {tags
                                                    .filter((tag) => !['Model', 'Texture', 'Animation'].includes(tag.label))
                                                    .map((tag) => <TagToggle label={tag.label} />)}
                                            </DropdownMenuGroup>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {currObject?.userData.comments.map((comment, i) => <ProjectDetailComment
                                    key={i}
                                    comment={comment}
                                    uuid={currObject.userData.uuid}
                                    onDelete={() => handleCommentDelete(comment)} />)}
                                <div className="flex items-center justify-between space-x-2 p-2 border rounded-sm">
                                    <Input id="comment" className="flex-1 border-0 h-7" placeholder="Leave a comment" onKeyDown={handleCommentSubmit} />
                                    <Button size="icon" className="w-7 h-7" onClick={handleUploadImageClick}>
                                        <ImageIcon className="w-4 h-4" />
                                        <input
                                            id="image"
                                            accept='image/*'
                                            type='file'
                                            hidden
                                            onChange={handleImageUpload}
                                        />
                                    </Button>
                                    <Button size="icon" className="w-7 h-7" onClick={handleCommentSubmit}>
                                        <ChevronRightIcon className="w-4 h-4" />
                                    </Button>
                                </div>
                            </div>
                        </div>}
                        <ScrollBar orientation="vertical" />
                    </ScrollArea>
                </HoverCardContent>
            </HoverCard>)}
            <div className='flex flex-col gap-2 p-4'>
                <h3 className="text-xs font-medium">
                    {`Polycount: ${polycount}`}
                </h3>
                <h3 className="text-xs font-medium">
                    {`Vertices: ${vertices}`}
                </h3>
            </div>
        </div>
    );
}