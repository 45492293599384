import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import {
  ref as databaseRef,
} from 'firebase/database';
import {
  ref as storageRef,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  getMetadata,
  uploadString,
} from 'firebase/storage';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  IconButton,
  Input,
  Menu,
  Paper,
  Popover,
  Slider,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Add,
  Close,
  UnfoldMore,
  Visibility,
  VisibilityOutlined,
} from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import { Rnd } from 'react-rnd';
import {
  Color,
  EquirectangularReflectionMapping,
  TextureLoader,
} from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { isMobile } from 'react-device-detect';
import { useDebouncedCallback } from 'use-debounce';

import { Lighting, Background } from 'components/OutputScreen';

import { set, update, increment } from 'modules/firebase/database';
import { getVersion } from 'modules/redux/storage';
import {
  getRGBELoader,
  getBackground,
  getLights,
  getMaps,
  getMaterial,
  getMaterials,
  getObjects,
  setShininess,
  getMaxAnisotropy,
  setLight,
  setMaterial,
  setMaps,
  updateBackground,
  updateMaterial,
  toggleMaterial,
} from 'modules/redux/threejs';
import { getPayloadFromToken, uuid, getFolderSize, generateThumbnail, deleteFolder } from 'modules/utils';
import addWatermark from 'modules/utils/addWatermark.js';

const materialTypes = {
  'MeshBasicMaterial': ['diffuse', 'specular', 'alpha', 'env', 'light', 'ao'],
  'MeshDepthMaterial': ['diffuse', 'alpha', 'displace'],
  'MeshNormalMaterial': ['bump', 'normal', 'displace'],
  'MeshLambertMaterial': ['diffuse', 'specular', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'env', 'light', 'ao'],
  'MeshMatcapMaterial': ['diffuse', 'matcap', 'alpha', 'bump', 'normal', 'displace'],
  'MeshPhongMaterial': ['diffuse', 'specular', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'env', 'light', 'ao'],
  'MeshToonMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'light', 'ao', 'gradient'],
  'MeshStandardMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'rough', 'metal', 'env', 'light', 'ao'],
  'MeshPhysicalMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'clearcoat-normal', 'displace', 'rough', 'metal', 'sheen-color', 'sheen-rough', 'env', 'light', 'ao', 'transmission', 'thickness'],
};

const materialLabels = {
  'MeshToonMaterial': 'Toon',
  'MeshStandardMaterial': 'Standard',
  'MeshPhysicalMaterial': 'Physical',
  'MeshPhongMaterial': 'Phong',
  'MeshMatcapMaterial': 'Matcap',
  'MeshLambertMaterial': 'Lambert',
  'MeshBasicMaterial': 'Basic',
}

const materialProperties = {
  'MeshToonMaterial': ['color'],
  'MeshStandardMaterial': ['color', 'emissive', 'roughness', 'metalness'],
  'MeshPhysicalMaterial': ['color', 'emissive', 'roughness', 'metalness', 'reflectivity'],
  'MeshPhongMaterial': ['color', 'emissive', 'specular', 'shininess'],
  'MeshMatcapMaterial': ['color'],
  'MeshLambertMaterial': ['color', 'emissive'],
  'MeshBasicMaterial': ['color'],
};

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
});

const textureLoader = new TextureLoader();
const rgbeLoader = new RGBELoader();

const MapInput = ({
  label,
  _key: key,
  open,
  editable,
  material,
  maps,
  value,
  onValueChange,
  valueXY,
  onValueXYChange,
  onOpen,
  onClose,
  sx,
  menuSx,
}) => {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const version = useSelector(getVersion);

  const id = material.material.userData.id;
  const materialMaps = (maps?.[id] || {})[key] || [];

  const materials = useSelector(getMaterials);

  const classes = useStyles();

  const selectRef = React.useRef();

  const onSelect = async (i) => {
    // if (i == null) {
    //   dispatch(updateMaterial({ material, updates: { [key]: null }, keys: { [key]: i } }));
    // } else {
    //   let texture = materialMaps[i].texture;
    //   if (!texture) {
    //     if (!materialMaps[i].url) return;

    //     const loader = materialMaps[i].type == 'hdr' ? rgbeLoader : textureLoader;

    //     texture = await loader.loadAsync(materialMaps[i].url);
    //     if (materialMaps[i].type == 'hdr') texture.mapping = EquirectangularReflectionMapping;
    //     texture.anisotropy = maxAnisotropy;
    //     if ('colorSpace' in material.material.userData.textures[key]) texture.colorSpace = material.material.userData.textures[key].colorSpace;
    //     if ('flipY' in material.material.userData.textures[key]) texture.flipY = material.material.userData.textures[key].flipY;
    //     if ('wrapS' in material.material.userData.textures[key]) texture.wrapS = material.material.userData.textures[key].wrapS;
    //     if ('wrapT' in material.material.userData.textures[key]) texture.wrapT = material.material.userData.textures[key].wrapT;

    //     dispatch(setMaps({
    //       ...maps,
    //       [material.object.userData.id]: {
    //         ...maps[material.object.userData.id],
    //         [key]: [
    //           ...materialMaps.slice(0, i),
    //           {
    //             ...materialMaps[i],
    //             texture,
    //           },
    //           ...materialMaps.slice(i + 1),
    //         ]
    //       }
    //     }));
    //   }

    //   dispatch(updateMaterial({ material, updates: { [key]: texture }, keys: { [key]: i } }));
    // }

    const objects = materials.filter((_material) => _material.material.uuid == material.material.uuid);

    await Promise.all(objects.map((object) => set(databaseRef(database, `objects/${version}/maps/${object.material.userData.id}/${key}`), i != null ? materialMaps[i].uuid : null)));

    onClose();
  };

  if (materialMaps.length < 1) return null;

  return (
    <Box
      sx={{
        width: isMobile ? 'calc(100vw - 20px)' : 250,
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // paddingLeft: '30px',
        padding: '0px 10px',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {/* <Button
          variant='outlined'
          onClick={() => inputRef.current.click()}
          sx={{
            backgroundColor: 'secondary.dark',
            color: '#0b8ce9',
            '&:hover': {
              backgroundColor: 'secondary.main',
              borderWidth: '0px',
            },
            margin: '5px',
            borderRadius: '10px',
            borderWidth: '0px',
            textTransform: 'none',
          }}>
          Add
          <input
            ref={inputRef}
            type='file'
            multiple
            accept='image/*, .hdr'
            hidden
            onChange={() => onInput(inputRef.current.files)}
          />
        </Button> */}
      </Box>
      <Popover
        anchorEl={selectRef.current}
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: 'secondary.main',
            borderRadius: '4px',
          },
        }}
      >
        <Box
          sx={{
            // position: 'absolute',
            width: selectRef.current?.clientWidth,
            // transform: `translate(61px, ${-scrollTop + 2}px)`,
            paddingBottom: '0px !important;',
            zIndex: 1,
            padding: '0px',
            borderColor: 'primary.main',
            // borderStyle: 'solid',
            // borderWidth: '1px',
            // borderRadius: '0px 0px 6px 6px',
          }}>
          <ButtonGroup
            orientation='vertical'
            sx={{
              fontWeight: 600,
              boxShadow: 'none',
            }}>
            <Button
              variant='outlined'
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                onSelect(null);
              }}
              sx={{
                justifyContent: 'flex-start',
                backgroundColor: 'secondary.main',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: .6,
                  boxShadow: 'none',
                  borderWidth: '0px',
                },
                minWidth: 0,
                padding: '6px',
                borderWidth: '0px',
                textTransform: 'none',
                borderRadius: '0px',
                borderRadius: '4px 4px 0px 0px',
                fontWeight: 600,
                boxShadow: 'none',
              }}>
              <Typography
                variant='subtitle2'
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  fontWeight: 400,
                }}>
                No texture
              </Typography>
            </Button>
            {materialMaps.map((texture, i) => (
              <Button
                key={texture.url}
                variant='outlined'
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(i);
                }}
                sx={{
                  justifyContent: 'flex-start',
                  backgroundColor: 'secondary.main',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    opacity: .6,
                    boxShadow: 'none',
                    borderWidth: '0px',
                  },
                  minWidth: 0,
                  padding: '6px',
                  borderWidth: '0px',
                  textTransform: 'none',
                  borderRadius: '0px',
                  borderRadius: i == materialMaps.length - 1 ? '0px 0px 4px 4px' : '0px',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    fontWeight: 400,
                  }}>
                  {texture.name}
                </Typography>
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      </Popover>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {/* <Checkbox
          checked={!!enabled}
          size='small'
          onChange={onToggle}
        /> */}
        {/* <Button
          variant='outlined'
          onClick={() => inputRef.current.click()}
          sx={{
            width: 30,
            height: 20,
            backgroundColor: 'secondary.dark',
            color: 'primary.main',
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
            minWidth: 0,
            padding: '0px',
            textTransform: 'none',
          }}>
          {url &&
            <canvas
              ref={canvasRef}
              width={29}
              height={19}
              style={{
                width: 29,
                height: 19,
                borderRadius: '4px',
              }}
            />
          }
          <input
            ref={inputRef}
            type='file'
            accept='image/*, .hdr'
            hidden
            onChange={() => onInput(inputRef.current.files[0])}
          />
        </Button> */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '3px 0px',
          }}>
          <Box sx={{ width: 90, color: 'primary.dark' }}>
            <Typography variant='body2'>{label}</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Card
              ref={selectRef}
              sx={{
                width: !onValueChange && !onValueXYChange ? (isMobile ? 'calc(100vw - 100px)' : 170) : !onValueXYChange ? (isMobile ? 'calc(100vw - 130px)' : 140) : (isMobile ? 'calc(100vw - 160px)' : 110),
                display: 'flex',
                flexDirection: 'column',
                // marginBottom: '4px',
                color: 'primary.dark',
                backgroundColor: 'secondary.main',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: .6,
                  boxShadow: 'none',
                  borderWidth: '0px',
                },
                boxShadow: 'none',
              }}>
              <CardActionArea
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'secondary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    opacity: .6,
                    boxShadow: 'none',
                    borderWidth: '0px',
                  },
                  padding: '6px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen();
                }}
              >
                <Typography
                  variant='body2'
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}>
                  {(materialMaps[material?.material?.userData?.textures[key].index] || {}).name || 'No texture'}
                </Typography>
                <KeyboardArrowDown fontSize='small' />
              </CardActionArea>
            </Card>
            {(onValueChange || onValueXYChange) &&
              <Paper
                elevation={1}
                sx={{
                  backgroundColor: 'secondary.main',
                  borderRadius: '4px',
                  // margin: '0px 10px',
                  padding: '6px',
                  marginLeft: '3px',
                }}>
                <Input
                  disableUnderline
                  value={valueXY ?
                    ((valueXY.x || valueXY.x == 0) ? valueXY.x : null) :
                    ((value || value == 0) ? value : null)
                  }
                  size='small'
                  className={classes.input}
                  sx={{
                    width: 15,
                    color: 'primary.dark',
                    // backgroundColor: 'secondary.main',
                    // borderRadius: '4px',
                    // margin: '0px 10px',
                    // padding: '4px',
                    '&:focused': {
                      borderWidth: 0,
                    },
                    fontSize: '.875rem',
                  }}
                  inputProps={{
                    step: .1,
                    type: 'number',
                    sx: {
                      padding: '0px',
                    },
                  }}
                  onChange={(e) => onValueXYChange ?
                    onValueXYChange({ x: e.target.value, y: (valueXY.y || valueXY.y == 0) ? valueXY.y : null }) :
                    onValueChange(e.target.value)
                  }
                />
              </Paper>
            }
            {onValueXYChange &&
              <Paper
                elevation={1}
                sx={{
                  backgroundColor: 'secondary.main',
                  borderRadius: '4px',
                  // margin: '0px 10px',
                  padding: '6px',
                  marginLeft: '3px',
                }}>
                <Input
                  disableUnderline
                  value={(valueXY.y || valueXY.y == 0) ? valueXY.y : null}
                  size='small'
                  className={classes.input}
                  sx={{
                    width: 15,
                    color: 'primary.dark',
                    // backgroundColor: 'secondary.main',
                    // borderRadius: '4px',
                    // margin: '0px 10px',
                    // padding: '4px',
                    '&:focused': {
                      borderWidth: 0,
                    },
                    fontSize: '.875rem',
                  }}
                  inputProps={{
                    step: .1,
                    type: 'number',
                    sx: {
                      padding: '0px',
                    },
                  }}
                  onChange={(e) => onValueXYChange({ x: (valueXY.x || valueXY.x == 0) ? valueXY.x : null, y: e.target.value })}
                />
              </Paper>
            }

          </Box>
          {editable &&
            <IconButton
              onClick={async () => {
                if (!material?.material?.userData?.textures[key].index && material?.material?.userData?.textures[key].index != 0) return;

                const id = material.material.userData.id;

                const _texture = materialMaps[material?.material?.userData?.textures[key].index];

                // const _materials = materials
                //   .reduce((obj, _material) => ({ ...obj, [typeof _material.object.userData.id == 'object' ? _material.object.userData.id[_material.material.uuid] : _material.object.userData.id]: _material.material.userData.textures }), {});

                const found = Object.entries(maps).reduce((bool1, [_id, idMaps]) =>
                  bool1 || Object.entries(idMaps).reduce((bool2, [_key, keyMaps]) =>
                    bool2 || (_id != id || _key != key) && !!keyMaps.find((map) => map.uuid == _texture.uuid)
                    , false)
                  , false);

                if (!found) {
                  if (user?.uid) {
                    const size = await getFolderSize(storageRef(storage, `maps/${version}/${_texture.uuid}`));
                    await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-size));
                  }
                  await deleteFolder(storageRef(storage, `maps/${version}/${_texture.uuid}`));
                }

                await Promise.all([
                  set(databaseRef(database, `maps/${version}/${id}/${key}/${_texture.uuid}`), null),
                  set(databaseRef(database, `objects/${version}/maps/${id}/${key}`), null),
                ]);

                // dispatch(updateMaterial({ material, updates: { [key]: null }, keys: { [key]: null } }));
              }}
              sx={{
                backgroundColor: 'secondary.background',
                color: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'secondary.background',
                },
                padding: '4px',
                borderRadius: '2px',
                visibility: (!material?.material?.userData?.textures[key].index && material?.material?.userData?.textures[key].index != 0) ? 'hidden' : '',
              }}>
              <Close sx={{ fontSize: 10 }} />
            </IconButton>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default function Materials({
  editable = false,
  environmentsEditable = false,
}) {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const objects = useSelector(getObjects);
  const maps = useSelector(getMaps);
  const lights = useSelector(getLights);
  const background = useSelector(getBackground);

  const materials = useSelector(getMaterials);
  // const materials = Object.entries(_materials)
  //   .reduce((arr, [uuid, object]) => arr.concat(Object.values(object.materials).map((value) => ({ material: value, uuid }))), [])
  // // .toSorted((material1, material2) => material1.material.userData.name - material2.material.userData.name);
  // materials.sort((material1, material2) => material1.material.userData.name - material2.material.userData.name);

  // const [material, setMaterial] = React.useState(materials[0]);
  const material = useSelector(getMaterial);

  const version = useSelector(getVersion);

  const [open, setOpen] = React.useState(null);
  const [materialsOpen, setMaterialsOpen] = React.useState(false);
  const [lightsOpen, setLightsOpen] = React.useState(false);

  const [showMaterial, setShowMaterial] = React.useState(false);
  const materialsRef = React.useRef();

  const [objectIndicesOffset, setObjectIndicesOffset] = React.useState(0);
  const [objectIndicesRange, setObjectIndicesRange] = React.useState(0);

  React.useEffect(() => {
    if (materialsRef.current?.clientHeight)
      setObjectIndicesRange(Math.ceil(materialsRef.current.clientHeight / 36));
  }, [materialsRef.current?.clientHeight]);

  const texturesRef = React.useRef();

  const [height, setHeight] = React.useState(150);
  const [heightDelta, setHeightDelta] = React.useState(0);

  // React.useEffect(() => {
  //   if (material?.material.uuid && !materials.find((_material) => _material.material.uuid == material.material.uuid)) {
  //     setMaterial(materials.find((_material) => _material.object.userData.id == material.object.userData.id));
  //   }
  // }, [materials]);

  const onPropertiesUpdate = useDebouncedCallback((_material) => {
    const objects = materials.filter((__material) => __material.material.uuid == _material.material.uuid);

    update(databaseRef(database, `objects/${version}/properties`), Object.fromEntries(objects.map((object) => [
      object.material.userData.id,
      Object.fromEntries(materialProperties[object.material.type].map((key) => [
        key,
        typeof object.material[key] == 'object' && object.material[key].isColor ? `#${object.material[key].getHexString()}` : object.material[key],
      ]))
    ])));
  }, 250);

  React.useEffect(() => {
    onPropertiesUpdate.cancel();
    onPropertiesUpdate(material);
  }, [material]);

  const typesRef = React.useRef();

  const uploadTextures = [
    materialTypes[material?.material?.type]?.includes('diffuse') && { key: 'map', text: 'Diffuse' },
    materialTypes[material?.material?.type]?.includes('matcap') && { key: 'matcap', text: 'Matcap' },
    materialTypes[material?.material?.type]?.includes('specular') && { key: 'specularMap', text: 'Specular' },
    materialTypes[material?.material?.type]?.includes('emissive') && { key: 'emissiveMap', text: 'Emissive' },
    materialTypes[material?.material?.type]?.includes('alpha') && { key: 'alphaMap', text: 'Alpha' },
    materialTypes[material?.material?.type]?.includes('bump') && { key: 'bumpMap', text: 'Bump' },
    materialTypes[material?.material?.type]?.includes('normal') && { key: 'normalMap', text: 'Normal' },
    materialTypes[material?.material?.type]?.includes('displace') && { key: 'displacementMap', text: 'Displace' },
    materialTypes[material?.material?.type]?.includes('rough') && { key: 'roughnessMap', text: 'Rough' },
    materialTypes[material?.material?.type]?.includes('metal') && { key: 'metalnessMap', text: 'Metal' },
    materialTypes[material?.material?.type]?.includes('light') && { key: 'lightMap', text: 'Light' },
    materialTypes[material?.material?.type]?.includes('ao') && { key: 'aoMap', text: 'AO' },
    materialTypes[material?.material?.type]?.includes('gradient') && { key: 'gradientMap', text: 'Gradient' },
  ]
    .filter((obj) => obj);

  const uploadRef = React.useRef();
  const uploadInputRef = React.useRef();
  const [uploadTexture, setUploadTexture] = React.useState(null);

  const sx = { width: isMobile ? '100vw' : 270, backgroundColor: 'secondary.background' };
  const menuSx = { marginLeft: '16px' };

  const mapInputProps = (key) => ({
    _key: key,
    editable,
    material: material,
    maps: maps,
    open: open == key,
    onOpen: () => setOpen(key),
    onClose: () => setOpen(null),
    sx,
    menuSx,
  });

  const [colorAnchorEl, setColorAnchorEl] = React.useState(null);
  const [color, setColor] = React.useState(null);
  const [onColorChange, setOnColorChange] = React.useState(null);
  const colorRef = React.useRef();
  const emissiveRef = React.useRef();
  const specularRef = React.useRef();
  const backgroundRef = React.useRef();

  return (
    <Box
      sx={{
        height: '100%',
        color: 'primary.main',
        backgroundColor: 'secondary.background',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        marginTop: '20px',
        ...sx,
      }}>
      <Rnd
        bounds='parent'
        style={{ position: 'relative' }}
        size={{ height }}
        disableDragging
        enableResizing={{
          bottom: true,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false,
        }}
        minHeight='150px'
        onResize={(e, dir, ele, delta) => setHeightDelta(delta.height)}
        onResizeStop={(e, dir, ele, delta) => {
          setHeight(height + delta.height);
          setHeightDelta(0);
        }}
      >
        <Box
          ref={materialsRef}
          sx={{
            width: isMobile ? 'calc(100vw - 20px)' : 250,
            height: '100%',
            backgroundColor: 'secondary.main',
            color: 'primary.main',
            borderRadius: '4px',
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            marginLeft: '10px',
          }}
          onScroll={() => {
            if (objectIndicesOffset > 0 && materialsRef.current.scrollTop <= 32) {
              materialsRef.current.scrollTop = materialsRef.current.scrollTop + (objectIndicesRange * 36);
              setObjectIndicesOffset(objectIndicesOffset - 1);
            }
            else if ((objectIndicesOffset + 2) * objectIndicesRange < materials.length &&
              materialsRef.current.scrollTop - 32 >= Math.ceil(materialsRef.current.clientHeight / 36) * 36) {
              materialsRef.current.scrollTop = materialsRef.current.scrollTop - (objectIndicesRange * 36);
              setObjectIndicesOffset(objectIndicesOffset + 1);
            }
          }}>
          <Button
            variant='outlined'
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              setMaterialsOpen(!materialsOpen);

              setObjectIndicesRange(Math.ceil(materialsRef.current.clientHeight / 36));
            }}
            sx={{
              justifyContent: 'flex-start',
              backgroundColor: 'secondary.main',
              color: 'primary.main',
              '&:hover': {
                // backgroundColor: 'secondary.dark',
                borderWidth: '0px',
              },
              minWidth: 0,
              padding: '6px',
              borderWidth: '0px',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
            }}>
            <Typography
              variant='body2'
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}>
              Objects
            </Typography>
          </Button>
          {materialsOpen && materials.slice(objectIndicesOffset * objectIndicesRange, (objectIndicesOffset + 2) * objectIndicesRange).map((_material, i) => (
            <Box key={i}>
              <Box
                sx={{
                  display: 'flex',
                }}>
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setMaterial({ material: _material, highlight: true }));
                    setShowMaterial(material.object.uuid == _material.object.uuid ? !showMaterial : true);
                  }}
                  sx={{
                    justifyContent: 'flex-start',
                    backgroundColor: 'secondary.main',
                    // backgroundColor: material?.object.userData.id == _material.object.userData.id ? 'secondary.dark' : 'secondary.main',
                    color: 'primary.main',
                    '&:hover': {
                      // backgroundColor: 'secondary.dark',
                      borderWidth: '0px',
                    },
                    minWidth: 0,
                    padding: '6px 6px 6px 10px',
                    borderWidth: '0px',
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: 'none',
                  }}>
                  <Typography
                    variant='body2'
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      wordBreak: 'break-all',
                    }}>
                    &#8226;&nbsp;&nbsp;{_material.material.userData.name}
                  </Typography>
                </Button>
                <IconButton
                  onClick={async () => {
                    dispatch(toggleMaterial({ material: _material, updates: { visible: !_material.object.visible } }));
                  }}
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.dark',
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                    },
                    borderRadius: '4px',
                  }}>
                  {!_material.object.visible ? <Visibility fontSize='small' /> : <VisibilityOutlined fontSize='small' />}
                </IconButton>
              </Box>
              {showMaterial && material?.object?.uuid == _material?.object?.uuid && material?.material?.uuid == _material?.material?.uuid &&
                <Box>
                  <Typography
                    variant='body2'
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      wordBreak: 'break-all',
                      fontSize: '.7rem',
                      paddingLeft: '25px',
                    }}>
                    - {material.material.name}
                  </Typography>
                </Box>
              }
            </Box>
          ))}
          <Button
            variant='outlined'
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              setLightsOpen(!lightsOpen);
            }}
            sx={{
              justifyContent: 'flex-start',
              backgroundColor: 'secondary.main',
              color: 'primary.main',
              '&:hover': {
                // backgroundColor: 'secondary.dark',
                borderWidth: '0px',
              },
              minWidth: 0,
              padding: '6px',
              borderWidth: '0px',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
            }}>
            <Typography
              variant='body2'
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}>
              Lights
            </Typography>
          </Button>
          {lightsOpen &&
            Object.values(lights).map((light) => (
              <Button
                key={light.uuid}
                variant='outlined'
                fullWidth
                onClick={() => {
                  if (!['AmbientLight', 'HDRI'].includes(light.type)) {
                    dispatch(setLight(light));
                  }
                }}
                sx={{
                  justifyContent: 'flex-start',
                  backgroundColor: 'secondary.main',
                  // backgroundColor: material?.object.userData.id == _material.object.userData.id ? 'secondary.dark' : 'secondary.main',
                  color: 'primary.main',
                  '&:hover': {
                    // backgroundColor: 'secondary.dark',
                    borderWidth: '0px',
                  },
                  minWidth: 0,
                  padding: '6px 6px 6px 10px',
                  borderWidth: '0px',
                  borderRadius: '0px',
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}>
                <Typography
                  variant='body2'
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}>
                  &#8226;&nbsp;&nbsp;{light.type.split('Light')[0]}
                </Typography>
              </Button>
            ))
          }
          <Box sx={{ position: 'fixed', right: 0, bottom: 0 }}>
            <UnfoldMore fontSize='small' />
          </Box>
        </Box>
      </Rnd>


      <Box
        sx={{
          height: '100%',
          // overflow: 'scroll',
        }}>
        {open == 'types' &&
          <Box
            sx={{
              position: 'absolute',
              width: typesRef.current?.clientWidth,
              transform: `translate(10px, ${-scrollTop + 45}px)`,
              paddingBottom: '0px !important;',
              zIndex: 1,
              padding: '0px',
              borderColor: 'primary.main',
              // borderStyle: 'solid',
              // borderWidth: '1px',
              // borderRadius: '0px 0px 6px 6px',
            }}>
            {Object.keys(materialTypes).map((key, i) => (
              <Button
                key={key}
                variant='outlined'
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();

                  dispatch(updateMaterial({ material, type: key }));
                  setOpen(null);
                }}
                sx={{
                  justifyContent: 'flex-start',
                  backgroundColor: 'secondary.main',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    borderWidth: '0px',
                  },
                  minWidth: 0,
                  padding: '6px',
                  borderWidth: '0px',
                  textTransform: 'none',
                  borderRadius: i == 0 ? '4px 4px 0px 0px' : i == Object.keys(materialTypes).length - 1 ? '0px 0px 4px 4px' : '0px',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}>
                  {key.match(/[A-Z][a-z]+/g)[1]}
                </Typography>
              </Button>
            ))}
          </Box>
        }
        {materialLabels[material?.material?.type] &&
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                // padding: '3px 0px',
                marginTop: '20px',
                padding: '0px 10px',
              }}>
              <Typography variant='subtitle2'>{materialLabels[material.material.type]}</Typography>
            </Box>
            {materialProperties[material.material.type].includes('color') &&
              <Box
                ref={colorRef}
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Color</Typography>
                </Box>
                <Button
                  onClick={() => {
                    setColorAnchorEl(colorRef.current);
                    setColor(material.material.color.getHexString());
                    setOnColorChange({ callback: (color) => dispatch(updateMaterial({ material, updates: { color: new Color(color) } })) });
                  }}
                  sx={{
                    height: 30,
                    minWidth: 150,
                    backgroundColor: `#${material.material.color.getHexString()}`,
                    '&:hover': {
                      backgroundColor: `#${material.material.color.getHexString()}`,
                      opacity: .6,
                      boxShadow: 'none',
                    },
                    padding: '0px',
                    borderRadius: '10px',
                  }}></Button>
              </Box>
            }
            {materialProperties[material.material.type].includes('emissive') &&
              <Box
                ref={emissiveRef}
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Emissive</Typography>
                </Box>
                <Button
                  onClick={() => {
                    setColorAnchorEl(emissiveRef.current);
                    setColor(material.material.emissive.getHexString());
                    setOnColorChange({ callback: (color) => dispatch(updateMaterial({ material, updates: { emissive: new Color(color) } })) });
                  }}
                  sx={{
                    height: 30,
                    minWidth: 150,
                    backgroundColor: `#${material.material.emissive.getHexString()}`,
                    '&:hover': {
                      backgroundColor: `#${material.material.emissive.getHexString()}`,
                      opacity: .6,
                      boxShadow: 'none',
                    },
                    padding: '0px',
                    borderRadius: '10px',
                  }}></Button>
              </Box>
            }
            {materialProperties[material.material.type].includes('specular') &&
              <Box
                ref={specularRef}
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Specular</Typography>
                </Box>
                <Button
                  onClick={() => {
                    setColorAnchorEl(specularRef.current);
                    setColor(material.material.specular.getHexString());
                    setOnColorChange({ callback: (color) => dispatch(updateMaterial({ material, updates: { specular: new Color(color) } })) });
                  }}
                  sx={{
                    height: 30,
                    minWidth: 150,
                    backgroundColor: `#${material.material.specular.getHexString()}`,
                    '&:hover': {
                      backgroundColor: `#${material.material.specular.getHexString()}`,
                      opacity: .6,
                      boxShadow: 'none',
                    },
                    padding: '0px',
                    borderRadius: '10px',
                  }}></Button>
              </Box>
            }
            {materialProperties[material.material.type].includes('roughness') &&
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Roughness</Typography>
                </Box>
                <Slider
                  color='secondary'
                  value={material.material.roughness * 100}
                  onChange={(e, value) => dispatch(updateMaterial({ material, updates: { roughness: value / 100 } }))}
                  sx={{
                    color: 'secondary.light',
                    width: 130,
                    marginLeft: '10px',
                  }}
                />
              </Box>
            }
            {materialProperties[material.material.type].includes('metalness') &&
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Metalness</Typography>
                </Box>
                <Slider
                  color='secondary'
                  value={material.material.metalness * 100}
                  onChange={(e, value) => dispatch(updateMaterial({ material, updates: { metalness: value / 100 } }))}
                  sx={{
                    color: 'secondary.light',
                    width: 130,
                    marginLeft: '10px',
                  }}
                />
              </Box>
            }
            {materialProperties[material.material.type].includes('reflectivity') &&
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Reflectivity</Typography>
                </Box>
                <Slider
                  color='secondary'
                  value={material.material.reflectivity * 100}
                  onChange={(e, value) => dispatch(updateMaterial({ material, updates: { reflectivity: value / 100 } }))}
                  sx={{
                    color: 'secondary.light',
                    width: 130,
                    marginLeft: '10px',
                  }}
                />
              </Box>
            }
            {materialProperties[material.material.type].includes('shininess') &&
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '3px 10px',
                }}>
                <Box sx={{ width: 80, color: 'primary.dark' }}>
                  <Typography variant='body2'>Shininess</Typography>
                </Box>
                <Slider
                  color='secondary'
                  value={material.material.shininess}
                  onChange={(e, value) => dispatch(updateMaterial({ material, updates: { shininess: value } }))}
                  sx={{
                    color: 'secondary.light',
                    width: 130,
                    marginLeft: '10px',
                  }}
                />
              </Box>
            }
          </>
        }
        <Box sx={{ marginTop: '20px' }}>
          <Lighting
            editable={editable}
            environmentsEditable={environmentsEditable}
            sx={sx}
            menuSx={menuSx}
          />
        </Box>
        <Box
          ref={texturesRef}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // padding: '3px 0px',
            marginTop: '20px',
            padding: '0px 10px',
          }}>
          <Typography variant='subtitle2'>Textures</Typography>
          {editable &&
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpen(open == 'upload' ? null : 'upload');
              }}
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                padding: '4px',
                borderRadius: '2px',
              }}>
              <Add fontSize='small' />
            </IconButton>
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...sx,
            // padding: '3px 0px',
            // padding: '15px 10px 30px 10px',
          }}>
          <input
            ref={uploadInputRef}
            accept='.hdr, image/*'
            type='file'
            multiple
            hidden
            onChange={async () => {
              if (!uploadInputRef.current.files || uploadInputRef.current.files.length < 1) return;

              await Promise.all([...uploadInputRef.current.files].map((file, i) => new Promise(async (resolve, reject) => {
                try {
                  const id = material.material.userData.id;

                  const { image, original } = await new Promise(async (_resolve, _reject) => {
                    const ele = new Image();
                    ele.src = URL.createObjectURL(file);
                    ele.onload = async () => {
                      const original = generateThumbnail(ele);
                      const img = { key: uploadTexture.key, image: original };//uploadTexture.key == 'map' ? await addWatermark(original) : original, original };
                      URL.revokeObjectURL(ele.src);
                      _resolve(img);
                    }
                    ele.onerror = () => {
                      URL.revokeObjectURL(ele.src);
                      _reject();
                    };
                  });

                  const _uuid = uuid();
                  // if (i == 0) setUploading({ id: material.object.userData.id, key: uploadTexture.key, uuid: _uuid });
                  const [_file] = await Promise.all([
                    uploadString(storageRef(storage, `maps/${version}/${_uuid}/${file.name == 'Default' ? 'Default (1)' : file.name}`), image, 'data_url'),
                    // uploadTexture.key == 'map' ?
                    //   uploadString(storageRef(storage, `maps/${version}/${_uuid}/original/${file.name == 'Default' ? 'Default (1)' : file.name}`), original, 'data_url') :
                    //   Promise.resolve(),
                  ]);
                  if (user?.uid) {
                    const metadata = await getMetadata(_file.ref);
                    await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(metadata.size));
                  }

                  await set(databaseRef(database, `maps/${version}/${id}/${uploadTexture.key}/${_uuid}`), file.name == 'Default' ? 'Default (1)' : file.name);

                  if (i == 0) await set(databaseRef(database, `objects/${version}/maps/${id}/${uploadTexture.key}`), _uuid);

                  resolve();
                } catch (e) { console.log(e); resolve() }
              })));

              uploadInputRef.current.value = null;

              setUploadTexture(null);
              setOpen(null);
            }}
          />
          <Popover
            anchorEl={texturesRef.current}
            open={open == 'upload'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={() => setOpen(null)}
            PaperProps={{
              sx: {
                backgroundColor: 'secondary.background',
                ...sx, ...menuSx,
              },
            }}>
            <Box
              sx={{
                height: '100%',
                color: 'primary.dark',
              }}>
              {/* <input
                ref={environmentInputRef}
                accept='.hdr'
                type='file'
                multiple
                hidden
                onChange={async () => {
                  if (!environmentInputRef.current.files || environmentInputRef.current.files.length < 1) return;

                  const files = environmentInputRef.current.files;

                  await Promise.all([...files].map((file) => new Promise(async (resolve, reject) => {
                    try {
                      const _uuid = uuid();
                      await uploadBytes(storageRef(storage, `environments/${version}/${file.name}`), file);
                      await set(databaseRef(database, `environments/${version}/${uuid()}`), file.name);
                      resolve();
                    } catch (e) { resolve() }
                  })));

                  setEnvironmentsOpen(false);
                  setOpen(null);
                }}
              /> */}
              {uploadTextures.map((_texture, i) => (
                <Button
                  key={_texture.key}
                  variant='outlined'
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();

                    if (uploadInputRef.current) uploadInputRef.current.click();

                    setUploadTexture(_texture);
                    // setOpen(null);
                  }}
                  sx={{
                    justifyContent: 'flex-start',
                    color: 'primary.main',
                    '&:hover': {
                      borderWidth: '0px',
                    },
                    minWidth: 0,
                    padding: '6px 6px 6px 10px',
                    borderWidth: '0px',
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: 'none',
                  }}>
                  <Typography
                    variant='body2'
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}>
                    {_texture.text}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Popover>
        </Box>
        {materialTypes[material?.material?.type]?.includes('diffuse') &&
          <MapInput
            label='Diffuse'
            {...mapInputProps('map')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('matcap') &&
          <MapInput
            label='Matcap'
            {...mapInputProps('matcap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('specular') &&
          <MapInput
            label='Specular'
            {...mapInputProps('specularMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('emissive') &&
          <MapInput
            label='Emissive'
            {...mapInputProps('emissiveMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('alpha') &&
          <MapInput
            label='Alpha'
            {...mapInputProps('alphaMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('bump') &&
          <MapInput
            label='Bump'
            value={material?.material?.bumpScale}
            onValueChange={(value) => {
              dispatch(updateMaterial({ material, updates: { bumpScale: value } }));
            }}
            {...mapInputProps('bumpMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('normal') &&
          <MapInput
            label='Normal'
            valueXY={material?.material?.normalScale}
            onValueXYChange={(value) => {
              dispatch(updateMaterial({ material, updates: { normalScale: value } }));
            }}
            {...mapInputProps('normalMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('displace') &&
          <MapInput
            label='Displace'
            value={material?.material?.displacementScale}
            onValueChange={(value) => {
              dispatch(updateMaterial({ material, updates: { displacementScale: value } }));
            }}
            {...mapInputProps('displacementMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('rough') &&
          <MapInput
            label='Rough'
            {...mapInputProps('roughnessMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('metal') &&
          <MapInput
            label='Metal'
            {...mapInputProps('metalnessMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('light') &&
          <MapInput
            label='Light'
            {...mapInputProps('lightMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('ao') &&
          <MapInput
            label='AO'
            value={material?.material?.aoMapIntensity}
            onValueChange={(value) => {
              dispatch(updateMaterial({ material, updates: { aoMapIntensity: value } }));
            }}
            {...mapInputProps('aoMap')}
          />
        }
        {materialTypes[material?.material?.type]?.includes('gradient') &&
          <MapInput
            label='Gradient'

            {...mapInputProps('gradientMap')}
          />
        }
        <Box sx={{ marginTop: '20px' }}><Background sx={sx} menuSx={menuSx} /></Box>
        <Box sx={{ height: 100 }}></Box>
        <Popover
          anchorEl={colorAnchorEl}
          open={!!colorAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => {
            setColorAnchorEl(null);
            setOnColorChange(null);
          }}
          PaperProps={{
            sx: {
              width: isMobile ? '100vw' : 270,
              backgroundColor: 'secondary.background',
              marginLeft: '16px',
            },
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 0px',
            }}>
            <SketchPicker
              color={color}
              disableAlpha
              presetColors={[]}
              onChange={(e) => {
                setColor(e.hex);
                onColorChange.callback(e.hex);
              }}
            />
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}