import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useDatabase,
  useStorage,
  useUser,
} from 'reactfire';
import {
  ref as databaseRef,
  get,
} from 'firebase/database';
import {
  ref as storageRef,
  getDownloadURL,
  getMetadata,
  listAll,
} from 'firebase/storage';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { isSafari } from 'react-device-detect';
import _ from 'lodash';

import { set } from 'modules/firebase/database';
import { httpsCallable } from 'modules/firebase/functions';
import { getThumbnail } from 'modules/redux/threejs';
import { getPayloadFromToken } from 'modules/utils';

export default function useWebsite({ objectLoadersRef } = {}) {
  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const { pathname } = useLocation();

  const { tab, username } = useParams();

  const navigate = useNavigate();

  const [initialized, setInitialized] = React.useState(false);
  const [objects, setObjects] = React.useState(null);
  const [objectThumbnails, setObjectThumbnails] = React.useState({});
  const [objectThumbnail, setObjectThumbnail] = React.useState(null);

  React.useEffect(() => {
    if (!objectThumbnail) return;

    setObjectThumbnails({ ...objectThumbnails, ...objectThumbnail });

  }, [objectThumbnail]);

  const fetchObjects = async () => {
    const _objects = await Promise.all((objects || []).map((object) => new Promise(async (resolve) => {
      let thumbnail = objectThumbnails[object.uuid]?.url;
      let custom = !!objectThumbnails[object.uuid]?.custom;
      let name// = objectThumbnails[object.uuid]?.name;

      const ref = await listAll(storageRef(storage, `thumbnails/${object.uuid}`));
      if (ref.prefixes.find((i) => i.name == 'custom')) {
        const [url, metadata] = await listAll(ref.prefixes.find((i) => i.name == 'custom')).then((ref) => Promise.all([getDownloadURL(ref.items[0]), getMetadata(ref.items[0])]));
        thumbnail = url
        custom = true;
        name = metadata.name.split('.').slice(1, metadata.name.split('.').length - 1).join('.');
      }
      else {
        const needsUpdate = await get(databaseRef(database, `objects/${object.uuid}/needsUpdate`)).then((snapshot) => !!snapshot.val());
        if (isSafari || !needsUpdate) {
          if (ref.items[0]) thumbnail = await getDownloadURL(ref.items[0]);
        }
        else if (needsUpdate && thumbnail && !custom) {
          thumbnail = null;
        }
        custom = false;
      }
 
      resolve({ thumbnail, custom, name });
    })))
      .then((thumbnails) => (objects || []).map((object, i) => ({
        ...object,
        thumbnail: thumbnails[i].thumbnail,
        custom: thumbnails[i].custom,
        name: thumbnails[i].name,
        progress: objectLoadersRef?.current?.[object.uuid]?.getValue(),
      })));

    setObjectThumbnail(Object.fromEntries(_objects.map(({ uuid, thumbnail, custom, name }) => [uuid, { custom, url: thumbnail, name }])));

    for (const object of _objects) {
      const i = _objects.indexOf(object);

      if (!object.thumbnail && (object.progress === undefined || object.progress === null)) {
        const url = await new Promise(async (resolve) => {
          const canvas = document.createElement('canvas');
          const offscreen = canvas.transferControlToOffscreen();

          const _url = await getDownloadURL(object.ref);

          getThumbnail({
            ...object,
            url: _url,
            type: object.zipped ? 'zip' : object.type,
            onProgress: (progress) => {
              const _progress =
                (progress.id == 'upload' ? 90 : 0) +
                (progress.id == 'upload' ? 10 : 90) *
                (progress.loaded / progress.total);

              objectLoadersRef?.current?.[object.uuid]?.setValue(_progress);
            },
            onComplete: ({ url }) => set(databaseRef(database, `objects/${object.uuid}/needsUpdate`), false)
              .then(() => resolve(url)),
          });

          // threejsWorker({
          //  type: 'getThumbnail',
          //  options: { ...object, canvas: offscreen, url: _url,  type: object.zipped ? 'zip' : object.type },
          //  onProgress: (progress) => {
          //   const _progress =
          //  (progress.id == 'upload' ? 90 : 0) +
          //  (progress.id == 'upload' ? 10 : 90) *
          //  (progress.loaded / progress.total);

          //   objectLoadersRef.current[object.uuid].setValue(_progress);
          //  },
          //  onComplete: ({ url }) => set(databaseRef(database, `objects/${object.uuid}/needsUpdate`), false)
          //   .then(() => resolve(url)),
          // });
        });

        setObjectThumbnail({ [object.uuid]: { url, custom: false } });
      }
    }
  };

  React.useEffect(() => { fetchObjects() }, [objects]);

  const [website, setWebsite] = React.useState(null);

  const fetchWebsite = async () => {
    let _user = user;
 
    let uid;
    if (username) {
      uid = await get(databaseRef(database, `websites/${username.toLowerCase()}`))
        .then((snapshot) => snapshot.val());

      if (!uid) return navigate('/');
    }
    else if (pathname != '/') {
      uid = await get(databaseRef(database, `websites${pathname.toLowerCase()}`))
        .then((snapshot) => snapshot.val());
    }

    if (uid) _user = { uid };
    if (!_user?.uid) return setInitialized(true);

    const website = await get(databaseRef(database, `users/${_user.uid}/website`)).then((snapshot) => snapshot.val());
    if (!website) {
      setObjects([]);
      return setInitialized(true);
    }

    const websiteRef = await listAll(storageRef(storage, `websites/${_user.uid}`));

    const profileRef = websiteRef.prefixes.find((item) => item.name.includes('profile'));
    const profile = profileRef ? await listAll(profileRef).then((ref) => getDownloadURL(ref.items[0])).catch(() => null) : null;

    const backgroundRef = websiteRef.prefixes.find((item) => item.name.includes('background'));
    const background = backgroundRef ? await listAll(backgroundRef).then((ref) => getDownloadURL(ref.items[0])).catch(() => null) : null;
    
    let _objects = await Promise.all(Object.keys(website.objects || {}).map((uuid) => new Promise(async (resolve, reject) => {
      try {
        const root = uuid;

        const { redirect, token, versions = [], ...rest } = await get(databaseRef(database, `objects/${uuid}`)).then((snapshot) => snapshot.val());

        let name, size, polycount, vertices, hasAnimations, id, title, category, description, termsAndConditions, price, productId, editRequests, commissions;
        if (versions[versions.length - 1]) {
          uuid = versions[versions.length - 1];
          const payload = await get(databaseRef(database, `objects/${uuid}`)).then((snapshot) => snapshot.val());
          name = payload.name;
          size = payload.size;
          polycount = payload.polycount;
          vertices = payload.vertices;
          hasAnimations = payload.hasAnimations;
          id = payload.id || 'shop';
          title = payload.title;
          category = payload.category || '';
          description = payload.description;
          termsAndConditions = payload.termsAndConditions;
          price = payload.price;
          productId = payload.productId;
          editRequests = payload.editRequests;
          commissions = payload.commissions;
        }
        else {
          name = rest.name;
          size = rest.size;
          polycount = rest.polycount;
          vertices = rest.vertices;
          hasAnimations = rest.hasAnimations;
          id = rest.id || 'shop';
          title = rest.title;
          category = rest.category || '';
          description = rest.description;
          termsAndConditions = rest.termsAndConditions;
          price = rest.price;
          productId = rest.productId;
          editRequests = rest.editRequests;
          commissions = rest.commissions;
        }

        let ref = await listAll(storageRef(storage, `objects/${uuid}`));
        let _ref = ref;

        const [metadata, url] = await Promise.all([getMetadata(ref.items[0]), getDownloadURL(ref.items[0])]);

        let type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 1].toLowerCase();
        let zipped = false;
        if (type == 'zip') {
          type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 2].toLowerCase();
          zipped = true;
        }

        type = name.split('.')[name.split('.').length - 1].toLowerCase();

        if (ref.prefixes[0]) ref = await listAll(ref.prefixes[0]);

        const download = await getDownloadURL(ref.items[0]);

        resolve({
          metadata,
          uuid,
          name: name || metadata.name,
          redirect,
          token,
          size: size || metadata.size,
          polycount,
          vertices,
          hasAnimations,
          thumbnail: objects?.find((object) => object.uuid == uuid)?.thumbnail,
          fileType: type,
          originalType: name.split('.')[name.split('.').length - 1].toLowerCase(),
          zipped,
          ref: _ref.items[0],
          url,
          download,
          versions,
          root,
          id,
          title,
          category,
          description,
          termsAndConditions,
          price,
          productId,
          editRequests,
          commissions,
        });
      } catch (e) {
        // deleteFolder(storageRef(storage, `maps/${uuid}`));
        // deleteFolder(storageRef(storage, `thumbnails/${uuid}`));
        // deleteFolder(storageRef(storage, `comments/${uuid}`));
        // set(databaseRef(database, `objects/${uuid}`), null);
        // if (user?.uid) {
        //   set(databaseRef(database, `users/${user.uid}/website/objects/${uuid}`), null);
        // }
        // else {
        //   get(databaseRef(database, `websites${window.location.pathname.toLowerCase()}`))
        //     .then((snapshot) => snapshot.val())
        //     .then((uid) => set(databaseRef(database, `users/${uid}/website/object/${uuid}`), null));
        // }

        resolve(null);
      };
    })))
      .then((__objects) => __objects.filter((object) => object));

    if (!initialized && getPayloadFromToken().objects && Date.now() - (getPayloadFromToken().timestamp || 0) < 60000) {
      const zip = new JSZip();
      const models = zip.folder(website.username);

      for (const objectUuid of getPayloadFromToken().objects) {
        const object = _objects.find((object) => object.uuid == objectUuid);

        const ref = await listAll(storageRef(storage, `objects/${object.uuid}/original`));

        const url = await getDownloadURL(ref.items[0]);

        let blob;
        if (!object.zipped) {
          blob = await fetch(url).then((res) => res.blob());
        }
        else {
          blob = await new Promise((resolve, reject) => {
            JSZipUtils.getBinaryContent(object.download, (err, data) => err ? reject(err) : resolve(data));
          })
            .then((data) => JSZip.loadAsync(data))
            .then((data) => Object.values(data.files)[0].async('arraybuffer'))
            .then((buffer) => new Blob([buffer]));
        }

        const model = models.folder(`${object.title}${_objects.filter((_object) => _object.title == object.title).length > 1 ? ` (${objects.indexOf(object)})` : ''}`);
        model.file(object.name, blob);
      }

      const blob = await zip.generateAsync({ type: 'blob' });

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${website.username}.zip`;
      link.click();

      URL.revokeObjectURL(url);
      link.remove();
    }

    let _website = {
      ...website,
      profile,
      background,
      categories: [...(website.categories || [])],
      pinned: [...(website.pinned || [])],
      user: _user,
    };

    setWebsite(_website);

    _objects.reverse();
    setObjects(_objects);

    setInitialized(true);
    // dispatch(fetchUser());

    const { data: seller = {} } = await httpsCallable('fetchAccount')({
      uid: _user.uid,
    });

    if (seller.paypal) {
      seller.paymentsEnabled = seller.paypal.payments_receivable && seller.paypal.primary_email_confirmed &&
        !!seller.paypal.oauth_integrations?.[0]?.oauth_third_party?.[0] &&
        !!seller.paypal.products?.find((p) => p.vetting_status == 'SUBSCRIBED' && p.capabilities.includes('CUSTOM_CARD_PROCESSING'));
      if (seller.paymentsEnabled) seller.provider = 'paypal';
    }
    else if (seller.stripe) {
      seller.paymentsEnabled = !seller.stripe.requirements.disabled_reason;
      if (seller.paymentsEnabled) seller.provider = 'stripe';
    }
    else {
      seller.paymentsEnabled = false;
    }

    _website.userData = { seller };
    setWebsite(_website);
  };

  React.useEffect(() => {
    setInitialized(false);
    fetchWebsite();
  }, [tab, username, user]);

  return {
    initialized,
    website: [website, fetchWebsite],
    objects,
    objectThumbnails,
  };
}