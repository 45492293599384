"use client"

import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"
import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils"

const indicatorVariants = cva(
  "w-full h-full",
  {
    variants: {
      variant: {
        default: "bg-primary",
        destructive: "bg-destructive",
        background: "bg-background",
        accent: "bg-accent",
        secondary: "bg-secondary",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Progress = React.forwardRef(({ className, variant, style, value, max, ...props }, ref) => (
    <ProgressPrimitive.Root ref={ref}
        value={value}
        max={max}
        className={cn(
            "relative overflow-hidden bg-blackA6 rounded-full w-[300px] h-[25px]",
            className,
        )}
        style={{ transform: 'translateZ(0)', ...style }}
        {...props}>
        <ProgressPrimitive.Indicator
            className={cn(indicatorVariants({ variant }))}
            style={{ transform: `translateX(-${100 - 100*(value / max)}%)` }} />
    </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
