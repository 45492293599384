import * as React from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUser } from "reactfire";
import {
  LoaderCircle,
} from "lucide-react"
import { useDebouncedCallback } from 'use-debounce';

import { cn } from "@/lib/utils"

import { Input } from "@/components/ui/input"

import { getUser } from '@/modules/redux/user';

export function MainNav({
  search: [search, setSearch] = ['', () => { }],
}) {

  const navigate = useNavigate();

  const { data: user } = useUser();

  const { seller: { initiated = false } = {} } = useSelector(getUser);

  const handleSearchDebounce = useDebouncedCallback(setSearch, 250);

  const handleSearch = (e) => {
    handleSearchDebounce.cancel();
    handleSearchDebounce(e.target.value);
  };

  const handleHomeClick = () => navigate('/');

  const handleNavClick = () => { navigate(initiated ? '/seller' : '/artists') };

  return (
    <div className="flex flex-1 gap-4 md:gap-10 items-center">
      <button onClick={handleHomeClick}>
        <picture>
          <source media="(min-width:768px)" srcSet="/images/logo.png" />
          <img src="/images/logo_small.png" alt="modelsend"
            className={cn("bg-white object-cover",
              "md:min-w-[128px] w-[26px]"
            )}
          />
        </picture>
      </button>
      {!initiated && <nav className="hidden gap-4 md:flex">
        <button
          onClick={handleNavClick}
          className={cn(
            "flex items-center text-lg font-medium transition-colors hover:text-foreground/80 sm:text-sm",
            "text-foreground h-5 items-start overflow-y-hidden",
            // "cursor-not-allowed opacity-80"
          )}
        >
          {`I'm a 3D artist`}
        </button>
      </nav>}
      <div className="max-w-md w-full flex items-center relative">
        <Input
          onChange={handleSearch}
          placeholder="Search for a 3D model"
          className="max-w-md" />
      </div>
    </div>
  )
}
