import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDatabase } from 'reactfire';
import { ref, get } from 'firebase/database';

import routes from '@/config/routes';

export default function useRedirect() {
  const database = useDatabase();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { username } = useParams();

  const [initialized, setInitialized] = React.useState(false);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  const handleRedirect = async () => {
    const websiteRef = ref(database, `websites${username ? `/${username}` : pathname.toLowerCase()}`);
    const websiteExists = await get(websiteRef).then((snapshot) => !!snapshot.val());

    if (websiteExists) return setInitialized(true);

    const redirectRef = ref(database, `redirects${pathname}`);
    const redirect = await get(redirectRef).then((snapshot) => snapshot.val() || '/')
      .catch(() => '/');

    navigate(redirect);

    setInitialized(true);
    setShouldRedirect(true);
  };

  React.useEffect(() => { if (!routes.includes(pathname.split('/')[1])) handleRedirect() }, []);

  return { initialized, shouldRedirect };
}