import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useAuth,
  useDatabase,
  useSigninCheck,
  useStorage,
  useUser,
} from 'reactfire';
import { isSignInWithEmailLink } from 'firebase/auth';
import { ref as databaseRef } from 'firebase/database';

import RootLayout from '@/app/layout/landing';
import Home from '@/app/home/home';

import { set } from '@/modules/firebase/database';
import { fetchUser, getUser } from '@/modules/redux/user';
import { uuid, getPayloadFromToken } from '@/modules/utils';

export default function ArtistsPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const auth = useAuth();

  const database = useDatabase();

  const { data: signInCheckResult } = useSigninCheck();

  const { data: user } = useUser();

  const { seller: { initiated = false } = {} } = useSelector(getUser);

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => { dispatch(fetchUser(true)) }, [user]);

  React.useEffect(() => {
    if (user && initiated) return navigate('/seller');

    setInitialized(true);
  }, [initiated]);

  const handleSellerInitiated = async ({ uid }) => {
    if (!uid) uid = user.uid;

    await set(databaseRef(database, `users/${uid}/seller/initiated`), true);
  };

  const handleLoginSuccess = async ({ user }) => {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
      await handleSellerInitiated(user);

      navigate('/seller');
    }
  };

  if (!initialized) return;

  return (
    <RootLayout
      onLoginSuccess={handleLoginSuccess}
      onSellerInitiated={handleSellerInitiated}>
      <Home
        onLoginSuccess={handleLoginSuccess}
        onSellerInitiated={handleSellerInitiated} />
    </RootLayout>
  );
}