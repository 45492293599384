import React from 'react';
import { useSelector } from 'react-redux';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import {
    SettingsIcon,
    EyeIcon,
    EyeOffIcon,
    CircleUserIcon,
} from "lucide-react"
import { useDebouncedCallback } from 'use-debounce';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"

import { set, update } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import { getUser } from '@/modules/redux/user';
import { getPayloadFromToken } from '@/modules/utils';

export function ProjectSettingDlg({
    project: [project, setProject] = [{}, () => { }],
}) {

    const {
        uuid,
        downloadable,
        creator = {},
        password,
        editors = {},
    } = project;

    const database = useDatabase();

    const { data: user } = useUser();

    const {
        isUpgraded,
        projects = {},
        editorsLimit,
    } = useSelector(getUser);

    const handleDownloadableChangeDebounce = useDebouncedCallback((downloadable) => {
        set(databaseRef(database, `projects/${uuid}/downloadable`), downloadable);
    }, 500);

    const handleDownloadableChange = (downloadable) => {
        handleDownloadableChangeDebounce.cancel();
        setProject({ ...project, downloadable });
        handleDownloadableChangeDebounce(downloadable);
    };

    const passwordForm = useForm();

    React.useEffect(() => { passwordForm.setValue('password', getPayloadFromToken(password).password) }, [password]);

    const [showPassword, setShowPassword] = React.useState(false);
    const ShowPasswordIcon = showPassword ? EyeOffIcon : EyeIcon;

    const handleShowPasswordClick = (e) => {
        e.preventDefault();

        setShowPassword(!showPassword);
    };

    const [passwordChanged, setPasswordChanged] = React.useState(false);

    const handlePasswordChange = async ({ password }) => {
        await (password ?
            httpsCallable('encrypt')({
                public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
                payload: { password, timestamp: Date.now() },
            }).then(({ data: { token } }) => token) : Promise.resolve(null))
            .then((token) => set(databaseRef(database, `projects/${uuid}/password`), token));

        setPasswordChanged(true);
        setTimeout(() => setPasswordChanged(false), 1000);
    };

    const editorForm = useForm({
        resolver: zodResolver(z.object({
            editor: z
                .string()
                .email("Email is not valid")
                .refine(() => Object.keys(editors).length < editorsLimit, { message: "Cannot add more users." })
                .refine((email) => email != user.email, { message: "Cannot add your own account." })
                .refine((email) => !Object.values(editors).includes(email), { message: "User already has access." })
                .refine(async (email) => {
                    if (Object.keys(editors).length >= editorsLimit || email == user.email || Object.values(editors).includes(email)) return false;

                    const { data: { users } } = await httpsCallable('fetchUsers')({
                        users: [{ email }],
                    });

                    return users?.[0];
                }, { message: "User does not exist." }),
        })),
        mode: 'onSubmit',
    });

    const handleEditorAdd = async ({ editor }) => {
        const { data: { users } } = await httpsCallable('fetchUsers')({
            users: [{ email: editor }],
        });

        if (!users?.[0]) return;

        await Promise.all([
            update(databaseRef(database, `projects/${uuid}/editors`), { [users[0].uid]: users[0].email }),
            update(databaseRef(database, `users/${users[0].uid}/editing`), { [uuid]: uuid }),
        ]);

        editorForm.reset({ editor: '' });
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline" size="icon" className="p-2" >
                    <SettingsIcon className="w-[18px] h-[18px]" />
                </Button>
            </DialogTrigger>
            <DialogContent className="max-w-lg">
                <DialogHeader>
                    <DialogTitle>Settings</DialogTitle>
                    <DialogDescription>
                        Manage your settings here.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col gap-6 py-8">
                    <div className="flex items-center justify-between space-x-2">
                        <Label htmlFor="necessary" className="flex flex-col space-y-1">
                            <span>Downloadable</span>
                            <span className="font-normal leading-snug text-muted-foreground max-w-sm">
                                Allow whoever has access to this project to download files
                            </span>
                        </Label>
                        <Switch id="necessary" checked={downloadable} onCheckedChange={handleDownloadableChange} />
                    </div>
                    {isUpgraded && Object.keys(projects).includes(uuid) &&
                        <div className="flex flex-col items-center space-y-4">
                            <Label htmlFor="password" className="flex flex-col space-y-1 w-full">
                                <span>Password</span>
                                <span className="font-normal leading-snug text-muted-foreground max-w-sm">
                                    Add password protection to project
                                </span>
                            </Label>
                            <Form {...passwordForm}>
                                <form className="flex justify-between w-full space-x-4" onSubmit={passwordForm.handleSubmit(handlePasswordChange)}>
                                    <FormField
                                        control={passwordForm.control}
                                        name="password"
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                id="password"
                                                type={showPassword ? "text" : "password"}
                                                autoComplete="username"
                                                placeholder="Enter password"
                                                {...field} />
                                        )} />
                                    <Button variant="outline" size="icon" className="p-2" onClick={handleShowPasswordClick}>
                                        <ShowPasswordIcon className="w-[18px] h-[18px]" />
                                    </Button>
                                    <Button className="w-28">
                                        {passwordChanged ? 'Saved!' : 'Save'}
                                    </Button>
                                </form>
                            </Form>
                        </div>
                    }
                    <Form {...editorForm}>
                        <form onSubmit={editorForm.handleSubmit(handleEditorAdd)}>
                            <FormField
                                control={editorForm.control}
                                name="editor"
                                defaultValue=""
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex flex-col items-center space-y-4">
                                            <Label htmlFor="password" className="flex flex-col space-y-1 w-full">
                                                <span>Contributors</span>
                                                <span className="font-normal leading-snug text-muted-foreground max-w-sm">
                                                    Give access to other users so they can upload files too
                                                </span>
                                            </Label>
                                            <div className="flex justify-between w-full space-x-4">
                                                <FormControl>
                                                    <Input
                                                        id="editor"
                                                        placeholder="Enter user email"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <Button className="w-28">
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </Form>
                    <div className="space-y-4">
                        <h4 className="text-sm font-medium">People with access</h4>
                        <div className="grid gap-6">
                            <div className="flex items-center justify-between space-x-4">
                                <div className="flex items-center space-x-4">
                                    <Avatar>
                                        <AvatarImage src={creator.photoURL} className="object-cover" />
                                        <AvatarFallback><CircleUserIcon /></AvatarFallback>
                                    </Avatar>
                                    <div>
                                        <p className="text-sm font-medium leading-none">
                                            {creator.displayName}
                                        </p>
                                        <p className="text-sm text-muted-foreground">{creator.email}</p>
                                    </div>
                                </div>
                                <p className="text-sm font-medium leading-none">
                                    Creator
                                </p>
                            </div>
                            {Object.values(editors).map((editor) =>
                                <div key={editor.uid} className="flex items-center justify-between space-x-4">
                                    <div className="flex items-center space-x-4">
                                        <Avatar>
                                            <AvatarImage src={editor.photoURL} className="object-cover" />
                                            <AvatarFallback><CircleUserIcon /></AvatarFallback>
                                        </Avatar>
                                        <div>
                                            <p className="text-sm font-medium leading-none">
                                                {editor.displayName}
                                            </p>
                                            <p className="text-sm text-muted-foreground">{editor.email}</p>
                                        </div>
                                    </div>
                                    <p className="text-sm font-medium leading-none">
                                        Can edit
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </DialogContent>
        </Dialog >
    )
}
