import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import {
  ref as databaseRef,
  get,
} from 'firebase/database';
import {
  ref as storageRef,
  uploadBytes,
  getMetadata,
  deleteObject,
} from 'firebase/storage';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardMedia,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  Menu,
  Modal,
  Popper,
  Slider,
  Switch,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import {
  ArrowBack,
  Close,
  LightModeOutlined,
  Add,
  Animation,
  Upload,
  Tag,
  KeyboardArrowDown,
  NearMeOutlined,
  ImageOutlined,
  KeyboardArrowRight,
  PlayArrow,
  Pause,
  Remove,
  Check,
  VideocamOutlined,
  LabelOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Rnd } from 'react-rnd';
import useMouse from '@react-hook/mouse-position';
import { useDebouncedCallback } from 'use-debounce';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import ShareSpace from 'components/ShareSpace';
import Comment from 'routes/Viewer/components/Comment';
import { set, increment } from 'modules/firebase/database';
import {
  getVersion,
  getVersions,
  setVersion,
} from 'modules/redux/storage';
import {
  addNote,
  updateNote,
  deleteNote,
  getComments,
  getCurrObject,
  getAmbientLight,
  getAnimations,
  getDirectionalLight,
  getEdges,
  getEdgesEnabled,
  getEnvironments,
  getExposure,
  getFreeview,
  getGrid,
  getNormals,
  getNotes,
  getPolycount,
  getShowBackground,
  getTags,
  getTextures,
  getType,
  getUVMap,
  getVertices,
  getWireframe,
  setCurrObject,
  toggleEdges,
  toggleGrid,
  toggleNormals,
  toggleWireframe,
  toggleUVMap,
  lookAt,
  resetThreejs,
  setAnimation,
  toggleViewMode,
} from 'modules/redux/threejs';
import { getUser } from 'modules/redux/user';
import {
  getPayloadFromToken,
  deleteFolder,
  getFolderSize,
  uuid,
} from 'modules/utils';

const Textarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  margin: '0px',
  padding: '6px',
  fontSize: '.8rem',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
  borderWidth: '0px',
  '&:focus': {
    borderWidth: '0px',
    outline: 'none',
  },
  resize: 'none',
}));

export default function DesktopOverlayUI() {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const userData = useSelector(getUser);

  const comments = useSelector(getComments);
  const currObject = useSelector(getCurrObject);
  const ambientLight = useSelector(getAmbientLight);
  const animations = useSelector(getAnimations);
  const directionalLight = useSelector(getDirectionalLight);
  const edges = useSelector(getEdges);
  const environments = useSelector(getEnvironments);
  const exposure = useSelector(getExposure);
  const freeview = useSelector(getFreeview);
  const grid = useSelector(getGrid);
  const normals = useSelector(getNormals);
  const notes = useSelector(getNotes);
  const polycount = useSelector(getPolycount);
  const showBackground = useSelector(getShowBackground);
  const tags = useSelector(getTags);
  const textures = useSelector(getTextures);
  const type = useSelector(getType);
  const uvMap = useSelector(getUVMap);
  const vertices = useSelector(getVertices);
  const wireframe = useSelector(getWireframe);

  const version = useSelector(getVersion);
  const versions = useSelector(getVersions);

  addEventListener("load", function () {
    var viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
  })

  const ref = React.useRef();

  const notesRef = React.useRef({});

  const [initialized, setInitialized] = React.useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    Object.entries(notesRef.current).forEach(([uuid, element]) => {
      if (!element || !notes[uuid]) return;

      // element.style.display = notes[uuid]?.userData.visible ? '' : 'none';
      element.style.opacity = notes[uuid]?.userData.visible ? 1 : .3;
      element.style.display = notes[uuid]?.element?.style?.display;
      element.style.transform = notes[uuid]?.element?.style?.transform;
      element.style.zIndex = 4;

      if (!notes[uuid]?.userData.visible && currObject?.userData.uuid == uuid) dispatch(setCurrObject(null));
    });

    if (!initialized && urlParams.has('review') && notes[urlParams.get('review')]) setInitialized(true);
  }, [notes]);

  React.useEffect(() => { dispatch(lookAt(notes[urlParams.get('review')])) }, [initialized]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);

  const navRef = React.useRef();

  const settingsRef = React.useRef();
  const animationsRef = React.useRef();
  const versionsRef = React.useRef();

  // const [mode, setMode] = React.useState('navigate');
  const [comment, setComment] = React.useState('');
  const commentRef = React.useRef();
  const commentImageInputRef = React.useRef();
  const [commentImage, setCommentImage] = React.useState(null);

  const _mouse = useMouse(ref);
  const [mouse, setMouse] = React.useState(_mouse);

  React.useEffect(() => {
    if (_mouse.x === null || _mouse.y === null) return;

    const x = Math.min(_mouse.x, _mouse.elementWidth);
    const y = Math.min(_mouse.y, _mouse.elementHeight);

    if (x != mouse.x || y != mouse.y) setMouse({ x, y });
  }, [_mouse]);

  const handleAddNote = () => {
    const note = { position: { x: mouse.x, y: mouse.y }, user: user?.id };

    dispatch(addNote(note));
  };

  const [sharing, setSharing] = React.useState(false);

  const [onDragDelta, setOnDragDelta] = React.useState({ x: 0, y: 0 });

  const onNoteHover = useDebouncedCallback(({ element, object }) => {
    dispatch(setCurrObject(object));
    setAnchorEl(element);
  }, 250);

  React.useEffect(() => {
    if (!anchorEl && currObject) {
      // if (commentRef.current) commentRef.current.value = '';
      setTimeout(() => dispatch(setCurrObject(null)), 250);
    } else {
      if (commentRef.current) commentRef.current.value = '';
    }
  }, [anchorEl]);

  React.useEffect(() => {
    if (!currObject && anchorEl) {
      setAnchorEl(null);
    } else if (currObject) {
      setAnchorEl(notesRef.current[currObject.userData.uuid]);
    }
  }, [currObject]);

  const animationRef = React.useRef();

  React.useEffect(() => {
    if (animationRef.current) {
      [...animationRef.current.children].forEach((child) => {
        child.style.transition = 'none';
        child.style.WebkitTransition = 'none';
      });
    }
  }, [animationRef.current]);

  const [autoplay, setAutoplay] = React.useState(false);

  React.useEffect(() => {
    if (!version) return;

    get(databaseRef(database, `objects/${version}/autoplay`)).then((snapshot) => setAutoplay(!!snapshot.val()));
  }, [version]);

  const [navigationPosition, setNavigationPosition] = React.useState(null);
  const [navigationSize, setNavigationSize] = React.useState(null);
  React.useEffect(() => {
    if (ref.current && navRef.current) {
      setNavigationPosition({ x: .5 * ref.current.clientWidth - .5 * navRef.current.clientWidth, y: 30 });
      setNavigationSize({ width: navRef.current.clientWidth, height: navRef.current.clientHeight });
    }
  }, [ref.current, navRef.current]);

  const tagRef = React.useRef();
  const [tagsOpen, setTagsOpen] = React.useState(false);
  const noteTags = _.uniqWith(currObject?.userData.tags || [], _.isEqual);
  const handleAddTag = ({ label, color }) => {
    if (!currObject.userData.uuid || !label) return;

    const tag = tags.find((_tag) => _tag.label == label);
    const noteHasTag = !!noteTags.find((_tag) => _tag.label == label);

    const newTags = noteHasTag ? noteTags.filter((_tag) => _tag.label != label) : noteTags.concat([{ label, color: color || tag?.color || `#${Math.floor(Math.random() * 16777215).toString(16)}` }]);

    dispatch(updateNote({ uuid: currObject.userData.uuid, updates: { tags: newTags } }));
  };

  const MobileDisabledRnd = isMobile ? Box : Rnd;
  const MobileDisabledRndProps = isMobile ? {} : {
    bounds: 'parent',
    style: { zIndex: 5 },
    enableResizing: false,
    disableDragging: !!anchorEl,//={mode != 'navigate'}
    position: navigationPosition ? { x: navigationPosition.x, y: navigationPosition.y } : undefined,
    size: navigationSize ? { width: navigationSize.width, height: navigationSize.height } : undefined,
    onDragStop: (e, d) => setNavigationPosition({ x: d.x, y: d.y }),
  };

  const [redirect, setRedirect] = React.useState(null);
  const [token, setToken] = React.useState(null);

  React.useEffect(() => {
    get(databaseRef(database, `objects/${getPayloadFromToken().object}/redirect`)).then((snapshot) => setRedirect(snapshot.val()));

    if (getPayloadFromToken().project)
      get(databaseRef(database, `projects/${getPayloadFromToken().project}/token`)).then((snapshot) => setToken(snapshot.val()));
  }, []);

  return (
    <>
      {ref.current &&
        <>
          <IconButton
            onClick={() => { window.location = token ? `/project?token=${token}` : user ? '/home?=website' : '/' }}
            sx={{
              top: 24,
              left: 24,
              position: 'absolute',
              color: 'primary.main',
              backgroundColor: 'secondary.dark',
              '&:hover': {
                backgroundColor: 'secondary.dark',
                opacity: .6,
                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
              },
              zIndex: 5,
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
            }}>
            <ArrowBack fontSize='medium' />
          </IconButton>
          {/*
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                backgroundColor: 'secondary.dark',
                display: 'flex',
                zIndex: 5,
                justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: 'secondary.dark',
              }}>
              <IconButton
                onClick={async () => {
                  if (getPayloadFromToken().project) {
                    const token = await get(databaseRef(database, `projects/${getPayloadFromToken().project}/token`)).then((snapshot) => snapshot.val());

                    window.location = token ? `/project?token=${token}` : '/';
                  } else {
                    window.location = '/';
                  }
                }}
                sx={{
                  // top: isMobile ? 70 : 24,
                  // left: 24,
                  // position: 'absolute',
                  color: 'primary.main',
                  backgroundColor: 'secondary.dark',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    opacity: .6,
                  boxShadow: 'none',
                  },
                  zIndex: 5,
                }}>
                <ArrowBack fontSize='medium' />
              </IconButton>
              <Button
                variant='contained'
                disabled={mode == 'viewer'}
                onClick={() => setMode('viewer')}
                sx={{
                  backgroundColor: 'secondary.dark',
                  color: 'primary.main',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    color: 'info.main',
                    backgroundColor: 'secondary.dark',
                  },
                  margin: '5px',
                  borderRadius: '10px',
                  textTransform: 'none',
                }}>
                Viewer
              </Button>
              <Button
                variant='contained'
                disabled={mode == 'comments'}
                onClick={() => setMode('comments')}
                sx={{
                  backgroundColor: 'secondary.dark',
                  color: 'primary.main',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    color: 'info.main',
                    backgroundColor: 'secondary.dark',
                  },
                  margin: '5px',
                  borderRadius: '10px',
                  textTransform: 'none',
                }}>
                Comments
              </Button>
              <Button
                variant='contained'
                disabled={mode == 'materials'}
                onClick={() => setMode('materials')}
                sx={{
                  backgroundColor: 'secondary.dark',
                  color: 'primary.main',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    color: 'info.main',
                    backgroundColor: 'secondary.dark',
                  },
                  margin: '5px',
                  borderRadius: '10px',
                  textTransform: 'none',
                }}>
                Materials
              </Button>
            </Box>
              */}
          <MobileDisabledRnd
            {...MobileDisabledRndProps}
          >
            <Box
              ref={navRef}
              sx={{
                position: 'absolute',
                backgroundColor: 'secondary.background',
                display: 'flex',
                zIndex: 5,
                borderRadius: '10px',
                padding: '5px',
                ...(isMobile ? {
                  bottom: 0,
                  // alignItems: 'center',
                  transform: `translate(-50%, 0%) translate(${.5 * ref.current?.clientWidth}px, -${.1 * ref.current?.clientHeight}px)`
                  // transform: `translate(-50%, -50%) translate(-${.5 * ref.current?.clientWidth}px,)`// -${.1 * ref.current?.clientHeight}px)`,
                } :
                  !navigationPosition ? {
                    // marginTop: '30px',
                    transform: `translate(-50%, -50%) translate(${.5 * ref.current?.clientWidth}px, ${.1 * ref.current?.clientHeight}px)`,
                  } : {})
              }}>
              {versions.length > 1 &&
                <Button
                  ref={versionsRef}
                  variant='contained'
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                      opacity: .6,
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                    },
                    minWidth: 0,
                    margin: '5px',
                    // paddingLeft: '10px',
                    // paddingRight: '0px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  }}>
                  {(() => {
                    const chars = (versions.indexOf(version) + 1).toString().split('');

                    return `v.${[chars[chars.length - 3] || 0, chars[chars.length - 2] || 0, chars[chars.length - 1]].join('.')}`;
                  })()}
                </Button>
              }
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl && anchorEl == versionsRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    backgroundColor: 'secondary.main',
                  },
                }}
                MenuListProps={{
                  sx: {
                    padding: '0px',
                  }
                }}>
                <ButtonGroup
                  orientation='vertical'
                  sx={{
                    // position: 'fixed',
                    // transform: `translateX(${(animationsRef.current?.offsetLeft || 0) - 10}px)`,
                    backgroundColor: 'secondary.dark',
                    // margin: '5px',
                    borderRadius: '10px',
                    zIndex: 1,
                  }}>
                  {versions.map((_version) => (
                    <Button
                      key={_version}
                      variant='contained'
                      disabled={_version == version}
                      onClick={(e) => {
                        e.stopPropagation();

                        dispatch(resetThreejs());
                        dispatch(setVersion(_version));
                        setOpen(null);
                      }}
                      sx={{
                        color: 'primary.main',
                        backgroundColor: 'secondary.main',
                        '&:hover': {
                          backgroundColor: 'secondary.main',
                          opacity: .6,
                          boxShadow: 'none',
                        },
                        '&:disabled': {
                          backgroundColor: 'info.main',
                          color: 'secondary.main',
                        },
                        padding: '6px 15px',
                        textTransform: 'none',
                        // borderRadius: '10px',
                        borderWidth: '0px',
                        fontWeight: 600,
                        boxShadow: 'none',
                      }}>
                      <Typography
                        variant='body2'
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }}>
                        {(() => {
                          const chars = (versions.indexOf(_version) + 1).toString().split('');

                          return `v.${[chars[chars.length - 3] || 0, chars[chars.length - 2] || 0, chars[chars.length - 1]].join('.')}`;
                        })()}
                      </Typography>
                    </Button>
                  ))}
                </ButtonGroup>
              </Menu>
              {animations.clips.length > 0 &&
                <Button
                  ref={animationsRef}
                  variant='contained'
                  startIcon={<Animation fontSize='medium' />}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                      opacity: .6,
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                    },
                    minWidth: 0,
                    margin: '5px',
                    paddingLeft: '10px',
                    paddingRight: '0px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  }}>
                </Button>
              }
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl && anchorEl == animationsRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    backgroundColor: 'secondary.main',
                  },
                }}
                MenuListProps={{
                  sx: {
                    padding: '0px',
                  }
                }}>
                <ButtonGroup
                  orientation='vertical'
                  sx={{
                    // position: 'fixed',
                    // transform: `translateX(${(animationsRef.current?.offsetLeft || 0) - 10}px)`,
                    backgroundColor: 'secondary.dark',
                    // margin: '5px',
                    borderRadius: '10px',
                    zIndex: 1,
                    fontWeight: 600,
                    boxShadow: 'none',
                  }}>
                  <Button
                    variant='contained'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setAnimation({ animation: null }));
                      setOpen(null);
                    }}
                    sx={{
                      color: 'primary.main',
                      backgroundColor: 'secondary.main',
                      '&:hover': {
                        backgroundColor: 'secondary.main',
                        opacity: .6,
                        boxShadow: 'none',
                      },
                      padding: '6px 15px',
                      textTransform: 'none',
                      // borderRadius: '10px',
                      borderWidth: '0px',
                      fontWeight: 600,
                      boxShadow: 'none',
                    }}>
                    <Typography
                      variant='body2'
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}>
                      No animation
                    </Typography>
                  </Button>
                  {animations.clips.map((animation) => (
                    <Button
                      key={animation.uuid}
                      variant='contained'
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setAnimation({ animation }));
                        setOpen(null);
                      }}
                      sx={{
                        color: animation.enabled ? 'secondary.main' : 'primary.main',
                        backgroundColor: animation.enabled ? 'info.main' : 'secondary.main',
                        '&:hover': {
                          backgroundColor: animation.enabled ? 'info.main' : 'secondary.main',
                          opacity: .6,
                          boxShadow: 'none',
                        },
                        padding: '6px 15px',
                        textTransform: 'none',
                        // borderRadius: '10px',
                        borderWidth: '0px',
                        fontWeight: 600,
                        boxShadow: 'none',
                      }}>
                      <Typography
                        variant='body2'
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }}>
                        {animation.name}
                      </Typography>
                    </Button>
                  ))}
                </ButtonGroup>
              </Menu>
              <Button
                ref={settingsRef}
                variant='contained'
                startIcon={<Tag fontSize='medium' />}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    opacity: .6,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  },
                  minWidth: 0,
                  margin: '5px',
                  paddingLeft: '10px',
                  paddingRight: '0px',
                  borderRadius: '10px',
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                }}>
              </Button>
              {/* <Box
                  sx={{
                    position: 'fixed',
                    transform: `translate(${(settingsRef.current?.offsetLeft || 0) - 10}px, 35px)`,
                    // backgroundColor: 'secondary.dark',
                    margin: '5px',
                    // padding: '10px',
                    // borderRadius: '10x',
                    zIndex: 1,
                  }}> */}
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl && anchorEl == settingsRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    backgroundColor: 'secondary.main',
                  },
                }}
                MenuListProps={{
                  sx: {
                    padding: '0px',
                  }
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'secondary.dark',
                    color: 'primary.main',
                    padding: '8px',
                    borderRadius: '10px 10px 0px 0px',
                    borderWidth: '0px',
                  }}>
                  <Typography variant='body2'>Wireframe</Typography>
                  <Switch
                    checked={wireframe}
                    sx={{
                      transform: 'translateX(5px)',
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'info.main',
                      },
                    }}
                    onChange={() => dispatch(toggleWireframe())}
                  />
                </Box>
                {typeof uvMap == 'boolean' &&
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: 'secondary.dark',
                      color: 'primary.main',
                      padding: '8px',
                      borderRadius: '0px',
                      borderWidth: '0px',
                    }}>
                    <Typography variant='body2'>UV Map</Typography>
                    <Switch
                      checked={uvMap}
                      sx={{
                        transform: 'translateX(5px)',
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: 'info.main',
                        },
                      }}
                      onChange={() => dispatch(toggleUVMap())}
                    />
                  </Box>
                }
                {/* <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'secondary.dark',
                    color: 'primary.main',
                    padding: '8px',
                    borderRadius: '0px',
                    borderWidth: '0px',
                  }}>
                  <Typography variant='body2'>Edges</Typography>
                  <Switch
                    checked={edges}
                    sx={{
                      transform: 'translateX(5px)',
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'info.main',
                      },
                    }}
                    onChange={() => {
                      if (normals) dispatch(toggleNormals());
                      dispatch(toggleEdges());
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'secondary.dark',
                    color: 'primary.main',
                    padding: '8px',
                    borderRadius: '10px 10px 0px 0px',
                    borderWidth: '0px',
                  }}>
                  <Typography variant='body2'>Normals</Typography>
                  <Switch
                    checked={normals}
                    sx={{
                      transform: 'translateX(5px)',
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'info.main',
                      },
                    }}
                    onChange={() => {
                      if (edges) dispatch(toggleEdges());
                      dispatch(toggleNormals());
                    }}
                  />
                </Box> */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'secondary.dark',
                    color: 'primary.main',
                    padding: '8px',
                    borderRadius: '0px 0px 10px 10px',
                    borderWidth: '0px',
                  }}>
                  <Typography variant='body2'>Grid</Typography>
                  <Switch
                    checked={grid}
                    sx={{
                      transform: 'translateX(5px)',
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'info.main',
                      },
                    }}
                    onChange={() => dispatch(toggleGrid())}
                  />
                </Box>
              </Menu>
              {/* </Box> */}
              {!getPayloadFromToken().website && <>
                <Button
                  variant='contained'
                  onClick={() => handleAddNote()}
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                      opacity: .6,
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                    },
                    margin: '5px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  }}>
                  Comment
                </Button>
                <Button
                  variant='contained'
                  onClick={() => setSharing(true)}
                  sx={{
                    backgroundColor: 'info.main',
                    color: 'secondary.main',
                    '&:hover': {
                      backgroundColor: 'info.main',
                      opacity: .6,
                      boxShadow: 'none',
                    },
                    margin: '5px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: 'none',
                  }}>
                  Share
                </Button>
                <ShareSpace
                  link={`${window.location.host}/${redirect}`}
                  embed={['pro', 'premium', 'enterprise'].includes(userData?.plan) ? redirect : undefined}
                  open={sharing}
                  autoplay={autoplay}
                  onAutoplayChange={(bool) => {
                    set(databaseRef(database, `objects/${getPayloadFromToken().object}/autoplay`), bool);
                    setAutoplay(bool);
                  }}
                  onClose={() => setSharing(false)}
                />
              </>}
            </Box>
          </MobileDisabledRnd>
          {animations.clips.length > 0 && animations.isPlaying &&
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: 'secondary.dark',
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                zIndex: 5,
                padding: '5px',
                borderRadius: '10px',
                ...(isMobile ? {
                  width: navRef.current?.clientWidth - 10,
                  bottom: 70,
                  // alignItems: 'center',
                  transform: `translate(-50%, 0%) translate(${.5 * ref.current?.clientWidth}px, -${.1 * ref.current?.clientHeight}px)`
                  // transform: `translate(-50%, -50%) translate(-${.5 * ref.current?.clientWidth}px,)`// -${.1 * ref.current?.clientHeight}px)`,
                } : {
                  width: 300,
                  marginBottom: '30px',
                  transform: `translate(-50%, -50%) translate(${.5 * ref.current?.clientWidth}px, ${.9 * ref.current?.clientHeight}px)`,
                })
              }}>
              <IconButton
                onClick={() => dispatch(setAnimation({ pause: !animations.isPaused }))}
                sx={{
                  // marginTop: '-8px',
                  // marginRight: '-8px',
                  backgroundColor: 'secondary.dark',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                  },
                  borderRadius: '2px',
                }}>
                {animations.isPaused ? <PlayArrow fontSize='small' /> : <Pause fontSize='small' />}
              </IconButton>
              <Typography variant='body2'>0</Typography>
              <Slider
                ref={animationRef}
                value={100 * animations.time / animations.duration}
                onChange={(e, value) => dispatch(setAnimation({ time: value * animations.duration / 100 }))}
                sx={{ margin: '0px 15px' }}
              />
              <Typography variant='body2'>{Math.round(animations.duration * 100) / 100}</Typography>
            </Box>}
        </>
      }
      <Box
        ref={ref}
        // onClick={(e) => {
        //   if (mode == 'comment' && e.currentTarget == e.target) {
        //     if (!currObject) {
        //       handleAddNote();
        //     } else {
        //       dispatch(deselectObject());
        //     }
        //   }
        // }}
        sx={{
          height: '100%',
          width: '100%',
          gridRowStart: 1,
          gridColumnStart: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'transparent',
          // zIndex: mode == 'comment' ? 4 : '',
        }}>

      </Box>
      {Object.entries(notes).map(([uuid, note], i) => (
        <Rnd
          key={uuid}
          style={{ zIndex: 4 }}
          enableResizing={false}
          disableDragging={!note.userData.visible}//{false}//={mode != 'navigate'}
          position={{ x: 0, y: 0 }}
          onDrag={(e, d) => setOnDragDelta({ x: d.x, y: d.y })}
          onDragStop={(e, d) => {
            const x = Number(notesRef.current[uuid].style.transform.split('translate(')[2].split('px,')[0]);
            const y = Number(notesRef.current[uuid].style.transform.split('translate(')[2].split('px')[1].split(',')[1]);

            // if (mode == 'navigate') {
            const updates = { position: { x: x + d.x, y: y + d.y } };

            dispatch(updateNote({ uuid, updates }));
            // }

            setOnDragDelta({ x: 0, y: 0 });
          }}
        >
          <Avatar
            key={uuid}
            ref={(el) => (notesRef.current[uuid] = el)}
            {...(isMobile ? {
              onTouchStart: (e) => onNoteHover({ object: note, element: e.currentTarget }),
              onTouchEnd: onNoteHover.cancel,
            } : {
              onMouseEnter: (e) => onNoteHover({ object: note, element: e.currentTarget }),
              onMouseLeave: onNoteHover.cancel,
            })}
            sx={{
              backgroundColor: 'info.main',
              color: 'secondary.main',
              '&:hover': {
                backgroundColor: 'info.main',
              },
              transform: `translate(${onDragDelta.x}px, ${onDragDelta.y}px)`,
            }}>
            {i + 1}
          </Avatar>
        </Rnd>
      ))
      }
      <Popper
        anchorEl={anchorEl}
        placement={isMobile ? 'bottom' : 'bottom-start'}
        // anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        // transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!anchorEl && !!currObject && notesRef.current[currObject.userData.uuid] == anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setTagsOpen(false);
        }}
        sx={{ overflow: 'hidden', zIndex: 6 }}
        popperOptions={{
          placement: isMobile ? 'bottom' : 'bottom-start',
          strategy: 'absolute',
          // modifiers: [
          //   {
          //     name: 'offset',
          //     options: {
          //       offset: [10, 10],
          //     },
          //   },
          // ],
        }}
      >
        <Box
          sx={{
            width: 300,
            maxWidth: 300,
            maxHeight: Math.min(anchorEl?.style.transform ? ref.current?.clientHeight - Number(anchorEl?.style.transform.split('translate(')[2].split('px')[1].split(',')[1]) - (onDragDelta.y || 0) - 104 : 300, 300),
            backgroundColor: 'secondary.background',
            padding: '10px',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);',
            borderRadius: '4px 4px 0px 0px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Box sx={{ marginTop: '-4px', marginBottom: currObject?.userData.comments?.length > 0 ? '6px' : '0px' }}>
              <Button
                variant='contained'
                startIcon={<Check sx={{ fontSize: '15px !important;', paddingLeft: '5px' }} />}
                onClick={async () => {
                  dispatch(updateNote({ uuid: currObject.userData.uuid, updates: { completed: !currObject?.userData.completed } }));
                }}
                sx={{
                  backgroundColor: currObject?.userData.completed ? 'info.main' : 'secondary.main',
                  color: currObject?.userData.completed ? 'secondary.main' : 'primary.main',
                  '&:hover': {
                    backgroundColor: currObject?.userData.completed ? 'info.main' : 'secondary.main',
                    opacity: .6,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  },
                  width: 102,
                  minWidth: 0,
                  padding: '3px 5px',
                  borderRadius: '10px',
                  textTransform: 'none',
                  fontSize: '.6rem',
                  margin: '3px',
                  fontWeight: 600,
                  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                }}>
                {currObject?.userData.completed ? 'Completed' : 'Mark Complete'}
              </Button>
              <Button
                variant='contained'
                startIcon={<LabelOutlined sx={{ fontSize: '10px !important;' }} />}
                onClick={() => setTagsOpen(true)}
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    opacity: .6,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  },
                  minWidth: 0,
                  margin: '3px',
                  padding: '3px 8px',
                  borderRadius: '10px',
                  textTransform: 'none',
                  fontSize: '.6rem',
                  fontWeight: 600,
                  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                }}>
                Add Tag
              </Button>
              {noteTags.map((tag) =>
                <Button
                  key={tag.label}
                  variant='contained'
                  startIcon={<Avatar sx={{ width: 8, height: 8, backgroundColor: tag.color }}>{' '}</Avatar>}
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                    },
                    minWidth: 0,
                    margin: '3px',
                    padding: '3px 8px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontSize: '.6rem',
                    fontWeight: 600,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
                  }}>
                  {tag.label}
                </Button>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginTop: '-8px',
                marginRight: '-8px',
              }}>
              <IconButton
                onClick={() => dispatch(setCurrObject(null))}
                sx={{
                  maxHeight: 28,
                  backgroundColor: 'secondary.background',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.background',
                  },
                  borderRadius: '2px',
                }}>
                <Remove sx={{ fontSize: '12px' }} />
              </IconButton>
              <IconButton
                onClick={async () => {
                  if (user?.uid) {
                    const size = await getFolderSize(storageRef(storage, `comments/${version}/${currObject.userData.uuid}`))
                    await set(databaseRef(database, `users/${user?.uid}/fileSpaceUsed`), increment(-size));
                  }

                  await deleteFolder(storageRef(storage, `comments/${version}/${currObject.userData.uuid}`));

                  dispatch(deleteNote(currObject.userData.uuid));
                }}
                sx={{
                  maxHeight: 28,
                  backgroundColor: 'secondary.background',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.background',
                  },
                  borderRadius: '2px',
                }}>
                <Close sx={{ fontSize: '12px' }} />
              </IconButton>
            </Box>
          </Box>
          {currObject?.userData.comments.map((_comment, i) => (
            <Comment
              key={i}
              comment={_comment}
              uuid={currObject.userData.uuid}
              onImageClick={_comment.image ? (url) => setCommentImage(url) : null}
              onDelete={async () => {
                if (_comment.image) {
                  if (user?.uid) {
                    const size = await getFolderSize(storageRef(storage, `comments/${version}/${currObject.userData.uuid}/${_comment.image}`));
                    await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-size));
                  }

                  await deleteFolder(storageRef(storage, `comments/${version}/${currObject.userData.uuid}/${_comment.image}`));
                }

                const updates = {
                  comments: [...currObject.userData.comments.slice(0, i), ...currObject.userData.comments.slice(i + 1)],
                };

                dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            backgroundColor: 'secondary.background',
            padding: '10px',
            borderRadius: '0px 0px 4px 4px',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              padding: '8px',
              backgroundColor: 'secondary.main',
              borderRadius: '2px',
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
            }}>
            {/* <Input
              autoFocus
              disableUnderline
              fullWidth
              placeholder='Leave a comment'
              value={comment}
              size='medium'
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.main',
                margin: '0px',
                padding: '0px 0px 0px 4px',
                fontSize: '.8rem',
                '&:focused': {
                  borderWidth: 0,
                },
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.key != 'Enter' || !comment) return;

                const updates = {
                  comments: [...currObject.userData.comments, { id, text: comment, timestamp: Date.now() }],
                };

                dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));

                setComment('');
              }}
            /> */}
            <Textarea
              ref={commentRef}
              placeholder='Leave a comment'
              onKeyDown={(e) => {
                if (e.key != 'Enter') return;

                const note = {
                  text: commentRef.current.value,
                  timestamp: Date.now(),
                  user: user?.uid || null,
                };

                const updates = {
                  comments: [...currObject.userData.comments, note],
                };

                dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));

                if (commentRef.current) commentRef.current.value = '';
              }}
            />
            <IconButton
              onClick={() => {
                if (!commentImageInputRef.current) return;

                commentImageInputRef.current.click();
              }}
              sx={{
                backgroundColor: 'info.main',
                color: 'secondary.main',
                '&:hover': {
                  backgroundColor: 'info.main',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                borderRadius: '2px',
              }}>
              <ImageOutlined fontSize='small' />
              <input
                ref={commentImageInputRef}
                accept='image/*'
                type='file'
                hidden
                onChange={async () => {
                  if (!commentImageInputRef.current.files || commentImageInputRef.current.files.length < 1) return;

                  const file = commentImageInputRef.current.files[0];

                  const _uuid = uuid();

                  const fileRef = storageRef(storage, `comments/${version}/${currObject.userData.uuid}/${_uuid}/${file.name == 'Default' ? 'Default (1)' : file.name}`);

                  const _file = await uploadBytes(fileRef, file);

                  if (user?.uid) {
                    const metadata = await getMetadata(_file.ref);
                    await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(metadata.size));
                  }

                  const note = { image: _uuid, timestamp: Date.now(), user: user?.uid || null };

                  const updates = {
                    comments: [...currObject.userData.comments, note],
                  };

                  dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));

                  if (commentRef.current) commentRef.current.value = '';
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                if (!commentRef.current.value) return;

                const updates = {
                  comments: [...currObject.userData.comments, { text: commentRef.current.value, timestamp: Date.now(), user: user?.uid || null }],
                };

                dispatch(updateNote({ uuid: currObject.userData.uuid, updates }));

                if (commentRef.current) commentRef.current.value = '';
              }}
              sx={{
                backgroundColor: 'info.main',
                color: 'secondary.main',
                '&:hover': {
                  backgroundColor: 'info.main',
                  opacity: .6,
                  boxShadow: 'none',
                },
                padding: '4px',
                marginLeft: '8px',
                borderRadius: '2px',
              }}>
              <KeyboardArrowRight fontSize='small' />
            </IconButton>
          </Box>
        </Box>
      </Popper>
      {tagsOpen &&
        <ClickAwayListener onClickAway={() => setTagsOpen(false)}>
          <Box
            sx={{
              position: 'fixed',
              backgroundColor: 'secondary.dark',
              color: 'primary.main',
              width: 150,
              transform: anchorEl?.style.transform.split('translate(-50%, -50%) ')[1],
              left: 101 + onDragDelta.x,
              top: 29 + onDragDelta.y,
              borderRadius: '4px',
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
              zIndex: 7,
              overflowY: 'scroll',
              overflowX: 'hidden',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}>
            <FormGroup
              sx={{
                padding: '6px',
                overflowY: 'scroll',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Input
                  disableUnderline
                  placeholder='Create tag'
                  // value={tag}
                  size='small'
                  sx={{
                    height: 20,
                    backgroundColor: 'transparent',
                    color: 'primary.main',
                    // margin: '0px 10px',
                    // paddingLeft: '10px',
                    fontSize: '0.6rem',
                    fontWeight: 600,
                    // lineHeight: 1.6,
                    // letterSpacing: '0.0075rem',
                    '&:focused': {
                      borderWidth: 0,
                    },
                  }}
                  inputProps={{
                    ref: tagRef,
                    sx: {
                      height: 12,
                      // textAlign: 'center',
                      backgroundColor: 'secondary.main',
                      padding: '4px',
                      borderRadius: '4px 0px 0px 4px',
                    },
                  }}
                  onKeyDown={(e) => {
                    if (e.key != 'Enter') return;

                    if (!tagRef.current.value) return;
                    if (tags.find((tag) => tag.label == tagRef.current.value)) return tagRef.current.value = '';

                    handleAddTag({ label: tagRef.current.value });
                    tagRef.current.value = '';
                  }}
                />
                <IconButton
                  onClick={() => {
                    if (!tagRef.current.value) return;
                    if (tags.find((tag) => tag.label == tagRef.current.value)) return tagRef.current.value = '';

                    handleAddTag({ label: tagRef.current.value });
                    tagRef.current.value = '';
                  }}
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: 'info.main',
                    color: 'secondary.main',
                    '&:hover': {
                      backgroundColor: 'info.main',
                      opacity: .6,
                      boxShadow: 'none',
                    },
                    // padding: '4px',
                    borderRadius: '0px 4px 4px 0px',
                  }}>
                  <KeyboardArrowRight fontSize='small' />
                </IconButton>
              </Box>
              <FormControlLabel
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ width: 8, height: 8, backgroundColor: 'green' }}>{' '}</Avatar>
                    <Typography sx={{ fontSize: '.6rem', fontWeight: 600, marginLeft: '4px' }}>Model</Typography>
                  </Box>
                }
                control={<Checkbox size='small' />}
                checked={!!noteTags.find((tag) => tag.label == 'Model')}
                onChange={() => handleAddTag({ label: 'Model', color: 'green' })}
                sx={{
                  marginLeft: '0px',
                  '.MuiButtonBase-root': {
                    padding: '0px',
                  },
                }} />
              <FormControlLabel
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ width: 8, height: 8, backgroundColor: 'purple' }}>{' '}</Avatar>
                    <Typography sx={{ fontSize: '.6rem', fontWeight: 600, marginLeft: '4px' }}>Texture</Typography>
                  </Box>
                }
                control={<Checkbox size='small' />}
                checked={!!noteTags.find((tag) => tag.label == 'Texture')}
                onChange={() => handleAddTag({ label: 'Texture', color: 'purple' })}
                sx={{
                  marginLeft: '0px',
                  '.MuiButtonBase-root': {
                    padding: '0px',
                  },
                }} />
              <FormControlLabel
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ width: 8, height: 8, backgroundColor: 'blue' }}>{' '}</Avatar>
                    <Typography sx={{ fontSize: '.6rem', fontWeight: 600, marginLeft: '4px' }}>Animation</Typography>
                  </Box>
                }
                control={<Checkbox size='small' />}
                checked={!!noteTags.find((tag) => tag.label == 'Animation')}
                onChange={() => handleAddTag({ label: 'Animation', color: 'blue' })}
                sx={{
                  marginLeft: '0px',
                  '.MuiButtonBase-root': {
                    padding: '0px',
                  },
                }} />
              {tags.filter((tag) => !['Model', 'Texture', 'Animation'].includes(tag.label)).map((tag) =>
                <FormControlLabel
                  key={tag.label}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ width: 8, height: 8, backgroundColor: tag.color }}>{' '}</Avatar>
                      <Typography sx={{ fontSize: '.6rem', fontWeight: 600, marginLeft: '4px' }}>{tag.label}</Typography>
                    </Box>
                  }
                  control={<Checkbox size='small' />}
                  checked={!!noteTags.find((_tag) => _tag.label == tag.label)}
                  onChange={() => handleAddTag({ label: tag.label })}
                  sx={{
                    marginLeft: '0px',
                    '.MuiButtonBase-root': {
                      padding: '0px',
                    },
                  }} />
              )}
            </FormGroup>
          </Box>
        </ClickAwayListener>
      }
      {commentImage &&
        <Modal open>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Card>
              <CardActionArea onClick={() => setCommentImage(null)}>
                <CardMedia
                  image={commentImage}
                  sx={{ width: '75vw', height: '75vh', objectFit: 'contain' }}
                />
              </CardActionArea>
            </Card>
          </Box>
        </Modal>
      }
      <Box
        sx={{
          position: 'absolute',
          left: 10,
          bottom: 10,
          color: 'primary.dark',
        }}>
        <Typography variant='body2'>{`Polycount: ${polycount}`}</Typography>
        <Typography variant='body2'>{`Vertices: ${vertices}`}</Typography>
      </Box>
      {!isMobile && !getPayloadFromToken().website &&
        <Box
          sx={{
            position: 'absolute',
            right: 270,
            bottom: 0,
          }}>
          <Button
            variant='contained'
            onClick={(e) => dispatch(toggleViewMode())}
            sx={{
              backgroundColor: 'secondary.main',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'secondary.main',
                opacity: .6,
                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
              },
              minWidth: 0,
              margin: '15px',
              borderRadius: '10px',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.04), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
              zIndex: 5,
            }}>
            {freeview ? 'Orbit Camera' : 'Free Camera'}
          </Button>
        </Box>
      }
    </>
  );
}