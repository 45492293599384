
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, onValue, ref as databaseRef, get } from 'firebase/database';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getStorage, ref as storageRef, listAll, getDownloadURL } from 'firebase/storage';
import store from 'modules/redux/store';

import { httpsCallable } from 'modules/firebase/functions';

const initialState = {
  user: {},
  unsubscribe: [],
};

const updateUid = createAction('user/updateUid');
const updateUser = createAction('user/updateUser');

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (init = false) => {
    if (!getAuth().currentUser) {
      for (const unsubscribe of store.getState().user.unsubscribe) {
        unsubscribe();
      }

      return { unsubscribe: [] };
    };

    const userRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}`);

    const user = await get(userRef).then((snapshot) => snapshot.val() || {}).catch(() => null);

    const profileRef = await listAll(storageRef(getStorage(), `websites/${getAuth().currentUser.uid}/profile`));
    const profile = profileRef.items[0] ? await getDownloadURL(profileRef.items[0]).catch(() => null) : null;

    let unsubscribe;
    if (init) {
      unsubscribe = [];

      const fileSpaceUsedRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/fileSpaceUsed`);
      const fileSpaceUsedUnsubscribe = onValue(fileSpaceUsedRef, (snapshot) => { store.dispatch(updateUser({ fileSpaceUsed: snapshot.val() || 0 })) });
      unsubscribe.push(fileSpaceUsedUnsubscribe);

      const foldersRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/folders`);
      const foldersUnsubscribe = onValue(foldersRef, (snapshot) => { store.dispatch(updateUser({ folders: snapshot.val() || {} })) });
      unsubscribe.push(foldersUnsubscribe);

      const notificationsRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/notifications`);
      const notificationsUnsubscribe = onValue(notificationsRef, (snapshot) => { store.dispatch(updateUser({ notifications: snapshot.val() || {} })) });
      unsubscribe.push(notificationsUnsubscribe);

      const planRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/plan`);
      const planUnsubscribe = onValue(planRef, (snapshot) => { store.dispatch(updateUser({ plan: snapshot.val() || 'core' })) });
      unsubscribe.push(planUnsubscribe);

      const projectsRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/projects`);
      const projectsUnsubscribe = onValue(projectsRef, (snapshot) => { store.dispatch(updateUser({ projects: snapshot.val() || {} })) });
      unsubscribe.push(projectsUnsubscribe);

      const sellerRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/seller`);
      const sellerUnsubscribe = onValue(sellerRef, async (snapshot) => {
        const { data: seller = {} } = await httpsCallable('fetchAccount')({
          uid: getAuth().currentUser.uid,
        });

        if (seller.stripe) {
          seller.paymentsEnabled = !seller.stripe.requirements.disabled_reason;
          if (seller.paymentsEnabled) seller.provider = 'stripe';
        }

        if (!seller.paymentsEnabled && seller.paypal) {
          seller.paymentsEnabled = seller.paypal.payments_receivable && seller.paypal.primary_email_confirmed &&
            !!seller.paypal.oauth_integrations?.[0]?.oauth_third_party?.[0] &&
            !!seller.paypal.products?.find((p) => p.vetting_status == 'SUBSCRIBED' && p.capabilities.includes('CUSTOM_CARD_PROCESSING'));
          if (seller.paymentsEnabled) seller.provider = 'paypal';
        }

        if (!seller.paymentsEnabled) seller.paymentsEnabled = false;

        store.dispatch(updateUser({ seller: { ...(snapshot.val() || {}), ...seller } }))
      });
      unsubscribe.push(sellerUnsubscribe);

      const buyerRef = databaseRef(getDatabase(), `users/${getAuth().currentUser.uid}/buyer`);
      const buyerUnsubscribe = onValue(buyerRef, async (snapshot) => {
        const buyer = snapshot.val() || {};
        const { cart = {}, purchases = {} } = buyer;

        const objects = await Promise.all(Object.entries(cart).concat(Object.entries(purchases)).map(([uuid, value]) => new Promise(async (resolve) => {
          try {

            const object = await getDoc(doc(getFirestore(), 'objects', uuid)).then((doc) => ({ ...doc.data(), uuid }))
            // const object = await get(databaseRef(getDatabase(), `objects/${uuid}`)).then((snapshot) => snapshot.val());
            if (!object) return resolve(null);

            const {
              title,
              price,
              user,
              name,
              description,
              fileType,
              paymentProvider,
            } = object;

            const purchasedOn = typeof value == 'object' ? value.purchasedOn : undefined;

            let ref = await listAll(storageRef(getStorage(), `objects/${uuid}`));

            if (!ref.items[0]) return resolve(null);

            let type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 1].toLowerCase();
            let zipped = false;
            if (type == 'zip') {
              type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 2].toLowerCase();
              zipped = true;
            }

            if (ref.prefixes[0]) ref = await listAll(ref.prefixes[0]);

            const download = await getDownloadURL(ref.items[0]);

            let thumbnail;
            ref = await listAll(storageRef(getStorage(), `thumbnails/${uuid}`));
            if (ref.prefixes.find((i) => i.name == 'custom')) {
              thumbnail = await listAll(ref.prefixes.find((i) => i.name == 'custom')).then((ref) => getDownloadURL(ref.items[0]));
            }
            else {
              if (ref.items[0]) thumbnail = await getDownloadURL(ref.items[0]);
            }

            resolve({
              uuid,
              thumbnail,
              type: fileType,
              name,
              zipped,
              title,
              price,
              user,
              download,
              description,
              purchasedOn,
              paymentProvider,
            });
          } catch (e) {
            resolve(null);
          };
        })))
          .then((o) => o.filter((o) => o));

        buyer.cart = Object.fromEntries(Object.keys(cart).map((uuid) => [uuid, objects.find((o) => o.uuid == uuid)])
          .filter(([, v]) => v));
        buyer.purchases = Object.fromEntries(Object.keys(purchases).map((uuid) => [uuid, objects.find((o) => o.uuid == uuid)])
          .filter(([, v]) => v));

        store.dispatch(updateUser({ buyer }));
      });
      unsubscribe.push(buyerUnsubscribe);
    }

    return { ...user, seller, profile, unsubscribe };
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      if (!action.payload) return;

      state.user = { ...state.user, ...action.payload };
      state.user.fileSpaceLimit = state.user.plan == 'premium' ? 429496729600 : 26843545600;//state.user.plan == 'premium' ? Infinity : state.user.plan == 'pro' ? 1610612736000 : 161061273600;
      state.user.projectsLimit = state.user.plan == 'premium' ? Infinity : 3; state.user.plan == 'pro' ? 20 : 5;
      state.user.editorsLimit = state.user.plan == 'premium' ? Infinity : state.user.plan == 'pro' ? 3 : 0;
      state.user.isUpgraded = ['pro', 'premium', 'enterprise'].includes(state.user.plan);
      if (!state.user.fileSpaceUsed) state.user.fileSpaceUsed = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (!action.payload) return;

        const { unsubscribe, ...user } = action.payload;

        state.user = { ...state.user, ...user };
        state.user.fileSpaceLimit = state.user.plan == 'premium' ? 429496729600 : 26843545600;//state.user.plan == 'premium' ? Infinity : state.user.plan == 'pro' ? 1610612736000 : 161061273600;
        state.user.projectsLimit = state.user.plan == 'premium' ? Infinity : 3;//state.user.plan == 'pro' ? 20 : 5;
        state.user.editorsLimit = state.user.plan == 'premium' ? Infinity : state.user.plan == 'pro' ? 3 : 0;
        state.user.isUpgraded = ['pro', 'premium', 'enterprise'].includes(state.user.plan);
        if (!state.user.fileSpaceUsed) state.user.fileSpaceUsed = 0;

        if (action.payload.unsubscribe) state.unsubscribe = action.payload.unsubscribe;
      });
  },
});

export const {
  updateNftTexture,
} = userSlice.actions;

export const getUser = (state) => state.user.user;

export default userSlice.reducer;