export const tabConfig = {
    productTab: [
      {
        title: "Commissions",
        value: "commission",
      },
      {
        title: "3D models",
        value: "shop",
      },
    ],
  }