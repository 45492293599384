import React from 'react';
import {
    EllipsisVerticalIcon,
    EditIcon,
    Trash2Icon,
    LoaderCircle,
} from "lucide-react"

import { cn } from "@/lib/utils"
import { ModelItemDlg } from "@/components/products/model-item-dlg";

import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export function ModelItem({
    model,
    open: [open, setOpen] = [false, () => { }],
    onDelete = () => { },
    aspectRatio = "portrait",
    width,
    height,
    className,
    ...props
}) {
    
    const { isPublic = false } = props;

    const outputScreenLoaderRef = React.useRef();

    const [editable, setEditable] = React.useState(false);

    const handleSetOpen = (open) => {
        setOpen(open);
        if (!open) setEditable(false);
    };

    const handleEdit = () => {
        if (!open) setOpen(true);
        setEditable(true);
    };

    return (
        <div className={cn("space-y-3",
            className)}>
            <ModelItemDlg
                models={[model]}
                open={[open, handleSetOpen]}
                editable={editable}
                onEdit={handleEdit}
                outputScreenLoaderRef={outputScreenLoaderRef}
                aspectRatio={aspectRatio}
                modelWidth={width}
                modelHeight={height}
                {...props}
            >
                <Button variant="link"
                    className={cn(
                        "px-0 w-full h-auto aspect-square",
                    )}
                >
                    <div className="overflow-hidden rounded-md border w-full h-full aspect-square">
                        {model.thumbnail?.url ? <img
                            src={model.thumbnail?.url}
                            alt={model.title}
                            className={cn(
                                "w-full aspect-square object-cover transition-all hover:scale-105",
                            )} /> : <div className="flex justify-center items-center w-full h-full">
                            <LoaderCircle className="animate-spin w-8 h-8" />
                        </div>}
                    </div>
                </Button>
            </ModelItemDlg>
            <div className="flex flex-row justify-between">
                <div className="space-y-1 text-lg truncate">
                    <h3 className="font-medium leading-none">{model.title}</h3>
                    <p className="text-base text-muted-foreground">{`${model.id == 'commission' ? 'Starting at ' : ''}$${parseFloat(model.price).toFixed(2)}`}</p>
                </div>
                {!isPublic && <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button size="icon" variant="ghost" className="h-7 w-7 rounded-sm border-0 shadow-none">
                            <EllipsisVerticalIcon className="w-4 h-4 text-muted-foreground" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="">
                        <DropdownMenuGroup className="">
                            <DropdownMenuItem onSelect={handleEdit}>
                                <EditIcon className="mx-2 w-[18px] h-[18px]" />
                                Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem onSelect={onDelete}>
                                <Trash2Icon className="mx-2 w-[18px] h-[18px]" />
                                Delete
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>}
            </div>
        </div>
    );
}