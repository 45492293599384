import React from 'react';

import { Progress } from "@/components/ui/progress"

const Loader = React.forwardRef(({ textProps, ...props }, ref) => {
    const [value, setValue] = React.useState(null);

    React.useImperativeHandle(ref, () => ({
        setValue: (newValue) => setValue(newValue),
        getValue: () => value,
    }));

    return (
        <div className="flex flex-col items-center">
            <div {...textProps}>{`${Math.ceil(value || 0)}%`}</div>
            <Progress
                value={value}
                max={100}
                {...props} />
        </div>

    );
});

export { Loader }