import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box, Container, Stack, Typography, Button, Grid,
  Card, CardContent, CardActions, Link, IconButton,
  Divider,
  CardActionArea,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import localFont from "next/font/local"
import { compareDesc } from "date-fns"

import { ThemeProvider } from "./components/theme-provider"
import { MainNav } from "./components/main-nav"
import { formatDate } from "./lib/utils"
import { allPosts } from "./contentlayer/generated/index.mjs"

import RootLayout from '@/app/layout/landing';

export default function BlogPage() {

  const [signIn, setSignIn] = React.useState(false);

  // const blogPost = blogPosts.find((post) => post.key == new URLSearchParams(window.location.search).get(''));

  React.useEffect(() => {
    document.body.classList.add('min-h-screen', 'bg-background', /*'font-sans',*/ 'antialiased');
  }, []);

  const posts = allPosts
    .filter((post) => post.published)
    .sort((a, b) => {
      return compareDesc(new Date(a.date), new Date(b.date))
    });

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta name='description' content={`Learn about 3D modeling, discover our latest product updates, partnership
announcements, user stories, and more. Modelsend is a 3d model annotation tool that helps
you get 3d model feedback from your clients. Securely upload your files and share a link to get
3d model reviews.`} />
        <link rel="stylesheet" type="text/css" href="/css/blog.css" />
      </Helmet>
      <ThemeProvider attribute="class" defaultTheme="light" enableSystem>
        <RootLayout>
          <div className="flex-1 min-h-screen">
            <div className="container max-w-4xl py-6 lg:py-10">
              <div className="flex flex-col items-start gap-4 md:flex-row md:justify-between md:gap-8">
                <div className="flex-1 space-y-4">
                  <h1 className="inline-block font-heading text-4xl tracking-tight lg:text-5xl">
                    Blog
                  </h1>
                  <p className="text-xl text-muted-foreground">
                    Learn about 3D modeling, product updates, partnerships, user stories, and more.
                  </p>
                </div>
              </div>
              <hr className="my-8" />
              {posts?.length ? (
                <div className="grid gap-10 sm:grid-cols-2">
                  {posts.map((post, index) => (
                    <article
                      key={post._id}
                      className="group relative flex flex-col space-y-2"
                    >
                      {post.image && (
                        <img
                          src={post.image}
                          alt={post.title}
                          width={804}
                          height={452}
                          className="rounded-md border bg-muted transition-colors"
                        // priority={index <= 1}
                        />
                      )}
                      <h2 className="text-2xl-1 font-extrabold">{post.title}</h2>
                      {post.description && (
                        <p className="text-muted-foreground">{post.description}</p>
                      )}
                      {post.date && (
                        <p className="text-sm text-muted-foreground">
                          {formatDate(post.date)}
                        </p>
                      )}
                      <a href={post.slug} className="absolute inset-0">
                        <span className="sr-only">View Article</span>
                      </a>
                    </article>
                  ))}
                </div>
              ) : (
                <p>No posts published.</p>
              )}
            </div>
          </div>
        </RootLayout>
      </ThemeProvider>
    </>
  );
}