import * as Sentry from "@sentry/react";

if (['modelsend.com', 'dcomments-prod.web.app', 'dcomments-prod.firebaseapp.com'].includes(window.location.host)) {
    Sentry.init({
        dsn: "https://5fec671186f7ba7961b133500921492c@o4506674889359360.ingest.sentry.io/4506675447070720",
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}