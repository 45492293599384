import React from 'react';
import {
  useDatabase,
  useStorage,
} from 'reactfire';
import {
  ref as databaseRef,
} from 'firebase/database';
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
  uploadString,
  getMetadata,
} from 'firebase/storage';
import { isFirefox, isSafari } from 'react-device-detect';
import JSZip from 'jszip';

import { set } from 'modules/firebase/database';
import { uploadMaps, getThumbnail, compressModel } from 'modules/redux/threejs';
import { uuid, generateThumbnail } from 'modules/utils';
import addWatermark from 'modules/utils/addWatermark.js';

export default function useUploadHandler() {
  const database = useDatabase();

  const storage = useStorage();

  const [uploading, setUploading] = React.useState(false);
  const [uploadingFiles, setUploadingFiles] = React.useState([]);
  const [uploadingProgress, setUploadingProgress] = React.useState(null);
  const [uploadingCallback, setUploadingCallback] = React.useState(null);

  const [objects, setObjects] = React.useState(null);

  const loaderRef = React.useRef();

  const handleUpload = async () => {
    const _uploadingProgress = [...uploadingProgress];

    const _objects = await Promise.all(uploadingFiles.map((file, i) => new Promise(async (resolve) => {
      const type = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
      const objectUuid = uuid();
      const objectUrl = URL.createObjectURL(file);
      let time = Date.now();
      if (/*file.size >= 78643200 && */['fbx', 'glb', 'obj', 'stl'].includes(type)) {
        file.object = await new Promise(async (_resolve) => {
          const [object] = await Promise.all([
            // isSafari/* || isFirefox*/ ?
            compressModel({
              url: objectUrl,
              type,
              uuid: objectUuid,
              name: file.name,
              onProgress: ({ id, loaded, total }) => {
                _uploadingProgress[i][id == 'upload' ? 1 : 0] = loaded / total;
                if (Date.now() - time >= 100) {
                  time = Date.now();
                  setUploadingProgress([..._uploadingProgress]);
                }
              },
            })
            // :
            // new Promise((__resolve) => {
            //   threejsWorker({
            //     type: 'compressModel',
            //     options: { url: objectUrl, type, uuid: objectUuid, name: file.name },
            //     onProgress: ({ id, loaded, total }) => {
            //       _uploadingProgress[i][id == 'load' ? 0 : 1] = loaded / total;
            //       if (Date.now() - time >= 100) {
            //         time = Date.now();
            //         setUploadingProgress([..._uploadingProgress]);
            //       }
            //     },
            //     onComplete: (object) => __resolve(object),
            //   })
            // })
            ,
            new Promise(async (__resolve, __reject) => {
              const zip = new JSZip();
              zip.file(file.name, file);
              const blob = await zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
                compressionOptions: { level: 9 },
              });

              const uploadTask = uploadBytesResumable(storageRef(storage, `objects/${objectUuid}/original/${file.name}.zip`), blob);
              uploadTask.on('state_changed',
                (snapshot) => {
                  _uploadingProgress[i][2] = snapshot.bytesTransferred / snapshot.totalBytes;
                  if (Date.now() - time >= 100) {
                    time = Date.now();
                    setUploadingProgress([..._uploadingProgress]);
                  }
                },
                __resolve,
                __resolve,
              );
            }),
          ]);

          _uploadingProgress[i][0] = 1;
          _uploadingProgress[i][1] = 1;
          _uploadingProgress[i][2] = 1;
          setUploadingProgress([..._uploadingProgress]);
          _resolve(object);
        });
      }

      resolve(await Promise.all([
        !!file.object ?
          new Promise(async (_resolve) => {
            const ref = storageRef(storage, `objects/${objectUuid}/${file.object.name}.zip`);

            const [url, metadata] = await Promise.all([getDownloadURL(ref), getMetadata(ref)]);

            _resolve({
              url,
              objectUrl,
              objectUrlType: file.name.split('.')[file.name.split('.').length - 1].toLowerCase(),
              size: file.size,
              uuid: objectUuid,
              name: file.name,
              type: metadata.name.split('.')[metadata.name.split('.').length - 1].toLowerCase(),
              metadata,
            });
          }) :
          new Promise(async (_resolve) => {
            const zip = new JSZip();
            zip.file(file.name, file);
            const blob = await zip.generateAsync({
              type: 'blob',
              compression: 'DEFLATE',
              compressionOptions: { level: 9 },
            });

            _uploadingProgress[i][0] = 1;

            const uploadTaskCompleted = [false, false];
            let upload;

            const uploadTask = uploadBytesResumable(storageRef(storage, `objects/${objectUuid}/original/${file.name}.zip`), blob);
            uploadTask.on('state_changed',
              (snapshot) => {
                _uploadingProgress[i][2] = snapshot.bytesTransferred / snapshot.totalBytes;
                if (Date.now() - time >= 100) {
                  time = Date.now();
                  setUploadingProgress([..._uploadingProgress]);
                }
              },
              () => {
                uploadTaskCompleted[0] = true;
                if (uploadTaskCompleted.every((bool) => bool)) _resolve(null);
              },
              () => {
                const object = uploadTask.snapshot;
                getDownloadURL(object.ref)
                  .then((url) => {
                    uploadTaskCompleted[0] = true;
                    _uploadingProgress[i][2] = 1;
                    if (upload && uploadTaskCompleted.every((bool) => bool)) _resolve(upload);
                  })
              }
            );

            const uploadTask2 = uploadBytesResumable(storageRef(storage, `objects/${objectUuid}/${file.name}.zip`), blob);
            uploadTask2.on('state_changed',
              (snapshot) => {
                _uploadingProgress[i][1] = snapshot.bytesTransferred / snapshot.totalBytes;
                if (Date.now() - time >= 100) {
                  time = Date.now();
                  setUploadingProgress([..._uploadingProgress]);
                }
              },
              () => {
                uploadTaskCompleted[1] = true;
                if (uploadTaskCompleted.every((bool) => bool)) _resolve(null);
              },
              () => {
                const object = uploadTask2.snapshot;
                getDownloadURL(object.ref)
                  .then((url) => {
                    upload = {
                      url,
                      objectUrl,
                      objectUrlType: file.name.split('.')[file.name.split('.').length - 1].toLowerCase(),
                      size: file.size,
                      uuid: objectUuid,
                      name: file.name,
                      type: file.name.split('.')[file.name.split('.').length - 1].toLowerCase(),
                      metadata: object.metadata,
                    };
                    uploadTaskCompleted[1] = true;
                    _uploadingProgress[i][1] = 1;
                    if (uploadTaskCompleted.every((bool) => bool)) _resolve(upload);
                  })
              }
            );
          }),
        new Promise(async (_resolve) => {
          const url = URL.createObjectURL(file);
          const sourceMap = {};

          const onProgress = (progress) => {
            // console.log(progress, sourceMap)
            // _uploadingProgress[i][3] = Math.min(progress.loaded, _uploadingProgress[i][2]);
            // if (Date.now() - time >= 100) {
            //   time = Date.now();
            //   setUploadingProgress([..._uploadingProgress]);
            // }
          };
          const onData = ({ data }) => {
            sourceMap[data.uuid] = data;
          };
          const onComplete = async ({ sources, object, size, polycount, vertices, hasAnimations, includeDefaultEnvMap, originalType, materials }) => {
            const maps = {};

            _uploadingProgress[i][3] = 1;
            setUploadingProgress([..._uploadingProgress]);

            await Promise.all(Object.entries(sources).map(([id, _sources]) =>
              Promise.all(Object.entries(_sources).map(([key, __sources]) =>
                Promise.all(Object.entries(__sources).map(([_, { uuid: mapUuid }]) =>
                  new Promise(async (__resolve, __reject) => {
                    try {
                      const { image, key } = sourceMap[mapUuid];

                      if (!maps[mapUuid]) {
                        if (typeof image === 'string') {
                          if (image.startsWith('data:image/')) {
                            maps[mapUuid] = { key, image };//{ key, image: key == 'map' ? await addWatermark(image) : image, original: image };
                            __resolve();
                          } else {
                            const ele = new Image();
                            ele.src = image;
                            ele.onload = async () => {
                              const original = generateThumbnail(ele);
                              maps[mapUuid] = { key, image: original };//{ key, image: key == 'map' ? await addWatermark(original) : original, original };
                              __resolve();
                            }
                            ele.onerror = () => __reject();
                          }
                        } else {
                          const original = generateThumbnail(image);
                          maps[mapUuid] = { key, image: original };//{ key, image: key == 'map' ? await addWatermark(original) : original, original };
                          __resolve();
                        }
                      } else __resolve();
                    } catch (e) { __reject() }
                  })
                    .then(() => Promise.all([
                      set(databaseRef(database, `maps/${objectUuid}/${id}/${key}/${mapUuid}`), 'Default'),
                      set(databaseRef(database, `objects/${objectUuid}/maps/${id}/${key}`), mapUuid),
                    ]))
                    .catch(() => { })
                ))
              ))
            ));
            // console.log(Object.entries(maps).length, Object.entries(maps))
            await Promise.all(Object.entries(maps).filter(([_, { image }]) => image).map(([mapUuid, { key, image, original }]) =>
              Promise.all([
                uploadString(storageRef(storage, `maps/${objectUuid}/${mapUuid}/Default`), image, 'data_url'),
                // key == 'map' ? uploadString(storageRef(storage, `maps/${objectUuid}/${mapUuid}/original/Default`), original, 'data_url') : Promise.resolve(),
              ]),
            ));

            URL.revokeObjectURL(url);
            _resolve({ object, dimensions: size, polycount, vertices, hasAnimations, includeDefaultEnvMap, originalType, materials });
          };

          // if (isSafari/* || isFirefox*/) {
          uploadMaps({
            url,
            uuid: objectUuid,
            object: file.object?.object,
            type: file.name.split('.')[file.name.split('.').length - 1].toLowerCase(),
            onProgress,
            onData,
            onComplete,
          });
          // }
          // else {
          //   threejsWorker({
          //     type: 'uploadMaps',
          //     options: {
          //       url,
          //       uuid: objectUuid,
          //       type: file.name.split('.')[file.name.split('.').length - 1].toLowerCase(),
          //       object: file.object?.object,
          //     },
          //     onProgress,
          //     onData,
          //     onComplete,
          //   });
          // }
        }),
      ])
        .then((values) => values.reduce((obj, _values) => ({ ...obj, ..._values }), {}))
      );
    })))
      .then((_objects) => _objects.filter((object) => object.uuid));

    for (const object of _objects) {
      const i = _objects.indexOf(object);

      const url = await new Promise(async (resolve) => {
        let time = Date.now();
        const onProgress = (progress) => {
          _uploadingProgress[i][4] = progress.loaded / progress.total;

          if (Date.now() - time >= 100) {
            time = Date.now();
            setUploadingProgress([..._uploadingProgress]);
          }
        };
        const onComplete = ({ url }) => resolve(url);

        getThumbnail({
          init: true,
          ...object,
          url: object.objectUrl,
          type: object.objectUrlType,
          originalType: object.objectUrlType,
          selectedMaps: uploadingFiles[i]?.textures,
          onProgress,
          onComplete,
        });
      });

      _objects[i].thumbnail = url;

      URL.revokeObjectURL(object.objectUrl);
    }

    setObjects(_objects);
  };

  React.useEffect(() => {
    if (uploading || !uploadingProgress || uploadingFiles.length < 1) return;

    setUploading(true);
  }, [uploadingProgress, uploadingFiles]);

  React.useEffect(() => { if (uploading) handleUpload() }, [uploading]);

  React.useEffect(() => {
    if (objects && uploadingCallback) {
      uploadingCallback.callback(objects);
      setObjects(null);
    }
  }, [objects, uploadingCallback]);

  const _uploadingProgress = uploadingProgress && 100 * (
    .05 * uploadingProgress.reduce((sum, progress) => sum + (progress[4] / uploadingProgress.length), 0) +
    .05 * uploadingProgress.reduce((sum, progress) => sum + (progress[3] / uploadingProgress.length), 0) +
    .9 * uploadingProgress.reduce((sum, progress) => sum + (((progress[0] + progress[1] + progress[2]) / 3) / uploadingProgress.length), 0)
  );

  React.useEffect(() => {
    if (loaderRef?.current && loaderRef.current.getValue() != _uploadingProgress) loaderRef.current.setValue(_uploadingProgress);
  }, [_uploadingProgress]);

  const _setUploadingFiles = (files) => {
    setUploadingFiles(files);
    setUploading(Array.isArray(files) && files.length > 0);
    setUploadingProgress(Array.isArray(files) ? files.map(() => [0, 0, 0, 0, 0]) : null);
  };

  return {
    uploading,
    uploadingFiles,
    uploadingLoaderRef: loaderRef,
    setUploadingFiles: _setUploadingFiles,
    setUploadingCallback: (callback) => setUploadingCallback({ callback }),
    upload: (files, callback) => {
      _setUploadingFiles(files);
      setUploadingCallback({
        callback: async (objects) => {
          try {
            await callback(objects);
          } finally { _setUploadingFiles([]) };
        },
      });
    },
  };
}