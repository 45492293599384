import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { compareDesc } from "date-fns"

import { ThemeProvider } from "./components/theme-provider"
import { MainNav } from "./components/main-nav"
import { allAuthors, allPosts } from "./contentlayer/generated/index.mjs"

import { Mdx } from "./components/mdx-components"

import { absoluteUrl, cn, formatDate } from "./lib/utils"
import { buttonVariants } from "./components/ui/button"
import { Icons } from "./components/icons"

import RootLayout from '@/app/layout/landing';

function getPostFromParams(params) {
  const slug = params//params?.slug?.join("/")
  const post = allPosts.find((post) => post.slugAsParams === slug)

  if (!post) {
    null
  }

  return post
}

export async function generateMetadata({
  params,
}) {
  const post = await getPostFromParams(params)

  if (!post) {
    return {}
  }

  const url = ''//env.NEXT_PUBLIC_APP_URL

  const ogUrl = new URL(`${url}/api/og`)
  ogUrl.searchParams.set("heading", post.title)
  ogUrl.searchParams.set("type", "Blog Post")
  ogUrl.searchParams.set("mode", "dark")

  return {
    title: post.title,
    description: post.description,
    authors: post.authors.map((author) => ({
      name: author,
    })),
    openGraph: {
      title: post.title,
      description: post.description,
      type: "article",
      url: absoluteUrl(post.slug),
      images: [
        {
          url: ogUrl.toString(),
          width: 1200,
          height: 630,
          alt: post.title,
        },
      ],
    },
    twitter: {
      card: "summary_large_image",
      title: post.title,
      description: post.description,
      images: [ogUrl.toString()],
    },
  }
}

export async function generateStaticParams() {
  return allPosts.map((post) => ({
    slug: post.slugAsParams.split("/"),
  }))
}

export default function BlogPostPage() {
  const params = useParams()?.['*'];

  const [signIn, setSignIn] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add('min-h-screen', 'bg-background', /*'font-sans',*/ 'antialiased');
  }, []);

  const post = getPostFromParams(params)

  const authors = post.authors.map((author) =>
    allAuthors.find(({ slug }) => slug === `/authors/${author.trim()}`)
  )

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta name='description' content={`Learn about 3D modeling, discover our latest product updates, partnership
announcements, user stories, and more. Modelsend is a 3d model annotation tool that helps
you get 3d model feedback from your clients. Securely upload your files and share a link to get
3d model reviews.`} />
      </Helmet>
      <ThemeProvider attribute="class" defaultTheme="light" enableSystem>
        <RootLayout>
          <div className="flex-1 min-h-screen">
            <article className="container relative max-w-3xl py-6 lg:py-10">
              <a
                href="/blog"
                className="absolute left-[-200px] top-14 hidden xl:inline-flex"
              >
                <Icons.chevronLeft className="mr-2 h-4 w-4" />
                See all posts
              </a>
              <div>
                {post.date && (
                  <time
                    dateTime={post.date}
                    className="block text-sm text-muted-foreground"
                  >
                    Published on {formatDate(post.date)}
                  </time>
                )}
                <h1 className="mt-2 inline-block font-heading text-4xl leading-tight lg:text-5xl">
                  {post.title}
                </h1>
                {authors?.length ? (
                  <div className="mt-4 flex space-x-4">
                    {authors.map((author) =>
                      author ? (
                        <a
                          key={author._id}
                          href={`https://twitter.com/${author.twitter}`}
                          className="flex items-center space-x-2 text-sm"
                        >
                          <img
                            src={author.avatar}
                            alt={author.title}
                            width={42}
                            height={42}
                            className="rounded-full bg-white"
                          />
                          <div className="flex-1 text-left leading-tight">
                            <p className="font-medium">{author.title}</p>
                            <p className="text-[12px] text-muted-foreground">
                              @{author.twitter}
                            </p>
                          </div>
                        </a>
                      ) : null
                    )}
                  </div>
                ) : null}
              </div>
              {post.image && (
                <img
                  src={post.image}
                  alt={post.title}
                  width={720}
                  height={405}
                  className="my-8 rounded-md border bg-muted transition-colors"
                // priority
                />
              )}
              <Mdx code={post.body.code} />
              <hr className="mt-12" />
              <div className="flex justify-center py-6 lg:py-10">
                <a href="/blog" className={cn(buttonVariants({ variant: "ghost" }))}>
                  <Icons.chevronLeft className="mr-2 h-4 w-4" />
                  See all posts
                </a>
              </div>
            </article>
          </div>
        </RootLayout>
      </ThemeProvider>
    </>
  );
}