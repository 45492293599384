import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

import { fetchUsers, getWebsites } from '@/modules/redux/storage';
import { getUser } from '@/modules/redux/user';
import { cn } from '@/lib/utils';
import { getPayloadFromToken } from '@/modules/utils';

export default function useDownloadRecentPurchases() {
    const dispatch = useDispatch();

    const { buyer: { purchases } = {} } = useSelector(getUser);

    const websites = useSelector(getWebsites);

    const [recentPurchasesDownloaded, setRecentPurchasesDownloaded] = React.useState(false);

    const downloadRecentPurchases = async () => {
        const zip = new JSZip();
        const models = zip.folder('Modelsend Purchases');

        const _purchases = getPayloadFromToken().purchases;

        window.history.replaceState({}, null, window.location.pathname);

        for (const uuid of _purchases) {
            const object = purchases[uuid];

            let blob;
            if (!object.zipped) {
                blob = await fetch(object.download).then((res) => res.blob());
            }
            else {
                blob = await new Promise((resolve, reject) => {
                    JSZipUtils.getBinaryContent(object.download, (err, data) => err ? reject(err) : resolve(data));
                })
                    .then((data) => JSZip.loadAsync(data))
                    .then((data) => Object.values(data.files)[0].async('arraybuffer'))
                    .then((buffer) => new Blob([buffer]));
            }

            const sameTitle = Object.values(purchases).filter((o) => o.title == object.title);

            const model = models.folder(websites[object.user].username);
            const folder = model.folder(`${object.title}${sameTitle.length > 1 ? ` (${sameTitle.indexOf(object) + 1})` : ''}`);
            folder.file(object.name, blob);
        }

        const blob = await zip.generateAsync({ type: 'blob' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Modelsend Purchases.zip`;
        link.click();

        URL.revokeObjectURL(url);
        link.remove();
    };

    React.useEffect(() => {
        if (purchases) {
            const users = Object.values(purchases).map((m) => m.user).filter((u) => !websites[u]);
            if (users.length) {
                dispatch(fetchUsers(users));
            }
            else if (!recentPurchasesDownloaded &&
                getPayloadFromToken().purchases &&
                getPayloadFromToken().purchases.every((uuid) => !!purchases[uuid])) {
                downloadRecentPurchases();
                setRecentPurchasesDownloaded(true);
            }
        }
    }, [purchases, websites]);
}