import OutputScreen from './OutputScreen';

import Background from './components/Background';
import DesktopOverlayUI from './components/DesktopOverlayUI';
import Lighting from './components/Lighting';
import OptionsUI from './components/OptionsUI';

export default OutputScreen;
export {
  Background,
  DesktopOverlayUI,
  Lighting,
  OptionsUI,
};