import {
  getFirestore,
  collection as collectionFirestore,
} from 'firebase/firestore';

import getFirebaseConfig from 'config/firebase';

export const collection = (path, firebaseConfig) => {
  let databaseId;

  if (!firebaseConfig) firebaseConfig = getFirebaseConfig();

  switch (firebaseConfig.storageBucket) {
    case `${firebaseConfig.projectId}-asia`:
      databaseId = `${firebaseConfig.projectId}-asia`;
      break;
    case `${firebaseConfig.projectId}-eu`:
      databaseId = `${firebaseConfig.projectId}-eu`;
      break;
    default:
  }

  return collectionFirestore(getFirestore(databaseId), path);
};