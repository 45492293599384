"use client"

import * as React from "react"
import { useDatabase, useUser } from "reactfire";
import { ref as databaseRef, get } from 'firebase/database';
import {
  AlertCircle,
  Archive,
  ArchiveX,
  File,
  Inbox,
  MessagesSquare,
  Search,
  Send,
  ShoppingCart,
  Trash2,
  Users2,
} from "lucide-react"
import {
  useChat,
} from "@chatscope/use-chat";
import { AutoDraft } from "@chatscope/use-chat/dist/enums/AutoDraft";

import { cn } from "@/lib/utils"
import { Input } from "@/components/ui/input"
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable"
import { Separator } from "@/components/ui/separator"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

import { MailDisplay } from "@/components/mail/mail-display"
import { MailList } from "@/components/mail/mail-list"

import { set } from '@/modules/firebase/database';
import { getPayloadFromToken } from '@/modules/utils';

const defaultLayout = [265, 440, 655];

export default function Mail({
  ...props
}) {

  const {
    conversation: [conversation] = [],
    updateChat = () => { },
  } = props;

  const database = useDatabase();

  const { data: user } = useUser();

  const {
    currentMessages, conversations, activeConversation, setActiveConversation, sendMessage, getUser, currentMessage, setCurrentMessage,
    sendTyping, setCurrentUser, getConversation, addMessage, messages,
  } = useChat();

  React.useEffect(() => {
    if (activeConversation?.id != conversation) {
      setActiveConversation(conversation);
      (async () => {
        if (activeConversation) return;

        const cMessages = messages[conversation]?.reduce((arr, g) => arr.concat(g.messages), []);
        const lastMessage = cMessages?.[cMessages?.length - 1];

        if (lastMessage?.content.unread && lastMessage?.content.user != user.uid) {
          const { tags, object, uuid } = getConversation(conversation).data;

          const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

          const messages = await get(databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`)).then((snapshot) => snapshot.val() || []);

          await Promise.all(messages.map((message, i) =>
            message.unread ? set(databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}/${i}/unread`), false) : Promise.resolve(),
          ));

          updateChat();
        }
      })();
    }
  }, [activeConversation, conversation, getConversation(conversation)]);

  const [collapsed, setCollapsed] = React.useState(true);

  const handleMailClick = () => setCollapsed(false);

  const handleBackClick = () => setCollapsed(true);

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    checkScreenWidth();

    // Event listener for screen width changes
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const [archived, setArchived] = React.useState(false);

  const [search, setSearch] = React.useState('');

  const handleSearchChange = (e) => setSearch(e.target.value);

  return (<>
    <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}
      className={cn(
        isMobile && !collapsed ? "hidden" : ""
      )}>
      <div className="flex flex-col h-full min-h-screen">
        <Tabs value={archived} onValueChange={setArchived} className="flex flex-col h-full overflow-y-auto">
          <div className="flex items-center px-4 py-2">
            <h1 className="text-xl font-bold">Inbox</h1>
            <TabsList className="ml-auto">
              <TabsTrigger
                value={false}
                className="text-zinc-600 dark:text-zinc-200"
              >
                Messages
              </TabsTrigger>
              <TabsTrigger
                value={true}
                className="text-zinc-600 dark:text-zinc-200"
              >
                Archived
              </TabsTrigger>
            </TabsList>
          </div>
          <Separator />
          <div className="bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <form>
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input id="search" value={search} onChange={handleSearchChange} placeholder="Search" className="pl-8" />
              </div>
            </form>
          </div>
          <div className="m-0 flex-1 overflow-y-auto">
            <MailList
              archived={archived}
              search={search}
              onMailClick={handleMailClick}
              {...props} />
          </div>
        </Tabs>
      </div>
    </ResizablePanel>
    <ResizableHandle withHandle
      className={cn(
        isMobile && collapsed ? "hidden" : ""
      )} />
    <ResizablePanel defaultSize={defaultLayout[2]}
      className={cn(
        isMobile && collapsed ? "hidden" : ""
      )}>
      <MailDisplay
        isMobile={isMobile}
        onBackClick={handleBackClick}
        {...props} />
    </ResizablePanel>
  </>);
}
