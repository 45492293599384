import {
  getFunctions,
  httpsCallable as httpsCallableFirebase,
} from 'firebase/functions';

import getFirebaseConfig from 'config/firebase';

export const httpsCallable = (name, firebaseConfig) => (params) => {
  let region;

  if (!firebaseConfig) firebaseConfig = getFirebaseConfig();

  switch (firebaseConfig.storageBucket) {
    case `${firebaseConfig.projectId}-asia`:
      name = `${name}-asia`;
      region = 'asia-east2';
      break;
      case `${firebaseConfig.projectId}-eu`:
      name = `${name}-eu`;
      region = 'europe-west1';
      break;
    default:
      name = `${name}-default`;
      region = 'us-central1';
  }

  return httpsCallableFirebase(getFunctions(undefined, region), name)(params)
    .catch(() => ({ data: {} }));
};