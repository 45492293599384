import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useAuth,
  useDatabase,
  useSigninCheck,
} from 'reactfire';
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import {
  get,
  ref as databaseRef,
  getDatabase,
} from 'firebase/database';
import {
  LoaderCircle,
} from "lucide-react"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import short from "short-uuid"

import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

import { set, update } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import { getPayloadFromToken } from '@/modules/utils';

export function SigninDlg({
  children,
  onOpenChange = () => { },
  onLoginSuccess = () => { },
}) {

  const auth = useAuth();

  const database = useDatabase();

  const { data: signInCheckResult } = useSigninCheck();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { token } = useParams();

  const form = useForm({
    resolver: zodResolver(z.object({
      email: z
        .string()
        .email("Email is not valid")
    })),
    mode: 'onSubmit',
  });

  const [loading, setLoading] = React.useState();
  const [verifyEmail, setVerifyEmail] = React.useState(false);

  const handleOpenChange = (open) => {
    if (open) setVerifyEmail(false);
    onOpenChange(open);
  };

  const sendEmailVerification = async (email) => {
    const { data: { token } } = await httpsCallable('encrypt')({
      public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
      payload: { email, timestamp: Date.now() },
    });

    await sendSignInLinkToEmail(auth, email, {
      url: `${window.location.origin}/?token=${token}`,
      handleCodeInApp: true,
    })
      .catch((e) => console.log(e));
  };

  const handleEmailSignin = async ({ email }) => {
    setLoading('email');

    await sendEmailVerification(email);

    setLoading();

    setVerifyEmail(true);
  };

  const handleGoogleSignin = async () => {
    try {
      setLoading('google');

      const credential = await signInWithPopup(auth, new GoogleAuthProvider());

      await generateUsernameForNewUser(credential.user);

      onLoginSuccess(credential);
    }
    finally {
      setLoading();
    }
  };

  const handleSellerNav = () => navigate('/artists');

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className="mx-auto max-w-sm">
        {verifyEmail ? <DialogHeader>
          <DialogTitle className="text-xl">Please verify link send to your email.</DialogTitle>
          <DialogDescription className="w-full flex justify-center">
            Didn't receive it?&nbsp;
            <Button variant="link" className="h-5 p-0">Resend link</Button>
          </DialogDescription>
        </DialogHeader> : <>
          <DialogHeader>
            <DialogTitle className="text-xl">Sign in or create an account</DialogTitle>
            <DialogDescription>
              Enter your email below to login to your account
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-2">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleEmailSignin)}>
                <FormItem>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <FormField
                    control={form.control}
                    name="email"
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        id="email"
                        type="email"
                        placeholder="m@example.com"
                        required
                        {...field} />
                    )} />
                </FormItem>
                <Button type="submit" className="w-full mt-4">
                  {loading == 'email' ? <LoaderCircle className="animate-spin w-6 h-6" /> : 'Login'}
                </Button>
              </form>
            </Form>
            <DialogDescription className="w-full flex justify-center">or</DialogDescription>
            <Button variant="outline" className="w-full" onClick={handleGoogleSignin}>
              {loading == 'google' ? <LoaderCircle className="animate-spin w-6 h-6" /> : 'Login with Google'}
            </Button>
            {pathname.split('/')[1] != 'artists' && <p className="w-full text-xs text-muted-foreground text-center">
              Are you a 3D artist? <button onClick={handleSellerNav} className="underline">Sign up</button> for a seller account
            </p>}
          </div>
        </>}
      </DialogContent>
    </Dialog>
  )
}

export async function generateUsernameForNewUser(user) {
  const database = getDatabase();

  let username = await get(databaseRef(database, `users/${user.uid}/website/username`)).then((snapshot) => snapshot.val());

  if (!username) {
    username = user.email.split('@')[0]
      .replaceAll('.', '-')
      .replaceAll('#', '-')
      .replaceAll('$', '-')
      .replaceAll('[', '-')
      .replaceAll(']', '-')
      .replaceAll('/', '-')
      .replaceAll('\\', '-')
      .replaceAll(' ', '-');

    const usernameExists = await get(databaseRef(database, `websites/${username.toLowerCase()}`)).then((snapshot) => !!snapshot.val());
    username = usernameExists ? `${username}-${short.generate()}` : username;

    await Promise.all([
      update(databaseRef(database, `users/${user.uid}/website`), {
        username,
        bio: '',
        email: '',
        facebook: '',
        instagram: '',
        twitter: '',
        website: '',
      }),
      set(databaseRef(database, `websites/${username.toLowerCase()}`), user.uid),
      update(databaseRef(database, `users/${user.uid}/notifications`), {
        marketing: true,
        message: true,
        payment: true,
      }),
    ]);
  }
};
