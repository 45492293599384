export default {
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
    fontFamily: 'Söhne, sans-serif',
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      sm2: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    // ...(window.location.pathname == '/pricing' ? {
    //   primary: {
    //     main: '#ffffff',
    //     dark: '#fff6',
    //   },
    //   secondary: {
    //     main: '#1e1f22',
    //     dark: '#121316',
    //     light: '#27282b',
    //   },
    //   info: {
    //     main: '#0b8ce9',
    //   },
    // } : {
    primary: {
      main: '#1e1f22',
      dark: '#121316',
      light: '#212B36',
      lighter: '#a2a2a2',
      text: 'rgba(0, 0, 0, 0.6)',
    },
    secondary: {
      // main: '#F9FAFB',
      // dark: '#F4F6F8',
      main: '#ffffff',
      dark: '#ffffff',
      light: '#ffffff',
      input: '#F9FAFB',
      inputSelected: '#e5e5e5',
      background: '#f6f6f6',
    },
    info: {
      main: '#0b8ce9',
      dark: '#0042eb',
    },
    // })
  },
}