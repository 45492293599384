import React from 'react';
import { SketchPicker } from 'react-color';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"

export function ColorPickerPopover({ children, onOpenChange, color, align, onChange }) {
    return (
        <Popover open={!!color} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent align={align} className="w-auto p-0">
                {color && <SketchPicker
                    color={color}
                    disableAlpha
                    presetColors={[]}
                    onChange={onChange}
                />}
            </PopoverContent>
        </Popover>
    );
}