import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSigninCheck, useUser } from 'reactfire';

import { cn } from "@/lib/utils"
import { navConfig } from "@/config/nav-config"

import { MainNav } from "@/components/main-nav"
import { SigninDlg } from "@/components/signin-dlg"
import { UserPopup } from "@/components/user-popup"
import { NoticePopup } from "@/components/notice-popup"

import {
  ShoppingCart,
  BellIcon,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"

export default function RootLayout({ children, loading = false, ...props }) {

  const { data: signInCheckResult } = useSigninCheck();

  const { data: user } = useUser();

  const navigate = useNavigate();

  const [handleLoginSuccess, setHandleLoginSuccess] = React.useState();

  const handleLinkClick = async (open) => {
    if (open) {
      const handleSignIn = async () => {
        setHandleLoginSuccess();

        navigate('/cart');
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }
    }
    else {
      setHandleLoginSuccess();
    }
  };

  return (
    <div
      className={cn(
        "h-screen bg-background font-sans --font-sans-heading antialiased flex flex-col overflow-y-hidden"
      )}
    >
      <header className={cn(
        "md:container px-4 z-40 bg-background",
        loading ? "hidden" : "",
      )}>
        <div className="flex h-16 items-center justify-between py-4">
          <MainNav items={navConfig.mainNav} {...props} />
          <nav className="flex items-center gap-4 ml-4">
            {signInCheckResult.signedIn ?
              <UserPopup /> :
              <SigninDlg>
                <Button>Sign in</Button>
              </SigninDlg>}
            {signInCheckResult.signedIn && <NoticePopup className="hidden md:flex" {...props} />}
            {signInCheckResult.signedIn ?
              <Button variant="outline" className="w-9 h-9 p-2" onClick={handleLinkClick}>
                <ShoppingCart />
              </Button> :
              <SigninDlg onOpenChange={handleLinkClick} onLoginSuccess={handleLoginSuccess?.callback}>
                <Button variant="outline" className="w-9 h-9 p-2">
                  <ShoppingCart />
                </Button>
              </SigninDlg>}
          </nav>
        </div>
      </header>
      <main className="flex-1 overflow-y-auto">
        {loading ? <div className="flex flex-col justify-center items-center w-screen h-screen">
            <img src="/images/loading.png" alt="loading" className="w-48 md:w-96" />
        </div> : children}
      </main>
    </div>
  );
}
