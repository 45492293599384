"use client"

import React from 'react';
import { useLocation } from 'react-router-dom';
import { LucideIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

export function Nav({ links, isCollapsed }) {
  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
    >
      <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) => {
          const ActionArea = React.forwardRef(({ children, ...props }, ref) => 
            React.createElement(link.onClick ? 'button' : 'a', { ...props, ref }, children));

          return isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <ActionArea
                  href={link.href}
                  onClick={link.onClick}
                  className={cn(
                    buttonVariants({ variant: link.isActive ? "default" : "ghost", size: "icon" }),
                    "h-9 w-9",
                    link.isActive
                    && "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white"
                  )}
                >
                  <link.icon className="h-4 w-4" />
                  <span className="sr-only">{link.title}</span>
                </ActionArea>
              </TooltipTrigger>
              <TooltipContent side="right" className="flex items-center gap-4">
                {link.title}
                {link.label && (
                  <span className="ml-auto text-muted-foreground">
                    {link.label}
                  </span>
                )}
              </TooltipContent>
            </Tooltip>
          ) : (
            <ActionArea
              key={index}
              href={link.href}
              onClick={link.onClick}
              className={cn(
                buttonVariants({ variant: link.isActive ? "default" : "ghost", size: "sm" }),
                link.isActive
                &&
                "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                "justify-start"
              )}
            >
              <link.icon className="mr-2 h-4 w-4" />
              {link.title}
              {link.label && (
                <span
                  className={cn(
                    "ml-auto",
                    link.variant === "default" &&
                    "text-background dark:text-white"
                  )}
                >
                  {link.label}
                </span>
              )}
            </ActionArea>
          )
        })}
      </nav>
    </div>
  )
}
