import { Buffer } from 'buffer';
import { deleteObject, getMetadata, listAll } from 'firebase/storage';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import nacl from 'tweetnacl';
import { v4 } from 'uuid';

const deleteFolder = (ref) =>
  listAll(ref)
    .then((dir) => Promise.all([
      ...dir.items.map((fileRef) => deleteObject(fileRef)),
      ...dir.prefixes.map((folderRef) => deleteFolder(folderRef)),
    ]));

const getFolderSize = (ref) =>
  listAll(ref)
    .then((dir) => Promise.all([
      ...dir.items.map((fileRef) => fileRef.name == 'Default' ||
        fileRef.parent?.name == 'original' ? Promise.resolve(0) :
        getMetadata(fileRef).then((metadata) => metadata.size)
      ),
      ...dir.prefixes.map((folderRef) => getFolderSize(folderRef)),
    ])
      .then((sizes) => sizes.reduce((sum, size) => sum + size, 0))
    );

const flipImage = (image) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', { willReadFrequently: true });

  canvas.width = image.width;
  canvas.height = image.height;
  ctx.scale(1, -1);
  ctx.drawImage(image, 0, -image.height, image.width, image.height);
  image = new Image;
  image.src = canvas.toDataURL();

  return image;
};

const getSrc = (nft) => new Promise(async (resolve, reject) =>
  resolve(await fetch(getUrl(nft.metadata?.image || nft.image_uri), { method: 'HEAD' })
    .then(() => getUrl(nft.metadata?.image || nft.image_uri))
    .catch(() => getUrl((nft.media && nft.media[0] && nft.media[0].thumbnail) ? nft.media[0].thumbnail : null))
  )
);

const generateThumbnail = (image, size = 2048) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d', { willReadFrequently: true });
  if (image.height > image.width) {
    canvas.height = Math.min(image.height, size);
    canvas.width = (image.width / image.height) * canvas.height;
  }
  else if (image.width > image.height) {
    canvas.width = Math.min(image.width, size);
    canvas.height = (image.height / image.width) * canvas.width;
  }
  else {
    canvas.width = Math.min(image.width, size);
    canvas.height = Math.min(image.height, size);
  }
  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/png');
};

const getThumbnail = async (url, isVideo = false) => new Promise((resolve, reject) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  if (!isVideo) {
    const image = new Image;
    image.src = getUrl(url);
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      let width, height;
      if (image.width > image.height) {
        width = Math.min(image.width, 300);
        height = (image.height / image.width) * width;
      } else if (image.width < image.height) {
        height = Math.min(image.height, 300);
        width = (image.width / image.height) * height;
      } else {
        width = Math.min(image.width, 300);
        height = width;
      }
      canvas.width = width;
      canvas.height = height;
      ctx.scale(canvas.width / image.width, canvas.height / image.height);
      ctx.drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        let thumbnail;
        try {
          thumbnail = URL.createObjectURL(blob);
        } catch (e) { }
        resolve({ thumbnail, image: thumbnail ? image : null });
      });
    };
    image.onerror = () => resolve({ thumbnail: null, image: null });
  } else {
    const video = document.createElement('video');
    video.src = getUrl(url);
    video.crossOrigin = 'anonymous';
    video.load();
    video.currentTime = 0.001;
    video.onseeked = () => {
      let width, height;
      if (video.videoWidth > video.videoHeight) {
        width = Math.min(video.videoWidth, 300);
        height = (video.videoHeight / video.videoWidth) * width;
      } else if (video.videoWidth < video.videoHeight) {
        height = Math.min(video.videoHeight, 300);
        width = (video.videoWidth / video.videoHeight) * height;
      } else {
        width = Math.min(video.videoWidth, 300);
        height = width;
      }
      canvas.width = width;
      canvas.height = height;
      ctx.scale(canvas.width / video.videoWidth, canvas.height / video.videoHeight);
      ctx.drawImage(video, 0, 0);
      canvas.toBlob((blob) => {
        let thumbnail;
        try {
          thumbnail = URL.createObjectURL(blob);
        } catch (e) { }
        resolve({ thumbnail })
      });
    };
    video.onerror = () => resolve({ thumbnail: null });
  }
});

const getUrl = (url) => {
  if (typeof url != 'string') return null;

  if (url.includes('ipfs://')) return url.replace('ipfs://', 'https://ipfs.io/');

  return url;
};

function toHexString(byteArray) {
  return Array.prototype.map.call(byteArray, function (byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('');
}

function toByteArray(hexString) {
  var result = [];
  for (var i = 0; i < hexString.length; i += 2) {
    result.push(parseInt(hexString.substr(i, 2), 16));
  }
  return new Uint8Array(result);
}

const getPayloadFromToken = (token) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);

    if (!token && !urlParams.has('token')) return {};

    const bytes = nacl.sign.open(
      toByteArray(token || urlParams.get('token')),
      toByteArray(process.env.REACT_APP_SERVER_PUBLIC_KEY)
    );

    return JSON.parse(Buffer.from(bytes).toString());
  } catch (e) {
    return {};
  }
};

const uuid = () => `${Date.now().toString(16)}-${v4()}`;

// This will parse a delimited string into an array of
// arrays. The default delimiter is the comma, but this
// can be overriden in the second argument.
function CSVToArray(strData, strDelimiter) {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );


  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;


  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(strData)) {

    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      (strMatchedDelimiter != strDelimiter)
    ) {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);

    }


    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {

      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      var strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );

    } else {

      // We found a non-quoted value.
      var strMatchedValue = arrMatches[3];

    }


    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  if (arrData.length < 2 || !arrData.every((row, i) => i == 0 ? true : row.length == arrData[0].length)) return [];

  const keys = arrData[0].map((key) => [key.split(' ')[0].toLowerCase(), ...key.split(' ').slice(1).map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())].join(''));
  const objArray = arrData.slice(1).map((values) => Object.fromEntries(values.map((value, i) => [keys[i], value])));

  // Return the parsed data.
  return (objArray);
}

const unzip = async (url, type) => {
  const _unzip = await new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err, data) => err ? reject(err) : resolve(data));
  })
    .then((data) => JSZip.loadAsync(data))
    .then((data) => Object.values(data.files)[0].async('arraybuffer'));

  url = url.split('?')[url.split('?').length - 2];
  url = url.slice(0, url.length - 4);
  type = url.split('.')[url.split('.').length - 1];
  url = URL.createObjectURL(new Blob([_unzip]));

  return [url, type];
}

const multiply = (...params) => params.length ? params[0] * 1000 * multiply(...params.slice(1)) / 1000 : 1;

export {
  deleteFolder,
  getFolderSize,
  flipImage,
  generateThumbnail,
  getSrc,
  getThumbnail,
  getUrl,
  uuid,
  toByteArray,
  toHexString,
  CSVToArray,
  getPayloadFromToken,
  unzip,
  multiply,
};