import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';

import {
    AlertCircle,
    File,
    LogOutIcon,
    MessagesSquare,
    RocketIcon,
    UserIcon,
    DownloadIcon,
    CompassIcon,
    FolderIcon,
} from "lucide-react"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuPortal,
} from "@/components/ui/dropdown-menu"

import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"

import { getUser } from '@/modules/redux/user';

export function UserPopup() {

    const auth = useAuth();

    const { data: { photoURL, displayName } = {} } = useUser();

    const {
        profile,
        seller: { initiated = false } = {},
    } = useSelector(getUser);

    const navigate = useNavigate();

    const handleClickWebsiteLink = () => {
        navigate(`/seller`);
    };
    const handleClickAccountLink = () => {
        navigate(`${initiated ? '/seller' : ''}/account`);
    };
    const handleClickMailLink = () => {
        navigate(`${initiated ? '/seller' : ''}/messages`);
    };
    const handleClickProjectsLink = () => {
        navigate(`${initiated ? '/seller' : ''}/projects`);
    };
    const handleClickPurchasesLink = () => {
        navigate('/purchases');
    };
    const handleClickFollowingLink = () => {
        navigate('/following');
    };
    const handleSignout = async () => {
        await auth.signOut();
        navigate('/');
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-9 w-9 rounded-full">
                    <Avatar className="h-9 w-9">
                        <AvatarImage src={profile || photoURL} alt={displayName} />
                        <AvatarFallback></AvatarFallback>
                    </Avatar>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-40 py-4">
                <DropdownMenuLabel className="space-y-2">
                    <h2 className="text-sm font-medium">{`Hey ${displayName}`}</h2>
                    <Separator className="my-6" />
                </DropdownMenuLabel>
                <DropdownMenuGroup className="mt-2 space-y-2">
                    {initiated && <DropdownMenuItem
                        onSelect={handleClickWebsiteLink}
                    >
                        <div className="flex items-center space-x-2">
                            <RocketIcon className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Website</p>
                        </div>
                    </DropdownMenuItem>}
                    <DropdownMenuItem
                        onSelect={handleClickMailLink}
                    >
                        <div className="flex items-center space-x-2">
                            <MessagesSquare className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Messages</p>
                        </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        onSelect={handleClickProjectsLink}
                    >
                        <div className="flex items-center space-x-2">
                            <FolderIcon className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Projects</p>
                        </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        onSelect={handleClickPurchasesLink}
                    >
                        <div className="flex items-center space-x-2">
                            <DownloadIcon className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Purchases</p>
                        </div>
                    </DropdownMenuItem>
                    {/* <DropdownMenuItem
                        onSelect={handleClickFollowingLink}
                    >
                        <div className="flex items-center space-x-2">
                            <UsersIcon className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Following</p>
                        </div>
                    </DropdownMenuItem> */}
                    <DropdownMenuItem
                        onSelect={handleClickAccountLink}
                    >
                        <div className="flex items-center space-x-2">
                            <UserIcon className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Account</p>
                        </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        onSelect={handleSignout}
                    >
                        <div className="flex items-center space-x-2">
                            <LogOutIcon className='w-[18px] h-[18px]' />
                            <p className="text-sm font-medium">Sign out</p>
                        </div>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}