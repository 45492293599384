"use client"

import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { toast } from "@/components/ui/use-toast"

import { set } from '@/modules/firebase/database';
import { getUser } from '@/modules/redux/user';

const accountFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  dob: z.date({
    required_error: "A date of birth is required.",
  }),
  language: z.string({
    required_error: "Please select a language.",
  }),
})

// This can come from your database or API.
const defaultValues = {
  // name: "Your name",
  // dob: new Date("2023-01-23"),
}

export function AccountForm() {
  const navigate = useNavigate();

  const database = useDatabase();

  const { data: user } = useUser();

  const { seller: { initiated = false } = {} } = useSelector(getUser);

  const form = useForm({
    resolver: zodResolver(accountFormSchema),
    defaultValues,
  })

  function onSubmit(data) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    })
  }

  const handleSellerInitiated = async () => {
    await set(databaseRef(database, `users/${user.uid}/seller/initiated`), true);

    navigate('/seller');
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input readOnly defaultValue={user?.email} {...field} />
              </FormControl>
              <FormDescription>
                This is the email that was used to create this account.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        {!initiated && <Button onClick={handleSellerInitiated}>Switch to artist account</Button>}
      </form>
    </Form>
  )
}
