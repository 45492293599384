import React from 'react';
import { useSelector } from 'react-redux';
import {
    useDatabase,
    useFirestore,
    useStorage,
    useUser,
} from 'reactfire';
import {
    ref as databaseRef,
    get,
} from 'firebase/database';
import {
    and,
    getDocs,
    limit,
    or,
    orderBy,
    query,
    startAfter,
    where,
} from 'firebase/firestore';
import {
    ref as storageRef,
    getDownloadURL,
    getMetadata,
    listAll,
} from 'firebase/storage';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { isSafari } from 'react-device-detect';

import { set } from '@/modules/firebase/database';
import { collection } from '@/modules/firebase/firestore';
import { getThumbnail } from '@/modules/redux/threejs';

export default function useMarketplace({
    category: [category] = [false],
    fileType: [fileType] = [false],
    free: [free] = [false],
    id: [id] = ['comission'],
    search: [search] = [''],
    objectLoadersRef,
} = {}) {
    const database = useDatabase();

    const storage = useStorage();

    const [initialized, setInitialized] = React.useState(false);
    const [objects, setObjects] = React.useState(null);
    const [objectThumbnails, setObjectThumbnails] = React.useState({});
    const [objectThumbnail, setObjectThumbnail] = React.useState(null);

    React.useEffect(() => {
        if (!objectThumbnail) return;

        setObjectThumbnails({ ...objectThumbnails, ...objectThumbnail });
    }, [objectThumbnail]);

    const fetchObjects = async () => {
        const _objects = await Promise.all((objects || []).map((object) => new Promise(async (resolve) => {
            let thumbnail = objectThumbnails[object.uuid]?.url;
            let custom = !!objectThumbnails[object.uuid]?.custom;

            const ref = await listAll(storageRef(storage, `thumbnails/${object.uuid}`));
            if (ref.prefixes.find((i) => i.name == 'custom')) {
                thumbnail = await listAll(ref.prefixes.find((i) => i.name == 'custom')).then((ref) => getDownloadURL(ref.items[0]));
                custom = true;
            }
            else {
                const needsUpdate = await get(databaseRef(database, `objects/${object.uuid}/needsUpdate`)).then((snapshot) => !!snapshot.val());
                if (isSafari || !needsUpdate) {
                    if (ref.items[0]) thumbnail = await getDownloadURL(ref.items[0]);
                }
                else if (needsUpdate && thumbnail && !custom) {
                    thumbnail = null;
                }
                custom = false;
            }

            resolve({ thumbnail, custom });
        })))
            .then((thumbnails) => (objects || []).map((object, i) => ({
                ...object,
                thumbnail: thumbnails[i].thumbnail,
                custom: thumbnails[i].custom,
                progress: objectLoadersRef?.current?.[object.uuid]?.getValue(),
            })));

        setObjectThumbnail(Object.fromEntries(_objects.map(({ uuid, thumbnail, custom }) => [uuid, { custom, url: thumbnail }])));

        for (const object of _objects) {
            const i = _objects.indexOf(object);

            if (!object.thumbnail && (object.progress === undefined || object.progress === null)) {
                const url = await new Promise(async (resolve) => {
                    const canvas = document.createElement('canvas');
                    const offscreen = canvas.transferControlToOffscreen();

                    const _url = await getDownloadURL(object.ref);

                    getThumbnail({
                        ...object,
                        url: _url,
                        type: object.zipped ? 'zip' : object.type,
                        onProgress: (progress) => {
                            const _progress =
                                (progress.id == 'upload' ? 90 : 0) +
                                (progress.id == 'upload' ? 10 : 90) *
                                (progress.loaded / progress.total);

                            objectLoadersRef?.current?.[object.uuid]?.setValue(_progress);
                        },
                        onComplete: ({ url }) => set(databaseRef(database, `objects/${object.uuid}/needsUpdate`), false)
                            .then(() => resolve(url)),
                    });
                });

                setObjectThumbnail({ [object.uuid]: { url, custom: false } });
            }
        }
    };

    React.useEffect(() => { fetchObjects() }, [objects]);

    const [fetching, setFetching] = React.useState(false);
    const [cursor, setCursor] = React.useState();

    const fetchMarketplace = async (reset = false) => {
        setFetching(true);

        const queries = [
            where('id', '==', id),
            !free ?
                or(where('price', '==', 0), where('paymentsEnabled', '==', true)) :
                where('price', '==', 0),
        ];

        if (category) queries.push(where('category', '==', category));
        if (fileType) queries.push(where('fileType', '==', fileType));
        if (search) queries.push(where('_title', 'array-contains-any', search.toLowerCase().split(' ')));

        const filters = [orderBy('timestamp', 'desc')];

        if (!reset && cursor) filters.push(startAfter(cursor));
        filters.push(limit(12));

        let _objects = await getDocs(query(collection('objects'), and(...queries), ...filters))
            .then((snapshot) => snapshot.docs.map((doc) => ({ ...doc.data(), uuid: doc.id, ref: doc })))
            .catch(() => []);

        if (_objects.length) {
            setCursor(_objects[_objects.length - 1].ref);
        }
        else {
            setCursor();
            setFetching(false);
            return;
        }

        _objects = await Promise.all(_objects.map((object) => new Promise(async (resolve) => {
            try {
                if (!object.id) return resolve(null);

                const uuid = object.uuid;

                const {
                    category,
                    description,
                    fileType,
                    hasAnimations,
                    id,
                    paymentsEnabled,
                    polycount,
                    price,
                    size,
                    termsAndConditions,
                    title,
                    user,
                    vertices,
                } = object;

                let ref = await listAll(storageRef(storage, `objects/${uuid}`));
                let _ref = ref;

                const url = await getDownloadURL(ref.items[0]);

                let type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 1].toLowerCase();
                let zipped = false;
                if (type == 'zip') {
                    zipped = true;
                }

                if (ref.prefixes[0]) ref = await listAll(ref.prefixes[0]);

                const download = await getDownloadURL(ref.items[0]);

                resolve({
                    uuid,
                    fileType,
                    size,
                    polycount,
                    vertices,
                    hasAnimations,
                    thumbnail: objects?.find((object) => object.uuid == uuid)?.thumbnail,
                    zipped,
                    ref: _ref.items[0],
                    url,
                    download,
                    id,
                    title,
                    category,
                    description,
                    termsAndConditions,
                    price,
                    paymentsEnabled,
                    user,
                });
            } catch (e) {
                resolve(null);
            };
        })))
            .then((__objects) => __objects.filter((object) => object));
        setObjects((reset ? [] : objects).concat(_objects));
        if (!initialized) setInitialized(true);

        setFetching(false);
    };

    const [sentryRef] = useInfiniteScroll({
        loading: fetching,
        hasNextPage: !!cursor,
        onLoadMore: fetchMarketplace,
    });

    const [waiting, setWaiting] = React.useState(false);

    React.useEffect(() => {
        if (fetching) {
            setWaiting(true);
        }
        else {
            fetchMarketplace(true);
        }
    }, [category, fileType, free, id, search]);

        React.useEffect(() => {
            if (waiting && !fetching) {
                fetchMarketplace(true);
                setWaiting(false);
            }
        }, [fetching]);

    return {
        initialized,
        fetching,
        objects: objects?.map((object) => ({ ...object, thumbnail: objectThumbnails[object.uuid] })),
        objectThumbnails,
        scrollProps: {
            loadingRef: sentryRef,
            loading: fetching,
            hasNextPage: !!cursor,
        },
    };
}