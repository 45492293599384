import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDatabase, useStorage, useUser } from "reactfire";
import { ref as databaseRef, get } from 'firebase/database';
import {
  ref as storageRef,
  getMetadata,
  uploadBytes,
} from 'firebase/storage';
import addDays from "date-fns/addDays"
import addHours from "date-fns/addHours"
import format from "date-fns/format"
import nextSaturday from "date-fns/nextSaturday"
import {
  Archive,
  ArchiveXIcon,
  Trash2,
  CircleDollarSignIcon,
  ArrowRightCircleIcon,
  PlusCircleIcon,
  PaperclipIcon,
  LoaderCircle,
  ArrowLeft,
} from "lucide-react"
import { useChat } from "@chatscope/use-chat";
import short from "short-uuid";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

import { Separator } from "@/components/ui/separator"
import { Switch } from "@/components/ui/switch"
import { Textarea } from "@/components/ui/textarea"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import { ChatList } from "@/components/mail/chat-list"
import { UploadModelsDlg } from "@/components/upload-models-dlg";

import { set, update as updateDatabase, increment } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import { getImages, getUsers } from '@/modules/redux/storage';
import { getUser as getUserData } from '@/modules/redux/user';
import { uuid as generateUuid, multiply } from '@/modules/utils';

const formSchema = z.object({
  amount: z
    .string()
    .refine((amount) => parseFloat(amount)),
  note: z
    .string()
    .max(160, {
      message: "Bio must be no longer than 160 characters.",
    })
    .optional(),
});

export function MailDisplay({ ...props }) {

  const {
    conversation: [conversation, setConversation] = [, () => { }],
    user,
    srcs = {},
    projects: [, fetchProjects] = [{}, () => { }],
    updateChat = () => { },
    isMobile = false,
    onBackClick = () => { },
    upload = () => { },
    projects: [projects] = [[]],
  } = props;

  const database = useDatabase();

  const storage = useStorage();

  const { data: userFirebase } = useUser();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const images = useSelector(getImages);
  const users = useSelector(getUsers);

  const { seller: { paymentsEnabled = false, provider, stripe } = {} } = useSelector(getUserData);

  const {
    currentMessages, conversations, activeConversation, setActiveConversation, sendMessage, getUser, currentMessage, setCurrentMessage,
    sendTyping, setCurrentUser, getConversation, addMessage, messages,
  } = useChat();

  // React.useEffect(() => {
  //   if (activeConversation?.id != conversation) setActiveConversation(conversation);
  // }, [activeConversation, conversation, getConversation(conversation)]);

  const editLabelsOnMessages = async (tag, label, value = true) => {
    const { object, uuid, createdBy } = activeConversation.data;

    const messagesRef = databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`);

    const messages = await get(messagesRef)
      .then((snapshot) => snapshot.val() || [])
      .then((messages) => messages.map((message) => ({ ...message, [label]: { ...(message[label] || {}), [userFirebase.uid]: userFirebase.uid } })));

    await set(messagesRef, messages);

    if (uuid == userFirebase.uid) {
      await set(databaseRef(database, `users/${userFirebase.uid}/${tag}s/${createdBy}/${object.uuid}/${label}`), value);
    }
    else {
      await set(databaseRef(database, `objects/${object.uuid}/${tag}s/${uuid}/${label}`), value);
    }

    updateChat();

    setActiveConversation(null);
    setConversation();
  };

  const onArchiveClick = async () => {
    const { object, uuid, tags, archived } = activeConversation.data;

    if (tags.includes('Comment')) {
      for (const [i, comment] of (object.reviews[uuid].comments || []).entries()) {
        if (!comment.archived) await set(databaseRef(database, `objects/${object.uuid}/reviews/${uuid}/comments/${i}/archived`), !archived);
      }

      await set(databaseRef(database, `objects/${object.uuid}/reviews/${uuid}/archived`), !archived);

      setConversation();
    }
    else {
      await editLabelsOnMessages(
        tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null,
        'archived',
        !archived,
      );
    }
  };

  const onTrashClick = async () => {
    const { object, uuid, tags } = activeConversation.data;

    if (tags.includes('Comment')) {
      for (const [i, comment] of (object.reviews[uuid].comments || []).entries()) {
        if (!comment.archived) await set(databaseRef(database, `objects/${object.uuid}/reviews/${uuid}/comments/${i}/deleted`), true);
      }

      await set(databaseRef(database, `objects/${object.uuid}/reviews/${uuid}/deleted`), true);

      setConversation();

      // const size = await Promise.all(Object.entries(object.reviews || {}).map(([rUuid, r]) => (r.comments || []).filter((c) => c.image).map((comment) => new Promise(async (resolve) => {
      //   try {
      //     await deleteFolder(storageRef(storage, `comments/${object.uuid}/${rUuid}/${comment.image}`));

      //     const size = await getFolderSize(storageRef(storage, `comments/${object.uuid}/${rUuid}/${comment.image}`));

      //     resolve(size);
      //   }
      //   catch (e) { resolve(0) }
      // }))).flat())
      //   .then((sizes) => sizes.reduce((sum, size) => sum + size, 0));

      // await Promise.all([
      //   set(databaseRef(database, `users/${userFirebase.uid}/fileSpaceUsed`), increment(-size)),
      //   set(databaseRef(database, `objects/${object.uuid}/reviews/${uuid}`), null),
      // ]);

      // fetchProjects();
    }
    else {
      await editLabelsOnMessages(tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null, 'deleted');
    }
  };

  const onProjectClick = () => {
    const lastMessage = activeConversation.data.object.reviews[activeConversation.data.object.reviews.length - 1];

    navigate(
      `/viewer?token=${activeConversation.data.object.token}${lastMessage ? `&review=${lastMessage.uuid}` : ''}`,
      { state: { back: pathname.split('/')[1] != 'messages' ? `/${pathname.split('/')[1]}` : '' } },
    );
  };

  const [uploading, setUploading] = React.useState(false);

  const handleFilesUpload = async (files) => {
    if (!files.length) return;

    upload([...files], async (_objects) => {
      const _uuid = generateUuid();
      const timestamp = Date.now();

      const { data: { token } } = await httpsCallable('encrypt')({
        public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
        payload: { project: _uuid, timestamp },
      }).catch((e) => ({ data: {} }));

      const redirect = short.generate();

      await Promise.all(_objects.map((object) => httpsCallable('encrypt')({
        public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
        payload: {
          ...{ object: object.uuid, project: _uuid, timestamp },
        },
      }).then(({ data }) => {
        const _redirect = short.generate();

        const lights = {
          [generateUuid()]: { type: 'AmbientLight', color: '#ffffff', intensity: object.originalType == 'fbx' ? .6 : .3 },
          [generateUuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: object.dimensions / 2, z: 0 } },
          [generateUuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: -object.dimensions / 2, z: 0 } },
        };
        if (object.includeDefaultEnvMap) lights.Default = { type: 'Environment', uuid: 'Default' };

        return Promise.all([
          updateDatabase(databaseRef(database, `objects/${object.uuid}`), {
            token: data.token,
            redirect: _redirect,
            lights,
            needsUpdate: false,
            name: object.name,
            size: object.size,
            autoplay: false,
          }),
          set(databaseRef(database, `redirects/${_redirect}`), `/viewer?token=${data.token}`),
        ]);
      })));

      await Promise.all([
        set(databaseRef(database, `projects/${_uuid}`), {
          createdAt: timestamp,
          createdBy: userFirebase.uid,
          objects: Object.fromEntries(_objects.map((object) => [object.uuid, object.metadata.name])),
          token,
          redirect,
          status: 'todo',
        }),
        set(databaseRef(database, `redirects/${redirect}`), `/project?token=${token}`),
      ]);

      const { tags, object, uuid } = activeConversation.data;

      if (tags.includes('Commission')) {
        const messagesRef = databaseRef(database, `messages/${uuid}/commissions/${object.uuid}`);

        const messages = await get(messagesRef).then((snapshot) => snapshot.val() || []);

        await set(messagesRef, messages.concat([{
          timestamp: Date.now(),
          link: `${window.location.origin}/${redirect}`,
          user: userFirebase.uid,
        }]));
      }
      else if (tags.includes('Edit Request')) {
        const messagesRef = databaseRef(database, `messages/${uuid}/editRequests/${object.uuid}`);

        const messages = await get(messagesRef).then((snapshot) => snapshot.val() || []);

        await set(messagesRef, messages.concat([{
          timestamp: Date.now(),
          link: `${window.location.origin}/${redirect}`,
          user: userFirebase.uid,
        }]));
      }

      await Promise.all([
        set(databaseRef(database, `users/${userFirebase.uid}/projects/${_uuid}`), _uuid),
        set(databaseRef(database, `users/${userFirebase.uid}/fileSpaceUsed`), increment(_objects.reduce((sum, object) => sum + object.size || 0, 0))),
      ]);

      setUploading(false);
    });
  };

  const lastMessage = (() => {
    const cMessages = messages[activeConversation?.id]?.reduce((arr, g) => arr.concat(g.messages), []);
    const lastMessage = cMessages?.[cMessages?.length - 1];

    return { ...(lastMessage?.content || {}), tags: activeConversation?.data.tags };
  })();

  const recipient = activeConversation?.data.tags.includes('Comment') ? null :
    activeConversation?.data.uuid == user?.uuid ? activeConversation?.data.createdBy : activeConversation?.data.uuid;


  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onSubmit",
  });

  const [creatingPaymentLink, setCreatingPaymentLink] = React.useState({ open: false, loading: false });

  const handlePaymentRequestOpenChange = (open) => setCreatingPaymentLink({ ...creatingPaymentLink, open });

  const handlePaymentRequestSubmit = async ({ amount, note }) => {
    const { object, uuid, tags, createdBy } = activeConversation.data;

    const { stripe = {}, paypal = {} } = await get(databaseRef(database, `users/${createdBy}/seller`)).then((snapshot) => snapshot.val());

    const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

    setCreatingPaymentLink({ open: true, loading: true });

    let checkoutSession = {};
    if (stripe.paymentsEnabled) {
      const { data: { token } } = await httpsCallable('encrypt')({
        public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
        payload: { [tag]: { object: object.uuid, user: createdBy }, timestamp: Date.now() },
      }).catch((e) => ({ data: {} }));

      const { data: _checkoutSession } = await httpsCallable('createCheckoutSession')({
        products: [{
          name: object.title.substring(0, 22),
          default_price_data: {
            currency: 'usd',
            unit_amount: Math.ceil(parseFloat(amount) * 100),
          },
          expand: ['default_price'],
          metadata: { uuid: object.uuid, accountId: stripe.accountId },
        }],
        provider: 'stripe',
        // transferGroup,
        successUrl: `${window.location.origin}/messages?token=${token}`,
        cancelUrl: `${window.location.origin}/messages?token=${token}`,
        metadata: { ref: `messages/${uuid}/${tag}s/${object.uuid}` },
      });

      checkoutSession = _checkoutSession;
    }
    else if (paypal.paymentsEnabled) {
      const { data: _checkoutSession } = await httpsCallable('createCheckoutSession')({
        products: [{
          reference_id: object.uuid,
          amount: {
            name: `${object.name.substring(0, 22 - 1 - tag.length)} ${tag}`,
            description: note,
            currency_code: 'USD',
            value: Math.ceil(parseFloat(multiply(1.1, amount)) * 100) / 100,
          },
          payee: { merchant_id: paypal.merchantId },
          payment_instruction: {
            platform_fees: [{
              amount: {
                currency_code: 'USD',
                value: Math.ceil(parseFloat(multiply(.10, amount)) * 100) / 100,
              },
            }],
          },
        }],
        provider: 'paypal',
      });

      checkoutSession = _checkoutSession;
    }

    const messagesRef = databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`);

    const messages = await get(messagesRef).then((snapshot) => snapshot.val() || []);

    await set(messagesRef, messages.concat([{ checkoutSession, timestamp: Date.now(), user: userFirebase.uid, note, unread: true }]));

    setCreatingPaymentLink({ open: false, loading: false });

    const from = createdBy == userFirebase.uid ? users[createdBy] : users[uuid];
    const to = createdBy == userFirebase.uid ? users[uuid] : users[createdBy];

    const sendEmailNotification = await get(databaseRef(database, `users/${to.uid}/notifications/payment`)).then((snapshot) => !!snapshot.val());

    if (sendEmailNotification) {
      const { data: { token: emailToken } } = await httpsCallable('encrypt')({
        public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
        payload: { [tag]: { object: object.uuid, user: from.uid }, timestamp: Date.now() },
      }).catch((e) => ({ data: {} }));

      await httpsCallable('sendEmail')({
        from: { name: 'Modelsend', email: 'noreply@news.modelsend.com' },
        to: {
          name: to.displayName,
          email: to.email,
        },
        subject: `You received a new payment request`,
        html: `
          <div>
            <p>Hello,</p>
            <p>You received a new payment request from ${from.displayName || from.email}. <a href="${window.location.origin}/messages?token=${emailToken}">View in Modelsend.</a></p>
            <p>Thanks,</p>
            <p>Your Modelsend team</p>
          </div>`,
      }).catch(() => null);
    }
  };

  const handleAttachmentClick = () => document.getElementById('attachment').click();

  const handleAttachmentUpload = async () => {
    if (!document.getElementById('attachment').files || document.getElementById('attachment').files.length < 1) return;

    const file = document.getElementById('attachment').files[0];

    const _uuid = generateUuid();

    const { object, uuid, createdBy, tags } = activeConversation.data;

    if (tags.includes('Comment')) {
      const lastComment = object.reviews[Object.keys(object.reviews)[Object.keys(object.reviews).length - 1]];

      const fileRef = storageRef(storage, `comments/${object.uuid}/${lastComment.uuid}/${_uuid}/${file.name}`);

      const _file = await uploadBytes(fileRef, file);

      const metadata = await getMetadata(_file.ref);
      await set(databaseRef(database, `users/${userFirebase.uid}/fileSpaceUsed`), increment(metadata.size));

      const note = { image: _uuid, timestamp: Date.now(), user: userFirebase.uid };

      const comments = [...(lastComment.comments || []), note];

      await set(databaseRef(database, `objects/${object.uuid}/reviews/${lastComment.uuid}/comments`), comments);

      fetchProjects();
    }
    else {
      const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

      if (!tag) return;

      const fileRef = storageRef(storage, `comments/${object.uuid}/${uuid}/${_uuid}/${file.name}`);

      const _file = await uploadBytes(fileRef, file);

      // const metadata = await getMetadata(_file.ref);
      // await set(databaseRef(database, `users/${userFirebase.uid}/fileSpaceUsed`), increment(metadata.size));

      const messagesRef = databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`);

      const messages = await get(messagesRef).then((snapshot) => snapshot.val() || []);

      await Promise.all([
        set(messagesRef, messages.concat([{ image: _uuid, timestamp: Date.now(), user: userFirebase.uid, unread: true }])),
        set(databaseRef(database, `users/${uuid}/${tag}s/${createdBy}/${object.uuid}/archived`), false),
        set(databaseRef(database, `objects/${object.uuid}/${tag}s/${uuid}/archived`), false),
      ]);

      updateChat();

      const from = createdBy == userFirebase.uid ? users[createdBy] : users[uuid];
      const to = createdBy == userFirebase.uid ? users[uuid] : users[createdBy];

      const sendEmailNotification = await get(databaseRef(database, `users/${to.uid}/notifications/message`)).then((snapshot) => !!snapshot.val());

      if (sendEmailNotification) {
        const { data: { token } } = await httpsCallable('encrypt')({
          public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
          payload: { [tag]: { object: object.uuid, user: from.uid }, timestamp: Date.now() },
        }).catch((e) => ({ data: {} }));

        await httpsCallable('sendEmail')({
          from: { name: 'Modelsend', email: 'noreply@news.modelsend.com' },
          to: {
            name: to.displayName,
            email: to.email,
          },
          subject: `You received a new attachment`,
          html: `
          <div>
            <p>Hello,</p>
            <p>You received a new attachment from ${from.displayName || from.email}. <a href="${window.location.origin}/messages?token=${token}">View in Modelsend.</a></p>
            <p>Thanks,</p>
            <p>Your Modelsend team</p>
          </div>`,
        }).catch(() => null);
      }
    }
  };

  const handleSend = async (e) => {
    if (e.key && (e.key != 'Enter' || e.shiftKey)) return;

    const text = document.getElementById('message').value;

    if (!text) return;

    const { tags, object, uuid, createdBy } = activeConversation.data;

    if (tags.includes('Comment')) {
      await set(databaseRef(database, `objects/${object.uuid}/reviews/${uuid}/comments/${object.reviews[uuid].comments.length}`), {
        text,
        timestamp: Date.now(),
        user: userFirebase.uid,
      });

      fetchProjects();
    }
    else {
      const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

      const messagesRef = databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`);

      const messages = await get(messagesRef).then((snapshot) => snapshot.val() || []);

      await Promise.all([
        set(messagesRef, messages.concat([{ timestamp: Date.now(), text, user: userFirebase.uid, unread: true }])),
        set(databaseRef(database, `users/${uuid}/${tag}s/${createdBy}/${object.uuid}/archived`), false),
        set(databaseRef(database, `objects/${object.uuid}/${tag}s/${uuid}/archived`), false),
      ]);

      document.getElementById('message').value = '';

      const from = createdBy == userFirebase.uid ? users[createdBy] : users[uuid];
      const to = createdBy == userFirebase.uid ? users[uuid] : users[createdBy];

      const sendEmailNotification = await get(databaseRef(database, `users/${to.uid}/notifications/message`)).then((snapshot) => !!snapshot.val());

      if (sendEmailNotification) {
        const { data: { token } } = await httpsCallable('encrypt')({
          public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
          payload: { [tag]: { object: object.uuid, user: from.uid }, timestamp: Date.now() },
        }).catch((e) => ({ data: {} }));

        await httpsCallable('sendEmail')({
          from: { name: 'Modelsend', email: 'noreply@news.modelsend.com' },
          to: {
            name: to.displayName,
            email: to.email,
          },
          subject: `You received a new message`,
          html: `
          <div>
            <p>Hello,</p>
            <p>You received a new message from ${from.displayName || from.email}. <a href="${window.location.origin}/messages?token=${token}">View in Modelsend.</a></p>
            <p>Thanks,</p>
            <p>Your Modelsend team</p>
          </div>`,
        }).catch(() => null);
      }
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center p-2" style={{ height: '3.25rem' }}>
        {isMobile && (
          <Button variant="ghost" size="icon" onClick={onBackClick}>
            <ArrowLeft className="h-4 w-4" />
          </Button>
        )}
        {activeConversation && <div className="flex items-center gap-2">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" size="icon" onClick={onArchiveClick}>
                {activeConversation.data.archived ? <ArchiveXIcon className="h-4 w-4" /> : <Archive className="h-4 w-4" />}
                <span className="sr-only">{activeConversation.data.archived ? 'Unarchive' : 'Archive'}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>{activeConversation.data.archived ? 'Unarchive' : 'Archive'}</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" size="icon" onClick={onTrashClick}>
                <Trash2 className="h-4 w-4" />
                <span className="sr-only">Move to trash</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Move to trash</TooltipContent>
          </Tooltip>
        </div>}
        <div className="ml-auto flex items-center gap-2">
          {!activeConversation?.data?.tags?.includes('Comment') &&
            activeConversation?.data?.createdBy == userFirebase?.uid &&
            paymentsEnabled && <Dialog open={creatingPaymentLink.open} onOpenChange={handlePaymentRequestOpenChange}>
              <DialogTrigger asChild>
                <Button variant="outline">
                  <CircleDollarSignIcon className="mr-2 w-[18px] h-[18px]" />
                  Request Payment
                </Button>
              </DialogTrigger>
              <DialogContent className="mx-auto max-w-sm">
                <DialogHeader className="flex items-center">
                  <DialogTitle className="text-xl">Request Payment</DialogTitle>
                  <Avatar>
                    <AvatarImage src={images[activeConversation?.data.uuid]?.url} className="object-cover" />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  {/* {!getUser(activeConversation?.data.uuid) ? 'Guest' : getUser(activeConversation?.data.uuid)?.email?.charAt(0).toUpperCase()} */}
                  <div>{users[activeConversation?.data.uuid]?.displayName}</div>
                </DialogHeader>
                <div className="grid gap-2">
                  <Form {...form}>
                    <form onSubmit={form.handleSubmit(handlePaymentRequestSubmit)} className="space-y-4">
                      <FormItem>
                        <div className="flex items-center">
                          $
                          <FormField
                            control={form.control}
                            name="amount"
                            defaultValue=""
                            render={({ field }) => (
                              <Input
                                id="amount"
                                type="number"
                                placeholder="0.00"
                                required
                                className="px-1"
                                // onChange={(e) => { if (e.nativeEvent.data != '-') setPrice(e.target.value) }}
                                {...field} />
                            )} />
                        </div>
                        <FormMessage />
                      </FormItem>
                      <FormItem>
                        <FormField
                          control={form.control}
                          name="note"
                          defaultValue=""
                          render={({ field }) => (
                            <Textarea
                              id="note"
                              placeholder="Write a note"
                              // onChange={(e) => { if (e.nativeEvent.data != '-') setPrice(e.target.value) }}
                              {...field} />
                          )} />
                        <FormMessage />
                      </FormItem>
                      <Button type="submit" disabled={creatingPaymentLink.loading} className="w-full mt-4">
                        {creatingPaymentLink.loading ? <LoaderCircle className="animate-spin w-6 h-6" /> : 'Request'}
                      </Button>
                    </form>
                  </Form>
                </div>
              </DialogContent>
            </Dialog>}
          {activeConversation?.data.tags.includes('Comment') ? <Button variant="outline" onClick={onProjectClick}>
            <ArrowRightCircleIcon className="mr-2 w-[18px] h-[18px]" />Go to Project
          </Button> : activeConversation && activeConversation?.data.uuid != userFirebase?.uid ? <UploadModelsDlg
            multiple
            open={[uploading, setUploading]}
            onFilesUpload={handleFilesUpload}
            {...props}>
            <Button variant="outline">
              <PlusCircleIcon className="mr-2 w-[18px] h-[18px]" />Add Project
            </Button>
          </UploadModelsDlg> : null}
        </div>
      </div>
      <Separator />
      {activeConversation ? (
        <div className="flex flex-1 flex-col overflow-y-auto">
          <div className="flex-none flex items-start p-4">
            <div className="flex items-start gap-4 text-sm">
              <Avatar>
                <AvatarImage src={srcs[activeConversation.data.object.thumbnail]?.url} alt={activeConversation.data.object.name} className="object-cover" />
                <AvatarFallback></AvatarFallback>
              </Avatar>
              <div className="grid gap-1">
                <div className="font-semibold">{activeConversation.data.tags.includes('Comment') ?
                `${projects.find(({ uuid }) => uuid == activeConversation.data.object.project).name} (${activeConversation.data.object.name})` :
                `${activeConversation.data.object.title} (${activeConversation.data.object.name})`}</div>
                <div className="line-clamp-1 text-xs">{
                  activeConversation.data.tags.includes('Comment') ? 'Comment' :
                    activeConversation.data.tags.includes('Commission') ? 'Commission' :
                      activeConversation.data.tags.includes('Edit Request') ? 'Edit Request' : null}
                </div>
              </div>
            </div>
            <div className="ml-auto text-xs text-muted-foreground">
              {lastMessage.timestamp && format(new Date(lastMessage.timestamp), "PPpp")}
            </div>
          </div>
          <Separator />
          <div className="flex-1 whitespace-pre-wrap p-4 text-sm overflow-y-auto">
            <ChatList {...props} />
          </div>
          <Separator className="mt-auto" />
          {!activeConversation.data.archived && <div className="flex-none p-4">
            <div className="grid gap-4">
              <Textarea
                id="message"
                className="p-4"
                placeholder={recipient ? `Reply to ${users[recipient]?.displayName || 'message'}...` : 'Reply to comment...'}
                onKeyDown={handleSend} />
              <div className="flex items-center justify-end">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button size="icon" onClick={handleAttachmentClick}>
                      <PaperclipIcon className="h-4 w-4" />
                      <input
                        id="attachment"
                        accept="image/*"
                        type="file"
                        hidden
                        onChange={handleAttachmentUpload} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Send attachment</TooltipContent>
                </Tooltip>
                <Button
                  onClick={handleSend}
                  // size="sm"
                  className="h-9 ml-2">
                  Send
                </Button>
              </div>
            </div>
          </div>}
        </div>
      ) : (
        <div className="p-8 text-center text-muted-foreground">
          No message selected
        </div>
      )}
    </div>
  )
}
