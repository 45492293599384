import React from 'react';
import { useSelector } from 'react-redux';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { useChat } from "@chatscope/use-chat";
import _ from "lodash";

import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"

import { set } from '@/modules/firebase/database';
import { getUsers } from '@/modules/redux/storage';

export function MailList({
  archived = false,
  search,
  conversation: [, setConversation] = [, () => { }],
  srcs = {},
  updateChat = () => { },
  onMailClick = () => { },
  storage: {
    conversations: storageConversations,
  },
  isGenerating = false,
  projects: [projects] = [[]],
}) {

  const database = useDatabase();

  const { data: userFirebase } = useUser();

  const users = useSelector(getUsers);

  const {
    activeConversation,
    messages,
    setActiveConversation,
    getConversation,
    getUser,
  } = useChat();

  const [conversations, setConversations] = React.useState([]);

  React.useEffect(() => {
    if (!isGenerating) setConversations(
      storageConversations.filter((c) => {
        if (archived) {
          return getConversation(c.id)?.data.archived;
        }
        else {
          return !getConversation(c.id)?.data.archived;
        }
      })
        .filter((c) => {
          const cMessages = messages[c.id]?.reduce((arr, g) => arr.concat(g.messages), []);
          const lastMessage = cMessages?.[cMessages?.length - 1];

          const user = users[lastMessage?.content?.user || c.data.uuid];

          return search ? user?.displayName.toLowerCase().includes(search.trim().toLowerCase()) ||
            c.data.object.name.toLowerCase().includes(search.trim().toLowerCase()) : true;
        })
        .filter((c) => {
          const cMessages = messages[c.id]?.reduce((arr, g) => arr.concat(g.messages), []);
          const lastMessage = cMessages?.[cMessages?.length - 1];

          return !lastMessage && !c.data.tags.includes('Comment') ? c.data.uuid == userFirebase?.uid : true;
        })
        .toSorted((a, b) => {
          const getTimestamp = (c) => {
            const cMessages = messages[c.id]?.reduce((arr, g) => arr.concat(g.messages), []);
            const lastMessage = cMessages?.[cMessages?.length - 1];

            return Date.parse(lastMessage?.createdTime) || Date.now();
          };

          return getTimestamp(b) - getTimestamp(a);
        })
    );
  }, [isGenerating]);

  const handleMailClick = async (c) => {
    onMailClick();
    setConversation(c?.id);

    const cMessages = messages[c.id]?.reduce((arr, g) => arr.concat(g.messages), []);
    const lastMessage = cMessages?.[cMessages?.length - 1];

    if (lastMessage?.content.unread && lastMessage?.content.user != userFirebase.uid) {
      const { tags, object, uuid } = c.data;

      const tag = tags.includes('Commission') ? 'commission' : tags.includes('Edit Request') ? 'editRequest' : null;

      const messages = await get(databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}`)).then((snapshot) => snapshot.val() || []);

      await Promise.all(messages.map((message, i) =>
        message.unread ? set(databaseRef(database, `messages/${uuid}/${tag}s/${object.uuid}/${i}/unread`), false) : Promise.resolve(),
      ));

      // updateChat();
    }
  };

  return (
    <div className="flex flex-col gap-2 p-4 pt-0">
      {conversations.map((c) => {
        // Helper for getting the data of the first participant
        const [name, subject, lastMessage, cMessages] = (() => {

          const cMessages = messages[c.id]?.reduce((arr, g) => arr.concat(g.messages), []);
          const lastMessage = cMessages?.[cMessages?.length - 1];

          const userData = users[lastMessage?.content.user || c.data.uuid];

          return [
            userData?.displayName,
            c.data.tags.includes('Comment') ?
              `${projects.find(({ uuid }) => uuid == c.data.object.project).name} (${c.data.object.name})` :
              `${c.data.object.title} (${c.data.object.name})`,
            { ...(lastMessage?.content || {}), tags: c.data.tags },
            cMessages?.map((m) => m.content) || [],
          ];
        })();

        return (
          <button
            key={c.id}
            className={cn(
              "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
              activeConversation?.id === c.id && "bg-muted"
            )}
            onClick={() => handleMailClick(c)}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{name}</div>
                  {lastMessage.unread && (
                    <span className="flex h-2 w-2 rounded-full bg-blue-600" />
                  )}
                </div>
                <div
                  className={cn(
                    "ml-auto text-xs",
                    activeConversation?.id === c.id
                      ? "text-foreground"
                      : "text-muted-foreground"
                  )}
                >
                  {lastMessage.timestamp && formatDistanceToNow(new Date(lastMessage.timestamp), {
                    addSuffix: true,
                  })}
                </div>
              </div>
              <div className="text-xs font-medium h-4 break-all overflow-hidden">{subject}</div>
            </div>
            <div className="text-xs text-muted-foreground h-4 break-all overflow-hidden" style={{ width: 'inherit', maxWidth: 'inherit' }}>
              {lastMessage.checkoutSession && ((lastMessage.checkoutSession.provider == 'stripe' && lastMessage.checkoutSession.receiptUrl && cMessages.find((m) => m.checkoutSession?.id == lastMessage.checkoutSession.id && m.checkoutSession?.url)) ||
                (lastMessage.checkoutSession.provider == 'paypal' && lastMessage.checkoutSession.paymentId) ? 'Payment successful' : 'Payment Request')}
              {lastMessage.image && srcs[`comments/${lastMessage.object}/${lastMessage.uuid}/${lastMessage.image}`]?.name}
              {lastMessage.text && lastMessage.text.substring(0, 300)}
            </div>
            {c.data.tags.length ? (
              <div className="flex items-center gap-2">
                {c.data.tags.map((tag) =>
                  <Badge key={tag} variant={getBadgeVariantFromLabel(tag)}>
                    {tag}
                  </Badge>
                )}
              </div>
            ) : null}
          </button>
        );
      })}
    </div>
  )
}

function getBadgeVariantFromLabel(
  label
) {
  if (["edit request"].includes(label.toLowerCase())) {
    return "default"
  }

  if (["comment"].includes(label.toLowerCase())) {
    return "outline"
  }

  return "secondary"
}
