import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import {
  getDatabase,
  ref as databaseRef,
  get,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  deleteObject as deleteStorageObject,
  listAll,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';
import { WebIO } from '@gltf-transform/core';
import { ALL_EXTENSIONS } from '@gltf-transform/extensions';
import { draco, listTextureInfoByMaterial, listTextureInfo, getTextureColorSpace } from '@gltf-transform/functions';
import {
  AmbientLight,
  AnimationMixer,
  ArrowHelper,
  Box3,
  Box3Helper,
  BoxGeometry,
  BufferAttribute,
  BufferGeometry,
  CanvasTexture,
  Clock,
  Color,
  CubeRefractionMapping,
  CubeTextureLoader,
  DirectionalLight,
  DoubleSide,
  Euler,
  EquirectangularReflectionMapping,
  EquirectangularRefractionMapping,
  Frustum,
  GridHelper,
  Group,
  HemisphereLight,
  LinearToneMapping,
  LinearSRGBColorSpace,
  LoadingManager,
  MathUtils,
  Matrix3,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshDepthMaterial,
  MeshNormalMaterial,
  MeshLambertMaterial,
  MeshMatcapMaterial,
  MeshPhongMaterial,
  MeshToonMaterial,
  MeshStandardMaterial,
  MeshPhysicalMaterial,
  MixOperation,
  NearestFilter,
  Object3D,
  PerspectiveCamera,
  PMREMGenerator,
  PlaneGeometry,
  PointLight,
  Quaternion,
  RepeatWrapping,
  Raycaster,
  REVISION,
  Scene,
  ShaderMaterial,
  SphereGeometry,
  SpotLight,
  SRGBColorSpace,
  TextureLoader,
  UnsignedByteType,
  Vector3,
  Vector2,
  VideoTexture,
  WebGLRenderer,
  WebGLRenderTarget,
  SrcAlphaFactor,
  DirectionalLightHelper,
  SpotLightHelper,
  PointLightHelper,
  HemisphereLightHelper,
  LinearFilter,
  RGBAFormat,
  FloatType,
  CustomBlending,
  EdgesGeometry,
  LineSegments,
  LineBasicMaterial,
  Vector4,
  SkeletonHelper,
  FileLoader,
  ReinhardToneMapping,
  ExtrudeGeometry,
  ShapeGeometry,
} from 'three';
import {
  // StaticGeometryGenerator,
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from 'three-mesh-bvh';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

import { OBB } from 'three/examples/jsm/math/OBB.js';

import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
// import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
import { IFCLoader } from 'web-ifc-three/IFCLoader.js';

import { OrbitControls } from './controls/OrbitControls.js';

import { BloomEffect, EffectComposer, EffectPass, RenderPass, SelectiveBloomEffect, GammaCorrectionEffect, BlendFunction } from 'postprocessing';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
// import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';

import { createMeshesFromMultiMaterialMesh } from 'three/examples/jsm/utils/SceneUtils.js';

import QRCode from 'qrcode';
import _ from 'lodash';
import { isMobile, isSafari, isWindows } from 'react-device-detect';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

import { DRACOExporter } from './exporters/DRACOExporter.js';
import { GLTFLoader } from './loaders/GLTFLoader.js';
import { GLTFExporter } from './exporters/GLTFExporter.js';
import { FreeControls } from './FreeControls.js';
import { FullStopControls } from './FullStopControls.js';
import { SphereControls } from './SphereControls.js';
// import { GLTFLoader } from './loaders/GLTFLoader.js';
// import { DRACOLoader } from './loaders/DRACOLoader.js';
import { ColladaLoader } from './loaders/ColladaLoader.js';
import { FBXLoader } from './loaders/FBXLoader.js';
import { Rhino3dmLoader } from './loaders/3DMLoader.js';
import { OBJLoader } from './loaders/OBJLoader.js';
import { OutlinePass } from './OutlinePass.js';
import { StaticGeometryGenerator } from './StaticGeometryGenerator.js';

import placeholder from './assets/textures/placeholder.png';
import uvMapImg from './assets/textures/uv_grid_opengl.jpg';
import defaultRoom from './assets/models/default.glb';
import frameFile from './assets/models/frame.glb';
import pedestalFile from './assets/models/pedestal.glb';
import placardFile from './assets/models/placard.glb';

import { set } from 'modules/firebase/database';
import store from 'modules/redux/store';
import { deleteFolder, getFolderSize, getPayloadFromToken, flipImage, uuid, unzip } from 'modules/utils';
import { compressModel as compressModelUtils, addWatermark } from './utils.js';

// Used for environment loading
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

let scene, scene2, sceneAnimated;
const setScene = createAction('threejs/setScene');

let domElement;

let renderer, maxAnisotropy;

let cssRenderer;
const cssRendererZIndex = 3;

let renderer2;
const css3dScale = 100;
const css3dObjectStyle = {
  height: '100px',
  width: '200px',
  fontSize: '15px',
};

const fov = 45;
const aspect = 2;  // the canvas default
const near = 0.00001;
const far = 10000;
const camera = new PerspectiveCamera(fov, aspect, near, far);
camera.layers.enable(1);
let threshold = Infinity;
let effectComposer, renderPass, outlinePass, selectiveBloom;
let background = '#e5e5e5';

let mixer, animations, animationsDuration = 0;
const clock = new Clock();
const setAnimationFrame = createAction('threejs/setAnimationFrame');

const textureLoader = new TextureLoader();

// Used for environment loading
const rgbeLoader = new RGBELoader();
const exrLoader = new EXRLoader();

let envMap, includeDefaultEnvMap;

const mouse = new Vector2();
const raycaster = new Raycaster();
raycaster.firstHitOnly = true;
let skinnedMeshHelper;

let controls, transformControls, transformControlsListener, transformControlsHelper;
const handleSetLight = createAction('threejs/handleSetLight');
const settingLight = createAction('threejs/settingLight');

let alight, dlight;
let lights = {};

let materials = {};
let textures = {};
let objects = {};
let polycount = 0;
let vertices = 0;
let room;
let grid;
let edges;
let normals;
const keys = ['map', 'matcap', 'specularMap', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'roughnessMap', 'metalnessMap', 'envMap', 'lightMap', 'aoMap', 'gradientMap'];
const properties = ['mipmaps', 'mapping', 'channel', 'wrapS', 'wrapT', 'magFilter', 'minFilter', 'anisotropy', 'format', 'internalFormat', 'type', 'offset', 'repeat', 'rotation', 'center', 'matrixAutoUpdate', 'matrix', 'generateMipmaps', 'premultiplyAlpha', 'flipY', 'unpackAlignment', 'colorSpace'];
const materialKeys = {
  'MeshBasicMaterial': ['map', 'specularMap', 'alphaMap', 'lightMap', 'aoMap'],
  'MeshDepthMaterial': ['map', 'alphaMap', 'displacementMap'],
  'MeshNormalMaterial': ['bumpMap', 'normalMap', 'displacementMap'],
  'MeshLambertMaterial': ['map', 'specularMap', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'lightMap', 'aoMap'],
  'MeshMatcapMaterial': ['map', 'matcap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap'],
  'MeshPhongMaterial': ['map', 'specularMap', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'lightMap', 'aoMap'],
  'MeshToonMaterial': ['map', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'lightMap', 'aoMap', 'gradientMap'],
  'MeshStandardMaterial': ['map', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'roughnessMap', 'metalnessMap', 'lightMap', 'aoMap'],
  'MeshPhysicalMaterial': ['map', 'emissiveMap', 'alphaMap', 'bumpMap', 'normalMap', 'displacementMap', 'roughnessMap', 'metalnessMap', 'lightMap', 'aoMap'],
};
export const setMaps = createAction('threejs/setMaps');

const basicObjects = {};
const stackObjects = {};
const wallObjects = {};
const ceilingObjects = {};
const floorObjects = {};
let noteObjects = {};
let cachedObjects = {};
export const setNotes = createAction('threejs/setNotes');

let currObject = null;
export const setCurrObject = createAction('threejs/setCurrObject');

let currWallIndex = -1;

const rotateDelta = Math.PI / 16;

let setNotesInterval, setNotesBoolean;
let videoWaiting = false; // Used for when video autoplay is disabled, eg. in Chrome
let renderVideo = false;
let animationFrame;
let pauseTime = 0;
const videoTexturesPlaying = {};
const videoTextures = {};
const useRequestAnimationFrame = false;//!isWindows && !isSafari;

let pedestal;

let uvMapMesh, uvMap = null;
textureLoader.load(uvMapImg, (map) => { uvMapMesh = new MeshBasicMaterial({ map, side: DoubleSide }) });

const render = (orbitControl = false) => {
  if (!scene || !camera || !room) return;

  if (domElement) {
    const width = domElement.clientWidth;
    const height = domElement.clientHeight;
    const needResize = renderer.domElement.width != width || renderer.domElement.height != height;
    if (needResize) {
      renderer.setSize(width, height, false);
      if (cssRenderer) cssRenderer.setSize(width, height);
      // renderer2.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    }
  }

  const timeDelta = clock.getDelta();

  if (mixer) mixer.update(timeDelta);

  // if (controls) {
  //   console.log('Direction: ', new Vector3().subVectors( controls.target, camera.position ).normalize() );
  // }
  // if (camera) console.log('Camera Position: ', camera.position);

  if (renderer) {
    if (orbitControl) {
      Object.values(noteObjects).map((note) => {
        const rayDirection = new Vector3().subVectors(note.userData.position, camera.position).normalize();
        const rayDistance = camera.position.distanceTo(note.userData.position);
        const noteRaycaster = new Raycaster(camera.position, rayDirection, 0, rayDistance);
        const noteIntersects = noteRaycaster.intersectObjects(skinnedMeshHelper ? [room, skinnedMeshHelper] : [room]);

        note.userData.visible = rayDistance - (noteIntersects?.[0]?.distance || rayDistance) < threshold;
      });

      // if (cssRenderer) cssRenderer.render(scene, camera);

      // store.dispatch(setNotes());
    }

    // console.log(Object.values(noteObjects)[0]?.element.style.transform)

    // Object.values(noteObjects).map((note) => {
    //   const rayDirection = new Vector3().subVectors(note.position, camera.position).normalize();
    //   const rayDistance = camera.position.distanceTo(note.position);
    //   const noteRaycaster = new Raycaster(camera.position, rayDirection, 0, rayDistance);
    //   const noteIntersects = noteRaycaster.intersectObject(room);

    //   note.visible = rayDistance - (noteIntersects.find((intersect) => !intersect.object.isLineSegments)?.distance || rayDistance) < threshold;
    // });

    if (scene) {
      // renderer.clear();
      if (effectComposer/*&& outlinePass?.selectedObjects[0]*/) {
        const highlighted = selectiveBloom.selection.values().next().value;
        // room.traverse((object) => {
        //   if (highlighted) return;

        //   if (object.material) {
        //     highlighted = object;

        //   }

        //   if (object.layers.isEnabled(1))
        // });

        if (highlighted) {
          room.traverse((object) => {
            if (object.layers.isEnabled(1)) object.layers.disable(0);
          });

          camera.layers.disable(1);
          camera.layers.enable(11);
          effectComposer.render();

          room.traverse((object) => {
            if (!object.layers.isEnabled(0)) object.layers.enable(0);
          });

          camera.layers.enable(1);

          highlighted.layers.disable(0);
          highlighted.layers.disable(1);
          renderer.render(scene, camera);
          highlighted.layers.enable(0);
          highlighted.layers.enable(1);
        }
        else {
          renderer.render(scene, camera);
        }
      }
      else {
        renderer.render(scene, camera);
      }
      if (cssRenderer) {
        cssRenderer.render(scene, camera);
        // clearTimeout(setNotes);
        setNotesBoolean = true;
        // store.dispatch(setNotes());

        // Object.values(noteObjects).forEach((object) => { object.element.style.zIndex = cssRendererZIndex });
      }
    }

    if (mixer && renderVideo) store.dispatch(setAnimationFrame());
  }
};

const renderOrbitControl = () => render(true);

const requestAnimationFrameOn = () => {
  animate();
};

const requestAnimationFrameOff = () => {
  if (animationFrame) cancelAnimationFrame(animationFrame);
  animationFrame = null;
  render(true);
};

const onResize = () => {
  if (envMap && window.location.pathname != '/viewer') {
    loadEnvironmentToScene(envMap).then(() => render());
  }
  else {
    render();
  }
};


// Update mouse for raycasting
const onMouseMove = (e) => {
  mouse.x = ((e.clientX - domElement.offsetLeft) / domElement.clientWidth) * 2 - 1;
  mouse.y = - ((e.clientY - domElement.offsetTop) / domElement.clientHeight) * 2 + 1;
};

// Detect object on click through raycasting
const onMouseDown = () => {
  if (!scene) return;

  if (transformControls?.object) {
    if (controls?.enabled) {
      transformControls?.detach();
      store.dispatch(settingLight(false));

      if (transformControlsListener) {
        transformControls?.removeEventListener('change', transformControlsListener);
        transformControlsListener = null;
      }

      if (transformControlsHelper) {
        scene.remove(transformControlsHelper);
        transformControlsHelper.dispose();
      }
    }
  }

  if (controls && !controls.enabled) controls.enabled = true;

  onObjectDeselect();
};

const onObjectDeselect = () => {
  if (currObject &&
    (
      noteObjects[currObject.userData.uuid]
    )) {

    // currObject.material.color.set( 0xffffff );
    currObject = null;
    store.dispatch(setCurrObject());

    // render();
  }
};

// Remove selected object from room
const deleteCurrentObject = () => {
  if (!currObject) return;

  deleteObject(currObject);

  currObject = null;
  store.dispatch(setCurrObject());

  render();
};

// Object deletion, used by deleteCurrentObject()
const deleteObject = (object) => {
  scene?.remove(object);
  sceneAnimated?.remove(object);
  disposeObject(object);

  // if (object.userData.css3dObject) {
  //   const css3dObject = scene2.children.find((child) => child.userData.uuid == object.userData.css3dObject.uuid);
  //   scene2.remove(css3dObject);
  //   disposeObject(css3dObject);
  // }
  if (object.name.toLowerCase().includes('canstack')) {
    if (stackObjects[object.userData.uuid]) delete stackObjects[object.userData.uuid];
  } else if (object.name.toLowerCase().includes('wall')) {
    if (wallObjects[object.userData.uuid]) delete wallObjects[object.userData.uuid];
  } else if (object.name.toLowerCase().includes('ceiling')) {
    if (ceilingObjects[object.userData.uuid]) delete ceilingObjects[object.userData.uuid];
  } else if (object.name.toLowerCase().includes('floor')) {
    if (floorObjects[object.userData.uuid]) delete floorObjects[object.userData.uuid];
  } else {
    if (basicObjects[object.userData.uuid]) delete basicObjects[object.userData.uuid];

    Object.values(stackObjects)
      .filter((_object) => _object.userData.stackedOnto == object.userData.uuid)
      .forEach((_object) => deleteObject(_object));
  }
};

// Object dispose, used by deleteCurrentObject()
const disposeObject = (object, options = { disposeMaterial: true, disposeGeometry: true }) => {
  if (!object) return;

  if (object.userData.audio?.src && object.userData.audio?.element) {
    const audio = object.userData.audio.element;

    if (audio.pause) audio.pause();
    audio.src = '';
    if (audio.remove) audio.remove();
  }

  if (options.disposeMaterial === false) {
    if (options.disposeGeometry === false) return;

    object = { geometry: object.geometry, userData: object.userData };
  } else if (options.disposeGeometry === false) {
    if (options.disposeMaterial === false) return;

    object = { material: object.material, userData: object.userData };
  }

  if (object.geometry) object.geometry.dispose();
  if (object.material) Array.isArray(object.material) ? object.material.forEach((material) => material.dispose()) : object.material.dispose();
  const texture = object.material?.map;
  if (texture?.dispose) texture.dispose();
  const element = texture?.source?.data;
  if (texture?.isVideoTexture || texture?.isCanvasTexture) {
    if (!texture?.isComposedTexture) {
      if (element.pause) element.pause();
      if (element.src) element.src = '';
      if (element.children && element.children[0]) {
        const source = element.children[0];
        if (source.pause) source.pause();
        if (source.src) source.src = '';
      }
    } else {
      const objects = Object.values(wallObjects).concat([currObject])
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((_object) => _object && _object.name.toLowerCase().includes('nft'))
        .filter((_object) => _object.children.find((child) => child.material?.name.toLowerCase().includes('paste')))
        .filter((_object) => _object.children.find((child) => child.material?.name.toLowerCase().includes('paste')).material?.map?.isComposedTexture)
        .filter((_object) => _object.children.find((child) => child.material?.name.toLowerCase().includes('paste')).material?.map == texture);

      if (objects.length <= 1) {
        object.material.map.dispose();

        const nfts = store.getState().user.nfts;
        const nft = nfts.find((_nft) => _nft.textures == texture);

        // if (nft) store.dispatch(updateNftTexture({ image: nft.image, texture: null }));
      }
    }

    delete videoTextures[object.userData.uuid];
    delete videoTexturesPlaying[object.userData.uuid];
    if (Object.keys(videoTexturesPlaying).length == 0) {
      if (!useRequestAnimationFrame) clearInterval(renderVideo);
      renderVideo = null;
    }
  }
  if (element?.remove) element.remove();

  object.children?.forEach((child) => disposeObject(child));
};

const loadModel = async (url, type, _renderer, onProgress = () => { }, options = {}) => {
  const loadingManager = new LoadingManager();

  const loader =
    type == 'obj' ? new OBJLoader(loadingManager, options) :
      type == 'fbx' ? new FBXLoader(loadingManager, options) :
        type == '3dm' ? new Rhino3dmLoader(loadingManager, options)
          .setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/') :
          type == 'stl' ? new STLLoader(loadingManager, options) :
            type == 'drc' ? new DRACOLoader(loadingManager, options)
              .setDecoderPath(`${THREE_PATH}/examples/jsm/libs/draco/gltf/`) :
              type == 'ifc' ? new IFCLoader(loadingManager, options) :
                type == 'ply' ? new PLYLoader(loadingManager, options) :
                  type == 'dae' ? new ColladaLoader(loadingManager, options) :
                    new GLTFLoader(loadingManager, options)
                      .setCrossOrigin('anonymous')
                      .setDRACOLoader(new DRACOLoader(loadingManager).setDecoderPath(`${THREE_PATH}/examples/jsm/libs/draco/gltf/`))
                      // .setKTX2Loader(new KTX2Loader(loadingManager).detectSupport(_renderer))
                      .setMeshoptDecoder(MeshoptDecoder);

  if (_renderer && loader.setKTX2Loader) loader.setKTX2Loader(new KTX2Loader(loadingManager).setTranscoderPath(`${THREE_PATH}/examples/jsm/libs/basis/`).detectSupport(_renderer));

  let object, loaded = false, file;
  await new Promise(async (resolve) => {
    loader.manager.onLoad = () => {
      if (object) {
        resolve();
      } else {
        loaded = true;
      }
    };

    let time = Date.now();
    file = await loader.loadAsync(url, (xhr) => {
      if (Date.now() - time >= 100 || xhr.loaded == xhr.total) {
        onProgress(xhr);
        time = Date.now();
      }
    });

    if (type == 'ply') file.computeVertexNormals();

    object =
      ['glb', 'gltf', 'dae'].includes(type) ? file.scene || file.scenes?.[0] :
        ['stl', 'drc', 'ply'].includes(type) ? new Mesh(file, new MeshStandardMaterial()) :
          file;

    if (loaded) resolve();
  });

  loader.dispose?.();

  object.updateMatrixWorld(true);

  if (type == '3dm') object.children = object.children.filter((child) => child.isMesh);

  if (
    ['3dm', 'stl'].includes(type) ||
    (type == 'drc' && url.split('?')[0].split('.')?.[url.split('?')[0].split('.').length - 2] == 'stl')
  ) object.lookAt(0, 1, 0);

  return { object, animations: file?.animations || [] };
};

const getObjectTree = (obj) => {
  if (obj.material) return Array.isArray(obj.material) ? obj.material : [obj.material];

  return obj.children.map((child) => [child.name, getObjectTree(child)]).filter((entry) => entry[1].length > 0);
};

const getTextureProperties = (material) => {
  const _properties = {};

  keys.forEach((key) => {
    _properties[key] = {};

    if (material[key]) {
      _properties[key].index = 0;
      properties.forEach((property) => {
        _properties[key][property] = material[key][property];
      });
    }
  });

  return _properties;
};

let objectUrl;

const loadStandaloneObject = async ({ url, type, onProgress, originalType, defaultLight = false }) => {
  try {
    if (mixer) {
      mixer.stopAllAction();
      delete videoTexturesPlaying[mixer.uuid];
    }

    if (renderVideo) renderVideo = false;

    if (animationFrame) cancelAnimationFrame(animationFrame);
    animationFrame = null;

    scene = new Scene();

    if (type == 'zip') {
      const _unzip = await unzip(url, type);
      url = _unzip[0];
      type = _unzip[1];
    }

    objectUrl = url;

    let { object, animations: _animations } = await loadModel(url, type, renderer, onProgress);

    if (objectUrl != url) return;

    URL.revokeObjectURL(url);

    object.traverse(function (obj) { obj.frustumCulled = false });

    scene.autoUpdate = true;
    scene.userData.uuid = object.userData.uuid || uuid();
    scene.userData.notes = [];

    scene.environment = null;
    scene.background = new Color(defaultLight ? '#e5e5e5' : background);

    object.userData.type = type;
    object.userData.originalType = originalType;
    room = object;
    scene.add(object);

    materials = {};

    edges = [];

    objects = getObjectTree(object);

    const _materials = {};
    let materialId = 0;
    object.traverse((_object) => {
      if (_object.material) {
        _object.userData.material = _object.material;

        materials[_object.uuid] = { name: _object.name, materials: {}, object: _object };
        (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material, i) => {
          material.userData.id = materialId++;
          _materials[material.uuid] = material;

          material.userData.name = `${_object.name}${i == 0 ? '' : ` (${i})`}`;

          material.userData.objectUuid = _object.uuid;

          material.userData.textures = getTextureProperties(material);

          if (originalType == 'obj') material.side = DoubleSide;

          materials[_object.uuid].materials[material.uuid] = material;

          _object.layers.enable(1);
        });
      }
    });

    let _uvMap, _includeDefaultEnvMap = true;
    Object.values(_materials).forEach((material) => {
      if (_includeDefaultEnvMap && (material.isMeshBasicMaterial || material.isMeshPhongMaterial)) _includeDefaultEnvMap = false;

      keys.forEach((key) => {
        if (!defaultLight || !material[key]?.source?.data) material[key] = null;
      });
    });

    if (object.children.length == 1 && object.children[0].userData.vertices && object.children[0].userData.polycount) {
      vertices = object.children[0].userData.vertices;
      polycount = object.children[0].userData.polycount;
    }
    else {
      vertices = 0;
      polycount = 0;

      object.traverse((_object) => {
        if (_object.geometry?.computeBoundsTree) _object.geometry.computeBoundsTree();

        if (_object.isMesh) {

          const geometry = _object.geometry;

          vertices += geometry.attributes.position.count;

          if (geometry.index !== null) {

            polycount += geometry.index.count / 3;

          } else {

            polycount += geometry.attributes.position.count / 3;

          }

        }

        if (typeof _uvMap != 'boolean' && _object.geometry?.attributes.uv) _uvMap = false;
      });
    }

    if (_animations[0]) {
      animations = [..._animations];
      mixer = new AnimationMixer(room);

      animations.forEach((animation) => { mixer.clipAction(animation).enabled = false });

      animationsDuration = Math.max(...animations.map((animation) => animation.duration));
    }

    if (controls) controls.dispose();

    controls = new OrbitControls(camera, domElement || renderer.domElement);
    controls.enabled = true;
    window.addEventListener('resize', onResize);
    // controls.target.copy(object.position);

    const size = new Box3().setFromObject(object).getSize(new Vector3());
    threshold = size.length() / 100;
    camera.near = threshold;
    camera.far = size.length() * 40;

    // controls.target.add(center);
    camera.position
      .copy(controls.target)
      .add(new Vector3(size.length(), size.length() / 2, size.length()));

    controls.update();

    const center = new Box3().setFromObject(object).getCenter(new Vector3());
    object.position.copy(center.negate());

    object.updateMatrixWorld(true);

    if (defaultLight) {
      const alight = new AmbientLight(0xffffff, originalType == 'fbx' ? .6 : .3);
      scene.add(alight);

      const dlight1 = new DirectionalLight(0xffffff, 2.5);
      dlight1.position.set(0, size.length() / 2, 0);
      scene.add(dlight1);

      const dlight2 = new DirectionalLight(0xffffff, 2.5);
      dlight2.position.set(0, -size.length() / 2, 0);
      scene.add(dlight2);

      if (_includeDefaultEnvMap) await loadEnvironmentToScene({ src: 'Default' }, false);
    }

    domElement?.addEventListener('pointerdown', requestAnimationFrameOn);
    domElement?.addEventListener('pointerup', requestAnimationFrameOff);
    domElement?.addEventListener('wheel', renderOrbitControl);

    transformControls = new TransformControls(camera, domElement || renderer.domElement);
    transformControls?.traverse((obj) => { // To be detected correctly by OutlinePass.
      obj.isTransformControls = true;
    });
    transformControls?.addEventListener('change', () => render(false));
    transformControls?.addEventListener('mouseDown', () => {
      controls.enabled = false;
    });
    transformControls?.addEventListener('mouseUp', () => {
      controls.enabled = true;
    });
    // transformControls?.attach(object);
    scene.add(transformControls);

    // await addWatermark(object, scene);
    // camera.far = 30000;

    // normals = [];

    // scene.traverse((_object) => {
    //   if (_object.geometry?.attributes?.normal?.count) {
    //     const normal = new VertexNormalsHelper( _object, .01 * size.length(), 0x0b8ce9 );
    //     normal.visible = false;
    //     normals.push(normal);
    //     scene.add(normal);
    //   }
    // });

    // scene.traverse((_object) => {
    //   if (_object.geometry) {
    //     if (_object.isSkinnedMesh) return;

    //     const edge = new LineSegments(new EdgesGeometry(_object.geometry), new LineBasicMaterial({ color: 0x0b8ce9 }));
    //     edges.push(edge);
    //     scene.add(edge);
    //     edge.applyMatrix4(_object.matrixWorld);
    //     edge.visible = false;
    //   }
    // });

    // if (skinnedMeshHelper) {
    //   const edge = new LineSegments(new EdgesGeometry(skinnedMeshHelper.geometry), new LineBasicMaterial({ color: 0x0b8ce9 }));
    //   scene.add(edge);
    //   edge.visible = false;
    // }

    grid = new GridHelper(size.length() * 2);
    grid.visible = false;
    grid.position
      .copy(controls.target)
      .sub(new Vector3(0, size.y / 2, 0));
    scene.add(grid);

    // if (!isMobile) {
    effectComposer = new EffectComposer(renderer, {
      stencilBuffer: true,
      frameBufferType: FloatType,
    });

    renderPass = new RenderPass(scene, camera);
    renderPass.clear = false;
    effectComposer.addPass(renderPass);

    selectiveBloom = new SelectiveBloomEffect(scene, camera, {
      intensity: 1,
      radius: 1,
      luminanceThreshold: 1,
    });
    selectiveBloom.ignoreBackground = true;
    effectComposer.addPass(new EffectPass(camera, selectiveBloom));
    // }

    setTimeout(render, 1);

    if (window.location.pathname == '/viewer') {
      setNotesInterval = setInterval(() => {
        if (setNotesBoolean) {
          store.dispatch(setNotes());
          setNotesBoolean = false;
        }
      }, 33);

      const skinnedMeshes = [];

      object.traverse((_object) => { if (_object.isSkinnedMesh) skinnedMeshes.push(_object) });

      if (skinnedMeshes.length > 0) {
        const generator = new StaticGeometryGenerator([...skinnedMeshes]);

        skinnedMeshHelper = new Mesh(new BufferGeometry(), new MeshStandardMaterial());
        skinnedMeshHelper.visible = false;
        generator.generate(skinnedMeshHelper.geometry);
        skinnedMeshHelper.geometry.computeBoundsTree();
        scene.add(skinnedMeshHelper);
      }
    }

    uvMap = _uvMap;
    includeDefaultEnvMap = _includeDefaultEnvMap;
  } catch (e) { console.log(e); _resetThreejs(); }
};

const setWireframe = (object, boolean) => {
  object.traverse((_object) => {
    if (_object.material) {
      (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material) => {
        material.wireframe = boolean;
      });
    }
  });

  render();
};

const loadEnvironmentToScene = async ({ src, uuid = 'Default', type }, fromSelf = true, other) => {
  try {
    const oldEnvMap = !other ? envMap : null;

    const _scene = other?.scene || scene;
    let _envMap = !other ? envMap : null;

    if (src) {
      let pmremGenerator;
      if (src == 'Default') {
        pmremGenerator = new PMREMGenerator(other?.renderer || renderer);
        pmremGenerator.compileEquirectangularShader();
      }

      _envMap =
        src == 'Default' ? pmremGenerator.fromScene(new RoomEnvironment()).texture :
          type == 'hdr' ? await rgbeLoader.loadAsync(src) :
            await exrLoader.loadAsync(src);

      _envMap.userData.uuid = uuid;
      if (src != 'Default') _envMap.mapping = EquirectangularReflectionMapping;

      _scene.environment = _envMap;
      if (!other) _scene.background = room?.userData.showBackground ? _envMap : new Color(background);
      if (fromSelf) {
        lights = Object.fromEntries(Object.values(lights).filter((_light) => _light.userData.uuid != oldEnvMap?.uuid).map((_light) => [_light.userData.uuid, _light]));
        lights = { ...lights, [uuid]: { uuid, type: 'Environment', envMap: _envMap, userData: { uuid } } };
      }

      _scene.traverse((object) => {
        if (object.material) {
          if (transformControls?.getObjectById(object.id)) return;

          if (Array.isArray(object.material)) {
            object.material.forEach((material) => {
              material.envMap = _envMap;
              material.needsUpdate = true;
            });
          } else {
            object.material.envMap = _envMap;
            object.material.needsUpdate = true;
          }
        }
      });
    } else {
      if (fromSelf) lights = Object.fromEntries(Object.values(lights).filter((_light) => _light.userData.uuid != oldEnvMap?.uuid).map((_light) => [_light.userData.uuid, _light]));

      _envMap = null;

      _scene.environment = null;
      if (!other) _scene.background = new Color(background);
      _scene.traverse((object) => {
        if (object.material) {
          if (transformControls?.getObjectById(object.id)) return;

          if (Array.isArray(object.material)) {
            object.material.forEach((material) => {
              material.envMap = null;
              material.needsUpdate = true;
            });
          } else {
            object.material.envMap = null;
            object.material.needsUpdate = true;
          }
        }
      });
    }

    if (!other) {
      envMap = { src, uuid, type, map: _envMap };
      // render();
    }
  } catch (e) { console.log(e) }
};

const createNote = (note = {}, fromSelf = true) => {
  if (!scene || !room) return;

  if (fromSelf && currObject) {
    if (stackObjects[currObject.userData.uuid] ||
      wallObjects[currObject.userData.uuid] ||
      ceilingObjects[currObject.userData.uuid] ||
      floorObjects[currObject.userData.uuid] ||
      basicObjects[currObject.userData.uuid] ||
      noteObjects[currObject.userData.uuid]) {

      onObjectDeselect();
    } else deleteCurrentObject();
  }

  const object = new CSS2DObject();
  object.name = `${note.id || ''}_note`;
  object.userData.uuid = note.uuid || uuid();
  // object.userData.id = note.id || '';
  object.userData.comments = note.comments || [];
  object.userData.completed = !!note.completed;
  object.userData.tags = note.tags || [];
  object.userData.visible = true;

  let position = note.position || { x: 0, y: 0, z: 0 };

  const center = new Box3().setFromObject(room).getCenter(new Vector3());

  let attachTo, intersect;
  if (fromSelf) {
    const rayDirection = new Vector3().subVectors(center, camera.position).normalize();
    intersect = new Raycaster(camera.position, rayDirection).intersectObjects(skinnedMeshHelper ? [room, skinnedMeshHelper] : [room]);
  } else {
    const origin = new Vector3(note.camera.x, note.camera.y, note.camera.z);
    const rayDirection = new Vector3().subVectors(position, origin).normalize();
    intersect = new Raycaster(origin, rayDirection).intersectObjects(skinnedMeshHelper ? [room, skinnedMeshHelper] : [room]);
  }

  position = intersect?.[0]?.point || center;

  const skinnedMeshIntersect = intersect.find((intersect) => intersect.object.isSkinnedMesh);
  if (skinnedMeshIntersect) {
    const indices = new Vector4().fromBufferAttribute(skinnedMeshIntersect.object.geometry.attributes.skinIndex, skinnedMeshIntersect.face.a);
    attachTo = skinnedMeshIntersect.object.skeleton.bones[indices.x];
  }
  else {
    attachTo = intersect?.[0]?.object;
  }

  scene.add(object);

  scene.attach(object);
  object.position.set(position.x, position.y, position.z);
  object.userData.position = new Vector3().copy(object.position);
  if (attachTo) attachTo.attach(object);

  object.traverse((_object) => {
    const texture = _object?.material?.map;
    if (texture) {
      texture.anisotropy = maxAnisotropy;
      texture.encoding = SRGBColorSpace;
      texture.needsUpdate = true;
    }
  });

  noteObjects[object.userData.uuid] = object;

  if (fromSelf) {
    currObject = object;
    store.dispatch(setCurrObject());

    scene.userData.notes = {
      ...scene.userData.notes,
      [object.userData.uuid]: {
        comments: object.userData.comments,
        tags: object.userData.tags,
        position,
        camera: { x: camera.position.x, y: camera.position.y, z: camera.position.z },
      },
    };

    const database = getDatabase();
    const commentsRef = databaseRef(database, `objects/${store.getState().storage.version}/reviews/${object.userData.uuid}`);
    set(commentsRef, scene.userData.notes[object.userData.uuid]);

    render(false);
  }
};

const _updateNote = (uuid, updates = {}, fromSelf = true) => {
  if (!noteObjects[uuid] || !scene.userData.notes[uuid] || !scene || !room) return;
  if (updates.position) {
    let position = updates.position;

    let attachTo, intersect;
    if (fromSelf) {
      const _mouse = {};
      _mouse.x = (position.x / domElement.clientWidth) * 2 - 1;
      _mouse.y = - (position.y / domElement.clientHeight) * 2 + 1;

      raycaster.setFromCamera(_mouse, camera);
      intersect = raycaster.intersectObjects(skinnedMeshHelper ? [room, skinnedMeshHelper] : [room]);
    } else {
      const origin = new Vector3(updates.camera.x, updates.camera.y, updates.camera.z);
      const rayDirection = new Vector3().subVectors(position, origin).normalize();
      intersect = new Raycaster(origin, rayDirection).intersectObjects(skinnedMeshHelper ? [room, skinnedMeshHelper] : [room]);
    }

    if (!intersect?.[0]) return;

    position = intersect[0].point;
    const skinnedMeshIntersect = intersect.find((intersect) => intersect.object.isSkinnedMesh);
    if (skinnedMeshIntersect) {
      const indices = new Vector4().fromBufferAttribute(skinnedMeshIntersect.object.geometry.attributes.skinIndex, skinnedMeshIntersect.face.a);
      attachTo = skinnedMeshIntersect.object.skeleton.bones[indices.x];
    }
    else {
      attachTo = intersect[0].object;
    }

    scene.attach(noteObjects[uuid]);
    noteObjects[uuid].position.set(position.x, position.y, position.z);
    noteObjects[uuid].userData.position = new Vector3().copy(noteObjects[uuid].position);
    if (attachTo) attachTo.attach(noteObjects[uuid]);

    if (fromSelf) {
      scene.userData.notes[uuid].position = position;
      scene.userData.notes[uuid].camera = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
    }
  }

  if (updates.comments) {
    noteObjects[uuid].userData.comments = updates.comments;
    if (fromSelf) scene.userData.notes[uuid].comments = updates.comments;
  }

  if ('completed' in updates) {
    noteObjects[uuid].userData.completed = !!updates.completed;
    // noteObjects[uuid].userData.comments = noteObjects[uuid].userData.comments.map((comment) => ({ ...comment, completed: !!updates.completed }));
    if (fromSelf) {
      scene.userData.notes[uuid].completed = !!updates.completed;
      // scene.userData.notes[uuid].comments = scene.userData.notes[uuid].comments.map((comment) => ({ ...comment, completed: !!updates.completed }));
    }
  }

  if ('tags' in updates) {
    noteObjects[uuid].userData.tags = updates.tags;
    // noteObjects[uuid].userData.comments = (noteObjects[uuid].userData.comments || []).map((comment) => ({ ...comment, tags: updates.tags }));
    if (fromSelf) {
      scene.userData.notes[uuid].tags = updates.tags;
      // scene.userData.notes[uuid].comments = (scene.userData.notes[uuid].comments || []).map((comment) => ({ ...comment, tags: updates.tags }));
    }
  }

  if (fromSelf) {
    const database = getDatabase();
    const commentsRef = databaseRef(database, `objects/${store.getState().storage.version}/reviews/${uuid}`);
    set(commentsRef, scene.userData.notes[uuid]);

    render(false);
  }
};

const getParent = (object) => {
  if (object.parent == scene || object.parent == sceneAnimated) return object;

  if (object.name.toLowerCase().includes('canstack')) return object;

  // if (!stackObjects[object.userData.uuid] &&
  //   !wallObjects[object.userData.uuid] &&
  //   !ceilingObjects[object.userData.uuid] &&
  //   !floorObjects[object.userData.uuid] &&
  //   !basicObjects[object.userData.uuid]) {

  return getParent(object.parent);
  // } else return object;
};

export const getThumbnail = async ({ type, originalType, uuid: _uuid, url, name, object: objectUuid, onProgress, onComplete, init = false, selectedMaps }) => {
  try {
    const _renderer = new WebGLRenderer({ alpha: true, antialias: true });

    _renderer.setSize(720, 405, false);
    _renderer.physicallyCorrectLights = true;
    // _renderer.setPixelRatio(2);
    _renderer.autoClear = false;

    let fetched, object;
    if (!objectUuid || !cachedObjects[objectUuid]) {
      if (type == 'zip') {
        const _unzip = await unzip(url, type);
        url = _unzip[0];
        type = _unzip[1];
      }

      fetched = await loadModel(url, type, _renderer, onProgress, { fbxConverted: type == 'glb' && originalType == 'fbx' });
      object = fetched.object;
    }
    else {
      object = cachedObjects[objectUuid];
      // delete objects[objectUuid];
    }

    const _scene = new Scene();
    _scene.autoUpdate = true;

    _scene.add(object);

    const size = new Box3().setFromObject(object).getSize(new Vector3());

    let materialId = 0;
    const _materials = {};
    object.traverse((_object) => {
      if (_object.material) {
        (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material, i) => {
          material.userData.id = materialId++;
          _materials[material.uuid] = material;

          if (originalType == 'obj') material.side = DoubleSide;

          material.userData.textures = getTextureProperties(material);
        });
      }
    });

    let _includeDefaultEnvMap = true;
    if (fetched && !init) {
      const selectedMaps = await get(databaseRef(getDatabase(), `objects/${_uuid}/maps`))
        .then((snapshot) => ({ ...snapshot.val() || {} }))
        .catch(() => { });

      const _maps = Object.values(selectedMaps).reduce((obj1, idMaps) => ({
        ...obj1, ...Object.values(idMaps).reduce((obj2, keyMap) => ({ ...obj2, [keyMap]: null }), {})
      }), {});

      const maps = await Promise.all(Object.keys(_maps).map((mapUuid) =>
        listAll(storageRef(getStorage(), `maps/${_uuid}/${mapUuid}`))
          .then((ref) => ref.prefixes[0] ? listAll(ref.prefixes[0]) : Promise.resolve(ref))
          .then((file) => getDownloadURL(file.items[0]))
          .then((url) => new Promise(async (resolve) => {
            try {
              const texture = await textureLoader.loadAsync(url);
              texture.colorSpace = SRGBColorSpace;
              resolve(texture);
            } catch (e) { resolve(null) }
          }))
          .then((texture) => [mapUuid, texture])
      ))
        .then((entries) => Object.fromEntries(entries));

      const _properties = await get(databaseRef(getDatabase(), `objects/${_uuid}/properties`))
        .then((snapshot) => ({ ...snapshot.val() || {} }))
        .catch(() => { });

      const lights = await get(databaseRef(getDatabase(), `objects/${_uuid}/lights`))
        .then((snapshot) => ({ ...snapshot.val() || {} }))
        .catch(() => { });

      const _background = await get(databaseRef(getDatabase(), `objects/${_uuid}/background`))
        .then((snapshot) => snapshot.val() || background)
        .catch(() => background);

      Object.values(_materials).forEach((material) => {
        if (_includeDefaultEnvMap && (material.isMeshBasicMaterial || material.isMeshPhongMaterial)) _includeDefaultEnvMap = false;

        keys.forEach((key) => {
          material[key] = null;

          if (selectedMaps?.[material.userData.id]?.[key]) {
            const texture = maps[selectedMaps[material.userData.id][key]];

            texture.colorSpace = SRGBColorSpace;

            properties.forEach((property) => {
              if (property in material.userData.textures[key]) texture[property] = material.userData.textures[key][property];
            });

            material[key] = texture;
          }
        });

        if (_properties?.[material.userData.id]) {
          Object.entries(_properties[material.userData.id]).map(([key, value]) => {
            if (typeof material[key] == 'object' && material[key].isColor) {
              material[key] = new Color(value);
            }
            else {
              material[key] = value;
            }
          });
        }

        material.needsUpdate = true;
      });

      const environment = Object.entries(lights).find(([_, light]) => light.type == 'Environment');
      const toAdd = Object.entries(lights).filter(([_, light]) => light.type != 'Environment');

      toAdd.forEach(([uuid, light]) => _addLight({ ...light, uuid }, false, { scene: _scene }));

      if (environment) {
        let environmentObj = {};
        if (environment[0] != 'Default') {
          environmentObj = await listAll(storageRef(getStorage(), `environments/${_uuid}/${environment[0]}`))
            .then((file) => getDownloadURL(file.items[0])
              .then((url) => ({ url, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() }))
            )
            .catch(() => { });
        }
        else {
          environmentObj = { url: 'Default' };
        }

        if (_includeDefaultEnvMap || environment[0] != 'Default') {
          await loadEnvironmentToScene({
            src: environmentObj?.url,
            uuid: environment[0],
            type: environmentObj?.type,
          }, false, { scene: _scene, renderer: _renderer });
        }
      }

      _scene.background = new Color(_background);
    }
    else {
      object.traverse((_object) => {
        if (_object.material) {
          (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material) => {
            if (_includeDefaultEnvMap && (material.isMeshBasicMaterial || material.isMeshPhongMaterial)) _includeDefaultEnvMap = false;

            if (originalType == 'obj') material.side = DoubleSide;

            keys.forEach((key) => {
              if (!material[key]?.source?.data) material[key] = null;
            });

            material.needsUpdate = true;
          });
        }
      });

      if (selectedMaps) {
        for (const material of Object.values(_materials)) {
          if (_includeDefaultEnvMap && (material.isMeshBasicMaterial || material.isMeshPhongMaterial)) _includeDefaultEnvMap = false;

          for (const key of materialKeys[material.type]) {
            const keyMaps = selectedMaps?.[material.userData.id]?.[key]?.filter((map) => map.name != 'Default');
            if (keyMaps?.[0] && material[key] !== undefined) {
              const texture = await textureLoader.loadAsync(keyMaps[0]?.url);
              texture.colorSpace = SRGBColorSpace;

              material[key] = texture;
            }
          }

          material.needsUpdate = true;
        }
      }

      _addLight({ type: 'AmbientLight', color: '#ffffff', intensity: object.userData.originalType == 'fbx' ? .6 : .3 }, false, { scene: _scene });
      _addLight({ type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: size.length() / 2, z: 0 } }, false, { scene: _scene });
      _addLight({ type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: -size.length() / 2, z: 0 } }, false, { scene: _scene });

      if (_includeDefaultEnvMap) await loadEnvironmentToScene({ src: 'Default' }, false, { scene: _scene, renderer: _renderer });

      _scene.background = new Color(background);
    }

    const _camera = new PerspectiveCamera(fov, aspect, near, far);

    _camera.near = size.length() / 100;
    _camera.far = size.length() * 40;
    _camera.updateProjectionMatrix();

    const center = new Box3().setFromObject(object).getCenter(new Vector3());

    _camera.position
      .copy(center)
      .add(new Vector3(size.length() * 1.1, size.length() * .55, size.length() * 1.1));

    _camera.lookAt(center);

    _renderer.render(_scene, _camera);

    const blob = await new Promise((resolve) => _renderer.domElement.toBlob(resolve, 'image/webp', 1));

    object.traverse((_object) => {
      if (_object.material) {
        (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material) => material.dispose());
      }
      if (_object.geometry) _object.geometry.dispose();
    });

    // _scene.dispose();
    // _camera.dispose();
    // _controls.dispose();
    _renderer.dispose();

    await deleteFolder(storageRef(getStorage(), `thumbnails/${_uuid}`));

    const img = await new Promise((resolve) => {
      const uploadTask = uploadBytesResumable(storageRef(getStorage(), `thumbnails/${_uuid}/${uuid()}.${name}.webp`), blob);
      uploadTask.on('state_changed',
        (snapshot) => onProgress({ id: 'upload', loaded: snapshot.bytesTransferred, total: snapshot.totalBytes }),
        () => resolve(null),
        () => resolve(getDownloadURL(uploadTask.snapshot.ref).catch(() => null)),
      );
    });

    onComplete({ url: img });
  } catch (e) { console.log(e) }
};

export const getData = async ({ type, originalType, uuid, url, name, onProgress, onComplete }) => {
  const _renderer = new WebGLRenderer({ alpha: true, antialias: true });
  _renderer.setSize(640, 360, false);
  _renderer.physicallyCorrectLights = true;
  // _renderer.setPixelRatio(2);
  _renderer.autoClear = false;

  if (type == 'zip') {
    const _unzip = await unzip(url, type);
    url = _unzip[0];
    type = _unzip[1];
  }

  const { object, animations } = await loadModel(url, type, _renderer);

  const _scene = new Scene();
  _scene.autoUpdate = true;

  _scene.add(object);

  const size = new Box3().setFromObject(object).getSize(new Vector3());

  const selectedMaps = await get(databaseRef(getDatabase(), `objects/${uuid}/maps`))
    .then((snapshot) => ({ ...snapshot.val() || {} }))
    .catch(() => { });

  const _maps = Object.values(selectedMaps).reduce((obj1, idMaps) => ({
    ...obj1, ...Object.values(idMaps).reduce((obj2, keyMap) => ({ ...obj2, [keyMap]: null }), {})
  }), {});

  const maps = await Promise.all(Object.keys(_maps).map((mapUuid) =>
    listAll(storageRef(getStorage(), `maps/${uuid}/${mapUuid}`))
      .then((ref) => ref.prefixes[0] ? listAll(ref.prefixes[0]) : Promise.resolve(ref))
      .then((file) => getDownloadURL(file.items[0]))
      .then((url) => new Promise(async (resolve) => {
        try {

          const texture = await textureLoader.loadAsync(url);
          // const texture = new Texture(imageBitmap);
          texture.colorSpace = SRGBColorSpace;
          resolve(texture);
        } catch (e) { resolve(null) }
      }))
      .then((texture) => [mapUuid, texture])
  ))
    .then((entries) => Object.fromEntries(entries));

  const _properties = await get(databaseRef(getDatabase(), `objects/${uuid}/properties`))
    .then((snapshot) => ({ ...snapshot.val() || {} }))
    .catch(() => { });

  const lights = await get(databaseRef(getDatabase(), `objects/${uuid}/lights`))
    .then((snapshot) => ({ ...snapshot.val() || {} }))
    .catch(() => { });

  const _background = await get(databaseRef(getDatabase(), `objects/${uuid}/background`))
    .then((snapshot) => snapshot.val() || background)
    .catch(() => background);

  let materialId = 0;
  object.traverse((_object) => {
    if (_object.material) {
      (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material, i) => {
        material.userData.id = materialId++;

        if (originalType == 'obj') material.side = DoubleSide;

        material.userData.textures = getTextureProperties(material);

        keys.forEach((key) => {
          material[key] = null;

          let texture = null;
          if (selectedMaps?.[material.userData.id]?.[key]) {
            texture = maps[selectedMaps[material.userData.id][key]].clone();

            properties.forEach((property) => {
              if (property in material.userData.textures[key]) texture[property] = material.userData.textures[key][property];
            });
          }

          material[key] = texture;
        });

        if (_properties?.[material.userData.id]) {
          Object.entries(_properties[material.userData.id]).map(([key, value]) => {
            if (typeof material[key] == 'object' && material[key].isColor) {
              material[key] = new Color(value);
            }
            else {
              material[key] = value;
            }
          });
        }

        material.needsUpdate = true;
      });
    }
  });

  const environment = Object.entries(lights).find(([_, light]) => light.type == 'Environment');
  const toAdd = Object.entries(lights).filter(([_, light]) => light.type != 'Environment');

  toAdd.forEach(([uuid, light]) => _addLight({ ...light, uuid }, false, { scene: _scene }));

  if (environment) {
    let environmentObj = {};
    if (environment[0] != 'Default') {
      environmentObj = await listAll(storageRef(getStorage(), `environments/${uuid}/${environment[0]}`))
        .then((file) => getDownloadURL(file.items[0])
          .then((url) => ({ url, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() }))
        )
        .catch(() => { });
    }
    else {
      environmentObj = { url: 'Default' };
    }

    await loadEnvironmentToScene({
      src: environmentObj?.url,
      uuid: environment[0],
      type: environmentObj?.type,
    }, false, { scene: _scene, renderer: _renderer });
  }

  _scene.background = new Color(_background);


  const _camera = camera.clone();

  _camera.near = size.length() / 100;
  _camera.far = size.length() * 40;
  _camera.updateProjectionMatrix();

  const center = new Box3().setFromObject(object).getCenter(new Vector3());

  _camera.position
    .copy(center)
    .add(new Vector3(size.length(), size.length() / 2, size.length()));

  _camera.lookAt(center);

  _renderer.render(_scene, _camera);

  const data = await new Promise((resolve, reject) => {
    new GLTFExporter()
      .parse(object, resolve, reject, {
        onlyVisible: false,
        binary: true,
        animations: animations || [],
      });
  });

  const blob = new Blob([data]);

  object.traverse((_object) => {
    if (_object.material) {
      (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material) => material.dispose());
    }
    if (_object.geometry) _object.geometry.dispose();
  });

  _renderer.dispose();

  onComplete(blob);

};

export const compressModel = async ({ type, url, name, uuid: objectUuid, onProgress }) => {
  let content;
  switch (type) {
    case 'glb':
      content = await new FileLoader().setResponseType('arraybuffer').loadAsync(url, ({ loaded, total }) => {
        onProgress({ id: 'load', loaded, total });
      });

      break;
    case 'fbx':
    case 'obj':
    case 'stl':
      content = await loadModel(url, type, null, onProgress);

      break;
    default:
  }

  const { object, data, name: newName } = await compressModelUtils({ content, type, name });

  url = await new Promise(async (resolve) => {
    const zip = new JSZip();
    zip.file(newName, data.buffer);
    const blob = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 9 },
    });

    const uploadTask = uploadBytesResumable(storageRef(getStorage(), `objects/${objectUuid}/${newName}.zip`), blob);
    uploadTask.on('state_changed',
      (snapshot) => onProgress({ id: 'upload', loaded: snapshot.bytesTransferred, total: snapshot.totalBytes }),
      () => resolve(null),
      () => resolve(getDownloadURL(uploadTask.snapshot.ref).catch(() => null)),
    );
  });

  cachedObjects[object.uuid] = object;

  return { object: object.uuid, name: newName };
};

const worker = new Worker(new URL('./worker.js', import.meta.url));
worker.postMessage({
  type: 'init', options: {
    host: window.location.host,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
});
const messages = {};

export function useThreejsWorker() {
  worker.onmessage = ({ data: { type, uuid: _uuid, payload } }) => {
    switch (type) {
      case 'onComplete':
        if (messages[_uuid]?.onComplete) messages[_uuid].onComplete(payload);
        delete messages[_uuid];
        break;
      case 'onProgress':
        if (messages[_uuid]?.onProgress) messages[_uuid].onProgress(payload);
        break;
      case 'onError':
        if (messages[_uuid]?.onError) messages[_uuid].onError(payload);
        break;
      case 'onData':
        if (messages[_uuid]?.onData) messages[_uuid].onData(payload);
      default:
    }
  };

  return ({ type, options, onComplete, onProgress, onError, onData }) => {
    const _uuid = uuid();
    messages[_uuid] = { onComplete, onProgress, onError, onData };

    switch (type) {
      case 'compressModel':
        worker.postMessage({ type, options, uuid: _uuid });
      case 'uploadMaps':
        worker.postMessage({ type, options, uuid: _uuid });
        break;
      case 'getThumbnail':
        worker.postMessage({ type, options, uuid: _uuid }, [options.canvas]);
        break;
      default:
    }
  };
};

const animate = () => {
  animationFrame = requestAnimationFrame(animate);

  render(true);
};

export const loadObject = createAsyncThunk(
  'threejs/loadObject',
  async ({ url, type, onProgress, originalType, defaultLight = false }) => {
    try {
      await loadStandaloneObject({ url, type, onProgress, originalType, defaultLight });
      // if (!defaultLight)
      store.dispatch(setScene({ type: originalType }));
    } catch (e) {
      console.log(e)
    }
  }
);

export const addNote = createAsyncThunk('threejs/addNote',
  (note) => { try { createNote(note, true) } catch (e) { console.log(e) } },
);

export const deleteNote = createAsyncThunk('threejs/deleteNote',
  (uuid) => {
    if (!noteObjects[uuid]) return;

    if (uuid == currObject?.userData.uuid) onObjectDeselect();

    disposeObject(noteObjects[uuid]);

    delete noteObjects[uuid];

    scene.userData.notes = Object.fromEntries(Object.entries(scene.userData.notes).filter(([_uuid, _]) => uuid != _uuid));
    const database = getDatabase();
    const commentsRef = databaseRef(database, `objects/${store.getState().storage.version}/reviews/${uuid}`);
    set(commentsRef, null);

    render(false);
  });

export const updateNote = createAsyncThunk('threejs/updateNote',
  ({ uuid, updates }) => { try { _updateNote(uuid, updates, true) } catch (e) { console.log(e) } },
);

export const updateNotes = createAsyncThunk('threejs/updateNotes',
  (notes) => {
    try {
      if (!scene || !room) return;

      if (!notes[currObject?.userData.uuid] && noteObjects[currObject?.userData.uuid]) onObjectDeselect();

      scene.userData.notes = notes;

      const toAdd = Object.entries(notes).filter(([uuid]) => !noteObjects[uuid]);
      const toUpdate = Object.entries(notes).filter(([uuid]) => noteObjects[uuid]);
      const toDelete = Object.entries(noteObjects).filter(([uuid]) => !notes[uuid]);

      toAdd.forEach(([uuid, note]) => createNote({ ...note, uuid }, false));
      toUpdate.forEach(([uuid, note]) => _updateNote(uuid, note, false));
      toDelete.forEach(([uuid]) => {
        disposeObject(noteObjects[uuid]);
        delete noteObjects[uuid];
      });

      // if (notes[currObject?.userData.uuid] && noteObjects[currObject?.userData.uuid]) {
      //   console.log(currObject)
      //   currObject = noteObjects[currObject?.userData.uuid];
      //   console.log(currObject)
      //   store.dispatch(setCurrObject());
      // }

      render(true);
    } catch (e) { console.log(e) }
  }
);

export const addQrCode = createAsyncThunk(
  'threejs/addQrCode',
  async (qrCode) => {
    const url = await QRCode.toDataURL(qrCode, {
      color: { light: '#00000000' },
      margin: 0,
    });

    createQrCodeElement(url);
  }
);

export const deleteModel = createAsyncThunk(
  'threejs/deleteModel',
  async () => deleteCurrentObject()
);

export const addModel = createAsyncThunk(
  'threejs/addModel',
  async ({ object, nft, file }) => {
    if (currObject?.name.toLowerCase().includes('nft') && !object &&
      !currObject.userData.qrCode &&
      ((nft && currObject.userData.nft) || (file && !currObject.userData.nft))) {

      await loadFrame(currObject, {
        image: nft?.image,
        src: nft?.src,
        audio: nft?.audio,
        nft,
        file,
      });
      store.dispatch(setCurrObject());
    } else {
      loadObjectIntoRoom(
        object || frameFile,
        (currObject?.name.toLowerCase().includes('pedestal') && object) && currObject,
        !!object,
        null,
        nft,
        file,
      );
    }
  }
);

export const uploadMaps = async ({ url, type, uuid: _uuid, object: objectUuid, onProgress, onData, onComplete }) => {
  try {
    let fetched, object;
    if (!objectUuid || !cachedObjects[objectUuid]) {
      fetched = await loadModel(url, type, null, onProgress);
      object = fetched.object;
      object.userData.hasAnimations = fetched.animations?.length || 0 > 0;

      object.userData.vertices = 0;
      object.userData.polycount = 0;

      object.traverse((_object) => {

        if (_object.isMesh) {

          const geometry = _object.geometry;

          object.userData.vertices += geometry.attributes.position.count;

          if (geometry.index !== null) {

            object.userDatapolycount += geometry.index.count / 3;

          } else {

            object.userDatapolycount += geometry.attributes.position.count / 3;

          }

        }

      });
    }
    else {
      object = cachedObjects[objectUuid];
      // delete objects[objectUuid];
    }

    const size = new Box3().setFromObject(object).getSize(new Vector3()).length();

    const sources = {};
    const sourceMap = {};

    const _materials = {};
    let materialId = 0;
    object.traverse((_object) => {
      if (_object.material) {
        (Array.isArray(_object.material) ? _object.material : [_object.material]).forEach((material) => {
          material.userData.id = materialId++;
          _materials[material.userData.id] = material;
        });
      }
    });

    let _includeDefaultEnvMap = true;
    Object.values(_materials).forEach((material) => {
      if (_includeDefaultEnvMap && (material.isMeshBasicMaterial || material.isMeshPhongMaterial)) _includeDefaultEnvMap = false;

      const id = material.userData.id;

      materialKeys[material.type].forEach((key) => {
        if (material[key]) {
          const source = material[key].source;

          if (!source?.data) return;

          if (!sources[id]) sources[id] = {};
          if (!sources[id][key]) sources[id][key] = {};
          if (sourceMap[source.uuid]?.uuid) {
            sources[id][key][source.uuid] = { uuid: sourceMap[source.uuid]?.uuid };
          }
          else {
            const __uuid = uuid();
            sources[id][key][source.uuid] = { uuid: sourceMap[source.uuid]?.uuid || __uuid };
            sourceMap[source.uuid] = { key, uuid: __uuid, image: typeof source.data === 'string' ? source.data : createImageBitmap(source.data) };
          }
        }
      });
    });

    for (const source of Object.values(sourceMap)) {
      source.image = await Promise.resolve(source.image);
      onData({ data: source });
    }

    cachedObjects[object.uuid] = object;

    onComplete({
      ...object.userData,
      sources,
      size,
      object: object.uuid,
      includeDefaultEnvMap: _includeDefaultEnvMap,
      materials: _materials,
    });
  } catch (e) { console.log(e) }
};

export const setAnimation = createAsyncThunk(
  'threejs/setAnimation',
  ({ animation, time, pause }) => {
    try {
      if (animation !== undefined) {
        if (animation) {
          const action = mixer.clipAction(animation);

          if (action.enabled) {
            action.stop();
            action.reset();
            action.enabled = false;
            animation.enabled = false;
          } else {
            action.enabled = true;
            animation.enabled = true;
            action.play();
          }

          const playing = animations.some((_animation) => mixer.clipAction(_animation).enabled);

          if (animationFrame) cancelAnimationFrame(animationFrame);
          animate();
          renderVideo = playing;

          if (!clock.running) clock.start();
        } else {
          animations.forEach((_animation) => {
            const action = mixer.clipAction(_animation);

            if (action.enabled) {
              action.stop();
              action.enabled = false;
              _animation.enabled = false;
            }
          });

          if (animationFrame) cancelAnimationFrame(animationFrame);
          animationFrame = null;
          renderVideo = false;
        }
      }

      if (time !== undefined) {
        mixer.setTime(time);
        animations.forEach((_animation) => {
          const action = mixer.clipAction(_animation);

          if (action.enabled) {
            action.time = time;
          }
        });
      }

      if (pause !== undefined) {
        animations.forEach((_animation) => {
          const action = mixer.clipAction(_animation);

          if (action.enabled) {
            if (pause) {
              clock.stop();
              action.paused = true;
            } else {
              action.paused = false;
              clock.start();
            }
          }
        });

        if (animationFrame) cancelAnimationFrame(animationFrame);
        if (pause) {
          animationFrame = null;
          renderVideo = false;
        }
        else {
          animate();
          renderVideo = true;
        }
      }

      if (!animationFrame) render();

      return {
        time: time !== undefined || pause !== undefined ? mixer.time : undefined,
        isPaused: pause !== undefined ? pause : time !== undefined ? undefined : false,
      };
    } catch (e) { console.log(e) }
  },
)

export const setAmbientLight = createAsyncThunk(
  'threejs/setAmbientLight',
  (value) => {
    room.userData.ambientLight = value;

    alight.intensity = room.userData.ambientLight;

    render();

    return room.userData.ambientLight;
  }
);

export const setDirectionalLight = createAsyncThunk(
  'threejs/setDirectionalLight',
  (value) => {
    room.userData.directionalLight = value;

    dlight.intensity = room.userData.directionalLight;

    render();

    return room.userData.directionalLight;
  }
);

export const setExposure = createAsyncThunk(
  'threejs/setExposure',
  (value) => {
    room.userData.exposure = value;

    renderer.toneMappingExposure = Math.pow(2, value);

    render();

    return value;
  }
);

const _addLight = (light, fromSelf = true, other) => {
  try {
    const size = !other ? new Box3().setFromObject(room).getSize(new Vector3()) : { x: 0, y: 0, z: 0 };

    const _scene = other?.scene || scene;

    let newLight;
    switch (light.type) {
      case 'AmbientLight':
        newLight = new AmbientLight();
        break;
      case 'DirectionalLight':
        newLight = new DirectionalLight();
        newLight.position.set(0, size.y / 2, 0);
        break;
      case 'SpotLight':
        newLight = new SpotLight();
        newLight.userData.angle = Math.round(MathUtils.radToDeg(newLight.angle));
        newLight.decay = 0;
        newLight.position.set(0, size.y / 2, 0);
        break;
      case 'PointLight':
        newLight = new PointLight();
        newLight.decay = 0;
        newLight.position.set(0, size.y / 2, 0);
        break;
      case 'HemisphereLight':
        newLight = new HemisphereLight();
        newLight.position.set(0, size.y / 2, 0);
        break;
      default:
    }

    newLight.userData.uuid = light.uuid || uuid();

    if (!fromSelf) {
      Object.entries(light).forEach(([key, value]) => {
        if (key == 'type') return;

        if ((key == 'color' || key == 'groundColor') && typeof value == 'string') {
          newLight[key] = new Color(value);
        } else if (key == 'position') {
          newLight.position.copy(value);
        } else if (key == 'angle') {
          newLight.userData.angle = value;
          newLight.angle = MathUtils.degToRad(value);
        } else {
          newLight[key] = value;
        }
      });
    }

    _scene.add(newLight);

    if (fromSelf) lights = { ...lights, [newLight.userData.uuid]: newLight };

    return newLight;
  } catch (e) { console.log(e) }
};

const _updateLight = (uuid, light, fromSelf = true) => {
  Object.entries(light).forEach(([key, value]) => {
    if ((key == 'color' || key == 'groundColor') && typeof value == 'string') {
      lights[uuid][key] = new Color(value);
    } else if (key == 'position') {
      lights[uuid].position.copy(value);
    } else if (key == 'angle') {
      lights[uuid].userData.angle = value;
      lights[uuid].angle = MathUtils.degToRad(value);
    } else {
      lights[uuid][key] = value;
    }
  });
};

const _removeLight = (uuid, fromSelf = true) => {
  const light = lights[uuid];
  if (transformControls?.object?.userData.uuid == uuid) {
    if (controls && !controls.enabled) controls.enabled = true;
    transformControls?.detach();
    store.dispatch(settingLight(false));
    if (transformControlsListener) {
      transformControls?.removeEventListener('change', transformControlsListener);
      transformControlsListener = null;
    }
    if (transformControlsHelper) {
      scene.remove(transformControlsHelper);
      transformControlsHelper.dispose();
    }
  }

  scene.remove(light);
  lights = Object.fromEntries(Object.values(lights).filter((_light) => _light.userData.uuid != light.userData.uuid).map((_light) => [_light.userData.uuid, _light]));
  light.dispose();
};

export const addLight = createAsyncThunk(
  'threejs/addLight',
  (type) => {
    try {
      if (transformControls?.object) {
        transformControls?.detach();
        store.dispatch(settingLight(false));
      }

      if (transformControlsListener) {
        transformControls?.removeEventListener('change', transformControlsListener);
        transformControlsListener = null;
      }

      if (transformControlsHelper) {
        scene.remove(transformControlsHelper);
        transformControlsHelper.dispose();
      }

      _addLight({ type });

      render();
    } catch (e) { console.log(e) }
  }
);

export const updateLight = createAsyncThunk(
  'threejs/updateLight',
  ({ light, updates }) => {
    try {
      if (transformControls?.object?.userData.uuid != light.userData.uuid) {
        if (transformControls?.object) {
          transformControls?.detach();
          store.dispatch(settingLight(false));
        }

        if (transformControlsListener) {
          transformControls?.removeEventListener('change', transformControlsListener);
          transformControlsListener = null;
        }

        if (transformControlsHelper) {
          scene.remove(transformControlsHelper);
          transformControlsHelper.dispose();
        }
      }

      _updateLight(light.userData.uuid, updates);
      if (transformControlsHelper) transformControlsHelper.update();

      render();
    } catch (e) { console.log(e) }
  }
);

export const removeLight = createAsyncThunk(
  'threejs/removeLight',
  (light) => {
    try {
      if (transformControls?.object) {
        transformControls?.detach();
        store.dispatch(settingLight(false));
      }

      if (transformControlsListener) {
        transformControls?.removeEventListener('change', transformControlsListener);
        transformControlsListener = null;
      }

      if (transformControlsHelper) {
        scene.remove(transformControlsHelper);
        transformControlsHelper.dispose();
      }

      _removeLight(light.userData.uuid);

      render();
    } catch (e) { console.log(e) }
  }
);

export const setLight = createAsyncThunk(
  'threejs/setLight',
  (light) => {
    store.dispatch(settingLight(true));

    if (transformControlsListener) {
      transformControls?.removeEventListener('change', transformControlsListener);
      transformControlsListener = null;
    }

    if (transformControlsHelper) {
      scene.remove(transformControlsHelper);
      transformControlsHelper.dispose();
    }

    const size = new Box3().setFromObject(room).getSize(new Vector3());
    const max = Math.max(...[...size]);

    switch (light.type) {
      case 'DirectionalLight':
        transformControlsHelper = new DirectionalLightHelper(light, max / 4, 0x272627);
        scene.add(transformControlsHelper);
        break;
      case 'SpotLight':
        transformControlsHelper = new SpotLightHelper(light, 0x272627);
        scene.add(transformControlsHelper);
        break;
      case 'PointLight':
        transformControlsHelper = new PointLightHelper(light, max / 4, 0x272627);
        scene.add(transformControlsHelper);
        break;
      case 'HemisphereLight':
        transformControlsHelper = new HemisphereLightHelper(light, max / 4, 0x272627);
        scene.add(transformControlsHelper);
        break;
      default:
    }

    transformControls?.attach(light);

    transformControlsListener = () => {
      if (controls?.enabled) controls.enabled = false;
      
      if (!isNaN(parseFloat(light.position.x))) light.position.x = parseFloat(light.position.x);
      if (!isNaN(parseFloat(light.position.y))) light.position.y = parseFloat(light.position.y);
      if (!isNaN(parseFloat(light.position.z))) light.position.z = parseFloat(light.position.z);
      if (transformControlsHelper) transformControlsHelper.update();
      store.dispatch(handleSetLight());
    };
    transformControls?.addEventListener('change', transformControlsListener);

    render();
  }
)

export const updateLights = createAsyncThunk(
  'threejs/updateLights',
  async (newLights) => {
    try {
      if (!scene) return;

      let environment = Object.entries(newLights).find(([_, light]) => light.type == 'Environment');

      if (!includeDefaultEnvMap && environment?.[0] == 'Default') environment = null;

      const toAdd = Object.entries(newLights).filter(([uuid, light]) => !lights[uuid] && light.type != 'Environment');
      const toUpdate = Object.entries(newLights).filter(([uuid, light]) => lights[uuid] && light.type != 'Environment');
      const toDelete = Object.entries(lights).filter(([uuid, light]) => !newLights[uuid] && light.type != 'Environment');

      const toAddLights = toAdd.map(([uuid, light]) => _addLight({ ...light, uuid }, false));
      toUpdate.forEach(([uuid, light]) => _updateLight(uuid, light, false));
      toDelete.forEach(([uuid]) => _removeLight(uuid, false));

      const _environment = store.getState().threejs.environments.find((__environment) => __environment.uuid == environment?.[0]);
      await loadEnvironmentToScene({
        src: _environment?.url,
        uuid: _environment?.uuid,
        type: _environment?.type,
      }, false);

      render();

      lights = Object.fromEntries([
        ...toUpdate.map(([uuid]) => [uuid, lights[uuid]]),
        ...toAdd.map(([uuid], i) => [uuid, toAddLights[i]]),
        [environment?.[0], { envMap, uuid: environment?.[0], type: 'Environment', userData: { uuid: environment?.[0] } }]
      ].filter(([uuid]) => uuid));
    } catch (e) { console.log(e) }
  }
);

export const lookAt = createAsyncThunk(
  'threejs/lookAt',
  (payload) => {
    currObject = typeof payload == 'string' ? noteObjects[payload] : payload;

    if (currObject) {
      camera.position.set(...Object.values(scene.userData.notes[currObject.userData.uuid].camera));
      camera.lookAt(controls.target);

      currObject.visible = true;

      render(true);
    }

    store.dispatch(setCurrObject(currObject));
  }
);

export const setShininess = createAsyncThunk(
  'threejs/setShininess',
  ({ uuid, value }) => {
    materials[uuid].roughness = value;

    render();
  }
);

export const toggleBackground = createAsyncThunk(
  'threejs/toggleBackground',
  () => {
    room.userData.showBackground = !room.userData.showBackground;

    scene.background = room.userData.showBackground && envMap ? envMap.map : new Color(background);

    render();

    return room.userData.showBackground;
  }
);

export const toggleGrid = createAsyncThunk(
  'threejs/toggleGrid',
  () => {
    grid.visible = !grid.visible;

    render();

    return grid.visible;
  }
);

export const toggleEdges = createAsyncThunk(
  'threejs/toggleEdges',
  () => {
    const toggle = !store.getState().threejs.edges;

    edges.forEach((edge) => { edge.visible = toggle });

    render();

    return toggle;
  }
);

export const toggleWireframe = createAsyncThunk(
  'threejs/toggleWireframe',
  () => {
    const toggle = !store.getState().threejs.wireframe;

    setWireframe(room, toggle);

    return toggle;
  }
);

export const toggleNormals = createAsyncThunk(
  'threejs/toggleNormals',
  () => {
    const toggle = !store.getState().threejs.normals;

    normals.forEach((normal) => { normal.visible = toggle });

    render();

    return toggle;
  }
);

export const toggleUVMap = createAsyncThunk(
  'threejs/toggleUVMap',
  () => {
    try {
      uvMap = !uvMap;

      if (uvMap) {
        room.traverse((object) => {
          if (object.material) {
            (Array.isArray(object.material) ? object.material : [object.material]).forEach(() => {
              object.material = uvMapMesh;
            });
          }
        });
      }
      else {
        room.traverse((object) => {
          if (object.material) {
            (Array.isArray(object.material) ? object.material : [object.material]).forEach(() => {
              object.material = object.userData.material;
            });
          }
        });
      }

      const object = selectiveBloom?.selection.values().next().value;

      if (object) {
        if (Array.isArray(object.material)) {
          const index = object.material.indexOf(materials[object.uuid]);

          object.material = [
            ...object.material.slice(0, index),
            uvMap ? uvMapMesh.clone() : object.material[index].clone(),
            ...object.material.slice(index + 1),
          ];
          object.material[index].color = new Color(0x0b8ce9);
          object.material[index].color.multiplyScalar(100);
          if (object.material[index].emissive) object.material[index].emissive = new Color(0x000000);
        } else {
          object.material = uvMap ? uvMapMesh.clone() : object.material.clone();
          object.material.color = new Color(0x0b8ce9);
          object.material.color.multiplyScalar(100);
          if (object.material.emissive) object.material.emissive = new Color(0x000000);
        }

        selectiveBloom.selection.clear();
        selectiveBloom.selection.add(object);
      }

      render();

      // room.traverse((object) => {
      //   if (object.material && object.userData.material) {
      //     if (Array.isArray(object.material)) {
      //       object.material.forEach((material) => material.dispose());
      //     }
      //     else {
      //       object.material.dispose();
      //     }
      //     object.material = object.userData.material;
      //     object.userData.material = null;
      //   }
      // });

      // if (material.object != selectiveBloom.selection.values().next().value) {
      //   room.traverse((object) => {
      //     if (object.material) {
      //       object.userData.highlighted = false;
      //     }
      //   });

      //   // material.object.userData.highlighted = true;

      //   material.object.userData.material = material.object.material;

      //   if (Array.isArray(material.object.material)) {
      //     const index = material.object.material.indexOf(material.material);

      //     material.object.material = [
      //       ...material.object.material.slice(0, index),
      //       material.object.material[index].clone(),
      //       ...material.object.material.slice(index + 1),
      //     ];
      //     material.object.material[index].color = new Color(0x0b8ce9);
      //     material.object.material[index].color.multiplyScalar(100);
      //     if (material.object.material[index].emissive) material.object.material[index].emissive = new Color(0x000000);
      //   } else {
      //     material.object.material = material.object.material.clone();
      //     material.object.material.color = new Color(0x0b8ce9);
      //     material.object.material.color.multiplyScalar(100);
      //     if (material.object.material.emissive) material.object.material.emissive = new Color(0x000000);
      //   }

      //   selectiveBloom.selection.clear();
      //   selectiveBloom.selection.add(material.object);

      //   // material.object.userData.highlighted = true;
      // }
      // else {
      //   selectiveBloom.selection.clear();
      //   // material.object.userData.highlighted = false;
      // }

      // materials[material.uuid].object.visible = true;

      render();

      // return material;
    } catch (e) { console.log(e) }
  }
);

export const toggleViewMode = createAsyncThunk(
  'threejs/toggleViewMode',
  () => {
    const toggle = !store.getState().threejs.freeview;

    controls.dispose();

    if (!toggle) {
      controls = new OrbitControls(camera, domElement || renderer.domElement);
      controls.enabled = true;
    }
    else {
      const offset = new Vector3().subVectors(controls.target, camera.position).normalize();

      controls = new FreeControls(camera, domElement || renderer.domElement, new Box3().setFromObject(room).getCenter(new Vector3()));
      controls.enabled = true;

      offset.multiplyScalar(controls.distance);
      controls.target.copy(camera.position).add(offset);
      camera.lookAt(controls.target);
    }

    return toggle;
  }
);

export const updateBackground = createAsyncThunk(
  'threejs/updateBackground',
  (color) => {
    background = color;

    scene.background = new Color(background);

    render();

    return color;
  }
);

export const loadEnvironment = createAsyncThunk(
  'threejs/loadEnvironment',
  async ({ url, uuid, isDefault, type } = {}) => {
    try {
      await loadEnvironmentToScene({ src: isDefault ? 'Default' : url, uuid, type });

      render();

      return room.userData.showBackground;
    } catch (e) { console.log(e) }
  },
);

export const updateMaterial = createAsyncThunk(
  'threejs/updateMaterial',
  async ({ material, updates, type, keys }) => {
    try {
      // if (type) {
      //   const oldMaterial = material.material;

      //   let newMaterial;
      //   switch (type) {
      //     case 'MeshBasicMaterial':
      //       newMaterial = new MeshBasicMaterial();
      //       break;
      //     case 'MeshDepthMaterial':
      //       newMaterial = new MeshDepthMaterial();
      //       break;
      //     case 'MeshNormalMaterial':
      //       newMaterial = new MeshNormalMaterial();
      //       break;
      //     case 'MeshLambertMaterial':
      //       newMaterial = new MeshLambertMaterial();
      //       break;
      //     case 'MeshMatcapMaterial':
      //       newMaterial = new MeshMatcapMaterial();
      //       break;
      //     case 'MeshPhongMaterial':
      //       newMaterial = new MeshPhongMaterial();
      //       break;
      //     case 'MeshToonMaterial':
      //       newMaterial = new MeshToonMaterial();
      //       break;
      //     case 'MeshStandardMaterial':
      //       newMaterial = new MeshStandardMaterial();
      //       break;
      //     case 'MeshPhysicalMaterial':
      //       newMaterial = new MeshPhysicalMaterial();
      //       break;
      //     default:
      //   }

      //   // if (newMaterial.map !== undefined) newMaterial.map = oldMaterial.map || null;
      //   // if (newMaterial.matcap !== undefined) newMaterial.matcap = oldMaterial.matcap || null;
      //   // if (newMaterial.specularMap !== undefined) newMaterial.specularMap = oldMaterial.specularMap || null;
      //   // if (newMaterial.emissiveMap !== undefined) newMaterial.emissiveMap = oldMaterial.emissiveMap || null;
      //   // if (newMaterial.alphaMap !== undefined) newMaterial.alphaMap = oldMaterial.alphaMap || null;
      //   // if (newMaterial.bumpMap !== undefined) newMaterial.bumpMap = oldMaterial.bumpMap || null;
      //   // if (newMaterial.normalMap !== undefined) newMaterial.normalMap = oldMaterial.normalMap || null;
      //   // if (newMaterial.displacementMap !== undefined) newMaterial.displacementMap = oldMaterial.displacementMap || null;
      //   // if (newMaterial.roughnessMap !== undefined) newMaterial.roughnessMap = oldMaterial.roughnessMap || null;
      //   // if (newMaterial.metalnessMap !== undefined) newMaterial.metalnessMap = oldMaterial.metalnessMap || null;
      //   // if (newMaterial.envMap !== undefined) newMaterial.envMap = oldMaterial.envMap || null;
      //   // if (newMaterial.lightMap !== undefined) newMaterial.lightMap = oldMaterial.lightMap || null;
      //   // if (newMaterial.gradientMap !== undefined) newMaterial.gradientMap = oldMaterial.gradientMap || null;

      //   if (newMaterial) {
      //     newMaterial.name = oldMaterial.name;
      //     newMaterial.userData.uuid = uuid;
      //     newMaterial.userData.name = oldMaterial.userData.name;
      //     newMaterial.userData.textures = oldMaterial.userData.textures;

      //     Object.keys(newMaterial.userData.textures).forEach((key) => {
      //       newMaterial.userData.textures[key].index = null;
      //     });


      //     scene.traverse((object) => {
      //       if (object.uuid == material.uuid) {
      //         if (Array.isArray(object.material)) {
      //           object.material.forEach((_material, i) => {
      //             if (_material.uuid == oldMaterial.uuid) {
      //               object.material[i] = newMaterial;

      //             }
      //           });
      //         } else {
      //           if (object.material.uuid == oldMaterial.uuid) {
      //             object.material = newMaterial;
      //           }
      //         }
      //       }
      //     });

      //     materials = {
      //       ...materials,
      //       [material.uuid]: {
      //         ...materials[material.uuid],
      //         materials: { ...Object.fromEntries(Object.entries(materials[material.uuid].materials).filter(([key, _]) => key != oldMaterial.uuid)) },
      //       },
      //     };

      //     materials[material.uuid].materials[newMaterial.uuid] = newMaterial;

      //     oldMaterial.dispose();
      //   }

      //   Object.values(lights).forEach((light) => {
      //     scene.remove(light);
      //     light.dispose();
      //     lights = {};
      //   });

      //   alight = new AmbientLight(0xFFFFFF, 0.3);
      //   scene.add(alight);
      //   lights[alight.uuid] = alight;

      //   dlight = new DirectionalLight(0xFFFFFF, 2.5);
      //   scene.add(dlight);
      //   lights[dlight.uuid] = dlight;

      //   await deleteFolder(storageRef(getStorage(), `maps/${store.getState().storage.version}/${material.object.userData.id}`));
      //   await set(databaseRef(getDatabase(), `maps/${store.getState().storage.version}/${material.object.userData.id}`), null);
      // }

      if (updates) {
        Object.entries(updates).forEach(([key, value]) => {
          materials[material.uuid].materials[material.material.uuid][key] = value;

          // if (key == 'envMap') {
          //   envMap = value;
          //   scene.environment = envMap;
          //   scene.background = room.userData.showBackground && envMap ? envMap : new Color(background);
          // }

          if (keys && keys[key] !== undefined) {
            materials[material.uuid].materials[material.material.uuid].userData.textures[key].index = keys[key];
          }
        });

        materials[material.uuid].materials[material.material.uuid].needsUpdate = true;
      }

      render();
    } catch (e) { console.log(e) }
  }
);

export const updateMaterials = createAsyncThunk(
  'threejs/updateMaterials',
  async ({ selectedMaps, mapList = [], loader, update = true }) => {
    try {
      if (!scene) return;

      const mapProgresses = Object.fromEntries(mapList.map((uuid) => [uuid, 0]));
      if (loader && Object.keys(mapProgresses).length < 1) loader.setValue(100);

      const _materials = Object.values(materials)
        .reduce((obj1, object) => ({
          ...obj1, ...Object.values(object.materials).reduce((obj2, material) =>
            ({ ...obj2, [material.userData.id]: material })
            , {})
        }), {});

      const maps = store.getState().threejs.maps;

      const newMaps = await Promise.all(Object.entries(maps).map(([id, _maps]) =>
        Promise.all(Object.entries(_maps).map(([key, keyMaps]) => new Promise(async (resolve, reject) => {
          const i = selectedMaps?.[id]?.[key] != -1 ? selectedMaps?.[id]?.[key] : _materials[id].userData.textures[key].index;

          let texture = keyMaps[i]?.texture //|| textures[keyMaps[i]?.url];

          if (i == null || i == undefined) {
            _materials[id][key] = null;
            delete _materials[id].userData.textures[key].index;
            // dispatch(updateMaterial({ material, updates: { [id]: null }, keys: { [id]: i } }));
          } else {
            if (!texture) {
              if (!keyMaps[i]?.url) return resolve([i, null]);

              // const loader = keyMaps[i].type == 'hdr' ? rgbeLoader : textureLoader;
              texture = await textureLoader.loadAsync(keyMaps[i].url);
              if (loader) {
                mapProgresses[keyMaps[i].uuid] = 1;
                loader.setValue(50 + (50 * (Object.values(mapProgresses).reduce((sum, progress) => sum + progress, 0) / Object.keys(mapProgresses).length)));
              }

              texture.colorSpace = SRGBColorSpace;
              // console.log(_materials, id)
              properties.forEach((property) => {
                if (property in _materials[id].userData.textures[key]) texture[property] = _materials[id].userData.textures[key][property];
              });

              // textures[keyMaps[i].url] = texture;

              // if (keyMaps[i].type == 'hdr') texture.mapping = EquirectangularReflectionMapping;
              // texture.anisotropy = maxAnisotropy;
            }

            _materials[id][key] = texture;
            _materials[id].userData.textures[key].index = i;

            // if (_materials[id].userData.objectUuid == selectiveBloom.selection.values().next().value?.uuid) {
            //   selectiveBloom.selection.values().next().value.material[key] = texture;
            // }
          }

          _materials[id].needsUpdate = true;

          resolve([i, { ...keyMaps[i], texture }]);
        })
          .then(([i, map]) => [key, i != undefined && i != null ? [...keyMaps.slice(0, i), map, ...keyMaps.slice(i + 1)] : null])
        ))
          .then((entries) => entries.filter(([_, keyMaps]) => keyMaps))
          .then((entries) => [id, { ...maps[id], ...Object.fromEntries(entries) }])
      ))
        .then((entries) => Object.fromEntries(entries));

      // store.dispatch(setMaps(newMaps));

      Object.entries(_materials).forEach(([id, material]) => {
        if (Array.isArray(material)) {
          material.forEach((_material) => {
            keys.forEach((key) => {
              if (_material[key] && !maps?.[id]?.[key]) _material[key] = null;
            });
          });
        } else {
          keys.forEach((key) => {
            if (material[key] && !maps?.[id]?.[key]) material[key] = null;
          });
        }
      });

      let environment = Object.entries(lights).find(([_, light]) => light.type == 'Environment');

      // if (!includeDefaultEnvMap && environment?.[0] == 'Default') environment = null;

      // const _environment = store.getState().threejs.environments.find((__environment) => __environment.uuid == environment?.[0]);
      // await loadEnvironmentToScene({
      //   src: _environment?.url,
      //   uuid: _environment?.uuid,
      //   type: _environment?.type,
      // }, false);

      render();

      return update;
    } catch (e) { console.log(e) }
  }
);

export const updateMaterialProperties = createAsyncThunk(
  'threejs/updateMaterialProperties',
  (properties) => {
    try {
      if (!scene) return;

      const objects = Object.values(materials)
        .reduce((obj1, object) => ({
          ...obj1, ...Object.values(object.materials).reduce((obj2, material) =>
            ({ ...obj2, [material.userData.id]: { ...obj1[material.userData.id], ...obj2[material.userData.id], [object.object.uuid]: object.object } })
            , {})
        }), {});

      const _materials = Object.values(materials)
        .reduce((obj1, object) => ({
          ...obj1, ...Object.values(object.materials).reduce((obj2, material) =>
            ({ ...obj2, [material.userData.id]: material })
            , {})
        }), {});

      Object.entries(properties).forEach(([id, _properties]) => {
        Object.entries(_properties).map(([key, value]) => {
          if (typeof _materials[id][key] == 'object' && _materials[id][key].isColor) {
            _materials[id][key] = new Color(value);
          }
          else {
            _materials[id][key] = value;
          }
        });
      });

      render();
    } catch (e) { console.log(e) }
  }
);

export const setMaterial = createAsyncThunk(
  'threejs/setMaterial',
  ({ material, highlight = false }) => {
    try {
      // if (outlinePass.selectedObjects?.[0]?.uuid == material.uuid) {
      //   outlinePass.selectedObjects = [];
      // }
      // else {
      //   outlinePass.selectedObjects = [materials[material.uuid].object];
      // }

      if (!highlight) return material;

      room.traverse((object) => {
        if (object.material && object.userData.material) {
          // (Array.isArray(object.material) ? object.material : [object.material]).forEach((material) => material.dispose());

          object.material = object.userData.material;

          // object.userData.material = uvMap ? uvMapMesh : null;
        }
      });

      if (material.object != selectiveBloom.selection.values().next().value) {
        material.object.userData.material = material.object.material;

        if (Array.isArray(material.object.material)) {
          const index = material.object.material.indexOf(material.material);

          material.object.material = [
            ...material.object.material.slice(0, index),
            uvMap ? uvMapMesh.clone() : material.object.material[index].clone(),
            ...material.object.material.slice(index + 1),
          ];
          material.object.material[index].color = new Color(0x0b8ce9);
          material.object.material[index].color.multiplyScalar(100);
          if (material.object.material[index].emissive) material.object.material[index].emissive = new Color(0x000000);
        } else {
          material.object.material = uvMap ? uvMapMesh.clone() : material.object.material.clone();
          material.object.material.color = new Color(0x0b8ce9);
          material.object.material.color.multiplyScalar(100);
          if (material.object.material.emissive) material.object.material.emissive = new Color(0x000000);
        }

        selectiveBloom.selection.clear();
        selectiveBloom.selection.add(material.object);
      }
      else {
        selectiveBloom.selection.clear();

        if (uvMap) {
          room.traverse((object) => {
            if (object.material) {
              (Array.isArray(object.material) ? object.material : [object.material]).forEach(() => {
                object.userData.material = object.material;

                object.material = uvMapMesh;
              });
            }
          });
        }
      }

      materials[material.uuid].object.visible = true;

      render();

      return material;
    } catch (e) { console.log(e) }
  }
);

export const toggleMaterial = createAsyncThunk(
  'threejs/toggleMaterial',
  ({ material, updates }) => {
    try {
      if (updates) {
        Object.entries(updates).forEach(([key, value]) => {
          materials[material.uuid].object[key] = value;
        });

        materials[material.uuid].object.needsUpdate = true;
      }

      render();
    } catch (e) { console.log(e) }
  }
);

const _initThreejs = (state, action) => {
  const {
    domElement: _domElement,
    webGLCanvas,
    renderCss2d,
  } = action.payload;

  domElement = _domElement;

  renderer = new WebGLRenderer({
    canvas: webGLCanvas,
    alpha: true,
    antialias: true,
    // preserveDrawingBuffer: true,
    // powerPreference: 'high-performance',
  });

  const width = domElement.clientWidth;
  const height = domElement.clientHeight;

  renderer.setSize(width, height);
  // renderer.useLegacyLights = false;
  // renderer.outputEncoding = SRGBColorSpace;
  // renderer.useLegacyLights = true;
  renderer.physicallyCorrectLights = true;
  // renderer.toneMappingExposure = 1;
  // renderer.toneMapping = LinearToneMapping;
  // renderer.setClearColor(0xcccccc);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.autoClear = false;

  if (renderCss2d) {
    cssRenderer = new CSS2DRenderer();
    cssRenderer.setSize(width, height);
  }

  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  // renderer.outputColorSpace = LinearSRGBColorSpace;
  maxAnisotropy = renderer.capabilities.getMaxAnisotropy();

  // Event listeners
  domElement?.addEventListener('pointermove', onMouseMove);
  // // domElement.addEventListener('wheel', onCameraZoom);
  domElement?.addEventListener('pointerdown', onMouseDown);

  if (controls) {
    controls.dispose();
    controls = new OrbitControls(camera, domElement || renderer.domElement);
    controls.enabled = true;
  }

  // state.loadingRoom = false;
  state.canvas = webGLCanvas || renderer.domElement;
  state.rgbeLoader = rgbeLoader;
};

const _changeCanvas = (state, action) => {
  const {
    domElement: _domElement,
    webGLCanvas,
    renderCss2d,
  } = action.payload;

  domElement?.removeEventListener('pointerdown', onMouseDown);
  domElement?.removeEventListener('pointermove', onMouseMove);

  domElement = _domElement;

  renderer.domElement = webGLCanvas;

  const width = domElement.clientWidth;
  const height = domElement.clientHeight;

  renderer.setSize(width, height);
  // renderer.useLegacyLights = false;
  // renderer.outputEncoding = SRGBColorSpace;
  // renderer.useLegacyLights = true;
  renderer.physicallyCorrectLights = true;
  // renderer.toneMappingExposure = 1;
  // renderer.toneMapping = LinearToneMapping;
  // renderer.setClearColor(0xcccccc);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.autoClear = false;

  if (renderCss2d) cssRenderer.setSize(domElement.clientWidth, renderer.domElement.width, domElement.clientHeight);

  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  // renderer.outputColorSpace = LinearSRGBColorSpace;
  maxAnisotropy = renderer.capabilities.getMaxAnisotropy();

  // Event listeners
  domElement?.addEventListener('pointermove', onMouseMove);
  domElement?.addEventListener('pointerdown', onMouseDown);

  state.canvas = webGLCanvas || renderer.domElement;
};

const initialState = {
  currObject: null,
  scene: null,
  loadingRoom: !!window.location.pathname == '/viewer',
  savingRoom: false,
  deletingRoom: false,
  creatingQrCode: false,
  deletedSharedSpaces: [],
  notes: {},
  canvas: null,
  ambientLight: 0.3,
  animationFrameIndex: 0,
  animationFrames: 0,
  animations: {
    isPaused: false,
    isPlaying: false,
    duration: 0,
    time: 0,
    clips: [],
  },
  hasAnimations: false,
  background: null,
  backgroundLoaded: false,
  comments: [],
  directionalLight: 2.5,
  edges: false,
  environmentLoaded: false,
  environments: null,
  exposure: 0,
  freeview: false,
  grid: false,
  lights: null,
  maps: null,
  mapsLoaded: false,
  mapsUploaded: false,
  material: null,
  materials: null,
  maxAnisotropy: 16,
  normals: false,
  objects: [],
  polycount: 0,
  rgbeLoader: null,
  settingLight: false,
  showBackground: false,
  tags: [],
  textures: [],
  type: null,
  uvMap: null,
  vertices: 0,
  wireframe: false,
};

const _resetThreejs = (state) => {
  if (room) disposeObject(room);
  room = null;

  if (scene) disposeObject(scene);
  scene = null;

  renderer?.dispose();

  Object.values(textures).forEach((texture) => texture.dispose());

  envMap = null;
  background = '#e5e5e5';

  materials = {};
  textures = {};
  vertices = 0;
  polycount = 0;
  edges = [];
  objects = {};
  noteObjects = {};
  lights = {};
  animations = [];
  grid = null;
  controls?.dispose();
  controls = null;
  transformControls?.dispose();
  transformControls = null;
  skinnedMeshHelper = null;
  effectComposer = null;
  renderPass = null;
  outlinePass = null;
  uvMap = null;

  if (state) {
    Object.entries(initialState).forEach(([key, value]) => {
      if (window.location.pathname != '/viewer' || key != 'canvas') state[key] = value;
    });
  }

  domElement?.removeEventListener('pointerdown', requestAnimationFrameOn);
  domElement?.removeEventListener('pointerup', requestAnimationFrameOff);
  domElement?.removeEventListener('wheel', renderOrbitControl);

  domElement?.removeEventListener('pointerdown', onMouseDown);
  domElement?.removeEventListener('pointermove', onMouseMove);
  window?.removeEventListener('resize', onResize);

  if (setNotesInterval) clearInterval(setNotesInterval);
};

export const threejsSlice = createSlice({
  name: 'threejs',
  initialState,
  reducers: {
    initThreejs: _initThreejs,
    changeCanvas: _changeCanvas,
    resetThreejs: _resetThreejs,
    handleSetLight: (state) => { state.lights = { ...lights } },
    mapsUploaded: (state) => { state.mapsUploaded = true },
    setAnimationFrame: (state) => { state.animations = { ...state.animations, time: mixer.time % state.animations.duration } },
    setCurrObject: (state, action) => {
      currObject = action.payload;
      state.currObject = currObject;
    },
    setEnvironments: (state, action) => {
      const { environments, canvasId } = action.payload;

      if (scene && renderer?.domElement?.id == canvasId) state.environments = [{ name: 'Default', isDefault: true, uuid: 'Default', url: 'Default' }, ...environments];
    },
    setMaps: (state, action) => { state.maps = action.payload },
    setNotes: (state) => {
      state.notes = { ...noteObjects };

      const comments = Object.entries(state.notes)
        .reduce((arr, [uuid, note]) => arr.concat(note.userData.comments.map((comment) => ({
          ...comment,
          uuid,
          completed: note.userData.completed,
          tags: note.userData.tags,
        }))), []);
      // .toSorted((comment1, comment2) => comment1.timestamp - comment2.timestamp);
      comments.sort((comment1, comment2) => comment1.timestamp - comment2.timestamp);

      const tags = _.uniqWith(Object.values(state.notes).reduce((arr, note) => arr.concat(note.userData.tags), []), _.isEqual);

      if (!_.isEqual(comments, state.comments)) state.comments = comments;
      if (!_.isEqual(tags, state.tags)) state.tags = tags;
    },
    setScene: (state, action) => {
      const { type } = action.payload || {};

      state.scene = scene;
      state.type = type;

      const _materials = Object.entries(materials)
        .reduce((arr, [uuid, object]) => arr.concat(Object.values(object.materials).map((material) => ({ material, uuid, object: object.object }))), [])
      // .toSorted((material1, material2) => material1.material.userData.name - material2.material.userData.name);
      _materials.sort((material1, material2) => material1.material.userData.name - material2.material.userData.name);

      state.materials = _materials;
      state.material = state.materials[0];

      state.polycount = polycount;
      state.vertices = vertices;
      state.maxAnisotropy = maxAnisotropy;
      state.objects = objects;
      state.lights = lights;
      state.animations = {
        isPaused: false,
        isPlaying: false,
        duration: animationsDuration,
        time: 0,
        clips: animations || [],
      };
      state.hasAnimations = animations?.length > 0;
      state.uvMap = uvMap;
      state.includeDefaultEnvMap = includeDefaultEnvMap;
    },
    setTextures: (state, action) => { state.textures = action.payload },
    settingLight: (state, action) => { state.settingLight = action.payload },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadObject.pending, (state) => { state.loadingRoom = true })
      .addCase(loadObject.fulfilled, (state) => { state.loadingRoom = false })
      .addCase(loadObject.rejected, (state) => { state.loadingRoom = false })
      .addCase(addLight.fulfilled, (state) => { state.lights = { ...lights } })
      .addCase(loadEnvironment.fulfilled, (state, action) => {
        if (!state.environmentLoaded) state.environmentLoaded = true;

        state.showBackground = action.payload;
        state.lights = { ...lights };
      })
      .addCase(removeLight.fulfilled, (state) => { state.lights = { ...lights } })
      .addCase(setAmbientLight.fulfilled, (state, action) => { state.ambientLight = action.payload })
      .addCase(setAnimation.fulfilled, (state, action) => {
        const isPaused = action.payload?.isPaused !== undefined ? action.payload.isPaused : state.animations.isPaused;
        const isPlaying = isPaused || !!renderVideo;
        const time = isPlaying ? (action.payload?.time || 0) : state.animations.time;

        state.animations = {
          ...state.animations,
          isPaused,
          isPlaying,
          time: time % state.animations.duration,
          clips: [...animations],
        };
      })
      .addCase(setDirectionalLight.fulfilled, (state, action) => { state.directionalLight = action.payload })
      .addCase(setExposure.fulfilled, (state, action) => { state.exposure = action.payload })
      .addCase(setMaterial.fulfilled, (state, action) => { state.material = action.payload })
      .addCase(toggleBackground.fulfilled, (state, action) => { state.showBackground = action.payload })
      .addCase(toggleEdges.fulfilled, (state, action) => { state.edges = action.payload })
      .addCase(toggleGrid.fulfilled, (state, action) => { state.grid = action.payload })
      .addCase(toggleMaterial.fulfilled, (state) => { state.material = { ...state.material } })
      .addCase(toggleNormals.fulfilled, (state, action) => { state.normals = action.payload })
      .addCase(toggleUVMap.fulfilled, (state) => { state.uvMap = uvMap })
      .addCase(toggleWireframe.fulfilled, (state, action) => { state.wireframe = action.payload })
      .addCase(toggleViewMode.fulfilled, (state, action) => { state.freeview = action.payload })
      .addCase(updateBackground.fulfilled, (state, action) => {
        if (!state.backgroundLoaded) state.backgroundLoaded = true;
        state.background = action.payload;
        state.showBackground = false;
      })
      .addCase(updateLight.fulfilled, (state) => { state.lights = { ...lights } })
      .addCase(updateLights.fulfilled, (state) => {
        if (!state.environmentLoaded) state.environmentLoaded = true;

        state.lights = { ...lights };
      })
      .addCase(updateMaterial.fulfilled, (state) => { state.material = { ...state.material } })
      .addCase(updateMaterialProperties.fulfilled, (state) => {
        const _materials = Object.entries(materials)
          .reduce((arr, [uuid, object]) => arr.concat(Object.values(object.materials).map((material) => ({ material, uuid, object: object.object }))), [])
        // .toSorted((material1, material2) => material1.material.userData.name - material2.material.userData.name);
        _materials.sort((material1, material2) => material1.material.userData.name - material2.material.userData.name);

        state.materials = _materials;
        state.material = { ...state.material };
      })
      .addCase(updateMaterials.fulfilled, (state, action) => {
        if (!state.mapsLoaded) state.mapsLoaded = true;

        if (action.payload) {
          const _materials = Object.entries(materials)
            .reduce((arr, [uuid, object]) => arr.concat(Object.values(object.materials).map((material) => ({ material, uuid, object: object.object }))), [])
          // .toSorted((material1, material2) => material1.material.userData.name - material2.material.userData.name);
          _materials.sort((material1, material2) => material1.material.userData.name - material2.material.userData.name);

          state.materials = _materials;
        }
      })
      .addCase(updateNotes.fulfilled, (state) => {
        state.notes = { ...noteObjects };

        const comments = Object.entries(state.notes)
          .reduce((arr, [uuid, note]) => arr.concat(note.userData.comments.map((comment) => ({
            ...comment,
            uuid,
            completed: note.userData.completed,
            tags: note.userData.tags,
          }))), []);
        // .toSorted((comment1, comment2) => comment1.timestamp - comment2.timestamp);
        comments.sort((comment1, comment2) => comment1.timestamp - comment2.timestamp);

        const tags = _.uniqWith(Object.values(state.notes).reduce((arr, note) => arr.concat(note.userData.tags), []), _.isEqual);

        if (!_.isEqual(comments, state.comments)) state.comments = comments;
        if (!_.isEqual(tags, state.tags)) state.tags = tags;
      });
  },
});

export const {
  addText,
  editRoom,
  focusModel,
  moveNote,
  initThreejs,
  changeCanvas,
  resetThreejs,
  rotateModel,
  scaleModel,
  setEnvironments,
  setPipeline,
  setTextures,
  toggleAudio,
  toggleInfo,
  toggleScreenshare,
  updateNfts,
} = threejsSlice.actions;

export const getCanvas = (state) => state.threejs.canvas;
export const getCurrObject = (state) => state.threejs.currObject;
export const getCurrObjectAudioMuted = (state) => state.threejs.currObjectAudioMuted;
export const getCurrObjectScale = (state) => state.threejs.currObjectScale;
export const getCurrObjectShowInfo = (state) => state.threejs.currObjectShowInfo;
export const getNotes = (state) => state.threejs.notes;
export const getScene = (state) => state.threejs.scene;
export const getScreenshare = (state) => state.threejs.screenshare;
export const getDeletedSharedSpaces = (state) => state.threejs.deletedSharedSpaces;
export const getLoadingRoom = (state) => state.threejs.loadingRoom;
export const getSavingRoom = (state) => state.threejs.savingRoom;
export const getDeletingRoom = (state) => state.threejs.deletingRoom;
export const getCreatingQrCode = (state) => state.threejs.creatingQrCode;
export const getAmbientLight = (state) => state.threejs.ambientLight;
export const getAnimations = (state) => state.threejs.animations;
export const getAnimationsExist = (state) => state.threejs.hasAnimations;
export const getBackground = (state) => state.threejs.background;
export const getBackgroundLoaded = (state) => state.threejs.backgroundLoaded;
export const getComments = (state) => state.threejs.comments;
export const getDirectionalLight = (state) => state.threejs.directionalLight;
export const getEdges = (state) => state.threejs.edges;
export const getExposure = (state) => state.threejs.exposure;
export const getEnvironmentLoaded = (state) => state.threejs.environmentLoaded;
export const getEnvironments = (state) => state.threejs.environments;
export const getFreeview = (state) => state.threejs.freeview;
export const getGrid = (state) => state.threejs.grid;
export const getIncludeDefaultEnvMap = (state) => state.threejs.includeDefaultEnvMap;
export const getLights = (state) => state.threejs.lights;
export const getNormals = (state) => state.threejs.normals;
export const getMaterial = (state) => state.threejs.material;
export const getMaterials = (state) => state.threejs.materials;
export const getPolycount = (state) => state.threejs.polycount;
export const getRGBELoader = (state) => state.threejs.rgbeLoader;
export const getMaps = (state) => state.threejs.maps;
export const getMapsLoaded = (state) => state.threejs.mapsLoaded;
export const getMapsUploaded = (state) => state.threejs.mapsUploaded;
export const getMaxAnisotropy = (state) => state.threejs.getMaxAnisotropy;
export const getObjects = (state) => state.threejs.objects;
export const getSettingLight = (state) => state.threejs.settingLight;
export const getShowBackground = (state) => state.threejs.showBackground;
export const getTags = (state) => state.threejs.tags;
export const getTextures = (state) => state.threejs.textures;
export const getType = (state) => state.threejs.type;
export const getUVMap = (state) => state.threejs.uvMap;
export const getVertices = (state) => state.threejs.vertices;
export const getWireframe = (state) => state.threejs.wireframe;

export default threejsSlice.reducer;