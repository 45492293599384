import React from 'react';

export default function Footer() {
    return (
        <footer className="bg-white" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">Footer</h2>
            <div className="mx-auto max-w-7xl px-6 pb-28 lg:px-8 border-t border-gray-900/10 pt-16">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8">
                        <img className="h-7" src="/images/logo_small.png" alt="Modelsend logo" />
                        <div className="text-sm leading-6 text-gray-600">3D commissions made easy. Sell more 3D models with Modelsend.</div>
                        <div className="flex space-x-6">
                            <a href="https://twitter.com/modelsendHQ" className="text-gray-400 hover:text-gray-500" target="_blank">
                                <span className="sr-only">Twitter</span>
                                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/company/modelsend/" className="text-gray-400 hover:text-gray-500" target="_blank">
                                <span className="sr-only">Linkedin</span>
                                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                                    <path fillRule="evenodd" d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z" clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="md:grid md:grid-cols-3 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold leading-6 text-gray-900">Product</h3>
                                <ul role="list" className="mt-6 space-y-4 list-none p-0">
                                    <li className="p-0 m-0"><a href="/" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Marketplace</a></li>
                                    <li className="p-0 m-0"><a href="/pricing" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Pricing</a></li>
                                    <li className="p-0 m-0"><a href="/blog" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Blog</a></li>
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm font-semibold leading-6 text-gray-900">Contact</h3>
                                <ul role="list" className="mt-6 space-y-4 list-none p-0">
                                    <li className="p-0 m-0"><a href="https://twitter.com/modelsendHQ" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Twitter</a></li>
                                    <li className="p-0 m-0"><a href="https://www.linkedin.com/company/modelsend/" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Linkedin</a></li>
                                    <li className="p-0 m-0"><a href="mailto: support@modelsend.com" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Email</a></li>
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm font-semibold leading-6 text-gray-900">Company</h3>
                                <ul role="list" className="mt-6 space-y-4 list-none p-0">
                                    <li className="p-0 m-0"><a href="https://modelsend.notion.site/modelsend/Modelsend-Terms-Conditions-989203f77950442291633dfb63faf437" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Terms of Service</a></li>
                                    <li className="p-0 m-0"><a href="https://modelsend.notion.site/modelsend/Modelsend-Privacy-Policy-4f5a4a15118b40549a29872a9101782d" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}