import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import { onValue, ref as databaseRef, get } from 'firebase/database';
import { ref as storageRef, listAll, getDownloadURL, getBlob, deleteObject } from 'firebase/storage';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import Loader from 'components/Loader';
import OutputScreen from 'components/OutputScreen';
import Password from 'components/Password';
import DesktopOverlayUI from '../DesktopOverlayUI';

import {
  getCanvas,
  loadObject,
  getScene,
  getBackground,
  getComments,
  getEnvironmentLoaded,
  getEnvironments,
  getMaps,
  getMapsLoaded,
  getNotes,
  getLoadingRoom,
  setEnvironments,
  setMaps,
  setTextures,
  updateBackground,
  updateLights,
  updateMaterialProperties,
  updateMaterials,
  updateNotes,
  loadEnvironment,
} from 'modules/redux/threejs';
import { getPayloadFromToken, deleteFolder } from 'modules/utils';

export default function ViewerPage() {
  const dispatch = useDispatch();

  const database = useDatabase();

  const storage = useStorage();

  const canvas = useSelector(getCanvas);
  const scene = useSelector(getScene);
  const background = useSelector(getBackground);
  const comments = useSelector(getComments);
  const environments = useSelector(getEnvironments);
  const maps = useSelector(getMaps);
  const notes = useSelector(getNotes);

  const loadingRoom = useSelector(getLoadingRoom);
  const environmentLoaded = useSelector(getEnvironmentLoaded);
  const mapsLoaded = useSelector(getMapsLoaded);

  const loaderRef = React.useRef();

  const [token, setToken] = React.useState(null);
  const [object, setObject] = React.useState(null);

  const fetchToken = async () => {
    const redirectRef = databaseRef(database, `redirects/${window.location.pathname.split('/')[2]}`);
    const token = await get(redirectRef).then((snapshot) => snapshot.val()?.split('/viewer?token=')?.[1])
      .catch(() => null);

    setToken(token);
  };

  React.useEffect(() => { fetchToken() }, []);
  
  const init = async (token) => {
    const { autoplay = false, background = '#e5e5e5' } = await get(databaseRef(database, `objects/${getPayloadFromToken(token).object}`))
      .then((snapshot) => snapshot.val());

    const thumbnail = await listAll(storageRef(storage, `thumbnails/${getPayloadFromToken(token).object}`))
      .then((ref) => getDownloadURL(ref.items[0]));

    setObject({ autoplay, background, thumbnail });
  };

  React.useEffect(() => { if (token) init(token) }, [token]);

  const handleLoadObject = async () => {
    const objectRef = storageRef(storage, `objects/${getPayloadFromToken(token).object}`);

    const { url, type, originalType } = await new Promise(async (resolve, reject) => {
      try {
        let ref = await listAll(objectRef);

        if (ref.prefixes[0]) ref = await listAll(ref.prefixes[0]);

        let _url, _type, name;
        if (ref.items[0]) {
          _url = await getDownloadURL(ref.items[0]);
          _type = ref.items[0].name.split('.')[ref.items[0].name.split('.').length - 1].toLowerCase();
          name = await get(databaseRef(database, `objects/${getPayloadFromToken(token).object}/name`))
            .then((snapshot) => snapshot.val() || null)
            .catch(() => null);
        }

        resolve({
          url: _url,
          type: _type,
          originalType: name ? name.split('.')[name.split('.').length - 1].toLowerCase() : null,
        });
      } catch (e) { resolve({}) };
    });

    const environmentsDatabaseRef = databaseRef(database, `environments/${getPayloadFromToken(token).object}`);
    onValue(environmentsDatabaseRef, async () => {
      const environmentsRef = storageRef(storage, `environments/${getPayloadFromToken(token).object}`);

      const environments = await new Promise(async (resolve, reject) => {
        try {
          const ref = await listAll(environmentsRef);

          const _environments = await Promise.all(ref.prefixes.map((uuid) => listAll(uuid)
            .then((file) => getDownloadURL(file.items[0])
              .then((url) => ({ url, name: file.items[0].name, uuid: uuid.name, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() }))
              .catch(() => null))
          ))
            .then((__environments) => __environments.filter((environment) => environment));

          // const _environments = await Promise.all(ref.items.map((ref) => getDownloadURL(ref)
          //   .then((url) => ({ url, name: ref.name }))
          //   .catch(() => null)
          // ))
          //   .then((__environments) => __environments.filter((environment) => environment));

          resolve(_environments);
        } catch (e) { resolve([]) };
      });

      dispatch(setEnvironments(environments));
    });

    const mapsDatabaseRef = databaseRef(database, `maps/${getPayloadFromToken(token).object}`);
    onValue(mapsDatabaseRef, async (snapshot) => {
      const mapsRef = storageRef(storage, `maps/${getPayloadFromToken(token).object}`);

      const storageMaps = await new Promise(async (resolve, reject) => {
        try {
          const ref = await listAll(mapsRef);

          const _storageMaps = await Promise.all(ref.prefixes.map((map) => listAll(map)
            .then((file) => getDownloadURL(file.items[0])
              .then((url) => [map.name, ({ url, uuid: map.name, name: file.items[0].name, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() })])
            )
          ))
            .then((maps) => Object.fromEntries(maps));

          // const _storageMaps = await Promise.all(ref.prefixes.map((key) => listAll(key)
          //   .then((maps) => Promise.all(maps.prefixes.map((map) => listAll(map)
          //     .then((file) => getDownloadURL(file.items[0])
          //       .then((url) => ({ url, uuid: map.name, name: file.items[0].name, type: file.items[0].name.split('.')[file.items[0].name.split('.').length - 1].toLowerCase() }))
          //     )
          //   )))
          //   .then((maps) => [key.name, maps.filter((map) => map)])
          //   // .then((maps) => [key.name, Object.fromEntries(maps.filter((map) => map).map((map) => [map.uuid, map]))])
          // ))
          //   .then((maps) => Object.fromEntries(maps))

          resolve(_storageMaps);
        } catch (e) { resolve([]) };
      });

      const databaseMaps = { ...snapshot.val() || {} };

      const _maps = Object.fromEntries(Object.entries(databaseMaps).map(([id, _maps]) =>
        [id, Object.fromEntries(Object.entries(_maps).map(([key, __maps]) =>
          [key, Object.keys(__maps).map((uuid) => storageMaps[uuid])]
        ))]
      ));

      dispatch(setMaps(_maps));
    });

    if (url && type) {
      dispatch(loadObject({
        url,
        type,
        originalType,
        onProgress: ({ loaded, total }) => loaderRef.current.setValue(50 * (loaded / total)),
      }));
    } else {
      window.location = '/';
    }
  }

  React.useEffect(() => { if (canvas && object?.autoplay) handleLoadObject() }, [canvas, object?.autoplay]);

  const [notesUnsubscribe, setNotesUnsubscribe] = React.useState(null);

  React.useEffect(() => {
    if (!scene || !getPayloadFromToken(token).object) return;

    if (notesUnsubscribe) notesUnsubscribe.unsubscribe();

    const notesRef = databaseRef(database, `objects/${getPayloadFromToken(token).object}/reviews`);
    const unsubscribe = onValue(notesRef, async (snapshot) => {
      const notes = snapshot.val() || {};

      dispatch(updateNotes(notes));
    });

    setNotesUnsubscribe({ unsubscribe });

    return unsubscribe;
  }, [scene, getPayloadFromToken(token).object]);

  const [mapIndices, setMapIndices] = React.useState(null);
  const [mapsUnsubscribe, setMapsUnsubscribe] = React.useState(null);

  React.useEffect(() => {
    if (!scene || !maps || !getPayloadFromToken(token).object) return;

    if (mapsUnsubscribe) mapsUnsubscribe.unsubscribe();

    const mapsRef = databaseRef(database, `objects/${getPayloadFromToken(token).object}/maps`);

    const unsubscribe = onValue(mapsRef, async (snapshot) => {
      const selectedMaps = { ...snapshot.val() || {} };

      let mapList;
      if (loaderRef.current) {
        mapList = Object.values(selectedMaps).reduce((idArr, idMaps) =>
          [...idArr, ...Object.values(idMaps).reduce((keyArr, uuid) =>
            [...keyArr, uuid]
            , [])]
          , []);
      }

      const _mapIndices = Object.fromEntries(Object.entries(selectedMaps).map(([id, _maps]) =>
        [id, Object.fromEntries(Object.entries(_maps).map(([key, uuid]) =>
          [key, (maps?.[id]?.[key] || []).indexOf((maps?.[id]?.[key] || []).find((map) => map?.uuid == uuid))]
        )
          .filter(([_, index]) => index !== null && index !== undefined))]
      ));

      if (!_.isEqual(mapIndices, _mapIndices) || (mapIndices && !mapsLoaded)) {
        setMapIndices(_mapIndices);
        dispatch(updateMaterials({ selectedMaps: _mapIndices, mapList, loader: loaderRef.current }));
      }
    });

    setMapsUnsubscribe({ unsubscribe });

    return unsubscribe;
  }, [scene, maps, getPayloadFromToken(token).object]);

  const [otherUnsubscribe, setOtherUnsubscribe] = React.useState(null);

  React.useEffect(() => {
    if (!scene || !environments || !getPayloadFromToken(token).object) return;

    if (otherUnsubscribe) otherUnsubscribe.unsubscribe();

    const propertiesRef = databaseRef(database, `objects/${getPayloadFromToken(token).object}/properties`);
    const propertiesUnsubscribe = onValue(propertiesRef, async (snapshot) => {

      const properties = { ...snapshot.val() || {} };

      dispatch(updateMaterialProperties(properties));
    });

    const lightsRef = databaseRef(database, `objects/${getPayloadFromToken(token).object}/lights`);
    const lightsUnsubscribe = onValue(lightsRef, async (snapshot) => {
      const lights = { ...snapshot.val() || {} };

      dispatch(updateLights(lights));
    });

    const backgroundRef = databaseRef(database, `objects/${getPayloadFromToken(token).object}/background`);
    const backgroundUnsubscribe = onValue(backgroundRef, async (snapshot) => {
      const background = snapshot.val() || '#e5e5e5';

      dispatch(updateBackground(background));
    });

    const unsubscribe = () => {
      propertiesUnsubscribe();
      lightsUnsubscribe();
      backgroundUnsubscribe();
    };

    setOtherUnsubscribe({ unsubscribe });

    return unsubscribe;
  }, [scene, environments, getPayloadFromToken(token).object]);

  const [authenticated, setAuthenticated] = React.useState(false);
  const [password, setPassword] = React.useState(null);

  const fetchPassword = async () => {
    if (!getPayloadFromToken(token).project || editable) return setAuthenticated(true);

    const password = await get(databaseRef(database, `projects/${getPayloadFromToken(token).project}/password`)).then((snapshot) => snapshot.val());

    if (!password) return setAuthenticated(true);

    setPassword(getPayloadFromToken(password).password);
  };

  if (!token) return null;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: object?.background,
      }}>
      {(object?.autoplay && (loadingRoom || !mapsLoaded || !environmentLoaded || !background)) &&
        <Box
          sx={{
            position: 'absolute',
            height: 'inherit',
            width: 'inherit',
            zIndex: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'secondary.dark',
          }}>
          <Loader ref={loaderRef} sx={{ color: 'info.main' }} />
        </Box>
      }
      {!object?.autoplay && object?.thumbnail &&
        <Card
          sx={{
            width: 'inherit',
            height: 'inherit',
            borderRadius: '0px',
          }}>
          <CardActionArea
            component='span'
            onClick={() => {
              setObject({ ...object, autoplay: true });
            }}
            sx={{
            }}>
            <CardMedia
              component='img'
              image={object.thumbnail}
              loading='lazy'
              sx={{ objectFit: 'cover' }}
            />
          </CardActionArea>
        </Card>
      }
      {!object?.autoplay &&
        <Box
          sx={{
            position: 'absolute',
          }}>
          Click for 3D
        </Box>
      }
      {object?.autoplay &&
        <OutputScreen width='inherit' height='inherit' renderCss2d>
          {!loadingRoom && mapsLoaded && environmentLoaded && <DesktopOverlayUI />}
        </OutputScreen>
      }
    </Box>
  );
}