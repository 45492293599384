import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { columns } from "./components/tasks/columns"
import { columnsFollow } from "./components/follow/columns-follow"
import { columnsPurchase } from "./components/purchase/columns-purchase"
import { DataTable } from "./components/tasks/data-table"
import { DataTablePurchase } from "./components/purchase/data-table-purchase"
import { DataTableFollow } from "./components/follow/data-table-follow"

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

import { cn } from '@/lib/utils';

export default function Tasks({
  ...props
}) {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { tab } = useParams();

  const handleValueChange = (value) => navigate(`${pathname.split('/')[1] == 'seller' ? '/seller' : ''}/${value}`);

  return (
    <div
      className={cn(
        "md:container px-4 flex-col space-y-8 md:flex min-h-screen overflow-y-auto",
        pathname.split('/')[1] == 'seller' ? "p-8" : "",
      )}>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Welcome back!</h2>
          <p className="text-muted-foreground">
            {`Here's a list of your projects and purchases!`}
          </p>
        </div>
        <div className="flex items-center space-x-2">
        </div>
      </div>
      <Tabs value={tab} onValueChange={handleValueChange}>
        <TabsList className="mb-5 h-10">
          <TabsTrigger value="projects" className="w-28">Projects</TabsTrigger>
          <TabsTrigger value="purchases" className="w-28">Purchases</TabsTrigger>
          {/* <TabsTrigger value="following" className="w-28">Following</TabsTrigger> */}
        </TabsList>
        <TabsContent value="projects">
          <DataTable columns={columns} {...props} />
        </TabsContent>
        <TabsContent value="purchases">
          <DataTablePurchase columns={columnsPurchase} />
        </TabsContent>
        <TabsContent value="following">
          <DataTableFollow columns={columnsFollow} />
        </TabsContent>
      </Tabs>
    </ div>
  );
}
