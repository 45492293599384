import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAuth,
  useDatabase,
  useFunctions,
  useSigninCheck,
  useStorage,
  useUser,
} from 'reactfire';
import {
  AutoDraft,
  ChatProvider,
} from "@chatscope/use-chat";

import RootLayout from '@/app/layout/seller';
import Mail from '@/app/mail/mail';
import Tasks from '@/app/tasks/tasks';
import Forms from '@/app/forms/forms';
import FormsLayout from '@/app/forms/layout';
import FormsAccount from '@/app/forms/account/account';
import FormsNotifications from '@/app/forms/notifications/notifications';
import Products from '@/app/products/products';

import useChat from '@/modules/useChat';
import useDownloadRecentPurchases from '@/modules/useDownloadRecentPurchases';
import useProjects from '@/modules/useProjects';
import useQuery from '@/modules/useQuery';
import useThreejs from '@/modules/useThreejs';
import useUploadHandler from '@/modules/useUploadHandler';
import useWebsite from '@/modules/useWebsite';

import { set } from '@/modules/firebase/database';
import { setVersion } from '@/modules/redux/storage';
import { fetchUser, getUser } from '@/modules/redux/user';
import { getPayloadFromToken } from '@/modules/utils';

export default function SellerPage() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { tab } = useParams();

  const database = useDatabase();

  const { data: signInCheckResult } = useSigninCheck();

  const { data: user } = useUser();

  const { seller } = useSelector(getUser);

  React.useEffect(() => { if (!signInCheckResult.signedIn) navigate('/artists') }, [signInCheckResult.signedIn]);

  React.useEffect(() => { dispatch(fetchUser(true)) }, [user]);

  useDownloadRecentPurchases();

  const threejsProps = useThreejs({ loadObjectManually: true });

  const [form, setForm] = React.useState();
  const FormsDynamic = form == 'account' ? FormsAccount :
    form == 'notifications' ? FormsNotifications :
      Forms;

  React.useEffect(() => {
    if (![undefined, 'messages', 'projects', 'purchases', 'account'].includes(tab)) return navigate('/seller');

    dispatch(setVersion(null));
  }, [tab]);

  // React.useEffect(() => {
  //   if (userData && !['pro', 'premium', 'enterprise'].includes(userData?.plan) && curTab == 'projects') window.location = '/home';
  // }, [curTab, userData]);

  const uploadHandlerProps = useUploadHandler();

  const websiteProps = useWebsite();

  const { objects: websiteObjects, website: [, fetchWebsite] } = websiteProps;

  const projectsProps = useProjects();

  const { objects: projectObjects, projects: [, fetchProjects] } = projectsProps;

  const chatProps = useChat({
    projectObjects,
    websiteObjects,
    fetchProjects,
    fetchWebsite,
  });

  const { serviceFactory, storage } = chatProps;

  const queryProps = useQuery();

  return (
    <ChatProvider serviceFactory={serviceFactory} storage={storage} config={{
      typingThrottleTime: 250,
      typingDebounceTime: 900,
      debounceTyping: true,
      autoDraft: AutoDraft.Save | AutoDraft.Restore
    }}>
      <RootLayout>
        {['projects', 'purchases'].includes(tab) ?
          <Tasks
            {...projectsProps}
            {...uploadHandlerProps} /> :
          tab == 'messages' ?
            <Mail
              {...chatProps}
              {...projectsProps}
              {...uploadHandlerProps} /> :
            tab == 'account' ?
              <FormsLayout {...websiteProps} /> :
              <Products
                {...projectsProps}
                {...queryProps}
                {...threejsProps}
                {...websiteProps}
                {...uploadHandlerProps} />}
      </RootLayout>
    </ChatProvider>
  )
}

{/* <div className="w-full min-h-screen overflow-y-auto"></div> */ }