import { getTimezone } from 'countries-and-timezones';
import { getCountryData } from 'countries-list';

export const databaseURLs = (projectId) => ({
  asia: `https://${projectId}-asia.asia-southeast1.firebasedatabase.app`,
  eu: `https://${projectId}-eu.europe-west1.firebasedatabase.app`,
  default: `https://${projectId}-default-rtdb.firebaseio.com`,
});

export default ({ host, timeZone } = {}) => {
  let ENV;
  switch (host || window.location.host) {
    case 'modelsend.com':
    case 'dcomments-prod.web.app':
    case 'dcomments-prod.firebaseapp.com':
      ENV = 'PROD';
      break;
    case 'modelsend-test.web.app':
    case 'modelsend-test.firebaseapp.com':
      ENV = 'TEST';
      break;
    default:
      ENV = 'DEV';
  }

  const projectId = process.env[`REACT_APP_${ENV}_FIREBASE_PROJECT_ID`];
  let databaseURL, storageBucket;
  switch (getCountryData( getTimezone(timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone)?.countries?.[0] )?.continent) {
    case 'AS':
      databaseURL = databaseURLs(projectId).asia;
      storageBucket = `${projectId}-asia`;
      break;
    case 'EU':
      databaseURL = databaseURLs(projectId).eu;
      storageBucket = `${projectId}-eu`;
      break;
    default:
      databaseURL = databaseURLs(projectId).default;
      storageBucket = process.env[`REACT_APP_${ENV}_FIREBASE_STORAGE_BUCKET`];
  }
  
  return {
    apiKey: process.env[`REACT_APP_${ENV}_FIREBASE_API_KEY`],
    authDomain: process.env[`REACT_APP_${ENV}_FIREBASE_AUTH_DOMAIN`],
    projectId: process.env[`REACT_APP_${ENV}_FIREBASE_PROJECT_ID`],
    storageBucket,
    messagingSenderId: process.env[`REACT_APP_${ENV}_FIREBASE_MESSAGING_SENDER_ID`],
    appId: process.env[`REACT_APP_${ENV}_FIREBASE_APP_ID`],
    measurementId: process.env[`REACT_APP_${ENV}_FIREBASE_MEASUREMENT_ID`],
    databaseURL,
  };
};