"use client"

import React from 'react';
import { ColumnDef } from "@tanstack/react-table"

import { Badge } from "@/components/ui/badge"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Button } from "@/components/ui/button"

import { labels, priorities, statuses } from "../../data/data"
import { tasks } from "../../data/tasks"
import { Task } from "../../data/schema"
import { DataTableColumnHeader } from "../data-table-column-header"
import { DataTableRowActions } from "../data-table-row-actions"

export const columns = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column} title="Name"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[300px] truncate font-medium">
            {row.getValue("name")}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "comments",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column} title="Comments"
        className="justify-end"
      />
    ),
    cell: ({ row }) =>
      <div className="flex space-x-2 justify-end">
        <span className="max-w-[50px] truncate font-medium">
          {row.getValue("comments")}
        </span>
      </div>,
  },
  {
    accessorKey: "models",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column} title="Models"
        className="justify-end"
      />
    ),
    cell: ({ row }) =>
      <div className="flex space-x-2 justify-end">
        <span className="max-w-[50px] truncate font-medium">
          {row.getValue("models")}
        </span>
      </div>,
    enableSorting: true,
    enableHiding: true,
  },
  {
    accessorKey: "date",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column} title="Date"
        className="justify-end"
      />
    ),
    cell: ({ row }) =>
      <div className="flex space-x-2 justify-end">
        <span className="max-w-[120px] truncate font-medium">
          {row.getValue("date")}
        </span>
      </div>,
    enableSorting: true,
    enableHiding: true,
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column} title="Status"
        className="justify-end"
      />
    ),
    cell: ({ table, row }) => {
      const status = statuses.find(
        (status) => status.value === row.getValue("status")
      )

      if (!status) {
        return null
      }

      return (
        <div className="flex space-x-2 items-center justify-end">
          <Select
            defaultValue={status.value}
            onValueChange={(value) => {
              table.options.updateData(row.index, "status", value)
            }}
          >
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Select a status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {statuses.map(stat => (
                  <SelectItem
                    key={stat.value}
                    value={stat.value}
                  >
                    {stat.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    id: "viewer",
    cell: ({ table, row }) => 
      <div className="flex space-x-2 items-center justify-end">
        <Button onClick={() => table.options.navigateToViewer(row.index)}>3D viewer</Button>
      </div>,
  },
  {
    id: "actions",
    cell: ({ table, row }) =>
      <div className="flex space-x-2 items-center justify-end">
        <DataTableRowActions row={row} onSelect={(id) => {
          if (id == 'delete') table.options.deleteRow(row.index);
        }} />
      </div>,
  },
]
