import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useStorage, useUser } from 'reactfire';
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import {
    CopyIcon,
    CircleDollarSignIcon,
    CirclePlusIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    MailIcon,
    Link2Icon,
    ImageIcon,
    CircleUserIcon,
    LoaderCircle,
} from "lucide-react"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"

import { tabConfig } from "@/config/tab-config"

import { ProductTabs } from "@/components/products/products-tab"
import { UploadModelsDlg } from "@/components/upload-models-dlg"
import { ModelItemDlg } from '@/components/products/model-item-dlg';

import { httpsCallable } from '@/modules/firebase/functions';
import useThreejs from '@/modules/useThreejs';
import { getUser } from '@/modules/redux/user';
import { uuid, deleteFolder } from '@/modules/utils';
import { cn } from '@/lib/utils';

export default function Products({ ...props }) {

    const {
        isPublic = false,
        website: [website, fetchWebsite] = [{}, () => { }],
        projects: [, fetchProjects] = [, () => { }],
        loaderRef,
    } = props;
    const {
        username,
        background,
        profile,
        bio,
        facebook,
        instagram,
        twitter,
        email,
        website: other,
    } = website || {};

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const storage = useStorage();

    const { data: user } = useUser();

    const { seller } = useSelector(getUser);

    const handleCopyClick = () => navigator.clipboard.writeText(`${window.location.origin}/website/${username}`);

    const [creatingLink, setCreatingLink] = React.useState();

    const handleLinkProcessorClick = async (provider = 'stripe') => {
        try {
            setCreatingLink(provider);

            const { data = {} } = await httpsCallable('createAccountLink')({
                uid: user.uid,
                returnUrl: `${window.location.origin}/seller/website`,
                refreshUrl: `${window.location.origin}/seller/website`,
                type: provider,
            });

            window.location.href = data?.links?.[1]?.href || data?.url;
        }
        finally { setCreatingLink() };
    };

    const handleLinkClick = (e) => window.open(`${e.target.id == 'email' ? 'mailto: ' : ''}${website[e.target.id]}`);

    const [uploading, setUploading] = React.useState();
    const [models, setModels] = React.useState();
    const [tab, setTab] = React.useState(tabConfig.productTab[0]);

    const handleFilesUpload = (files) => {
        setModels([...files].map((file) => {
            file.id = uploading.value;
            file.title = '';
            file.category = '';
            file.description = '';
            file.termsAndConditions = '';
            file.price = '';

            return file;
        }));
    };

    const handleModelsSubmit = () => {
        setTab(tabConfig.productTab.find((t) => t.value == uploading.value));
        setUploading();
        setModels();
    };

    const handleBackgroundChange = async (e) => {
        if (e.target.files.length < 1) return;

        await deleteFolder(storageRef(storage, `websites/${user.uid}/background`));

        await uploadBytes(storageRef(storage, `websites/${user.uid}/background/${e.target.files[0].name}.webp`), e.target.files[0]);

        fetchWebsite();
    };

    const handleProfileChange = async (e) => {
        if (e.target.files.length < 1) return;

        const ele = await new Promise((resolve) => {
            const url = URL.createObjectURL(e.target.files[0]);
            let img = new Image();
            img.onload = () => {
                URL.revokeObjectURL(url);
                resolve(img);
            }
            img.src = url;
        });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d', { willReadFrequently: true });
        if (ele.height > ele.width) {
            canvas.height = Math.min(ele.height, 300);
            canvas.width = (ele.width / ele.height) * canvas.height;
        }
        else if (ele.width > ele.height) {
            canvas.width = Math.min(ele.width, 300);
            canvas.height = (ele.height / ele.width) * canvas.width;
        }
        else {
            canvas.width = Math.min(ele.width, 300);
            canvas.height = Math.min(ele.height, 300);
        }
        context.drawImage(ele, 0, 0, canvas.width, canvas.height);

        const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/webp', 1));

        await deleteFolder(storageRef(storage, `websites/${user.uid}/profile`));

        await uploadBytes(storageRef(storage, `websites/${user.uid}/profile/${e.target.files[0].name}.webp`), blob);

        fetchWebsite();
        fetchProjects();
    };

    return (
        <div className={cn(
            "w-full min-h-screen overflow-y-auto md:mt-0",
        )}>
            {!isPublic && <div className="flex h-[52px] items-center justify-between p-6">
                <div className="flex items-center space-x-2 md:w-auto w-full">
                    <Input
                        id="link"
                        value={username ? `${window.location.origin}/website/${username}` : ''}
                        readOnly
                        className="h-9 md:min-w-80 text-[0.8rem]"
                    />
                    <Button type="submit" size="sm" className="px-3" variant="outline" onClick={handleCopyClick}>
                        <span className="sr-only">Copy</span>
                        <CopyIcon className="h-4 w-4" />
                    </Button>
                </div>
                <nav className="flex items-center gap-4">
                    {seller && !seller.paymentsEnabled &&
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button className="md:flex hidden text-[0.8rem]">
                                    <CircleDollarSignIcon className="mr-2 w-4 h-4" />Link Payment Processor
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="mx-auto max-w-md">
                                <DialogHeader>
                                    <DialogTitle className="text-xl">Choose payment processor</DialogTitle>
                                </DialogHeader>
                                <div className="grid gap-2">
                                    <div className="border rounded-xl p-4 space-y-2.5">
                                        <DialogTitle className="text-xl">Stripe</DialogTitle>
                                        <DialogDescription>Click for more details</DialogDescription>
                                        <DialogDescription>Processing fee: 2.9% + 30¢</DialogDescription>
                                        <DialogDescription>Supported countries: See Stripe's full list of supported countries&nbsp;
                                            <button className="underline"
                                                onClick={() => window.open('https://docs.stripe.com/connect/cross-border-payouts#supported-countries')}>here</button>.
                                        </DialogDescription>
                                        <div className="w-full flex flex-row justify-end"><Button className="w-28" onClick={() => handleLinkProcessorClick('stripe')}>
                                            {creatingLink == 'stripe' ? <LoaderCircle className="animate-spin w-6 h-6" /> : 'Get started'}
                                        </Button></div>
                                    </div>
                                    <div className="border rounded-xl p-4 space-y-2.5">
                                        <DialogTitle className="text-xl">PayPal</DialogTitle>
                                        <DialogDescription>Click for more details</DialogDescription>
                                        <DialogDescription>Processing fee: 3.49% +&nbsp;
                                            <button className="underline"
                                                onClick={() => window.open('https://www.paypal.com/us/webapps/mpp/merchant-fees')}>fixed fee</button>.
                                        </DialogDescription>
                                        <DialogDescription>Supported countries: See PayPal's full list of supported countries&nbsp;
                                            <button className="underline"
                                                onClick={() => window.open('https://www.paypal.com/us/webapps/mpp/country-worldwide')}>here</button>.
                                        </DialogDescription>
                                        <div className="w-full flex flex-row justify-end"><Button className="w-28" onClick={() => handleLinkProcessorClick('paypal')}>
                                            {creatingLink == 'paypal' ? <LoaderCircle className="animate-spin w-6 h-6" /> : 'Get started'}
                                        </Button></div>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>}
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button className="md:flex hidden text-[0.8rem]">
                                <CirclePlusIcon className="mr-2 w-4 h-4" />Add files
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuGroup>
                                {tabConfig.productTab.map((tab) => <DropdownMenuItem
                                    key={tab.value}
                                    onSelect={() => setUploading(tab)}>
                                    {tab.title}
                                </DropdownMenuItem>)}
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <UploadModelsDlg
                        multiple
                        open={[!!uploading && !models, () => setUploading()]}
                        onFilesUpload={handleFilesUpload} />
                    <ModelItemDlg
                        models={models}
                        open={[!!models, handleModelsSubmit]}
                        editable
                        outputScreenLoaderRef={loaderRef}
                        {...props} />
                </nav>
            </div>}
            <div className="relative w-full h-48">
                <Button
                    variant="link"
                    disabled={isPublic}
                    className={cn(
                        "w-full h-48 flex justify-center item-center bg-gray-400 text-center rounded-none p-0 hover:opacity-70",
                        isPublic ? "disabled:opacity-100" : "",
                    )}
                    onClick={() => document.getElementById('background').click()}>
                    {background ?
                        <img src={background} className="w-full h-48 object-cover" /> :
                        <ImageIcon />
                    }
                    <input id="background" type="file" accept="image/*" hidden onChange={handleBackgroundChange} />
                </Button>
                <div className="absolute inset-x-1/2">
                    <div className="relative bg-background rounded-full w-24 h-24 bottom-12 -left-12">
                        <Button
                            variant="link"
                            disabled={isPublic}
                            className={cn(
                                "w-24 h-24 flex bg-gray-400 shrink-0 overflow-hidden rounded-full p-0 border-4 border-white hover:opacity-70",
                                isPublic ? "disabled:opacity-100" : "",
                            )}
                            onClick={() => document.getElementById('profile').click()}>
                            {profile ?
                                <img src={profile} className="w-24 h-24 object-cover" /> :
                                <CircleUserIcon />
                            }
                            <input id="profile" type="file" accept="image/*" hidden onChange={handleProfileChange} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-5 text-center mt-20 mb-10">
                <h3 className="text-xl leading-none font-bold tracking-tight">
                    {username ? `@${username}` : null}
                </h3>
                <p className="text-md leading-none text-muted-foreground">
                    {bio}
                </p>
                <div className="flex flex-row">
                    {facebook && <Button id="facebook" variant="ghost" size="icon" onClick={handleLinkClick}><FacebookIcon className="h-4 w-4" /></Button>}
                    {instagram && <Button id="instagram" variant="ghost" size="icon" onClick={handleLinkClick}><InstagramIcon className="h-4 w-4" /></Button>}
                    {twitter && <Button id="twitter" variant="ghost" size="icon" onClick={handleLinkClick}><TwitterIcon className="h-4 w-4" /></Button>}
                    {email && <Button id="email" variant="ghost" size="icon" onClick={handleLinkClick}><MailIcon className="h-4 w-4" /></Button>}
                    {other && <Button id="website" variant="ghost" size="icon" onClick={handleLinkClick}><Link2Icon className="h-4 w-4" /></Button>}
                </div>
            </div>
            <div className="w-full relative mr-auto md:px-28 px-4 mb-16">
                <ProductTabs tab={[tab, setTab]} tabs={tabConfig.productTab} {...props} />
            </div>
        </div>
    );
}
