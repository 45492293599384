import React from 'react';

import { cn } from "@/lib/utils"

import Header from "@/components/header"
import Footer from "@/components/footer"

import Three from '@/app/layout/three';

export default function RootLayout({ children, ...props }) {

    React.useEffect(() => {
        document.body.classList.add('overflow-y-hidden');
        document.getElementById('root').classList.add('overflow-y-hidden');
    }, []);

    return(
        <div 
            className={cn(
                "h-screen bg-background font-sans --font-sans-heading antialiased flex flex-col overflow-y-hidden"
            )}
        >
            <header className="md:container px-4 z-40 bg-background border-b border-gray-900/10">
                <Header {...props} />
            </header>
            <main className="flex-1 overflow-y-auto">
                {children}
                <Footer />
            </main>
            <Three />
        </div>
    )
}