import React from 'react';
import { useSelector } from 'react-redux';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectGroup,
    SelectValue,
} from "@/components/ui/select"

import { Button } from "@/components/ui/button"

import {
    getMaterial,
    getMaterials,
} from '@/modules/redux/threejs';

const materialTypes = {
    'MeshBasicMaterial': ['diffuse', 'specular', 'alpha', 'env', 'light', 'ao'],
    'MeshDepthMaterial': ['diffuse', 'alpha', 'displace'],
    'MeshNormalMaterial': ['bump', 'normal', 'displace'],
    'MeshLambertMaterial': ['diffuse', 'specular', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'env', 'light', 'ao'],
    'MeshMatcapMaterial': ['diffuse', 'matcap', 'alpha', 'bump', 'normal', 'displace'],
    'MeshPhongMaterial': ['diffuse', 'specular', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'env', 'light', 'ao'],
    'MeshToonMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'light', 'ao', 'gradient'],
    'MeshStandardMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'displace', 'rough', 'metal', 'env', 'light', 'ao'],
    'MeshPhysicalMaterial': ['diffuse', 'emissive', 'alpha', 'bump', 'normal', 'clearcoat-normal', 'displace', 'rough', 'metal', 'sheen-color', 'sheen-rough', 'env', 'light', 'ao', 'transmission', 'thickness'],
};

const mapItems = {
    diffuse: { id: 'map', label: 'Diffuse' },
    matcap: { id: 'matcap', label: 'Matcap' },
    specular: { id: 'specularMap', label: 'Specular' },
    emissive: { id: 'emissiveMap', label: 'Emissive' },
    alpha: { id: 'alphaMap', label: 'Alpha' },
    bump: { id: 'bumpMap', label: 'Bump', value: 'bumpScale' },
    normal: { id: 'normalMap', label: 'Normal', valueXY: 'normalScale' },
    displace: { id: 'displacementMap', label: 'Displace', value: 'displacementMap' },
    rough: { id: 'roughnessMap', label: 'Rough' },
    metal: { id: 'metalnessMap', label: 'Metal' },
    light: { id: 'lightMap', label: 'Light' },
    ao: { id: 'aoMap', label: 'AO', value: 'aoMapIntensity' },
    gradient: { id: 'gradientMap', label: 'Gradient' },
};

export function UploadTexturesPopover({
    children,
    multiple = false,
    contentProps = {},
    onUpload = async () => {},
}) {

    const materials = useSelector(getMaterials);
    const material = useSelector(getMaterial);

    const materialsList = Object.values(materials?.reduce((obj, material) => ({
        ...obj,
        [material.material.userData.id]: material,
    }), {}) || {});

    const [addingTexture, setAddingTexture] = React.useState();

    const handleAddingTextureClick = (open) => setAddingTexture(open ? { material, texture: materialTypes[material.material.type][0] } : undefined);
    const handleAddingMaterialSelect = (uuid) => setAddingTexture({ ...addingTexture, material: materials.find((m) => m.material.uuid == uuid) });
    const handleAddingMaterialTextureSelect = (key) => setAddingTexture({ ...addingTexture, texture: key });
    const handleAddingTextureSubmit = () => document.getElementById('texture').click();
    const handleAddingTextureUpload = async () => {
        if (!document.getElementById('texture').files || !document.getElementById('texture').files.length) return;

        await onUpload({ files: [...document.getElementById('texture').files], ...addingTexture });

        document.getElementById('texture').value = null;

        setAddingTexture();
    };

    return (<>
        <Popover open={!!addingTexture} onOpenChange={handleAddingTextureClick}>
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent className="w-80 p-5" {...contentProps}>
                <div className="flex flex-col space-y-5">
                    {!!addingTexture && <><div className="space-y-2">
                        <h2 className="text-sm font-medium">Add texture</h2>
                        <p className="text-sm text-muted-foreground">
                            Choose which material to add a texture to
                        </p>
                    </div>
                        <div className="space-y-2">
                            <h2 className="text-sm font-medium">Material</h2>
                            <Select
                                value={addingTexture.material.material.uuid}
                                onValueChange={handleAddingMaterialSelect}>
                                <SelectTrigger className="flex-1 h-6">
                                    <SelectValue placeholder="Example dropdown" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {materialsList?.map((m) => <SelectItem key={m.material.uuid} value={m.material.uuid}>
                                            {m.material.name}
                                        </SelectItem>)}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="space-y-2">
                            <h2 className="text-sm font-medium">Texture</h2>
                            <Select
                                value={addingTexture.texture}
                                onValueChange={handleAddingMaterialTextureSelect}>
                                <SelectTrigger className="flex-1 h-6">
                                    <SelectValue placeholder="Example dropdown" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {materialTypes[addingTexture.material.material.type]
                                            .filter((key) => mapItems[key])
                                            .map((key) => <SelectItem key={key} value={key}>
                                                {mapItems[key].label}
                                            </SelectItem>)}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex justify-end">
                            <Button className="" onClick={handleAddingTextureSubmit}>Continue</Button>
                        </div></>}
                </div>
            </PopoverContent>
        </Popover>
        <input
            id='texture'
            accept='.hdr, image/*'
            type='file'
            multiple={multiple}
            hidden
            onChange={handleAddingTextureUpload}
        />
    </>);
}