"use client"

import React from 'react';
import { ColumnDef } from "@tanstack/react-table"

import { Badge } from "@/components/ui/badge"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { labels, priorities, statuses } from "../../data/data"
import { DataTableColumnHeader } from "../data-table-column-header"
import { DataTableRowActions } from "../data-table-row-actions"
import { Button } from "@/components/ui/button"

export const columnsFollow = [
  {
    accessorKey: "image",
    header: ({ column }) => (
      <DataTableColumnHeader 
        column={column} title=""
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center justify-center">
          <div className="w-12 h-12">
            <img
                src={row.getValue("image")}
                alt="img"
                className="aspect-square object-cover rounded-md border max-w-full"
              />
          </div>
        </div>
      )
    },
    enableSorting: false,
    enableHiding: true,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader 
        column={column} title="Name"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[300px] truncate font-medium">
            {row.getValue("name")}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "followers",
    header: ({ column }) => (
      <DataTableColumnHeader 
        column={column} title="Followers" 
        className="justify-end"
      />
    ),
    cell: ({ row }) => 
      <div className="flex space-x-2 justify-end">
        <span className="max-w-[50px] truncate font-medium">
            {row.getValue("followers")}
        </span>
      </div>,
  },
  {
    accessorKey: "models",
    header: ({ column }) => (
      <DataTableColumnHeader 
        column={column} title="Models"
        className="justify-end"
      />
    ),
    cell: ({ row }) => 
      <div className="flex space-x-2 justify-end">
        <span className="max-w-[50px] truncate font-medium">
            {row.getValue("models")}
        </span>
      </div>,
    enableSorting: true,
    enableHiding: true,
  },
  {
    accessorKey: "date",
    header: ({ column }) => (
      <DataTableColumnHeader 
        column={column} title="Follow Date" 
        className="justify-end"
      />
    ),
    cell: ({ row }) => 
      <div className="flex space-x-2 justify-end">
        <span className="max-w-[120px] truncate font-medium">
            {row.getValue("date")}
        </span>
      </div>,
    enableSorting: true,
    enableHiding: true,
  },
  {
    id: "actions",
    cell: ({ row }) => 
      <div className="flex space-x-2 items-center justify-center">
        <Button>Unfollow</Button>
      </div>,
  },
]
