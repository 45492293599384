import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Popper,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import Comment from '../Comment';

import {
  getCurrObject,
  getNotes,
  setCurrObject,
  lookAt,
} from 'modules/redux/threejs';

export default function DesktopOverlayUI() {
  const dispatch = useDispatch();

  const currObject = useSelector(getCurrObject);
  const notes = useSelector(getNotes);

  addEventListener("load", function () {
    var viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
  })

  const ref = React.useRef();

  const notesRef = React.useRef({});

  const [initialized, setInitialized] = React.useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    Object.entries(notesRef.current).forEach(([uuid, element]) => {
      if (!element || !notes[uuid]) return;

      element.style.opacity = notes[uuid]?.userData.visible ? 1 : .3;
      element.style.display = notes[uuid]?.element?.style?.display;
      element.style.transform = notes[uuid]?.element?.style?.transform;
      element.style.zIndex = 4;

      if (!notes[uuid]?.userData.visible && currObject?.userData.uuid == uuid) dispatch(setCurrObject(null));
    });

    if (!initialized && urlParams.has('review') && notes[urlParams.get('review')]) setInitialized(true);
  }, [notes]);

  React.useEffect(() => { dispatch(lookAt(notes[urlParams.get('review')])) }, [initialized]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onNoteHover = useDebouncedCallback(({ element, object }) => {
    dispatch(setCurrObject(object));
    setAnchorEl(element);
  }, 250);

  React.useEffect(() => {
    if (!anchorEl && currObject) {
      setTimeout(() => dispatch(setCurrObject(null)), 250);
    }
  }, [anchorEl]);

  React.useEffect(() => {
    if (!currObject && anchorEl) {
      setAnchorEl(null);
    } else if (currObject) {
      setAnchorEl(notesRef.current[currObject.userData.uuid]);
    }
  }, [currObject]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          height: '100%',
          width: '100%',
          gridRowStart: 1,
          gridColumnStart: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>

      </Box>
      {
        Object.entries(notes).map(([uuid, note], i) => (
          <Avatar
            key={uuid}
            ref={(el) => (notesRef.current[uuid] = el)}
            {...(isMobile ? {
              onTouchStart: (e) => onNoteHover({ object: note, element: e.currentTarget }),
              onTouchEnd: onNoteHover.cancel,
            } : {
              onMouseEnter: (e) => onNoteHover({ object: note, element: e.currentTarget }),
              onMouseLeave: onNoteHover.cancel,
            })}
            sx={{
              position: 'absolute',
              backgroundColor: 'info.main',
              color: 'secondary.main',
              '&:hover': {
                backgroundColor: 'info.main',
              },
            }}>
            {i + 1}
          </Avatar>
        ))
      }
      <Popper
        anchorEl={anchorEl}
        placement={isMobile ? 'bottom' : 'bottom-start'}
        open={!!anchorEl && !!currObject && notesRef.current[currObject.userData.uuid] == anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setTagsOpen(false);
        }}
        sx={{ overflow: 'hidden', zIndex: 6 }}
        popperOptions={{
          placement: isMobile ? 'bottom' : 'bottom-start',
          strategy: 'absolute',
        }}
      >
        <Box
          sx={{
            width: 300,
            maxWidth: 300,
            maxHeight: Math.min(anchorEl?.style.transform ? ref.current?.clientHeight - Number(anchorEl?.style.transform.split('translate(')[2].split('px')[1].split(',')[1]) - 104 : 300, 300),
            backgroundColor: 'secondary.dark',
            padding: '10px',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);',
            borderRadius: '4px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}>
          {currObject?.userData.comments.map((_comment, i) => (
            <Comment
              key={i}
              comment={_comment}
              uuid={currObject.userData.uuid}
            />
          ))}
        </Box>
      </Popper>
    </>
  );
}