import React from 'react';
import { useParams } from 'react-router-dom';

export default function useQuery() {
    const { tab } = useParams();

    const [category, setCategory] = React.useState(false);
    const [fileType, setFileType] = React.useState(false);
    const [free, setFree] = React.useState(false);
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        setCategory(false);
        setFileType(false);
        setFree(false);
        setSearch('');
    }, [tab]);

    return {
        category: [category, setCategory],
        fileType: [fileType, setFileType],
        free: [free, setFree],
        search: [search, setSearch],
    };
}