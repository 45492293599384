import * as React from "react"
import { useDispatch } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import { ref as storageRef, deleteObject } from 'firebase/storage';
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

import { Toggle } from "@/components/ui/toggle"

import { ProjectItem } from "@/components/projects/project-item"

import { set, increment } from '@/modules/firebase/database';
import { fetchUser } from '@/modules/redux/user';
import { getFolderSize, deleteFolder } from '@/modules/utils';

export function ProjectList({
    models = [],
    project: [project,, fetchProject] = [{},, () => {}],
 }) {

    const dispatch = useDispatch();

    const database = useDatabase();

    const storage = useStorage();

    const { data: user } = useUser();

    const handleDelete = async (object) => {
        try {
            const objects = models;

            await Promise.all([object.root].concat(object.versions).map((objectUuid) =>
                (objects.find((_object) => _object.uuid == objectUuid) ?
                    Promise.resolve(objects.find((_object) => _object.uuid == objectUuid)) :
                    get(databaseRef(database, `objects/${objectUuid}`))
                        .then((snapshot) => snapshot.val())
                        .then((_object) => ({ ..._object, uuid: objectUuid }))
                )
                    .then((_object) => Promise.all([
                        // deleteObject(storageRef(storage, `objects/${object.uuid}/${object.metadata.name}`)),
                        Promise.all([
                            getFolderSize(storageRef(storage, `maps/${_object.uuid}`)),
                            getFolderSize(storageRef(storage, `environments/${_object.uuid}`)),
                            getFolderSize(storageRef(storage, `comments/${_object.uuid}`)),
                        ])
                            .then(([mapsSize, environmentsSize, commentsSize]) => Promise.all([
                                deleteFolder(storageRef(storage, `maps/${_object.uuid}`)),
                                deleteFolder(storageRef(storage, `thumbnails/${_object.uuid}`)),
                                deleteFolder(storageRef(storage, `comments/${_object.uuid}`)),
                                set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(- mapsSize - environmentsSize - commentsSize - _object.size)),
                            ])),
                        set(databaseRef(database, `projects/${project.uuid}/objects/${_object.uuid}`), null),
                        set(databaseRef(database, `objects/${_object.uuid}`), null),
                        set(databaseRef(database, `redirects/${_object.redirect}`), null),
                        set(databaseRef(database, `maps/${_object.uuid}`), null),
                        set(databaseRef(database, `environments/${_object.uuid}`), null),
                    ]))
            ));

            fetchProject();
            dispatch(fetchUser());
        } catch (e) { console.log(e) }
    }

    return (
        <div className="flex flex-col gap-4 my-4">
            <div className="relative">
                <ScrollArea>
                    <div className="grid md:grid-cols-4 grid-cols-2 justify-items-stretch gap-6">
                        {models.slice().reverse().map((model) => (
                            <ProjectItem
                                key={model.uuid}
                                model={model}
                                onDelete={() => handleDelete(model)}
                                aspectRatio="square"
                                width={300}
                                height={300}
                            />
                        ))}
                    </div>
                    <ScrollBar orientation="vertical" />
                </ScrollArea>
            </div>
        </div>
    );
}