import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDatabase,
  useUser,
} from 'reactfire';
import {
  ref as databaseRef,
  get,
} from 'firebase/database';

import { getUser, fetchUser } from '@/modules/redux/user';

export default function useProjects() {
  const dispatch = useDispatch();

  const database = useDatabase();

  const { data: user } = useUser();

  const userData = useSelector(getUser);

  const [initialized, setInitialized] = React.useState(false);

  const [projects, setProjects] = React.useState(null);
  const [objects, setObjects] = React.useState([]);

  React.useEffect(() => {
    if (!projects) return;

    setObjects((projects || [])
      .reduce((arr, project) => arr.concat(project.objects.map((object) => ({
        ...object,
        project: project.uuid,
      }))), []),
    );
  }, [projects]);

  const fetchProjects = async () => {
    const projectUuids = Object.values(userData.projects || {}).concat(Object.values(userData.editing || {}));

    if (projectUuids.length < 1) {
      setProjects([]);
      if (!initialized) setInitialized(true);
      return;
    };

    const projects = await Promise.all(projectUuids.map((projectUuid) =>
      get(databaseRef(database, `projects/${projectUuid}`))
        .then((snapshot) => snapshot.val() || {})
        .then((project) => Promise.all(Object.keys(project.objects || {}).map((objectUuid) =>
          get(databaseRef(database, `objects/${objectUuid}`))
            .then((snapshot) => {
              if (!snapshot.val()) return [];

              const { name, reviews, token, redirect, size } = snapshot.val();

              return Promise.all([Promise.resolve({ name, reviews: reviews || null, uuid: objectUuid, token, redirect, size })]
                .concat(Object.values(snapshot.val().versions || {}).map((version) => get(databaseRef(database, `objects/${version}`))
                  .then((snapshot) => snapshot.val() ? Promise.resolve(snapshot.val())
                    .then(({ name, reviews, size }) => ({ name, reviews: reviews || null, uuid: version, token, size, redirect })) : null)))
              )
                .then((objects) => objects.filter((o) => o))
            })
        ))
          .then((objects) => ({
            ...project,
            objects: objects.reduce((arr, o) => arr.concat(o), []),
            uuid: projectUuid,
          }))
        )
    ))
      .then((projects) => projects.map((project, index) => ({ ...project, index })));
  
    projects.reverse();

    setProjects(projects);

    if (!initialized) setInitialized(true);
  };

  const [projectUuids, setProjectUuids] = React.useState();

  React.useEffect(() => {
    if (userData?.projects && !_.isEqual(userData.projects, projectUuids)) {
      setProjectUuids(userData?.projects);
      fetchProjects();
    }
  }, [userData?.projects]);

  return {
    projects: [projects, fetchProjects],
    objects,
  };
}