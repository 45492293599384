import BlogPage, { BlogPostPage } from './components/BlogPage';

export default {
  root: {
    path: '/blog',
    component: BlogPage,
  },
  post: {
    path: '/blog/*',
    component: BlogPostPage,
  },
}
