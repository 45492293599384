import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import {
  ref as storageRef,
  getMetadata,
  uploadBytes,
  uploadString,
} from 'firebase/storage';
import {
  DeleteIcon,
  MailIcon,
  EditIcon,
  ShoppingCartIcon,
  XIcon,
  PanelBottomOpenIcon,
  PanelBottomCloseIcon,
} from 'lucide-react';
import prettyBytes from 'pretty-bytes';


import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Separator } from "@/components/ui/separator"

import OutputScreen from '@/components/OutputScreen';
import { Loader } from '@/components/loader';
import { SigninDlg } from '@/components/signin-dlg';
import { UploadTexturesPopover } from '@/components/upload-textures-popover';
import ProjectDetailOptions from '@/components/projects/project-detail-options';

import { set, update, increment } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import {
  fetchUsers,
  getImages,
  getWebsites,
  getVersion,
  setVersion,
} from '@/modules/redux/storage';
import { fetchUser, getUser } from '@/modules/redux/user';
import {
  getBackground,
  getBackgroundLoaded,
  getCanvas,
  getEnvironmentLoaded,
  getLoadingRoom,
  getMaps,
  getMapsLoaded,
  getMaterial,
  getMaterials,
  loadObject,
  resetThreejs,
  setMaps,
  updateMaterials,
} from '@/modules/redux/threejs';
import { uuid, deleteFolder, generateThumbnail } from '@/modules/utils';
import { cn } from '@/lib/utils';

import categories from '@/config/categories';

const keys = {
  map: 'Diffuse',
  matcap: 'Matcap',
  specularMap: 'Specular',
  emissiveMap: 'Emissive',
  alphaMap: 'Alpha',
  bumpMap: 'Bump',
  normalMap: 'Normal',
  displacementMap: 'Displace',
  roughnessMap: 'Rough',
  metalnessMap: 'Metal',
  // envMap: 'Environment',
  lightMap: 'Light',
  aoMap: 'AO',
  gradientMap: 'Gradient',
};

const mapItems = {
  diffuse: { id: 'map', label: 'Diffuse' },
  matcap: { id: 'matcap', label: 'Matcap' },
  specular: { id: 'specularMap', label: 'Specular' },
  emissive: { id: 'emissiveMap', label: 'Emissive' },
  alpha: { id: 'alphaMap', label: 'Alpha' },
  bump: { id: 'bumpMap', label: 'Bump', value: 'bumpScale' },
  normal: { id: 'normalMap', label: 'Normal', valueXY: 'normalScale' },
  displace: { id: 'displacementMap', label: 'Displace', value: 'displacementMap' },
  rough: { id: 'roughnessMap', label: 'Rough' },
  metal: { id: 'metalnessMap', label: 'Metal' },
  light: { id: 'lightMap', label: 'Light' },
  ao: { id: 'aoMap', label: 'AO', value: 'aoMapIntensity' },
  gradient: { id: 'gradientMap', label: 'Gradient' },
};

const generateBlob = async (file) => {
  const ele = await new Promise((_resolve) => {
    const url = URL.createObjectURL(file);
    let img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      _resolve(img);
    }
    img.src = url;
  });

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d', { willReadFrequently: true });
  if (ele.height > ele.width) {
    canvas.height = Math.min(ele.height, 500);
    canvas.width = (ele.width / ele.height) * canvas.height;
  }
  else if (ele.width > ele.height) {
    canvas.width = Math.min(ele.width, 500);
    canvas.height = (ele.height / ele.width) * canvas.width;
  }
  else {
    canvas.width = Math.min(ele.width, 500);
    canvas.height = Math.min(ele.height, 500);
  }
  context.drawImage(ele, 0, 0, canvas.width, canvas.height);

  const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/webp', 1));

  return blob;
};

export function ModelItemDlg({
  children,
  models = [],
  open: [open, setOpen] = [false, () => { }],
  editable = false,
  onEdit = () => { },
  isPublic = false,
  outputScreenLoaderRef,
  upload = () => { },
  uploading = false,
  uploadingLoaderRef,
  website: [website, fetchWebsite] = [, () => { }],
  unmountChat = () => { },
  objects,
  aspectRatio,
  modelWidth,
  modelHeight,
}) {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const database = useDatabase();

  const storage = useStorage();

  const { data: user } = useUser();

  const {
    buyer: {
      cart = {},
      purchases = {},
    } = {},
    seller: {
      paymentsEnabled = false,
    } = {},
  } = useSelector(getUser);

  const background = useSelector(getBackground);
  const backgroundLoaded = useSelector(getBackgroundLoaded);
  const canvas = useSelector(getCanvas);
  const environmentLoaded = useSelector(getEnvironmentLoaded);
  const loadingRoom = useSelector(getLoadingRoom);
  const maps = useSelector(getMaps);
  const mapsLoaded = useSelector(getMapsLoaded);
  const material = useSelector(getMaterial);
  const materials = useSelector(getMaterials);

  const images = useSelector(getImages);
  const version = useSelector(getVersion);
  const websites = useSelector(getWebsites);

  const outputScreenRef = React.useRef();

  const [index, setIndex] = React.useState(0);

  const [title, setTitle] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [termsAndConditions, setTermsAndConditions] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [thumbnail, setThumbnail] = React.useState();
  const [selectedMaps, setSelectedMaps] = React.useState();

  const resetStates = () => {
    setIndex(0);
    setTitle('');
    setCategory('');
    setDescription('');
    setTermsAndConditions('');
    setPrice('');
    setThumbnail();
    setSelectedMaps();
  };

  React.useEffect(() => {
    if (open) {
      outputScreenRef?.current?.initThreejs(false);
      dispatch(setVersion(model.uuid));
    }
    else {
      dispatch(resetThreejs());
      dispatch(setVersion(null));
      resetStates();
    }
  }, [open]);

  const model = models[index];
  const submitDisabled = !models.every((m, i) => {
    if (i == index) m = { title, description, price };
    return !!m.title && !!m.description && !isNaN(m.price) && (m.price == '' || m.price >= 0);
  });

  React.useEffect(() => {
    if (open && canvas && (model instanceof File || version == model?.uuid)) {
      const type = model instanceof File ?
        model.name.split('.')[model.name.split('.').length - 1].toLowerCase() :
        model.zipped ? 'zip' : model.fileType;

      dispatch(loadObject({
        url: model instanceof File ? URL.createObjectURL(model) : model.url,
        type,
        originalType: type,
        defaultLight: model instanceof File,
        onProgress: ({ loaded, total }) => outputScreenLoaderRef?.current?.setValue(100 * (loaded / total)),
      }));

      if (!website && version == model?.uuid && !images[model.user]) dispatch(fetchUsers([model.user]));

      setTitle(model.title);
      setCategory(model.category);
      setDescription(model.description);
      setTermsAndConditions(model.termsAndConditions);
      setPrice(model.price);
      setThumbnail(model.thumbnail);
    }
  }, [canvas, index, version]);


  const handleThumbnailClick = () => {
    if (thumbnail?.custom) {
      setThumbnail();
    }
    else {
      document.getElementById('thumbnail').click();
    }
  };

  const saveFileInputValues = () => {
    model.title = title;
    model.category = category;
    model.description = description;
    model.termsAndConditions = termsAndConditions;
    model.price = price;
    model.thumbnail = thumbnail;
    model.selectedMaps = selectedMaps
  };

  const [deletedMaps, setDeletedMaps] = React.useState([]);

  React.useEffect(() => {
    if (!model || !open || !materials) return;

    if (model instanceof File) {
      const maps = materials.reduce((obj, { material }) => ({
        ...obj,
        [material.userData.id]: Object.fromEntries(Object.values(mapItems).map(({ id: key }) =>
          [
            key,
            [
              ...[{ name: 'Default', type: 'Default', texture: material[key] }].filter((map) => !!map.texture),
              ...(model.textures?.[material.userData.id]?.[key] || []),
            ],
          ]).filter(([, map]) => map.length > 0)),
      }), {});
      dispatch(setMaps(maps));

      const textures = Object.fromEntries(Object.entries(maps).map(([id, maps]) => [
        id,
        Object.fromEntries(Object.entries(maps).map(([key, maps]) => [key, (maps?.length || 0) - 1])),
      ]));

      setSelectedMaps(textures);
    }
    else if (maps) {
      setSelectedMaps(Object.fromEntries(Object.entries(maps).map(([id, _maps]) => [
        id,
        Object.fromEntries(Object.entries(_maps).map(([key]) => [key, maps[id][key].length - 1])),
      ])));
    }
  }, [open, materials]);

  React.useEffect(() => { if (selectedMaps) dispatch(updateMaterials({ selectedMaps: selectedMaps, update: false })) }, [selectedMaps]);

  const handleTextureUpload = async ({ files: [file], texture, material: toMaterial }) => {
    const id = material.material.userData.id;
    const key = mapItems[texture].id;

    const { image, original } = await new Promise(async (_resolve, _reject) => {
      const ele = new Image();
      ele.src = URL.createObjectURL(file);
      ele.onload = async () => {
        const original = generateThumbnail(ele);
        const img = { key, image: original };//texture.key == 'map' ? await addWatermark(original) : original, original };
        URL.revokeObjectURL(ele.src);
        _resolve(img);
      }
      ele.onerror = () => {
        URL.revokeObjectURL(ele.src);
        _reject();
      };
    });

    let keyMaps = Object.entries(maps).map(([_id, idMaps]) =>
      Object.entries(idMaps).map(([_key, keyMaps]) => keyMaps.filter(() => _id != id && _key == key))
    ).flat().flat();

    const newMap = {
      uuid: uuid(),
      url: image,
      name: `${mapItems[texture].label}${keyMaps.length > 0 ? keyMaps.length + 1 : ''}`,
    };

    let _deletedMaps = [...deletedMaps];

    keyMaps = maps[id]?.[key] || [];
    if (keyMaps?.[0]?.name == 'Default') {
      const map = maps[id]?.[key]?.[1];
      if (map?.ref) {
        _deletedMaps = [...deletedMaps, { ...map, id, key }];
        setDeletedMaps(_deletedMaps);
      }

      keyMaps = [keyMaps[0], newMap];
    }
    else {
      const map = maps[id]?.[key]?.[0];
      if (map?.ref) {
        _deletedMaps = [...deletedMaps, { ...map, id, key }];
        setDeletedMaps(_deletedMaps);
      }

      keyMaps = [newMap];
    }

    const _maps = {
      ...maps,
      [id]: {
        ...(maps[id] || {}),
        [key]: keyMaps,
      },
    };

    dispatch(setMaps(_maps));

    setSelectedMaps({
      ...maps,
      [id]: {
        ...maps[id],
        [key]: keyMaps.length - 1,
      },
    });

    model.textures = _maps;
  };

  const handleTextureDelete = (texture) => {
    setSelectedMaps({
      ...selectedMaps,
      [texture.id]: maps[texture.id][texture.key]?.[0].name == 'Default' ?
        { ...selectedMaps[texture.id], [texture.key]: 0 } :
        { ...Object.fromEntries(Object.entries(selectedMaps[texture.id]).filter(([key]) => key != texture.key)) },
    });

    const _maps = {
      ...maps,
      [texture.id]: {
        ...(maps[texture.id] || {}),
        [texture.key]: maps[texture.id][texture.key].filter((map) => map.uuid != texture.uuid),
      },
    };

    dispatch(setMaps(_maps));

    const map = maps[texture.id][texture.key].find((map) => map.uuid == texture.uuid);

    if (map?.ref) {
      setDeletedMaps([...deletedMaps, { ...map, id: texture.id, key: texture.key }]);
    }

    let _deletedMaps = [...deletedMaps];
    if (map?.ref) {
      _deletedMaps = [...deletedMaps, { ...map, id: texture.id, key: texture.key }];
      setDeletedMaps(_deletedMaps);
    }

    model.textures = _maps;
  }

  const handleBackPress = () => {
    saveFileInputValues();
    outputScreenRef?.current?.initThreejs(false);
    setIndex(index - 1);
  };

  const handleContinuePress = () => {
    saveFileInputValues();
    outputScreenRef?.current?.initThreejs(false);
    setIndex(index + 1);
  };

  const handleSubmitPress = async () => {
    saveFileInputValues();

    if (submitDisabled) return;

    const object = model.object;

    if (object) {
      // let needsUpdate = false;
      // for (const map of files[0].deletedTextures) {
      //   if (user?.uid) {
      //     const metadata = await getMetadata(map.ref);
      //     await set(databaseRef(database, `users/${user?.uid}/fileSpaceUsed`), increment(-metadata.size));
      //   }

      //   await Promise.all([
      //     deleteObject(storageRef(storage, map.ref)),
      //     set(databaseRef(database, `maps/${object.uuid}/${map.id}/${map.key}/${map.uuid}`), null),
      //     set(databaseRef(database, `objects/${object.uuid}/maps/${map.id}/${map.key}`), maps[map.id][map.key].find((_map) => _map.name == 'Default')?.uuid || null),
      //   ]);

      //   if (!needsUpdate) needsUpdate = true;
      // }

      // for (const [id, idMaps] of Object.entries(files[0].textures)) {
      //   for (const [key, keyMaps] of Object.entries(idMaps)) {
      //     for (const [mapIndex, map] of keyMaps.filter((map) => map.name != 'Default').entries()) {
      //       if (!map || map.ref) continue;

      //       const _uuid = uuid();

      //       const [_file] = await Promise.all([
      //         uploadString(storageRef(storage, `maps/${object.uuid}/${_uuid}/${map.name}`), map.url, 'data_url'),
      //         set(databaseRef(database, `maps/${object.uuid}/${id}/${key}/${_uuid}`), map.name),
      //         mapIndex == 0 ? set(databaseRef(database, `objects/${object.uuid}/maps/${id}/${key}`), _uuid) : Promise.resolve(),
      //       ]);

      //       if (user?.uid) {
      //         const metadata = await getMetadata(_file.ref);
      //         await set(databaseRef(database, `users/${user?.uid}/fileSpaceUsed`), increment(metadata.size));
      //       }

      //       if (!needsUpdate) needsUpdate = true;
      //     }
      //   }
      // }

      // await set(databaseRef(database, `objects/${object.uuid}/needsUpdate`), true);
      await Promise.all([
        update(databaseRef(database, `objects/${object.uuid}`), {
          title: models[0].title || '',
          category: models[0].category || '',
          description: models[0].description || '',
          price: Math.ceil(parseFloat(models[0].price || 0) * 100) / 100,
        }),
        !models[0].thumbnail || models[0].thumbnail instanceof File ? new Promise(async (resolve) => {
          await deleteFolder(storageRef(storage, `thumbnails/${object.uuid}/custom`));

          if (!models[0].thumbnail) return resolve();

          const blob = await generateBlob(models[0].thumbnail);

          await uploadBytes(storageRef(storage, `thumbnails/${object.uuid}/custom/${uuid()}.${models[0].thumbnail.name}.webp`), blob);

          resolve();
        }) : Promise.resolve(),
      ]);

      setOpen(false);
      fetchWebsite();
    }
    else {
      upload(models, async (_objects) => {
        try {
          const timestamp = Date.now();

          for (const [index, file] of models.entries()) {
            if (!file.textures) continue;

            for (const [id, idMaps] of Object.entries(file.textures)) {
              for (const [key, keyMaps] of Object.entries(idMaps)) {
                for (const [mapIndex, map] of keyMaps.filter((map) => map.name != 'Default').entries()) {
                  if (!map || !_objects[index]?.materials?.[id]?.[key]) continue;

                  const _uuid = uuid();

                  const [_file] = await Promise.all([
                    uploadString(storageRef(storage, `maps/${_objects[index].uuid}/${_uuid}/${map.name}`), map.url, 'data_url'),
                    set(databaseRef(database, `maps/${_objects[index].uuid}/${id}/${key}/${_uuid}`), map.name),
                    mapIndex == 0 ? set(databaseRef(database, `objects/${_objects[index].uuid}/maps/${id}/${key}`), _uuid) : Promise.resolve(),
                  ]);

                  if (user?.uid) {
                    const metadata = await getMetadata(_file.ref);
                    await set(databaseRef(database, `users/${user?.uid}/fileSpaceUsed`), increment(metadata.size));
                  }
                }
              }
            }
          }

          await Promise.all(
            [
              update(databaseRef(database, `users/${user?.uid}/website/objects`), Object.fromEntries(_objects.map((object) => [object.uuid, object.metadata.name]))),
              set(databaseRef(database, `users/${user?.uid}/fileSpaceUsed`), increment(_objects.reduce((sum, object) => sum + object.size || 0, 0))),
            ]
              .concat(_objects.map((_object, i) => httpsCallable('encrypt')({
                public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
                payload: {
                  ...{ object: _object.uuid, website: website.username, timestamp },
                },
              }).then(({ data }) => {
                // const redirect = short.generate();

                const lights = {
                  [uuid()]: { type: 'AmbientLight', color: '#ffffff', intensity: _object.originalType == 'fbx' ? .6 : .3 },
                  [uuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: _object.dimensions / 2, z: 0 } },
                  [uuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: -_object.dimensions / 2, z: 0 } },
                };
                if (_object.includeDefaultEnvMap) lights.Default = { type: 'Environment', uuid: 'Default' };

                return Promise.all([
                  update(databaseRef(database, `objects/${_object.uuid}`), {
                    token: data.token,
                    // redirect,
                    lights,
                    needsUpdate: false,
                    name: _object.name,
                    size: _object.size,
                    polycount: _object.polycount || 0,
                    vertices: _object.vertices || 0,
                    hasAnimations: !!_object.hasAnimations,
                    autoplay: false,
                    id: models[i].id || '',
                    title: models[i].title || '',
                    category: models[i].category || '',
                    description: models[i].description || '',
                    termsAndConditions: models[i].termsAndConditions || '',
                    price: Math.ceil(parseFloat(models[i].price || 0) * 100) / 100,
                    user: user?.uid,
                    timestamp,
                  }),
                  models[i].thumbnail ? new Promise(async (resolve) => {
                    const blob = await generateBlob(models[i].thumbnail);

                    await uploadBytes(storageRef(storage, `thumbnails/${_object.uuid}/custom/${uuid()}.${models[i].thumbnail.name}.webp`), blob);

                    resolve();
                  }) : Promise.resolve(),
                  // set(databaseRef(database, `users/${user?.uid}/website/categories`), _.uniq(files.map((f) => f.category.value).concat(website.categories).filter((c) => c))),
                  // set(databaseRef(database, `redirects/${redirect}`), `/viewer?token=${data.token}`),
                ]);
              })))
          );

          setOpen(false);
          fetchWebsite();
          dispatch(fetchUser());
        }
        catch (e) { console.log(e) }
      });
    }
  };

  const handleWebsiteClick = () => {
    if (websites[model.user].username) navigate(`/website/${websites[model.user].username.toLowerCase()}`);
  };

  const [handleLoginSuccess, setHandleLoginSuccess] = React.useState();

  const handleCartClick = async (open) => {
    if (open) {
      const handleSignIn = async (credential) => {
        setHandleLoginSuccess();

        await set(databaseRef(database, `users/${credential.user?.uid}/buyer/cart/${model.uuid}`), { uuid: model.uuid });

        // dispatch(fetchUser());
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }
    }
    else {
      setHandleLoginSuccess();
    }
  };

  const handleDownloadClick = async (open) => {
    if (open) {
      const handleSignIn = async (credential) => {
        setHandleLoginSuccess();

        const timestamp = Date.now();

        const { data: { token } } = await httpsCallable('encrypt')({
            public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
            payload: { purchases: [model.uuid], timestamp },
        }).catch((e) => ({ data: {} }));

        await Promise.all([
            set(databaseRef(database, `users/${credential.user.uid}/buyer/purchases/${model.uuid}/purchasedOn`), timestamp),
            set(databaseRef(database, `users/${credential.user.uid}/buyer/cart/${model.uuid}`), null),
        ]);

        navigate(`/purchases?token=${token}`);

        // dispatch(fetchUser());
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }
    }
    else {
      setHandleLoginSuccess();
    }
  };

  const handleMessageClick = async (open) => {
    if (open) {
      const object = model;

      const tag = object.id == 'commission' ? 'commission' : 'editRequest';

      const uid = website?.user?.uid || model.user;

      if (!uid) return;

      const handleSignIn = async (credential) => {
        setHandleLoginSuccess();

        if (credential.user?.uid == uid) {
          setHandleLoginSuccess();
          return navigate(`/messages`);
        }

        unmountChat();

        await set(databaseRef(database, `objects/${object.uuid}/${tag}s/${credential.user?.uid}`), { archived: false });
        await set(databaseRef(database, `users/${credential.user?.uid}/${tag}s/${uid}/${object.uuid}`), { archived: false });

        const { data: { token } } = await httpsCallable('encrypt')({
          public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
          payload: { [tag]: { object: object.uuid, user: uid }, timestamp: Date.now(), popup: true },
        }).catch((e) => ({ data: {} }));

        navigate(`/messages?token=${token}`);
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }

      if (!user) {
        setHandleLoginSuccess({ callback: handleSignIn });
      }
      else {
        handleSignIn({ user });
      }
    }
    else {
      setHandleLoginSuccess();
    }
  };

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    checkScreenWidth();

    // Event listener for screen width changes
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  // <Button size="icon" className={
  //   cn(
  //     // "w-9 h-9",
  //     "md:hidden right-4 -translate-y-[3.25rem]",
  //   )}
  //   onClick={() => setShowPanel(!showPanel)}
  //   style={{ zIndex: 4 }}>
  //   {showPanel ?
  //     <PanelBottomCloseIcon className="w-[18px] h-[18px]" /> :
  //     <PanelBottomOpenIcon className="w-[18px] h-[18px]" />}
  // </Button>

  return (<>
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogPortal style={{ backgroundColor: 'red' }}>
        <div className={cn(
          "w-screen h-screen absolute top-0 md:block hidden",
          "inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
        )}></div>
        <div className="mx-auto md:max-w-4xl w-screen p-0 border-0 fixed left-[50%] top-[50%] z-50 grid translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
          <div className="flex md:flex-row flex-col md:h-[620px] h-screen md:overflow-hidden overflow-y-auto">
            <div className="relative md:basis-1/2 bg-gray-300 overflow-hidden md:rounded-l-lg md:min-h-[620px] min-h-[90vh]"
              style={{ backgroundColor: background }}>
              {open && <OutputScreen
                ref={outputScreenRef}
                autoInit={open}
              >
                {model instanceof File ?
                  loadingRoom :
                  (loadingRoom || !mapsLoaded || !environmentLoaded || !backgroundLoaded) &&
                  <div
                    className="basis-1/2 bg-gray-300 overflow-hidden rounded-l-lg absolute w-full h-full flex flex-row justify-center items-center">
                    <Loader ref={outputScreenLoaderRef} variant="background" className="w-[200px] h-1.5" />
                  </div>
                }
                <ProjectDetailOptions align="end" className="absolute bottom-4 right-4 z-[4]" />
                <DialogClose
                  className="md:hidden w-[18px] h-[18px] inline-flex items-center justify-center bg-primary text-primary-foreground absolute right-4 top-4 rounded-sm ring-offset-background transition-opacity hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground z-[4]">
                  <XIcon className="w-3 h-3" />
                </DialogClose>
              </OutputScreen>}
            </div>
            {editable ?
              <div className="flex flex-col basis-1/2 p-4 md:min-h-[620px] gap-4 md:gap-0 md:overflow-y-auto">
                <div className="flex-1">
                  <div className="grid gap-6 p-2">
                    <div className="grid gap-2">
                      <Label htmlFor="title">Title</Label>
                      <Input id="title" placeholder="Enter the title you want to display" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="category">Category</Label>
                      <Select id="category" value={category} defaultValue={category} onValueChange={setCategory}>
                        <SelectTrigger>
                          <SelectValue placeholder="Add a tag" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value={false}>None</SelectItem>
                            <SelectSeparator />
                            {categories.map((category) =>
                              <SelectItem key={category} value={category}>{category}</SelectItem>
                            )}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="description">Description</Label>
                      <Textarea id="description" placeholder="Enter the description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                    {model?.id == 'commission' && <div className="grid gap-2">
                      <Label htmlFor="description">Terms and Conditions</Label>
                      <Textarea id="termsAndConditions" placeholder="Enter the terms and conditions" value={termsAndConditions} onChange={(e) => setTermsAndConditions(e.target.value)} />
                    </div>}
                    <div className="grid gap-2">
                      <Label htmlFor="price">{model?.id == 'commission' ? 'Starting price (USD)' : 'Pricing (USD)'}</Label>
                      <Input
                        id="price" type="number"
                        disabled={!paymentsEnabled}
                        placeholder="Enter the price"
                        value={price}
                        onChange={(e) => { if (e.nativeEvent.data != '-') setPrice(e.target.value) }} />
                      {!paymentsEnabled && <div className="text-xs text-destructive">Link Stripe account to add a price</div>}
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="thumbnail" onClick={(e) => e.preventDefault()}>Thumbnail</Label>
                      <Button variant="outline" className="max-w-48 flex flex-row justify-between items-center" onClick={handleThumbnailClick}>
                        <div></div>
                        <div className="truncate">{thumbnail?.name || 'Add Thumbnail'}</div>
                        {thumbnail instanceof File || thumbnail?.custom ? <DeleteIcon className="mr-2 w-[18px] h-[18px]" /> : <div></div>}
                        <input id="thumbnail" type="file" accept="image/*" hidden onChange={(e) => setThumbnail(e.target.files[0])} />
                      </Button>
                    </div>
                    {model instanceof File && <div className="grid gap-2">
                      <Label htmlFor="texture" onClick={(e) => e.preventDefault()}>Texture</Label>
                      <UploadTexturesPopover
                        contentProps={{ align: "start" }}
                        onUpload={handleTextureUpload}>
                        <Button variant="outline" className="max-w-48">
                          Add Texture
                        </Button>
                      </UploadTexturesPopover>
                      <div className="flex flex-row flex-wrap">
                        {Object.entries(maps || {})
                          .map(([id, _maps]) => Object.entries(_maps || {}).map(([key, _maps]) => _maps.map((map) => ({ ...map, id, key })).filter((_map) => _map.name != 'Default')))
                          .flat()
                          .flat()
                          .map((texture) => <Button key={texture.uuid} variant="outline" className="flex flex-row justify-between items-center"
                            onClick={() => handleTextureDelete(texture)}>
                            <div></div>
                            <div className="truncate">{texture.name}</div>
                            <DeleteIcon className="w-[18px] h-[18px]" />
                          </Button>)}
                      </div>
                    </div>}
                  </div>
                </div>
                {models.length > 1 && <Label className="flex flex-row justify-end gap-4 px-2 py-1 text-muted-foreground">{`${index + 1} of ${models.length}`}</Label>}
                <div className="flex flex-row justify-end gap-4 px-2">
                  {index > 0 && <Button variant='ghost' onClick={handleBackPress}>Back</Button>}
                  {index < models.length - 1 && <Button onClick={handleContinuePress}>Continue</Button>}
                  {index == models.length - 1 && <Button disabled={submitDisabled} onClick={handleSubmitPress}>
                    {uploading ? <Loader
                      ref={uploadingLoaderRef} variant="background"
                      className="w-full h-1" textProps={{ className: "text-xs" }}
                      style={{ width: 46.77 }} /> : 'Submit'}
                  </Button>}
                </div>
              </div>
              :
              <div className="flex flex-col basis-1/2 p-4 md:min-h-[620px] gap-4 md:gap-0 md:overflow-y-auto">
                <div className="space-y-1 mb-2">
                  <h2 className="text-2xl font-bold tracking-tight">{model.title}</h2>
                  <p className="text-muted-foreground">
                    {`$${parseFloat(model.price).toFixed(2)}`}
                  </p>
                  {!website && <div>
                    <Button variant="ghost" className="flex items-center space-x-1 p-0 pr-2 rounded-full" onClick={handleWebsiteClick}>
                      <Avatar className="h-8 w-8">
                        <AvatarImage
                          src={images[model.user]?.url}
                          alt={websites[model.user] ? `@${websites[model.user].username}` : null}
                          className="object-cover" />
                        <AvatarFallback></AvatarFallback>
                      </Avatar>
                      <p className="text-sm font-medium">{websites[model.user] ? `@${websites[model.user].username}` : null}</p>
                    </Button>
                    {/* <Button variant="ghost" className="p-0 h-6 text-muted-foreground">
                      {follow ? "Unfollow" : "Follow"}
                    </Button> */}
                  </div>}
                  <Separator />
                </div>
                <div className="flex-1 space-y-2" >
                  {model.category && <div>
                    <h3 className="text-lg font-medium">Category</h3>
                    <p className="text-sm text-muted-foreground">
                      {model.category}
                    </p>
                  </div>}
                  <div>
                    <h3 className="text-lg font-medium">Description</h3>
                    <p className="text-sm text-muted-foreground">
                      {model.description}
                    </p>
                  </div>
                  {model?.id == 'commission' ?
                    <div>
                      <h3 className="text-lg font-medium">Terms and Conditions</h3>
                      <p className="text-sm text-muted-foreground">
                        {model.termsAndConditions}
                      </p>
                    </div> : <>
                      <div>
                        <h3 className="text-lg font-medium">File Size</h3>
                        <p className="text-sm text-muted-foreground">
                          {prettyBytes(model.size || 0)}
                        </p>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium">File Type</h3>
                        <p className="text-sm text-muted-foreground">
                          {model.fileType}
                        </p>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium">Polygons</h3>
                        <p className="text-sm text-muted-foreground">
                          {model.polycount}
                        </p>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium">Vertices</h3>
                        <p className="text-sm text-muted-foreground">
                          {model.vertices}
                        </p>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium">Animations</h3>
                        <p className="text-sm text-muted-foreground">
                          {model.hasAnimations ? 'Yes' : 'No'}
                        </p>
                      </div>
                    </>
                  }
                </div>
                <div className="flex flex-row justify-end gap-4">
                  {isPublic && model.id == 'shop' && model.price > .5 && (user ?
                    <Button
                      disabled={model.user == user?.uid || purchases[model.uuid] || cart[model.uuid]}
                      onClick={handleCartClick}>
                      {purchases[model.uuid] ? 'Already purchased' :
                        cart[model.uuid] ? 'Added to Cart' :
                          'Add to Cart'}
                    </Button> :
                    <SigninDlg onOpenChange={handleCartClick} onLoginSuccess={handleLoginSuccess?.callback}>
                      <Button>Add to Cart</Button>
                    </SigninDlg>)}
                  {isPublic && model.id == 'shop' && model.price == 0 && (user ?
                    <Button
                      disabled={model.user == user?.uid || purchases[model.uuid]}
                      onClick={handleDownloadClick}>
                      {purchases[model.uuid] ? 'Already purchased' : 'Download'}
                    </Button> :
                    <SigninDlg onOpenChange={handleDownloadClick} onLoginSuccess={handleLoginSuccess?.callback}>
                      <Button>Download</Button>
                    </SigninDlg>)}
                  {isPublic && model.id == 'shop' && (user ?
                    <Button variant="outline" className="w-9 h-9 p-2"
                      disabled={model.user == user?.uid || user?.uid == website?.user?.uid} onClick={handleMessageClick}>
                      <MailIcon />
                    </Button> :
                    <SigninDlg onOpenChange={handleMessageClick} onLoginSuccess={handleLoginSuccess?.callback}>
                      <Button variant="outline" className="w-9 h-9 p-2" disabled={model.user == user?.uid}>
                        <MailIcon />
                      </Button>
                    </SigninDlg>)}
                  {isPublic && model.id == 'commission' && (user ?
                    <Button disabled={model.user == user?.uid || user?.uid == website?.user?.uid} onClick={handleMessageClick}>
                      Request
                    </Button> :
                    <SigninDlg onOpenChange={handleMessageClick} onLoginSuccess={handleLoginSuccess?.callback}>
                      <Button disabled={model.user == user?.uid}>
                        Request
                      </Button>
                    </SigninDlg>)}
                  {!isPublic && <Button onClick={onEdit}>Edit</Button>}
                </div>
              </div>}
          </div>
          <DialogClose
            className="md:block hidden absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </div>
      </DialogPortal>
    </Dialog >
  </>);
}