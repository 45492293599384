export default [
  'Animals',
  'Architecture',
  'Art',
  'Characters & People',
  'Culture & History',
  'Electronics',
  'Fashion & Style',
  'Food & Drink',
  'Furniture & Home',
  'Music',
  'Nature & Plants',
  'News & Politics',
  'Places & Travel',
  'Sports',
  'Weapons and Military',
  'Vehicles',
  'Miscellaneous',
];