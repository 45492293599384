import * as React from "react"
import { useSelector } from "react-redux";
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Switch } from "@/components/ui/switch"
import { toast } from "@/components/ui/use-toast"

import { update } from '@/modules/firebase/database';
import { getUser } from "@/modules/redux/user";

const notificationsFormSchema = z.object({
  type: z.enum(["all", "none", ""]).optional(),
  mobile: z.boolean().default(false).optional(),
  marketing_emails: z.boolean().default(false).optional(),
  message_emails: z.boolean().default(false).optional(),
  payment_emails: z.boolean().default(false).optional(),
})

// This can come from your database or API.
const defaultValues = {
  type: "all",
  communication_emails: true,
  marketing_emails: true,
  social_emails: true,
  security_emails: true,
}

export function NotificationsForm() {
  const database = useDatabase();

  const { data: user } = useUser();

  const { notifications = {} } = useSelector(getUser);

  const form = useForm({
    resolver: zodResolver(notificationsFormSchema),
    // defaultValues,
  });

  React.useEffect(() => {
    form.setValue("marketing_emails", !!notifications.marketing);
    form.setValue("message_emails", !!notifications.message);
    form.setValue("payment_emails", !!notifications.payment);
  }, [notifications]);

  const watchType = form.watch("type", null);
  const watchMarketing = form.watch("marketing_emails", null);
  const watchMessage = form.watch("message_emails", null);
  const watchPayment = form.watch("payment_emails", null);

  function onTypeChanged(newValue) {
    if (newValue == watchType) {
      form.setValue("type", null)
    }
    else {
      form.setValue("type", newValue)
    }
  }

  React.useEffect(() => {
    if (watchType) {
      form.setValue("marketing_emails", watchType == "all");
      form.setValue("message_emails", watchType == "all");
      form.setValue("payment_emails", watchType == "all");
    }
    else {

    }
  }, [watchType]);

  React.useEffect(() => {
    const values = form.getValues();

    if (values.marketing_emails && values.message_emails && values.payment_emails) {
      form.setValue('type', 'all');
    }
    else if (!values.marketing_emails && !values.message_emails && !values.payment_emails) {
      form.setValue('type', 'none');
    }
    else {
      form.setValue('type', '');
    }
  }, [watchMarketing, watchMessage, watchPayment]);

  const [updated, setUpdated] = React.useState(false);

  React.useEffect(() => { if (updated) setTimeout(() => setUpdated(false), 1000) }, [updated]);

  const handleSubmit = async ({
    marketing_emails,
    message_emails,
    payment_emails,
  }) => {
    if (updated) return;

    await Promise.all([
      update(databaseRef(database, `users/${user.uid}/notifications`), {
        marketing: marketing_emails,
        message: message_emails,
        payment: payment_emails,
      }),
    ]);

    setUpdated(true);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Notify me about...</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  value={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="all" onClick={(e) => { onTypeChanged("all") }} />
                    </FormControl>
                    <FormLabel className="font-normal">
                      All new messages
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="none" onClick={(e) => { onTypeChanged("none") }} />
                    </FormControl>
                    <FormLabel className="font-normal">Nothing</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <h3 className="mb-4 text-lg font-medium">Email Notifications</h3>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="marketing_emails"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">
                      Marketing emails
                    </FormLabel>
                    <FormDescription>
                      Receive emails about new products, features, and more.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="message_emails"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Message emails</FormLabel>
                    <FormDescription>
                      Receive emails when clients message you.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="payment_emails"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Payment emails</FormLabel>
                    <FormDescription>
                      Receive emails when clients pay you.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button type="submit" style={{ width: 178.94 }}>{updated ? 'Notifications updated!' : 'Update notifications'}</Button>
      </form>
    </Form>
  )
}
