import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Separator } from '@/components/ui/separator';
import { SidebarNav } from '@/app/forms/components/sidebar-nav';
import Forms from '@/app/forms/forms';
import FormsAccount from '@/app/forms/account/account';
import FormsNotifications from '@/app/forms/notifications/notifications';

import { cn } from '@/lib/utils';

const sidebarNavItems = [
  {
    title: "Profile",
    value: "profile",
  },
  {
    title: "Notifications",
    value: "notifications",
  },
  {
    title: "Account",
    value: "account",
  }
]

export default function FormsLayout(props) {
  const { pathname } = useLocation();

  const { tab } = useParams();

  const [form, setForm] = React.useState();

  React.useEffect(() => {
    if (![undefined, 'messages', 'projects', 'purchases', 'account'].includes(tab)) return navigate('/seller');

    setForm();
  }, [tab]);


  const _sidebarNavItems = sidebarNavItems.map((i) => ({ ...i, onClick: () => setForm(i.value) }));

  return (
    <div
      className={cn(
        "md:container px-4 w-full space-y-6 pb-16 md:block min-h-screen overflow-y-auto",
        pathname.split('/')[1] == 'seller' ? "p-8" : "",
      )}>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
          <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p>
        </div>
        <div className="flex items-center space-x-2">
        </div>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5">
          <SidebarNav items={_sidebarNavItems.filter((i) => pathname.split('/')[1] == 'seller' ? true : i.value != 'profile')} />
        </aside>
        <div className="flex-1 lg:max-w-2xl">
          {form == 'account' ? <FormsAccount {...props} /> :
            form == 'notifications' ? <FormsNotifications {...props} /> :
              <Forms {...props} />}
        </div>
      </div>
    </div>
  )
}
