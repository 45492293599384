import React from 'react';
import { SuspenseWithPerf } from 'reactfire';
import { Routes, Route, Navigate } from 'react-router-dom';

import ArtistsRoute from './Artists';
import BlogRoute from './Blog';
import BuyerRoute from './Buyer';
import EmbedRoute from './Embed';
import HomeRoute from './Seller';
import PricingRoute from './Pricing';
import ProjectRoute from './Project';
import RedirectRoute from './Redirect';
import SellerRoute from './Seller';
// import RootRoute from './Root';
import ViewerRoute from './Viewer';

export default function createRoutes() {
  return (
    <SuspenseWithPerf traceId="router-wait">
      <Routes>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Route exact path={ArtistsRoute.path} element={<ArtistsRoute.component />} />
        <Route exact path={BlogRoute.root.path} element={<BlogRoute.root.component />} />
        <Route exact path={BlogRoute.post.path} element={<BlogRoute.post.component />} />
        <Route exact path={EmbedRoute.path} element={<EmbedRoute.component />} />
        <Route exact path={HomeRoute.path} element={<HomeRoute.component />} />
        <Route exact path={PricingRoute.path} element={<PricingRoute.component />} />
        <Route exact path={ProjectRoute.path} element={<ProjectRoute.component />} />
        <Route exact path={SellerRoute.path} element={<SellerRoute.component />} />
        <Route exact path={`${SellerRoute.path}/:tab`} element={<SellerRoute.component />} />
        {/* <Route exact path={RootRoute.path} element={<RootRoute.component />} /> */}
        <Route exact path={ViewerRoute.path} element={<ViewerRoute.component />} />
        <Route exact path={`${BuyerRoute.path}/:tab`} element={<BuyerRoute.component />} />
        <Route exact path={`${BuyerRoute.path}/website/:username`} element={<BuyerRoute.component />} />
        <Route path='*' element={<BuyerRoute.component />} />
        {/* <Route path='*' element={<RedirectRoute.component />} /> */}
      </Routes>
    </SuspenseWithPerf>
  );
}
