import React from 'react';
import {
    EllipsisVerticalIcon,
    EditIcon,
    XIcon,
    Trash2Icon,
    LoaderCircle,
} from "lucide-react"

import { cn } from "@/lib/utils"
import { useLocation, useNavigate } from 'react-router-dom';
import prettyBytes from 'pretty-bytes';

import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export function ProjectItem({
    model,
    onDelete = () => { },
    aspectRatio = "portrait",
    width,
    height,
    className,
    ...props
}) {

    const { state } = useLocation();
    const { back = '' } = state || {};

    const navigate = useNavigate();

    const handleClickLink = () => {
        navigate(`/viewer?token=${model.token}`, { state: { back } });
    };

    return (
        <div className={cn("space-y-3",
            className)}
            {...props}
        >
            <Button variant="link"
                className={cn(
                    "px-0 w-full h-auto aspect-square",
                )}
                onClick={handleClickLink}
            >
                <div className="overflow-hidden rounded-md border w-full aspect-square">
                    {model.thumbnail ? <img
                        src={model.thumbnail}
                        alt={model.name}
                        className={cn(
                            "w-full aspect-square object-cover transition-all hover:scale-105",
                        )} /> : <div className="flex justify-center items-center w-full h-full">
                        <LoaderCircle className="animate-spin w-8 h-8" />
                    </div>}
                </div>
            </Button>
            <div className="flex flex-row justify-between">
                <div className="space-y-1 text-lg truncate">
                    <h3 className="font-medium leading-none">{model.name}</h3>
                    <p className="text-base text-muted-foreground">{prettyBytes(model.size)}</p>
                </div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button size="icon" variant="ghost" className="h-7 w-7 rounded-sm border-0 shadow-none">
                            <EllipsisVerticalIcon className="w-4 h-4 text-muted-foreground" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="">
                        <DropdownMenuGroup className="">
                            <DropdownMenuItem onSelect={onDelete}>
                                <Trash2Icon className="mx-2 w-[18px] h-[18px]" />
                                Delete
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    );
}