import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectGroup,
    SelectValue,
} from "@/components/ui/select"
import ProjectDetailComment from '@/components/projects/project-detail-comment';

import {
    getComments,
    getTags,
    lookAt,
} from '@/modules/redux/threejs';

function ProjectDetailComments() {

    const dispatch = useDispatch();

    const comments = useSelector(getComments);
    const tags = useSelector(getTags);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mode, setMode] = React.useState('open');

    const onCommentClick = (comment) => dispatch(lookAt(comment.uuid));

    return (
        <div className="space-y-4">
            <div className="flex justify-end ">
                <Select value={mode} onValueChange={setMode}>
                    <SelectTrigger className="w-[80px]">
                        <SelectValue placeholder="All textures" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value="open">Open</SelectItem>
                            <SelectItem value="all">All</SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            {comments.filter((comment) => mode == 'open' ? !comment.completed :
                typeof mode === 'object' ? (comment.tags || []).find((tag) => tag.label == mode.label) : true)
                .map((comment, i) => <ProjectDetailComment
                    key={i}
                    showLabels
                    comment={comment}
                    uuid={comment.uuid}
                    onClick={() => onCommentClick(comment)}
                />)}
        </div>
    );
}

export default React.memo(ProjectDetailComments);