import React from 'react';
import { FirebaseAppProvider } from 'reactfire';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StyledEngineProvider } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import {
  useFirebaseApp,
  AuthProvider,
  DatabaseProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
} from 'reactfire';

import { ToastProvider } from '@/components/ui/toast';
import { TooltipProvider } from '@/components/ui/tooltip';
import { ThemeProvider } from 'modules/theme';
import store from 'modules/redux/store';
import createRoutes from 'routes';
import firebaseConfig from 'config/firebase';

// screen?.orientation?.lock('portrait');

function FirebaseProvider({ children }) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const database = getDatabase(app);
  const firestore = getFirestore(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>
        <FirestoreProvider sdk={firestore}>
          <FunctionsProvider sdk={functions}>
            <StorageProvider sdk={storage}>
              {children}
            </StorageProvider>
          </FunctionsProvider>
        </FirestoreProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
};

function App() {
  const routes = createRoutes();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <FirebaseAppProvider firebaseConfig={firebaseConfig()} suspense>
          <FirebaseProvider>
            <ReduxProvider store={store}>
              <DndProvider backend={HTML5Backend}>
                <ToastProvider>
                  <TooltipProvider delayDuration={0}>
                    <Router>{routes}</Router>
                  </TooltipProvider>
                </ToastProvider>
              </DndProvider>
            </ReduxProvider>
          </FirebaseProvider>
        </FirebaseAppProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;