import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef, get } from 'firebase/database';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import {
    ShoppingCart,
    CreditCard,
    Download,
    LoaderCircle,
} from "lucide-react"
import _ from 'lodash';

import {
    Breadcrumb,
    BreadcrumbEllipsis,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
// import { SigninChkDlg } from "@/components/signin_chk_dlg";

import { set } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import {
    fetchUsers,
    getImages,
    getWebsites,
} from '@/modules/redux/storage';
import { fetchUser, getUser } from '@/modules/redux/user';
import { cn } from '@/lib/utils';
import { multiply } from '@/modules/utils';

const payPalClientId = ['modelsend.com', 'dcomments-prod.web.app', 'dcomments-prod.firebaseapp.com'].includes(window.location.host) ?
  process.env.REACT_APP_PROD_PAYPAL_CLIENT_ID :
  process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID;

export default function Cart({
}) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const database = useDatabase();

    const { data: user } = useUser();

    const { buyer: { cart = {} } = {} } = useSelector(getUser);

    const images = useSelector(getImages);
    const websites = useSelector(getWebsites);

    React.useEffect(() => {
        const users = Object.values(cart).map((m) => m.user).filter((u) => !websites[u]);
        if (users.length) dispatch(fetchUsers(users));
    }, [cart]);

    const handleWebsiteClick = (model) => {
        if (websites[model.user].username) navigate(`/website/${websites[model.user].username.toLowerCase()}`);
    };

    const handleRemoveClick = async (model) => {
        await set(databaseRef(database, `users/${user.uid}/buyer/cart/${model.uuid}`), null);

        dispatch(fetchUser());
    };

    const subtotalStripe = Object.values(cart).filter((m) => m.paymentProvider == 'stripe').reduce((s, m) => s + m.price, 0);
    const subtotalPayPal = Object.values(cart).filter((m) => m.paymentProvider == 'paypal').reduce((s, m) => s + m.price, 0);

    const [checkingOut, setCheckingOut] = React.useState(false);

    const handleCreateCheckoutSession = async () => {
        setCheckingOut(true);

        if (subtotalStripe < .5) {
            await handleDownload('stripe');
            setCheckingOut(false);
            return;
        }

        try {
            const accountIds = await Promise.all(_.uniq(Object.values(cart).map((m) => m.user)).map((uid) =>
                get(databaseRef(database, `users/${uid}/seller/stripe/accountId`)).then((snapshot) => [uid, snapshot.val()])
            ))
                .then((entries) => Object.fromEntries(entries));

            const { data: { token } } = await httpsCallable('encrypt')({
                public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
                payload: { purchases: Object.values(cart).filter((m) => m.paymentProvider == 'stripe').map((model) => model.uuid), timestamp: Date.now() },
            }).catch((e) => ({ data: {} }));

            const { data } = await httpsCallable('createCheckoutSession')({
                products: Object.values(cart).filter((m) => m.paymentProvider == 'stripe').map((object) => ({
                    name: object.title.substring(0, 22),
                    // description: object.description.substring(0, 127),
                    default_price_data: {
                        currency: 'usd',
                        unit_amount: Math.ceil(parseFloat(object.price) * 100),
                    },
                    expand: ['default_price'],
                    metadata: { uuid: object.uuid, accountId: accountIds[object.user] },
                })),
                metadata: {
                    purchases: JSON.stringify(Object.values(cart).filter((m) => m.paymentProvider == 'stripe').map((model) => model.uuid)),
                    user: user.uid,
                },
                provider: 'stripe',
                // transferGroup,
                successUrl: `${window.location.origin}/${Object.values(cart).filter((m) => m.paymentProvider == 'paypal').length ? 'cart' : 'purchases'}?token=${token}`,
                cancelUrl: `${window.location.origin}/cart`,
            });

            window.location.href = data?.url;
        }
        finally {
            setCheckingOut(false);
        }
    };

    const handleCreateOrder = async () => {
        const merchantIds = await Promise.all(_.uniq(Object.values(cart).filter((m) => m.paymentProvider == 'paypal').map((m) => m.user)).map((uid) =>
            get(databaseRef(database, `users/${uid}/seller/paypal/merchantId`)).then((snapshot) => [uid, snapshot.val()])
        ))
            .then((entries) => Object.fromEntries(entries));

        const { data } = await httpsCallable('createCheckoutSession')({
            products: Object.values(cart).filter((m) => m.paymentProvider == 'paypal').map((object) => ({
                reference_id: object.uuid,
                amount: {
                    name: object.title.substring(0, 22),
                    description: object.description.substring(0, 127),
                    currency_code: 'USD',
                    value: Math.ceil(parseFloat(multiply(1.1, object.price)) * 100) / 100,
                },
                payee: { merchant_id: merchantIds[object.user] },
                payment_instruction: {
                    platform_fees: [{
                        amount: {
                            currency_code: 'USD',
                            value: Math.ceil(parseFloat(multiply(.1, object.price)) * 100) / 100,
                        },
                    }],
                },
            })),
            provider: 'paypal',
        });

        return data.orderId;
    };

    const handleDownload = async (provider = 'paypal') => {
        const timestamp = Date.now();

        const { data: { token } } = await httpsCallable('encrypt')({
            public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
            payload: { purchases: Object.values(cart).filter((m) => m.paymentProvider == provider).map((model) => model.uuid), timestamp },
        }).catch((e) => ({ data: {} }));

        await Promise.all(Object.values(cart).map((model) => Promise.all([
            set(databaseRef(database, `users/${user.uid}/buyer/purchases/${model.uuid}/purchasedOn`), timestamp),
            set(databaseRef(database, `users/${user.uid}/buyer/cart/${model.uuid}`), null),
        ])));

        navigate(`/${Object.values(cart).filter((m) => m.paymentProvider != provider).length ? 'cart' : 'purchases'}?token=${token}`);
    };

    const handleOnApprove = async ({ orderID }, actions) => {
        const { data } = await httpsCallable('createCheckoutSession')({
            orderId: orderID,
        });

        if (data.error === 'INSTRUMENT_DECLINED') return actions.restart();

        if (data.details?.[0]) return alert(data.details[0].description);

        if (data.status == 'COMPLETED') await handleDownload('paypal');
    };

    return (
        <div className="container flex-1 flex flex-col overflow-y-auto pb-20">
            <div className="flex items-center h-14">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <div className="flex items-center gap-2 text-foreground">
                                <ShoppingCart className="text-foreground w-[18px] h-[18px]" />Cart
                            </div>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <div className="flex items-center gap-2">
                                <CreditCard className="w-[18px] h-[18px]" />Payment
                            </div>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <div className="flex items-center gap-2">
                                <Download className="w-[18px] h-[18px]" />Download
                            </div>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>
            <div className="flex-1 w-full flex md:flex-row flex-col gap-8 overflow-y-auto size-fit">
                <div className="flex flex-1 flex-col space-y-2 h-full">
                    {!!Object.values(cart).filter((m) => m.paymentProvider == 'stripe').length && <div className="flex flex-1 flex-col border rounded-lg h-full overflow-y-auto">
                        <div
                            className={cn(
                                "px-6 pt-6",
                                Object.keys(cart).length ? "" : "pb-6",
                            )}>
                            <h2 className="text-md font-medium">Cart (Stripe)</h2>
                        </div>
                        <div className="flex flex-1 flex-col">
                            {Object.values(cart).filter((m) => m.paymentProvider == 'stripe').map((model, i) => <div key={model.uuid} className={cn(
                                "flex flex-row justify-between space-x-6 p-6",
                                i < Object.values(cart).length - 1 ? "border-b" : "",
                            )}>
                                <div className="w-32 h-32">
                                    <div className="max-w-full bg-slate-400 aspect-square">
                                        <img
                                            src={model.thumbnail}
                                            alt={model.title}
                                            className="w-full aspect-square object-cover" />
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="space-y-1">
                                        <h2 className="text-md font-medium">{model.title}</h2>
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium">by&nbsp;</p>
                                            <Button variant="ghost" className="flex items-center space-x-1 p-0 pr-2 rounded-full" onClick={() => handleWebsiteClick(model)}>
                                                <Avatar className="h-8 w-8">
                                                    <AvatarImage
                                                        src={images[model.user]?.url}
                                                        alt={websites[model.user] ? `@${websites[model.user].username}` : null}
                                                        className="object-cover" />
                                                    <AvatarFallback></AvatarFallback>
                                                </Avatar>
                                                <p className="text-sm font-medium">{websites[model.user] ? `@${websites[model.user].username}` : null}</p>
                                            </Button>
                                        </div>
                                        <p className="text-sm font-medium">{model.type}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between items-end">
                                    <p className="text-md font-medium">{`$${parseFloat(model.price).toFixed(2)}`}</p>
                                    <Button variant="link" onClick={() => handleRemoveClick(model)}
                                        className="text-sm font-medium text-muted-foreground h-auto p-0">
                                        Remove
                                    </Button>
                                </div>
                            </div>)}
                        </div>
                    </div>}
                    {!!Object.values(cart).filter((m) => m.paymentProvider == 'paypal').length && <div className="flex flex-1 flex-col border rounded-lg h-full overflow-y-auto">
                        <div
                            className={cn(
                                "px-6 pt-6",
                                Object.keys(cart).length ? "" : "pb-6",
                            )}>
                            <h2 className="text-md font-medium">Cart (PayPal)</h2>
                        </div>
                        <div className="flex flex-1 flex-col">
                            {Object.values(cart).filter((m) => m.paymentProvider == 'paypal').map((model, i) => <div key={model.uuid} className={cn(
                                "flex flex-row justify-between space-x-6 p-6",
                                i < Object.values(cart).length - 1 ? "border-b" : "",
                            )}>
                                <div className="w-32 h-32">
                                    <div className="max-w-full bg-slate-400 aspect-square">
                                        <img
                                            src={model.thumbnail}
                                            alt={model.title}
                                            className="w-full aspect-square object-cover" />
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="space-y-1">
                                        <h2 className="text-md font-medium">{model.title}</h2>
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium">by&nbsp;</p>
                                            <Button variant="ghost" className="flex items-center space-x-1 p-0 pr-2 rounded-full" onClick={() => handleWebsiteClick(model)}>
                                                <Avatar className="h-8 w-8">
                                                    <AvatarImage
                                                        src={images[model.user]?.url}
                                                        alt={websites[model.user] ? `@${websites[model.user].username}` : null}
                                                        className="object-cover" />
                                                    <AvatarFallback></AvatarFallback>
                                                </Avatar>
                                                <p className="text-sm font-medium">{websites[model.user] ? `@${websites[model.user].username}` : null}</p>
                                            </Button>
                                        </div>
                                        <p className="text-sm font-medium">{model.type}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between items-end">
                                    <p className="text-md font-medium">{`$${parseFloat(model.price).toFixed(2)}`}</p>
                                    <Button variant="link" onClick={() => handleRemoveClick(model)}
                                        className="text-sm font-medium text-muted-foreground h-auto p-0">
                                        Remove
                                    </Button>
                                </div>
                            </div>)}
                        </div>
                    </div>}
                </div>
                <div className="flex flex-col md:w-1/4 space-y-2 h-full min-w-64">
                    {!!Object.values(cart).filter((m) => m.paymentProvider == 'stripe').length && <div className="flex flex-col border rounded-lg h-full overflow-y-auto">
                        <div className="flex flex-col border-b gap-6 p-6">
                            <h2 className="text-md font-medium">Order Summary (Stripe)</h2>
                            <div className="space-y-3">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-medium text-muted-foreground">Subtotal</h2>
                                    <p className="text-md font-medium">{`$${parseFloat(subtotalStripe).toFixed(2)}`}</p>
                                </div>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-medium text-muted-foreground">Processing fee</h2>
                                    <p className="text-md font-medium">{`$${parseFloat(.1 * subtotalStripe).toFixed(2)}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col p-6 gap-6">
                            <div className="flex items-center justify-between">
                                <h2 className="text-md font-medium">Total</h2>
                                <p className="text-md font-medium">{`$${parseFloat(1.1 * subtotalStripe).toFixed(2)}`}</p>
                            </div>
                            <Button disabled={checkingOut} className="w-full" onClick={handleCreateCheckoutSession}>
                                {checkingOut ? <LoaderCircle className="animate-spin w-6 h-6" /> : 'Checkout'}
                            </Button>
                        </div>
                    </div>}
                    {!!Object.values(cart).filter((m) => m.paymentProvider == 'paypal').length && <div className="flex flex-col border rounded-lg h-full overflow-y-auto">
                        <div className="flex flex-col border-b gap-6 p-6">
                            <h2 className="text-md font-medium">Order Summary (PayPal)</h2>
                            <div className="space-y-3">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-medium text-muted-foreground">Subtotal</h2>
                                    <p className="text-md font-medium">{`$${parseFloat(subtotalPayPal).toFixed(2)}`}</p>
                                </div>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-medium text-muted-foreground">Processing fee</h2>
                                    <p className="text-md font-medium">{`$${parseFloat(.1 * subtotalPayPal).toFixed(2)}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col p-6 gap-6">
                            <div className="flex items-center justify-between">
                                <h2 className="text-md font-medium">Total</h2>
                                <p className="text-md font-medium">{`$${parseFloat(1.1 * subtotalPayPal).toFixed(2)}`}</p>
                            </div>
                            <PayPalScriptProvider options={{ clientId: payPalClientId, intent: 'capture' }}>
                                <PayPalButtons
                                    style={{ layout: 'vertical' }}
                                    forceReRender={[cart]}
                                    onError={(e) => alert(e)}
                                    createOrder={handleCreateOrder}
                                    onApprove={handleOnApprove}
                                />
                            </PayPalScriptProvider>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}