import React from 'react';
import { cn } from "@/lib/utils"

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import Models from "@/components/products/models"

export function ProductTabs({
    tab: [tab, setTab] = [{}, () => { }],
    tabs,
    objects,
    ...props
}) {

    const {
        category: [category] = [false],
        fileType: [fileType] = [false],
        free: [free] = [false],
    } = props;
 
    const onValueChange = (value) => setTab(tabs.find((t) => t.value == value));

    const filteredObjects = objects
        ?.filter((o) => o.id == tab.value)
        .filter((o) => category ? o.category == category : true)
        .filter((o) => fileType ? o.fileType == fileType : true)
        .filter((o) => free ? o.price == 0 : true);

    return (
        <Tabs value={tab.value} onValueChange={onValueChange}>
            {tabs?.length ? (
                <TabsList className="w-full justify-start rounded-none border-b bg-transparent p-0">
                    {tabs?.map((tab) => (
                        <TabsTrigger key={tab.value} value={tab.value}
                            className="relative rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none"
                        >
                            {tab.title}
                        </TabsTrigger>
                    ))}
                </TabsList>
            ) : null}
            {tabs?.map((tab) =>
                <TabsContent key={tab.value} value={tab.value}>
                    <Models objects={filteredObjects} {...props} />
                </TabsContent>
            )}
        </Tabs>
    );
}