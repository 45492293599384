"use client"

import * as React from "react"
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"

import { DataTablePagination } from "../data-table-pagination"
import { DataTableToolbarPurchase } from "./data-table-toolbar-purchase"

import { getUser } from '@/modules/redux/user';

function useSkipper() {
  const shouldSkipRef = React.useRef(true)
  const shouldSkip = shouldSkipRef.current

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  React.useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}

export function DataTablePurchase({
  columns,
}) {
  const navigate = useNavigate();

  const { buyer: { purchases } = {} } = useSelector(getUser);

  const handleClickTableRow = () => {
    navigate('/projects2');
  };

  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] =
    React.useState({})
  const [columnFilters, setColumnFilters] = React.useState(
    []
  )
  const [sorting, setSorting] = React.useState([])

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(
      Object.values(purchases || {}).map((object) => ({
        uuid: object.uuid,
        image: object.thumbnail,
        name: object.title || '',
        price: `$${parseFloat(1.1 * object.price).toFixed(2)}`,
        type: object.type || '',
        date: new Date(object.purchasedOn).toDateString(),
        download: object.download,
      })));
  }, [purchases]);

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

  const handleDownload = async (rowIndex) => {
    const object = purchases[data[rowIndex].uuid];

    let blob;
    if (!object.zipped) {
      blob = await fetch(object.download).then((res) => res.blob());
    }
    else {
      blob = await new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(object.download, (err, data) => err ? reject(err) : resolve(data));
      })
        .then((data) => JSZip.loadAsync(data))
        .then((data) => Object.values(data.files)[0].async('arraybuffer'))
        .then((buffer) => new Blob([buffer]));
    }

    const zip = new JSZip();
    const folder = zip.folder(object.title);
    folder.file(object.name, blob);
    blob = await zip.generateAsync({ type: 'blob' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = object.title;
    link.click();

    URL.revokeObjectURL(url);
    link.remove();
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    handleDownload,
  })

  return (
    <div className="space-y-4">
      <DataTableToolbarPurchase table={table} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
