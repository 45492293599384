import React from 'react';
import { useSelector } from 'react-redux';
import {  useLocation, useNavigate } from 'react-router-dom';
import { useDatabase, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import { getDownloadURL } from 'firebase/storage';
import {
    ArrowLeftIcon,
    DownloadIcon,
    CirclePlusIcon,
} from "lucide-react"
import { useDebouncedCallback } from 'use-debounce';
import JSZip from 'jszip';
import prettyBytes from 'pretty-bytes';
import short from 'short-uuid';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"

import { ProjectList } from "@/components/projects/project-list"
import { ProjectShare } from "@/components/projects/project-share"
import { ProjectSettingDlg } from "@/components/projects/project-setting-dlg";
import { UploadModelsDlg } from '@/components/upload-models-dlg';

import { set, update, increment } from '@/modules/firebase/database';
import { httpsCallable } from '@/modules/firebase/functions';
import { getData } from '@/modules/redux/threejs';
import { getUser } from '@/modules/redux/user';
import { uuid } from '@/modules/utils';

export default function Projects({
    models = [],
    upload = () => { },
    ...props
}) {
  
    const { project = [{}] } = props;
    const [{
        uuid: projectUuid,
        creator = {},
        downloadable = false,
        editors = {},
        name,
        redirect,
    }] = project;

    const database = useDatabase();

    const { data: user } = useUser();

    const {
        projects = {},
        projectsLimit = 0,
        fileSpaceLimit = 0,
        fileSpaceUsed = Infinity,
    } = useSelector(getUser);

    const { state } = useLocation();
    const  { back = '' } = state || {};

    const navigate = useNavigate();

    const handleClickBack = () => navigate(`${back}/projects`);

    const handleDownload = async () => {
        const zip = new JSZip();
        const _models = zip.folder(project.name || 'Project Name');

        for (const object of models) {
            let blob;
            if (!object.type != 'zip') {
              blob = await fetch(object.download).then((res) => res.blob());
            }
            else {
              blob = await new Promise((resolve, reject) => {
                JSZipUtils.getBinaryContent(object.download, (err, data) => err ? reject(err) : resolve(data));
              })
                .then((data) => JSZip.loadAsync(data))
                .then((data) => Object.values(data.files)[0].async('arraybuffer'))
                .then((buffer) => new Blob([buffer]));
            }
      
            _models.file(object.name, blob);
          }

        const blob = await zip.generateAsync({ type: 'blob' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${project.name || 'Project Name'}.zip`;
        link.click();

        URL.revokeObjectURL(url);
        link.remove();
    };

    const nameForm = useForm();

    React.useEffect(() => { nameForm.setValue('name', name) }, [name]);

    const handleNameChangeDebounce = useDebouncedCallback((name) => {
        set(databaseRef(database, `projects/${projectUuid}/name`), name);
    }, 500);

    const handleNameChange = (e) => {
        handleNameChangeDebounce.cancel();
        handleNameChangeDebounce(e.target.value);
    };

    const [uploading, setUploading] = React.useState(false);

    const handleAddFilesClick = fileSpaceUsed >= fileSpaceLimit || Object.keys(projects).length >= projectsLimit ?
        () => navigate('/pricing') : undefined;

    const handleFilesUpload = async (files) => {
        if (files.length < 1) return;

        upload([...files], async (_objects) => {
            try {
                const timestamp = Date.now();

                const object = null;

                await Promise.all(_objects.map((_object) => (object ? Promise.resolve({}) : httpsCallable('encrypt')({
                    public_key: process.env.REACT_APP_SERVER_PUBLIC_KEY,
                    payload: {
                        ...{ object: _object.uuid, project: projectUuid, timestamp },
                        // room: myroom,
                    },
                })).then(({ data }) => {

                    const lights = {
                        [uuid()]: { type: 'AmbientLight', color: '#ffffff', intensity: _object.originalType == 'fbx' ? .6 : .3 },
                        [uuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: _object.dimensions / 2, z: 0 } },
                        [uuid()]: { type: 'DirectionalLight', color: '#ffffff', intensity: 2.5, position: { x: 0, y: -_object.dimensions / 2, z: 0 } },
                    };
                    if (_object.includeDefaultEnvMap) lights.Default = { type: 'Environment', uuid: 'Default' };

                    const redirect = short.generate();
                    return Promise.all([
                        update(databaseRef(database, `objects/${_object.uuid}`), {
                            token: object ? null : data.token,
                            redirect: object ? null : redirect,
                            lights,
                            needsUpdate: false,
                            name: _object.name,
                            size: _object.size,
                            polycount: _object.polycount || 0,
                            vertices: _object.vertices || 0,
                            hasAnimations: !!_object.hasAnimations,
                            autoplay: false,
                        }),
                        object ?
                            update(databaseRef(database, `objects/${object.root}`), {
                                versions: object.versions.concat([_object.uuid]),
                            }) :
                            set(databaseRef(database, `redirects/${redirect}`), `/viewer?token=${data.token}`),
                    ]);
                })));

                await Promise.all([
                    object ? Promise.resolve() : update(databaseRef(database, `projects/${projectUuid}/objects`), Object.fromEntries(_objects.map((object) => [object.uuid, object.metadata.name]))),
                    set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(_objects.reduce((sum, object) => sum + object.size || 0, 0))),
                ]);


                setUploading(false);
            } catch (e) { console.log(e) }
        });
    };

    return (
        <div className="w-full min-h-screen overflow-y-auto">
            <div className="w-full relative mr-auto md:px-28 px-4">
                <div className="flex h-20 items-center justify-between py-6">
                    <div className="flex flex-1 gap-4 items-center">
                        <Button variant="outline" size="icon" className="p-2"
                            onClick={handleClickBack}
                        >
                            <ArrowLeftIcon className="w-[18px] h-[18px]" />
                        </Button>
                        {Object.keys(projects).includes(projectUuid) && <ProjectSettingDlg project={project} />}
                        {downloadable && <Button variant="outline" onClick={handleDownload}>
                            <DownloadIcon className="mr-2 w-[18px] h-[18px]" />Download
                        </Button>}
                    </div>
                    <nav className="flex items-center gap-4">
                        <ProjectShare redirect={redirect} />
                        {(Object.keys(projects).includes(projectUuid) || editors[user?.uid]) && <UploadModelsDlg
                            multiple
                            open={[uploading, setUploading]}
                            onFilesUpload={handleFilesUpload}
                            {...props}>
                            <Button onClick={handleAddFilesClick}>
                                <CirclePlusIcon className="mr-2 w-[18px] h-[18px]" />Add files
                            </Button>
                        </UploadModelsDlg>}
                    </nav>
                </div>
                <div className="flex flex-col justify-center items-center gap-4 text-center h-40">
                    <Form {...nameForm}>
                        <form onChange={handleNameChange} onSubmit={(e) => e.preventDefault()}>
                            <FormField
                                control={nameForm.control}
                                name="name"
                                defaultValue=""
                                render={({ field }) => (
                                    <Input
                                        id="name"
                                        disabled={!Object.keys(projects).includes(projectUuid) && !editors[user?.uid]}
                                        placeholder="Project Name"
                                        className="text-xl font-bold tracking-tight max-w-xs text-center"
                                        {...field}
                                    />
                                )} />
                        </form>
                    </Form>
                    <p className="text-md text-muted-foreground">
                        {`${models.length} item${models.length != 1 ? 's' : ''}, 
                        ${prettyBytes(models.reduce((sum, object) => sum + object.size, 0))}`}
                    </p>
                </div>
                <ProjectList models={models} {...props} />
            </div>
        </div>
    );
}
