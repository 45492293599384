import React from 'react';
import { useSigninCheck } from 'reactfire';

import { SigninDlg } from '@/components/signin-dlg';

import { cn } from "@/lib/utils"

import {
    MenuIcon
} from "lucide-react"
import { Button } from "@/components/ui/button"
import MobileNav from "./landing-mobile-nav";

export default function Header({ ...props }) {
    const {
        onLoginSuccess = () => { },
        onSellerInitiated = () => { },
    } = props;

    const { data: signInCheckResult } = useSigninCheck();

    return (
        <div className="flex h-14 items-center justify-between py-4">
            <div className="flex flex-1 gap-4 md:gap-10 items-center">
                <a href="/">
                    <picture>
                        <source media="(min-width:768px)" srcSet="/images/logo.png" />
                        <img src="/images/logo_small.png" alt="modelsend"
                            className={cn("bg-white object-cover",
                                "md:min-w-[128px] w-[26px]"
                            )}
                        />
                    </picture>
                </a>
            </div>
            <nav className="flex items-center">
                <div className="hidden md:flex items-center gap-8">
                    <a href="/blog">
                        <p className="text-sm font-medium">Blog</p>
                    </a>
                    <a href="/pricing">
                        <p className="text-sm font-medium">Pricing</p>
                    </a>
                    <div className="flex items-center gap-4">
                        {signInCheckResult.signedIn ?
                            <Button variant="outline" onClick={onSellerInitiated}>Start selling</Button> :
                            <SigninDlg onLoginSuccess={onLoginSuccess}>
                                <Button variant="outline">Log in</Button>
                            </SigninDlg>}
                    </div>
                </div>
                <div className="flex md:hidden">
                    <MobileNav {...props} />
                </div>
            </nav>
        </div>
    );
}