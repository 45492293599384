"use client"

import React from 'react';
import { useDatabase, useUser } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { ref as databaseRef, get, getDatabase } from 'firebase/database';
import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
} from "@/components/ui/toast"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "@/components/ui/use-toast"

import routes from '@/config/routes';
import { set, update } from '@/modules/firebase/database';

const socialMediaItems = [
  {
    key: 'facebook',
    title: 'Facebook',
    domain: 'facebook.com',
  },
  {
    key: 'instagram',
    title: 'Instagram',
    domain: 'instagram.com',
  },
  {
    key: 'twitter',
    title: 'Twitter',
    domain: 'twitter.com',
  }
];

const profileFormSchema = z.object({
  username: z
    .string()
    .min(1, {
      message: "Username is required.",
    })
    .max(124, {
      message: "Username must no be longer than 124 characters.",
    })
    .refine((username) => !['.', '#', '$', '[', ']', '/', '\\', ' '].some((c) => username.includes(c)), {
      message: `Username contains invalid characters (".", "#", "$", "[", "]", "/", "\", " ").`,
    })
    .refine(async (username) => !routes.includes(username), {
      message: "Username has been taken already.",
    })
    .refine(async (username) => await get(databaseRef(getDatabase(), `websites/${username.toLowerCase()}`))
      .then((s) => !s.val() || s.val() == getAuth().currentUser?.uid), {
      message: "Username has been taken already.",
    }),
  email: z
    .string()
    .email({ message: "Email is invalid." })
    .or(z.string().max(0)),
  bio: z.string().max(160, {
    message: "Bio must be no longer than 160 characters.",
  }),
  urls: z
    .tuple(socialMediaItems.map((i) =>
      z.object({
        value: z.string()
          .includes(i.domain, { message: `Please enter a valid ${i.title} URL.` })
          .url({ message: `Please enter a valid ${i.title} URL.` })
          .or(z.string().max(0)),
      })
    ).concat([
      z.object({
        value: z.string()
          .url({ message: "Please enter a valid URL." })
          .or(z.string().max(0)),
      })
    ])),
})

export function ProfileForm({ website: [website, fetchWebsite] = [{}, () => { }] }) {

  const {
    username = '',
    email = '',
    bio = '',
  } = website || {};

  const urls = socialMediaItems
    .map((i) => ({ id: i.key, value: website?.[i.key] || '', placeholder: i.title }))
    .concat([{ id: 'other', value: website?.website || '', placeholder: 'Other url' }]);

  const database = useDatabase();

  const { data: user } = useUser();

  const form = useForm({
    resolver: zodResolver(profileFormSchema),
    mode: "onSubmit",
  });

  React.useEffect(() => {
    if (!website) return;

    form.setValue('username', website.username);
    form.setValue('email', website.email);
    form.setValue('bio', website.bio);
    form.setValue('urls', [
      { value: website.facebook },
      { value: website.instagram },
      { value: website.twitter },
      { value: website.website },
    ]);
  }, [website]);

  const [updated, setUpdated] = React.useState(false);

  React.useEffect(() => { if (updated) setTimeout(() => setUpdated(false), 1000) }, [updated]);

  const handleSubmit = async ({
    username: newUsername,
    email,
    bio,
    urls: [
      { value: facebook },
      { value: instagram },
      { value: twitter },
      { value: website },
    ],
  }) => {
    if (updated) return;

    await Promise.all([
      update(databaseRef(database, `users/${user.uid}/website`), {
        username: newUsername,
        email,
        bio,
        facebook,
        instagram,
        twitter,
        website,
      }),
      set(databaseRef(database, `websites/${newUsername.toLowerCase()}`), user.uid),
      username.toLowerCase() != newUsername.toLowerCase() ? set(databaseRef(database, `websites/${username.toLowerCase()}`), null) : Promise.resolve(),
    ]);

    fetchWebsite();

    setUpdated(true);
  };

  return (<>
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="username"
          defaultValue={username}
          render={({ field, ...f }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="Username123" {...field} />
              </FormControl>
              <FormDescription>
                This is your public display name. It can be your real name or a
                pseudonym.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          defaultValue={email}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Select a email to display" {...field} />
              </FormControl>
              <FormDescription>
                You can manage verified email addresses in your email settings.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bio"
          defaultValue={bio}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Tell us a little bit about yourself"
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                You can write a short bio to introduce yourself.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          {urls.map((_field, index) => (
            <FormField
              control={form.control}
              key={_field.id}
              name={`urls.${index}.value`}
              defaultValue={_field.value}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={cn(index !== 0 && "sr-only")}>
                    URLs
                  </FormLabel>
                  <FormDescription className={cn(index !== 0 && "sr-only")}>
                    Add links to your website, blog, or social media profiles.
                  </FormDescription>
                  <FormControl>
                    <Input placeholder={_field.placeholder} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
        </div>
        <Button type="submit" style={{ width: 138.47 }}>{updated ? 'Profile updated!' : 'Update profile'}</Button>
      </form>
    </Form>
  </>)
}
