import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDatabase, useStorage, useUser } from 'reactfire';
import {
    ref as databaseRef,
} from 'firebase/database';
import {
    ref as storageRef,
    uploadBytes,
    deleteObject,
    getMetadata,
} from 'firebase/storage';
import {
    EyeIcon,
    EyeOffIcon,
    PlusIcon,
    XIcon,
    SunIcon,
    EarthIcon,
    PointerIcon,
    EllipsisVerticalIcon,
    RulerIcon,
    TriangleRightIcon,
    ConeIcon,
    BlendIcon,
} from "lucide-react"
import {
    Color,
} from 'three';
import { useDebouncedCallback } from 'use-debounce';

import { Slider } from "@/components/ui/slider"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectGroup,
    SelectValue,
} from "@/components/ui/select"
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { ColorPickerPopover } from '@/components/color-picker-popover';

import { set, increment, update } from 'modules/firebase/database';
import { getVersion } from 'modules/redux/storage';
import {
    getLights,
    updateLight,
    addLight,
    removeLight,
    getEnvironments,
    getIncludeDefaultEnvMap,
    getShowBackground,
    toggleBackground,
    loadEnvironment,
    setLight,
} from 'modules/redux/threejs';
import { uuid } from 'modules/utils';

const lightProps = {
    'AmbientLight': { key: 'ambient', label: 'Ambient' },
    'DirectionalLight': { key: 'directional', label: 'Directional' },
    'Environment': { key: 'environment', label: 'Environment' },
    'SpotLight': { key: 'spot', label: 'Spot' },
    'PointLight': { key: 'point', label: 'Point' },
    'HemisphereLight': { key: 'hemisphere', label: 'Hemisphere' },
};

const Light = ({ light, editable }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState();

    const handlePropertyClick = (key, open = true) => setOpen(open ? key : undefined);

    const handlePropertyChange = (updates) => dispatch(updateLight({ light, updates }));

    const handlePositionHighlightClick = () => {
        dispatch(setLight(light));
        setOpen();
    };

    const handleDeleteClick = () => dispatch(removeLight(light));

    const id = lightProps[light.type].key;
    const label = lightProps[light.type].label;

    if (light.type == 'Environment') return null;

    return (
        <div className="flex items-center justify-between space-x-2">
            <p className="text-sm leading-none w-1/3">
                {label}
            </p>
            <div className="flex flex-1 space-x-2">
                <ColorPickerPopover
                    color={open == 'color' && light.color.getHexString()}
                    onOpenChange={(open) => handlePropertyClick('color', open)}
                    onChange={({ hex }) => handlePropertyChange({ color: new Color(hex) })}>
                    <Button
                        size="icon"
                        className="h-7 w-7 rounded-sm"
                        style={{ backgroundColor: `#${light.color.getHexString()}` }} />
                </ColorPickerPopover>
                <Popover
                    open={open == 'intensity'}
                    onOpenChange={(open) => handlePropertyClick('intensity', open)}>
                    <PopoverTrigger asChild>
                        <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm">
                            <SunIcon className="h-4 w-4 text-muted-foreground" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-80 p-5">
                        <Slider
                            value={[open == 'intensity' && light.intensity]}
                            onValueChange={(value) => handlePropertyChange({ intensity: value })}
                            max={50} step={0.5}
                        />
                    </PopoverContent>
                </Popover>
                {id != 'ambient' && <Popover
                    open={open == 'position'}
                    onOpenChange={(open) => handlePropertyClick('position', open)}>
                    <PopoverTrigger asChild>
                        <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm">
                            <EarthIcon className="h-4 w-4 text-muted-foreground" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="p-2">
                        <div className="flex items-center gap-4 w-auto">
                            <div className="flex items-center space-x-2">
                                <p className="text-base">x</p>
                                <Input
                                    type="number"
                                    value={light.position.x}
                                    onChange={(e) => handlePropertyChange({ position: { x: e.target.value, y: light.position.y, z: light.position.z } })}
                                    className="h-9 w-12 text-center"
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <p className="text-base">y</p>
                                <Input
                                    type="number"
                                    value={light.position.y}
                                    onChange={(e) => handlePropertyChange({ position: { x: light.position.x, y: e.target.value, z: light.position.z } })}
                                    className="h-9 w-12 text-center"
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <p className="text-base">z</p>
                                <Input
                                    type="number"
                                    value={light.position.z}
                                    onChange={(e) => handlePropertyChange({ position: { x: light.position.x, y: light.position.y, z: e.target.value } })}
                                    className="h-9 w-12 text-center"
                                />
                            </div>
                            <Button size="icon" onClick={handlePositionHighlightClick}>
                                <PointerIcon className="h-4 w-4" />
                            </Button>
                        </div>
                    </PopoverContent>
                </Popover>}
                {['spot', 'point'].includes(id) && <Popover
                    open={open == 'additional'}
                    onOpenChange={(open) => handlePropertyClick('additional', open)}>
                    <PopoverTrigger asChild>
                        <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm">
                            <EllipsisVerticalIcon className="h-4 w-4 text-muted-foreground" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-2">
                        <div className="flex items-center gap-4 w-auto">
                            {['spot', 'point'].includes(id) && <div className="flex items-center space-x-2">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <RulerIcon title="distance" className="h-4 w-4 text-muted-foreground" />
                                    </TooltipTrigger>
                                    <TooltipContent>Distance</TooltipContent>
                                </Tooltip>
                                <Input
                                    type="number"
                                    value={light.distance}
                                    onChange={(e) => handlePropertyChange({ distance: e.target.value })}
                                    className="h-9 w-12 text-center"
                                />
                            </div>}
                            {id == 'spot' && <div className="flex items-center space-x-2">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <TriangleRightIcon type="angle" className="h-4 w-4 text-muted-foreground" />
                                    </TooltipTrigger>
                                    <TooltipContent>Angle</TooltipContent>
                                </Tooltip>
                                <Input
                                    type="number"
                                    value={light.userData.angle}
                                    onChange={(e) => handlePropertyChange({ angle: e.target.value })}
                                    className="h-9 w-12 text-center"
                                />
                            </div>}
                            {['spot', 'point'].includes(id) && <div className="flex items-center space-x-2">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <ConeIcon className="h-4 w-4 text-muted-foreground" />
                                    </TooltipTrigger>
                                    <TooltipContent>Decay</TooltipContent>
                                </Tooltip>
                                <Input
                                    type="number"
                                    value={light.decay}
                                    onChange={(e) => handlePropertyChange({ decay: e.target.value })}
                                    className="h-9 w-12 text-center"
                                />
                            </div>}
                        </div>
                    </PopoverContent>
                </Popover>}
                {id == 'hemisphere' && <ColorPickerPopover
                    color={open == 'groundColor' && light.groundColor.getHexString()}
                    onOpenChange={(open) => handlePropertyClick('groundColor', open)}
                    onChange={({ hex }) => handlePropertyChange({ groundColor: new Color(hex) })}>
                    <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm"
                        style={{ backgroundColor: `#${light.groundColor.getHexString()}` }}>
                        <BlendIcon className="h-4 w-4 text-muted-foreground" />
                    </Button>
                </ColorPickerPopover>}
            </div>
            {editable && <Button size="icon" variant="ghost" className="h-7 w-7 rounded-sm" onClick={handleDeleteClick}>
                <XIcon className="w-4 h-4 text-muted-foreground" />
            </Button>}
        </div>
    );
};

export default function ProjectDetailSceneLighting({
    editable = false,
    environmentsEditable = false,
}) {
    const dispatch = useDispatch();

    const database = useDatabase();

    const storage = useStorage();

    const { data: user } = useUser();

    const lights = useSelector(getLights);
    const environments = useSelector(getEnvironments);
    const includeDefaultEnvMap = useSelector(getIncludeDefaultEnvMap);
    const showBackground = useSelector(getShowBackground);

    const version = useSelector(getVersion);

    const environment = environments?.find((_environment) => _environment.uuid == Object.entries(lights || {}).find(([_, light]) => light.type == 'Environment')?.[0]);

    const handleLightUpdate = useDebouncedCallback((newLights) => {
        const _lights = Object.fromEntries(Object.entries(newLights).map(([uuid, light]) => {
            switch (light.type) {
                case 'AmbientLight':
                    return [uuid, { type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity }];
                case 'DirectionalLight':
                    return [uuid, {
                        type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
                        position: { x: light.position.x, y: light.position.y, z: light.position.z },
                    }];
                case 'Environment':
                    return [uuid, { type: light.type, uuid: light.uuid, }];
                case 'SpotLight':
                    return [uuid, {
                        type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
                        position: { x: light.position.x, y: light.position.y, z: light.position.z },
                        distance: light.distance, angle: light.userData.angle, decay: light.decay,
                    }];
                case 'PointLight':
                    return [uuid, {
                        type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
                        position: { x: light.position.x, y: light.position.y, z: light.position.z },
                        distance: light.distance, decay: light.decay,
                    }];
                case 'HemisphereLight':
                    return [uuid, {
                        type: light.type, color: `#${light.color.getHexString()}`, intensity: light.intensity,
                        position: { x: light.position.x, y: light.position.y, z: light.position.z },
                        groundColor: `#${light.groundColor.getHexString()}`,
                    }];
                default:
                    return [uuid, {}];
            }
        }));

        set(databaseRef(database, `objects/${version}/lights`), _lights);
        set(databaseRef(database, `objects/${version}/needsUpdate`), true);
    }, 250);

    React.useEffect(() => {
        handleLightUpdate.cancel();

        if (lights) handleLightUpdate(lights);
    }, [lights]);

    const handleAddLightingSelect = (type) => {
        if (type == 'Environment') {
            document.getElementById('environment').click();
        } else {
            dispatch(addLight(type));
        }
    };

    const handleEnvironmentSelect = (uuid) => dispatch(loadEnvironment(environments.find((e) => e.uuid == uuid)));
    
    const handleEnvironmentUpload = async () => {
        if (!document.getElementById('environment').files || !document.getElementById('environment').files.length) return;

        const files = document.getElementById('environment').files;

        await Promise.all([...files].map((file, i) => new Promise(async (resolve, reject) => {
            try {
                const _uuid = uuid();
                const _file = await uploadBytes(storageRef(storage, `environments/${version}/${_uuid}/${file.name == 'Default' ? 'Default (1)' : file.name}`), file);
                if (user?.uid) {
                    const metadata = await getMetadata(_file.ref);
                    await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(metadata.size));
                }
                await set(databaseRef(database, `environments/${version}/${_uuid}`), file.name == 'Default' ? 'Default (1)' : file.name);
                if (i == 0) {
                    const updates = { [_uuid]: { uuid: _uuid, type: 'Environment' } };
                    const environment = Object.entries(lights).find(([_, light]) => light.type == 'Environment');
                    if (environment) updates[environment[0]] = null;
                    await Promise.all([
                        update(databaseRef(database, `objects/${version}/lights`), updates),
                        set(databaseRef(database, `objects/${version}/needsUpdate`), true),
                    ]);
                }
                resolve();
            } catch (e) { resolve() }
        })));

        document.getElementById('environment').value = null;
    };

    const handleShowBackgroundClick = () => dispatch(toggleBackground());

    const handleEnvironmentDeleteClick = async () => {
        if (!environment || environment?.uuid == 'Default' || !(editable || environmentsEditable)) return;
        dispatch(loadEnvironment());
        if (user?.uid) {
            const metadata = await getMetadata(storageRef(storage, `environments/${version}/${environment.uuid}/${environment.name}`));
            await set(databaseRef(database, `users/${user.uid}/fileSpaceUsed`), increment(-metadata.size));
        }
        await deleteObject(storageRef(storage, `environments/${version}/${environment.uuid}/${environment.name}`));
        await Promise.all([
            set(databaseRef(database, `environments/${version}/${environment.uuid}`), null),
            set(databaseRef(database, `objects/${version}/lights/${environment.uuid}`), null),
            set(databaseRef(database, `objects/${version}/needsUpdate`), true),
        ]);
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium">Lighting</h2>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm border-0 shadow-none">
                            <PlusIcon className="w-4 h-4 text-muted-foreground" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-80 py-4">
                        <DropdownMenuLabel className="space-y-2">
                            <h2 className="text-sm font-medium">Add lighting</h2>
                            <p className="text-sm font-normal text-muted-foreground">
                                Choose which type of lighting to add to your scene
                            </p>
                        </DropdownMenuLabel>
                        <DropdownMenuGroup className="mt-2">
                            {Object.entries(lightProps)
                                .filter(([type]) => editable || (environmentsEditable && type == 'Environment'))
                                .map(([type, light]) => <DropdownMenuItem
                                    key={type}
                                    onSelect={() => handleAddLightingSelect(type)}>
                                    {light.label}
                                </DropdownMenuItem>)}
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
                <input
                    id='environment'
                    accept='.hdr, .exr'
                    type='file'
                    multiple
                    hidden
                    onChange={handleEnvironmentUpload} />
            </div>
            <div className="grid gap-3">
                {Object.values(lights)?.map((light) =>
                    <Light key={light.uuid} light={light} editable={editable} />
                )}
                {environments?.length && <div className="flex items-center justify-between space-x-2">
                    <p className="text-sm leading-none w-1/3">
                        Environment
                    </p>
                    <div className="flex flex-1 space-x-2">
                        <Select value={environment?.uuid || false} onValueChange={(value) => handleEnvironmentSelect(value)}>
                            <SelectTrigger className="flex-1 h-7 w-2">
                                <SelectValue placeholder="All environments" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value={false}>
                                        No Environment
                                    </SelectItem>
                                    {environments
                                        .filter((environment) => includeDefaultEnvMap || environment.uuid != 'Default')
                                        .map((environment) => <SelectItem key={environment.uuid} value={environment.uuid}>
                                            {environment.name}
                                        </SelectItem>)}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <Button size="icon" variant="outline" className="h-7 w-7 rounded-sm" onClick={handleShowBackgroundClick}>
                            {showBackground ? <EyeIcon className="h-4 w-4 text-muted-foreground" /> :
                                <EyeOffIcon className="h-4 w-4 text-muted-foreground" />}
                        </Button>
                    </div>
                    {editable && <Button size="icon" variant="ghost" className="h-7 w-7 rounded-sm" onClick={handleEnvironmentDeleteClick}>
                        <XIcon className="w-4 h-4 text-muted-foreground" />
                    </Button>}
                </div>}
            </div>
        </div>
    );
}