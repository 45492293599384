import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch"

import {
    getFreeview,
    getGrid,
    getUVMap,
    getWireframe,
    toggleViewMode,
    toggleGrid,
    toggleUVMap,
    toggleWireframe,
} from '@/modules/redux/threejs';
import { getPayloadFromToken } from '@/modules/utils';
import { cn } from '@/lib/utils';

export default function ProjectDetailOptions({ align = "center", className, ...props }) {
    const dispatch = useDispatch();

    const freeview = useSelector(getFreeview);
    const grid = useSelector(getGrid);
    const uvMap = useSelector(getUVMap);
    const wireframe = useSelector(getWireframe);

    const handleWireframeChange = () => dispatch(toggleWireframe());
    const handleUVMapChange = () => dispatch(toggleUVMap());
    const handleGridChange = () => dispatch(toggleGrid());
    const handleViewModeChange = () => dispatch(toggleViewMode());

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    className={cn(
                        "w-9 h-9",
                        className,
                    )}
                    {...props}>#</Button>
            </PopoverTrigger>
            <PopoverContent align={align} className="w-96 p-6">
                <div className="grid gap-6">
                    <div className="flex justify-between space-x-2">
                        <div className="flex-1 space-x-1">
                            <h2 className="text-sm font-medium px-1">Wireframe</h2>
                            <p className="text-sm text-muted-foreground">
                                Use this to display the geometry wireframe
                            </p>
                        </div>
                        <Switch checked={wireframe} onCheckedChange={handleWireframeChange} />
                    </div>
                    {typeof uvMap == 'boolean' && <div className="flex justify-between space-x-2">
                        <div className="flex-1 space-x-1">
                            <h2 className="text-sm font-medium px-1">UV Map</h2>
                            <p className="text-sm text-muted-foreground">
                                Use this to display a UV map on the object
                            </p>
                        </div>
                        <Switch checked={uvMap} onCheckedChange={handleUVMapChange} />
                    </div>}
                    <div className="flex justify-between space-x-2">
                        <div className="flex-1 space-x-1">
                            <h2 className="text-sm font-medium px-1">Grid</h2>
                            <p className="text-sm text-muted-foreground">
                                Use this to display a grid below the object
                            </p>
                        </div>
                        <Switch checked={grid} onCheckedChange={handleGridChange} />
                    </div>
                    {!isMobile && !getPayloadFromToken().website && <div className="flex justify-between space-x-2">
                        <div className="flex-1 space-x-1">
                            <h2 className="text-sm font-medium px-1">Free camera</h2>
                            <p className="text-sm text-muted-foreground">
                                Use this to change the camera movement controls
                            </p>
                        </div>
                        <Switch checked={freeview} onCheckedChange={handleViewModeChange} />
                    </div>}
                </div>
            </PopoverContent>
        </Popover>
    );
}