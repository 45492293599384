import React from 'react';
import { useDispatch } from 'react-redux';
import { useDatabase, useSigninCheck, useUser } from 'reactfire';
import { ref as databaseRef } from 'firebase/database';
import {
    LoaderCircle,
} from "lucide-react"

import {
    useStripe,
    useElements,
    Elements,
    PaymentElement,
} from '@stripe/react-stripe-js';
import { isMobile } from 'react-device-detect';

import RootLayout from '@/app/layout/landing';
import Pricing from '@/app/pricing/pricing';

import { Button } from "@/components/ui/button"

import { update } from 'modules/firebase/database';
import { httpsCallable } from 'modules/firebase/functions';
import { fetchUser } from 'modules/redux/user';

import { stripePromise as getStripePromise } from 'config/stripe';

const stripePromise = getStripePromise();

const appearance = {
    theme: 'none',
    variables: {
        colorPrimary: 'hsl(222.2 47.4% 11.2%)',
        colorText: 'hsl(222.2 47.4% 11.2%)',
        colorBackground: 'hsl(210 40% 96.1%)',
    }
};

const CheckoutComponent = ({ pricing: [pricing, setPricing] = [{}, () => { }] }) => {
    const stripe = useStripe();
    const elements = useElements();

    const database = useDatabase();

    const { data: user } = useUser();

    const [loaderStart, setLoaderStart] = React.useState(false);

    const [error, setError] = React.useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;
        const { paymentIntent, error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/home`,
            },
            redirect: 'if_required',
        });

        if (paymentIntent?.status != 'succeeded') {
            if (error?.paymentIntent?.status == 'cancelled') return window.location = '/';

            setError(error);

            return;
        }

        await update(databaseRef(database, `users/${user.uid}`), { plan: pricing.plan, planType: pricing.planType });

        window.location = '/';
    };
    
    if (!stripe || !elements) return;

    return (
        <div className="w-full h-full flex justify-center items-center">
            <div className="w-96">
                <PaymentElement
                    onLoaderStart={() => setLoaderStart(true)}
                    onChange={() => setError(null)}
                />
                {loaderStart ?
                    <div className="flex flex-row justify-center items-center p-4">
                        <Button variant="ghost" onClick={() => setPricing()}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit}>
                            Submit
                        </Button>
                        {/* {error && <div className="text-destructive">{error?.message}</div>} */}
                    </div>
                    :
                    <div className="flex justify-center items-center w-full h-full">
                        <LoaderCircle className="animate-spin w-8 h-8" />
                    </div>
                }
            </div>
        </div>
    );
};

export default function PricingPage() {
    const dispatch = useDispatch();

    const database = useDatabase();

    const { data: signInCheckResult } = useSigninCheck();

    const { data: user } = useUser();

    React.useEffect(() => { dispatch(fetchUser(true)) }, [user]);

    const [pricing, setPricing] = React.useState(null);
    const [clientSecret, setClientSecret] = React.useState(null);
    const [signIn, setSignIn] = React.useState(false);

    const createPaymentIntent = async (credential) => {
        const { data: { clientSecret: _clientSecret } } = await httpsCallable('createPaymentIntent')({
            ...pricing,
            uid: credential?.user?.uid || user.uid,
        }).catch((e) => ({ data: {} }));

        setClientSecret(_clientSecret);
        setSignIn(false);
    };

    React.useEffect(() => {
        if (!pricing) return;

        if (!user) {

        }
        else if (pricing.plan != 'core') {
            createPaymentIntent();
        }
    }, [user, pricing]);


    return (
        <RootLayout>
            {(clientSecret && pricing) ?
                <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                    <CheckoutComponent pricing={[pricing, setPricing]} />
                </Elements> :
                <Pricing setPricing={setPricing} />}
        </RootLayout>
    );
}