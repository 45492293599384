import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

import { Loader } from '@/components/loader';

export function UploadModelsDlg({
  children,
  open: [open, setOpen] = [false, () => { }],
  multiple,
  onFilesUpload,
  uploading = false,
  uploadingLoaderRef,
}) {

  const handleInteractOutside = (e) => e.preventDefault();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className="max-w-3xl min-h-96" onInteractOutside={handleInteractOutside}>
        <div className="w-full h-full">
          <FileUploader
            id='file-uploader'
            multiple
            disabled={uploading}
            types={['obj', 'glb', 'fbx', '3dm', 'stl', 'ifc', 'dae', 'ply']}
            hoverTitle=' '
            handleChange={onFilesUpload}
          >
            <Button variant='ghost' disabled={uploading} className="flex flex-col justify-center items-center gap-1 text-center w-full h-full">
              {!uploading ? <>
                <h3 className="text-2xl font-bold tracking-tight">
                  {`Upload model${multiple ? 's' : ''}`}
                </h3>
                <h3 className="text-l font-bold tracking-tight">
                  or Drag and Drop
                </h3>
                <p className="text-sm text-muted-foreground">
                  Supported files: 3dm, fbx, glb, ifc, obj, stl, dae, and ply
                </p>
              </> :
                <Loader ref={uploadingLoaderRef} className="h-1.5" />
              }
            </Button>
          </FileUploader>
        </div>
      </DialogContent>
    </Dialog>
  );
}