import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDatabase, useStorage, useUser } from 'reactfire';
import { ref as databaseRef, get, onValue } from 'firebase/database';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import {
  ArrowLeftIcon,
  SettingsIcon,
  CirclePlusIcon
} from "lucide-react"
import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "@/components/ui/select"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

import ProjectDetailComments from '@/components/projects/project-detail-comments';
import ProjectDetailScene from '@/components/projects/project-detail-scene';
import ProjectDetailOptions from '@/components/projects/project-detail-options';
import ProjectDetailShare from '@/components/projects/project-detail-share';
import ProjectDetailCommentLeave from '@/components/projects/project-detail-comment-leave';
import ProjectDetailOverlay from '@/components/projects/project-detail-overlay';

import { Loader } from '@/components/loader';
import OutputScreen, { DesktopOverlayUI } from 'components/OutputScreen';
import Password from 'components/Password';
import Comments from '../Comments';
import Materials from '../Materials';

import useThreejs from 'modules/useThreejs';
import {
  fetchImages,
  fetchVersions,
  getVersion,
} from 'modules/redux/storage';
import {
  getBackgroundLoaded,
  getComments,
  getEnvironmentLoaded,
  getMapsLoaded,
  getLoadingRoom,
} from 'modules/redux/threejs';
import { getUser, fetchUser } from 'modules/redux/user';
import { getPayloadFromToken, deleteFolder } from 'modules/utils';
import { cn } from '@/lib/utils';

export default function ViewerPage() {
  const dispatch = useDispatch();

  const database = useDatabase();

  const { data: user } = useUser();

  const userData = useSelector(getUser);

  const backgroundLoaded = useSelector(getBackgroundLoaded);
  const comments = useSelector(getComments);
  const loadingRoom = useSelector(getLoadingRoom);
  const environmentLoaded = useSelector(getEnvironmentLoaded);
  const mapsLoaded = useSelector(getMapsLoaded);

  const { loaderRef } = useThreejs();

  if (!getPayloadFromToken().object) window.location = '/';

  React.useEffect(() => {
    const tokenRef = databaseRef(database, `objects/${getPayloadFromToken().object}/token`);
    return onValue(tokenRef, async (snapshot) => {
      if (!snapshot.val()) {
        if (getPayloadFromToken().project) {
          const token = await get(databaseRef(database, `projects/${getPayloadFromToken().project}/token`)).then((snapshot) => snapshot.val());

          window.location = token ? `/project?token=${token}` : '/';
        } else {
          window.location = '/';
        }
      }
    });
  }, []);

  React.useEffect(() => { dispatch(fetchUser(true)) }, [user]);

  React.useEffect(() => { dispatch(fetchVersions()) }, []);

  React.useEffect(() => { dispatch(fetchImages()) }, [comments]);

  const editable = getPayloadFromToken().standalone || getPayloadFromToken().website ||
    Object.keys(userData?.projects || {}).includes(getPayloadFromToken().project) ||
    Object.keys(userData?.editing || {}).includes(getPayloadFromToken().project);

  const [authenticated, setAuthenticated] = React.useState(false);
  const [password, setPassword] = React.useState(null);

  const fetchPassword = async () => {
    if (!getPayloadFromToken().project || editable) return setAuthenticated(true);

    const password = await get(databaseRef(database, `projects/${getPayloadFromToken().project}/password`)).then((snapshot) => snapshot.val());

    if (!password) return setAuthenticated(true);

    setPassword(getPayloadFromToken(password).password);
  };

  React.useEffect(() => { fetchPassword() }, [userData]);

  const [{ width, height }, setDimensions] = React.useState({ width: window.innerWidth, height: window.innerHeight });

  React.useEffect(() => {
    const onResize = () => setDimensions({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const [mode, setMode] = React.useState(isMobile ? 'viewer' : getPayloadFromToken().website ? 'materials' : 'comments');

  let navigate = useNavigate();

  return (
    <div className="w-full h-screen overflow-y-auto">
      <div className="w-full flex flex-row h-full justify-between">
        <div className="flex-1">
          {(loadingRoom || !mapsLoaded || !environmentLoaded || !backgroundLoaded) &&
            <div
              className="basis-1/2 bg-gray-300 overflow-hidden rounded-l-lg absolute w-full h-full flex flex-row justify-center items-center"
              style={{ zIndex: 11 }}>
              <Loader ref={loaderRef} variant="background" className="h-1.5" />
            </div>}
          <OutputScreen renderCss2d className="w-full">
            {!loadingRoom && mapsLoaded && environmentLoaded && <ProjectDetailOverlay />}
          </OutputScreen>
        </div>
        <div className="md:flex hidden w-80 border-l px-4 h-full overflow-y-auto z-10">
          <Tabs defaultValue="comments" className="flex flex-col w-full h-full py-1">
            <TabsList className="grid w-full grid-cols-2 my-5">
              <TabsTrigger value="comments">Comments</TabsTrigger>
              <TabsTrigger value="scene">Scene</TabsTrigger>
            </TabsList>
            <TabsContent value="comments" className="flex-1 space-y-4"><ProjectDetailComments /></TabsContent>
            <TabsContent value="scene" className="flex-1"><ProjectDetailScene editable={editable} /></TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
}